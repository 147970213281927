import Heading from '../Heading';
import SubHeading from '../SubHeading';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '../../assets/icons/CloseIcon';
import {
    DownArrowIcon,
    ExploreIcon,
    FavouriteIcon,
    HomeIcon,
    LogoutIcon,
    OrganizationIcon,
    SettingsIcon,
    UpwardArrowIcon,
} from '../../assets/icons/SidebarIcons';
import { auth } from '../../firebase';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { DataContext } from '../../contexts/DataContext';
import MembersAndRolesIcon from '../../assets/icons/MembersAndRolesIcon';
import BountiesIcon from '../../assets/icons/BountiesIcon';
import ProjectsIcon from '../../assets/icons/ProjectsIcon';

import ValidationsIcon from '../../assets/icons/ValidationsIcon';
import { useWallet } from '@solana/wallet-adapter-react';
import { getOrganizationMembers } from '../../apis/oragnisations/organisation';
import { getRoles } from '../../helpers/getRoles';
import EventsIcon from '../../assets/icons/EventsIcon';
const Logo = '/assets/images/impactfoundry.png';
function Sidebar({ showSidebar, toggle }) {
    const { state: userState, dispatch } = useContext(AuthContext);
    const [orgMembers, setOrgMembers] = useState([]);
    const { state } = useContext(DataContext);
    const [active, setActive] = useState('');
    const location = useLocation();
    const { disconnect } = useWallet();
    const navigate = useNavigate();

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const response = await getOrganizationMembers(active);
                    setOrgMembers(response);
                } catch (error) {
                    console.error(error, 'erroe');
                }
            };
            if (active) fetchData();
        },
        [active]
    );
    const { favourite } = state;

    const { user } = userState;
    const userProfile = user[0];
    const userUID = userProfile?.uid;
    const handleLogout = async () => {
        try {
            await auth.signOut();
            await disconnect();

            dispatch({ type: 'logout' });
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };
    const isMemberOfOrganisation = orgMembers.length && orgMembers?.find((member) => member?.uid === userUID);

    const roles = getRoles(isMemberOfOrganisation);
    const isAdmin = roles.includes('isAdmin');
    const isBountyManager = roles.includes('isBountyManager');
    const isBountyValidator = roles.includes('isBountyValidator');
    // const isTreasurer = roles.includes('isTreasurer');

    const activeOrganisation = location?.pathname.split('/')[2] || '';
    const activeSubRoute = location?.pathname.split('/')[3] || '';

    const activeRoute = activeSubRoute || activeOrganisation ? 'no-route' : location?.pathname.split('/')[1] || '';

    return (
        <div
            className={`bg-white fixed   ${
                showSidebar ? 'left-0' : '-left-[300px]'
            } h-screen  lg:left-0  lg:relative border-r transition-all duration-75 ease-in-out overflow-auto modal   z-[100] `}
        >
            <div className="flex justify-between items-center pr-3">
                <div className="flex items-center  ">
                    <img className="w-28 h-28" alt="Atlantis logo" src={Logo} />
                    <div>
                        <Heading className="text-textBold" type="medium">
                            Impact
                        </Heading>
                        <SubHeading type="large" className={'text-[#969696]'}>
                            Foundry
                        </SubHeading>
                    </div>
                </div>
                <button className="lg:hidden border border-borderColor p-2 rounded-md" onClick={toggle}>
                    <CloseIcon />
                </button>
            </div>
            <div className="flex flex-col h-[84vh]">
                <div className="pl-8 pr-6 mb-6 mt-2 ">
                    <Link
                        className={`flex gap-2 items-center ${
                            activeRoute === '' ? 'text-foundryBlue font-semibold' : ''
                        }  `}
                        to="/"
                    >
                        <HomeIcon fillColor={activeRoute === '' ? '#0085ff' : '#585757'} />
                        <p>Home</p>
                    </Link>
                </div>
                <div className="pl-8 pr-6 mb-6">
                    <p className="text-xs">FAVORITES</p>
                    {favourite.map((org) => (
                        <div key={org.id}>
                            <div className=" flex gap-2 items-center   mt-2">
                                <FavouriteIcon fillColor={activeOrganisation === org.id ? '#0085ff' : '#585757'} />
                                <Link
                                    onClick={() => {
                                        if (active !== org.id) setActive(org.id);
                                        else setActive('');
                                    }}
                                    className={`flex gap-2 max-w-[85%] items-center   ${
                                        activeOrganisation === org.id ? 'text-foundryBlue font-semibold' : ''
                                    }   `}
                                    to={`/organizations/${org.id}`}
                                >
                                    <span className="truncate ">{org.name}</span>
                                    <span>{active !== org.id ? <DownArrowIcon /> : <UpwardArrowIcon />}</span>
                                </Link>
                            </div>
                            {org.id === active && (
                                <div className="pl-8">
                                    {isAdmin && (
                                        <div className="flex cursor-pointer  items-center my-2 gap-2">
                                            <MembersAndRolesIcon
                                                fillColor={activeSubRoute === 'view-members' ? '#0085ff' : '#585757'}
                                            />
                                            <p
                                                className={`${
                                                    activeSubRoute === 'view-members'
                                                        ? 'text-foundryBlue font-semibold'
                                                        : ''
                                                }`}
                                                onClick={() => navigate(`/organizations/${org.id}/view-members`)}
                                            >
                                                Members and Roles
                                            </p>
                                        </div>
                                    )}
                                    {isBountyManager && (
                                        <div className="flex cursor-pointer  items-center my-3 gap-2">
                                            <BountiesIcon
                                                fillColor={activeSubRoute === 'view-bounties' ? '#0085ff' : '#585757'}
                                            />
                                            <p
                                                className={`${
                                                    activeSubRoute === 'view-bounties'
                                                        ? 'text-foundryBlue font-semibold'
                                                        : ''
                                                }`}
                                                onClick={() => navigate(`/organizations/${org.id}/view-bounties`)}
                                            >
                                                Bounties
                                            </p>
                                        </div>
                                    )}
                                    {isBountyManager && (
                                        <div className="flex cursor-pointer  items-center my-3 gap-2">
                                            <ProjectsIcon
                                                fillColor={activeSubRoute === 'view-projects' ? '#0085ff' : '#585757'}
                                            />
                                            <p
                                                className={`${
                                                    activeSubRoute === 'view-projects'
                                                        ? 'text-foundryBlue font-semibold'
                                                        : ''
                                                }`}
                                                onClick={() => navigate(`/organizations/${org.id}/view-projects`)}
                                            >
                                                Projects
                                            </p>
                                        </div>
                                    )}
                                    {isBountyManager && (
                                        <div className="flex cursor-pointer  items-center my-3 gap-2">
                                            <EventsIcon
                                                fillColor={activeSubRoute === 'view-events' ? '#0085ff' : '#585757'}
                                            />
                                            <p
                                                className={`${
                                                    activeSubRoute === 'view-events'
                                                        ? 'text-foundryBlue font-semibold'
                                                        : ''
                                                }`}
                                                onClick={() => navigate(`/organizations/${org.id}/view-events`)}
                                            >
                                                Events
                                            </p>
                                        </div>
                                    )}
                                    {/* {isTreasurer && (
                                        <div className="flex cursor-pointer  items-center my-3 gap-2">
                                            <ProposalsIcon
                                                fillColor={
                                                    activeSubRoute === 'view-proposalsandrequests'
                                                        ? '#0085ff'
                                                        : '#585757'
                                                }
                                            />
                                            <p
                                                className={`${
                                                    activeSubRoute === 'view-proposalsandrequests'
                                                        ? 'text-foundryBlue font-semibold'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    navigate(`/organizations/${org.id}/view-proposalsandrequests`)
                                                }
                                            >
                                                Proposals
                                            </p>
                                        </div>
                                    )} */}
                                    {isBountyValidator && (
                                        <div className="flex cursor-pointer  items-center my-3 gap-2">
                                            <ValidationsIcon
                                                fillColor={activeSubRoute === 'validation' ? '#0085ff' : '#585757'}
                                            />
                                            <p
                                                className={`${
                                                    activeSubRoute === 'validation'
                                                        ? 'text-foundryBlue font-semibold'
                                                        : ''
                                                }`}
                                                onClick={() => navigate(`/organizations/${org.id}/validation`)}
                                            >
                                                Validation
                                            </p>
                                        </div>
                                    )}
                                    {/* <div className="flex cursor-pointer items-center my-3 gap-2">
                                        <AttestIcon />
                                        <p onClick={() => navigate(`/organizations/${org.id}/attest`)}>Attest</p>
                                    </div> */}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="pl-8 pr-6">
                    <p className="text-xs">MAIN MENU</p>
                    {/* <FavouriteIcon /> */}

                    <Link
                        className={`flex gap-2 items-center my-2 ${
                            activeRoute === 'myorganizations' ? 'text-foundryBlue font-semibold' : ''
                        }  `}
                        to="/myorganizations"
                    >
                        <div className="w-5 h-5 flex items-center">
                            <OrganizationIcon fillColor={activeRoute === 'myorganizations' ? '#0085ff' : '#585757'} />
                        </div>
                        <p>My Organisations</p>
                        {/* <DownArrowIcon /> */}
                    </Link>
                    <Link
                        className={`flex gap-2 items-center my-2 ${
                            activeRoute === 'organizations' ? 'text-foundryBlue font-semibold' : ''
                        }  `}
                        to="/organizations"
                    >
                        <div className="w-5 h-5 flex items-center">
                            <ExploreIcon fillColor={activeRoute === 'organizations' ? '#0085ff' : '#585757'} />
                        </div>
                        <p>Explore Organisations</p>
                    </Link>
                    {/* <Link className="flex gap-2 items-center my-2  " to="/">
                        <LandscapeIcon />
                        <p>Landscape</p>
                    </Link> */}
                    {/* <Link className="flex gap-2 items-center my-2  " to="/attestation">
                        <LandscapeIcon />
                        <p>Attestations</p>
                    </Link> */}
                    {/* <Link className="flex gap-2 items-center my-2  " to="/">
                        <LandscapeIcon />
                        <p>Certificates</p>
                    </Link> */}
                </div>

                <div className="pl-8 pr-6 mt-auto ">
                    <p className="text-xs">GENERAL</p>
                    <Link className="flex gap-2 items-center my-2  " to="/">
                        <div className="w-5 h-5 flex items-center">
                            <SettingsIcon />
                        </div>
                        <p>Settings</p>
                    </Link>
                    <button onClick={handleLogout} className="flex gap-2 items-center my-2  ">
                        <div className="w-5 h-5 flex items-center">
                            <LogoutIcon />
                        </div>
                        <p>Logout</p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
