import axios from 'axios';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';
import { toast } from 'sonner';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};
export const saveTransactionHashAtDb = async (data) => {
    try {
        const url = `${BASE_URL}/transactionRecord`;

        const accessToken = await getAccessToken();

        const response = await axios.post(url, data, getConfig(accessToken));

        return response.data;
    } catch (error) {
        toast.error('Sorry unable to save transaction hash.');
    }
};

export const validating = async (bountyId) => {
    try {
        const url = `${BASE_URL}/organisation/validation/finalise/${bountyId}`;

        const accessGoogle = await auth.currentUser.getIdToken(true);
        const response = await axios.patch(
            url,
            {},
            {
                headers: {
                    Authorization: `Bearer ${accessGoogle}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const validatingSingleSubmission = async (subId, payload) => {
    try {
        const url = `${BASE_URL}/organisation/validation/${subId}`;

        const accessGoogle = await auth.currentUser.getIdToken(true);
        console.log(payload);
        const response = await axios.patch(url, payload, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });
        console.log(response);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
