import { useEffect, useState } from 'react';
import DeleteIcon from '../../assets/icons/Delete';
import Button from '../../ui/Button';
import Dropdown from '../../ui/Dropdown';
import Table from '../../ui/Table';
import Checkbox from '../../ui/Checkbox';
import { containsObjectWithKeyAndString } from '../../helpers/isObjectContainsKeyAndString';
import { useOutletContext, useParams } from 'react-router-dom';
import Modal from '../../ui/Modal';
import ConfirmUpdateModal from './ConfirmUpdateModal';
import { deepEqual } from '../../helpers/deepEqual';
import { getOrganizationMembers } from '../../apis/oragnisations/organisation';
import StatsCard from '../../ui/StatsCard';
import WhiteCard from '../../ui/WhiteCard';
import NoDataAvailable from '../../ui/NoDataAvailable';
import Loader from '../../ui/Loader';
import { useLoadingState } from '../../hooks/useLoader';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import { executorToFetchThreshold } from '../../blockchainInteraction/fetchThreshold';
import DeleteProjectModal from '../projects/DeleteProjectModal';
import DeleteMemberModal from './DeleteMemberModal';
import { RPC_ENDPOINT } from '../../constants/apiPaths';

// const data = ['BOUNTY_MANAGER', 'BOUNTY_VALIDATOR', 'TREASURER'];
const data = ['BOUNTY_MANAGER', 'BOUNTY_VALIDATOR'];
function EditMembersAndRoles() {
    const [orgMembers, setOrgMembers] = useState([]);
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [treasurerThreshold, setTreasurerThreshold] = useState(0);
    const [particularOrg] = useOutletContext();
    const { id } = useParams();
    const [membersToBeEdited, setMembersToBeEdited] = useState(orgMembers);
    const [initial, setInitial] = useState([]);
    const [membersToBeRemoved, setMembersToBeRemoved] = useState([]);

    const { treasurerMultisig } = particularOrg;
    const [selected, setSelected] = useState(orgMembers);
    const [selectedRoles, setSelectedRoles] = useState([]);
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const [response1] = await Promise.all([getOrganizationMembers(id)]);

                    setOrgMembers(response1);
                    setMembersToBeEdited(response1);
                    setSelected(response1);
                    stopLoading();
                } catch (error) {
                    console.error(error, 'erroe');
                }
            };
            const onExecution = async () => {
                const connection = new Connection(RPC_ENDPOINT, 'confirmed');

                try {
                    const fetchedThreshold = await executorToFetchThreshold(treasurerMultisig, connection);

                    setTreasurerThreshold(fetchedThreshold);
                } catch (error) {
                    console.log(error);
                }
            };

            fetchData();
            if (treasurerMultisig) onExecution();
        },
        [id]
    );
    useEffect(
        function () {
            const membersWithPreviousRoles = orgMembers.map((member) => {
                let arr = [];

                if (member.isBountyManager) arr.push('BOUNTY_MANAGER');
                if (member.isBountyValidator) arr.push('BOUNTY_VALIDATOR');
                if (member.isTreasurer) arr.push('TREASURER');
                return { [member.uid]: arr };
            });

            setSelectedRoles(membersWithPreviousRoles);
            setInitial(membersWithPreviousRoles);
        },

        [orgMembers]
    );
    const initialCount = { totalMembers: orgMembers.length, bountyManagers: 0, bountyValidators: 0, treasurer: 0 };
    const membersCount = orgMembers.reduce((acc, item, index) => {
        return {
            ...acc,
            bountyManagers: item.isBountyManager ? acc.bountyManagers + 1 : acc.bountyManagers,
            bountyValidators: item.isBountyValidator ? acc.bountyValidators + 1 : acc.bountyValidators,
            treasurer: item.isTreasurer ? acc.treasurer + 1 : acc.treasurer,
        };
    }, initialCount);

    function updateValue(value, name) {
        if (value) setSelected([...selected, name]);
        else {
            setSelected(selected.filter((item) => item.uid !== name.uid));
        }
    }
    function selectAll(value) {
        if (value) setSelected(membersToBeEdited.map((member) => member));
        else setSelected([]);
    }
    function updateOptions(value, name, member) {
        // value - true means there is no role exist in selected roles for member.uid
        //value - false means there is role exist in selected roles for member.uid, means now we want to remove that role, so go to else block to remove it
        if (value) {
            const isPresent = selectedRoles.findIndex((i) => Object.keys(i).includes(member.uid));
            // if selected has member leave unchanged, if not add member into selected
            // updateValue(!isArrayContainsThis(selected, member), member);
            // add a role in existing array of roles in member.uid
            const ar = selectedRoles.map((op) => {
                if (Object.keys(op).includes(member.uid)) return { [member.uid]: [...op[member.uid], name] };
                return op;
            });

            // make a new array for roles for a member.uid
            if (isPresent < 0) setSelectedRoles([...selectedRoles, { [member.uid]: [name] }]);
            else setSelectedRoles(ar);
        }
        // to remove role from array of roles for a member.uid
        else {
            const filteredArr = selectedRoles.map((op) => {
                if (Object.keys(op).includes(member.uid))
                    return { [member.uid]: op[member.uid].filter((item) => item !== name) };
                return op;
            });

            setSelectedRoles(filteredArr);
        }
    }

    // first find selected member has assigned role (>=0) and then findout atleast one role should be assigned
    const selectedKeys = selected.map((member) => member.uid);

    // shouldEnable(selected, selectedRoles);

    const editThem = selectedRoles.filter((member) => {
        for (let [key, value] of Object.entries(member)) {
            if (selectedKeys.includes(key)) {
                const initialRole = initial.find((i) => i.hasOwnProperty(key));
                const shouldReturn = deepEqual(initialRole, member);
                const isMemberGoingToBeRemoved = membersToBeRemoved.includes(key);

                if (!shouldReturn && !isMemberGoingToBeRemoved) return member;
            }
        }
    });
    const onDeletion = (id) => {
        const filteredRemoval = membersToBeEdited.filter((item) => item.uid !== id);
        setMembersToBeEdited(filteredRemoval);
    };
    const addToRemovalList = (uid) => {
        setMembersToBeRemoved((initial) => [...initial, uid]);
    };
    const removeFromRemovalList = (uid) => {
        const filteredRemoval = membersToBeRemoved.filter((item) => item !== uid);
        setMembersToBeRemoved(filteredRemoval);
    };

    const withoutAdmin = membersToBeEdited.filter((member) => !member.isAdmin);
    const allHaveRoles =
        editThem.length > 0 &&
        editThem.every((obj) => {
            const roles = obj[Object.keys(obj)[0]];
            return roles.length > 0;
        });

    return (
        <div>
            <WhiteCard className="w-fit p-6 flex gap-6">
                <StatsCard countHeading={'Total Members'} count={membersCount.totalMembers} />
                <StatsCard countHeading={'Bounty Managers'} count={membersCount.bountyManagers} />
                <StatsCard countHeading={'Bounty Validators'} count={membersCount.bountyValidators} />
                {/* <StatsCard countHeading={'Treasurer'} count={membersCount.treasurer} /> */}
            </WhiteCard>
            {/* <WhiteCard className="flex p-6 font-semibold text-lightBlack gap-1 w-fit ">
                <p>Treasury Threshold:</p>
                <p>{treasurerThreshold}</p>
            </WhiteCard> */}

            <Table cols="grid-cols-tableInvite gap-2">
                <Table.Description
                    description="Members & Roles"
                    // secondaryBtn="Remove"
                    // clickOnSecondary={() => {
                    //     setMembersToBeEdited([]);
                    // }}
                />
                <Table.Header>
                    <Checkbox
                        name="all"
                        value={selected.length === membersToBeEdited.length}
                        update={() => selectAll(!(selected.length === membersToBeEdited.length))}
                    />
                    <p className="text-sm self-center font-semibold text-secondaryText ">Citizen ID</p>
                    <p className="text-sm self-center font-semibold text-secondaryText ">Member Username</p>
                    <p className="text-sm self-center  font-semibold text-secondaryText ">Roles</p>
                    <p className="text-sm  self-center font-semibold text-secondaryText ">Remove</p>
                </Table.Header>

                {!loading ? (
                    withoutAdmin.length > 0 ? (
                        withoutAdmin.map((member, index) => {
                            const shouldDisable = selected.map((user) => user.uid).includes(member.uid);
                            const rolesFromSelected = selectedRoles.find((r) => r.hasOwnProperty(member.uid));
                            const rolesFromInitial = initial.find((r) => r.hasOwnProperty(member.uid));
                            const isEqual = deepEqual(rolesFromInitial, rolesFromSelected);
                            const isRemoved = membersToBeRemoved.includes(member.uid);

                            return (
                                <Table.Row
                                    rowClassName={`${
                                        !isEqual && !isRemoved && shouldDisable && allHaveRoles ? 'bg-update' : ''
                                    } ${isRemoved && shouldDisable ? 'bg-remove' : ''} `}
                                    key={member.uid + index + Math.random()}
                                >
                                    <Checkbox
                                        name={member.userName}
                                        value={selected.map((user) => user.uid).includes(member.uid)}
                                        update={() => {
                                            updateValue(!selected.map((user) => user.uid).includes(member.uid), member);
                                        }}
                                    />
                                    <p className="truncate w-28 self-center  ">{member.uid}</p>
                                    <p className="self-center ">{member.userName}</p>
                                    <div className="relative">
                                        <Dropdown
                                            w="w-full"
                                            disabled={!shouldDisable}
                                            btnClass={` ${!shouldDisable ? 'cursor-not-allowed' : ''} `}
                                            data={data}
                                            selected={selectedRoles}
                                            id={member.uid}
                                            render={(listItem, index) => {
                                                return (
                                                    <Checkbox
                                                        key={listItem + index + Math.random()}
                                                        name={member.uid + listItem}
                                                        // this function checks if a particular member has assigned any role or not
                                                        value={containsObjectWithKeyAndString(
                                                            selectedRoles,
                                                            member.uid,
                                                            listItem
                                                        )}
                                                        disabling={member.isTreasurer && listItem === 'TREASURER'}
                                                        label={listItem}
                                                        update={() =>
                                                            updateOptions(
                                                                !containsObjectWithKeyAndString(
                                                                    selectedRoles,
                                                                    member.uid,
                                                                    listItem
                                                                ),
                                                                listItem,
                                                                member
                                                            )
                                                        }
                                                    />
                                                );
                                            }}
                                            withCheckBox={true}
                                        />
                                    </div>

                                    {!member.isTreasurer && (
                                        <Modal>
                                            <Modal.Button
                                                className="flex gap-2 w-full cursor-pointer items-center"
                                                opens={'deletemember'}
                                            >
                                                <button
                                                    // disabled={!shouldDisable || member.isTreasurer}
                                                    // onClick={() => {
                                                    //     isRemoved
                                                    //         ? removeFromRemovalList(member.uid)
                                                    //         : addToRemovalList(member.uid);
                                                    // }}
                                                    className={` place-self-center ${
                                                        !shouldDisable || member.isTreasurer ? 'cursor-not-allowed' : ''
                                                    }  `}
                                                >
                                                    <DeleteIcon />
                                                </button>
                                            </Modal.Button>
                                            <Modal.Window name={'deletemember'}>
                                                <DeleteMemberModal orgId={id} onDeletion={onDeletion} member={member} />
                                            </Modal.Window>
                                        </Modal>
                                    )}
                                </Table.Row>
                            );
                        })
                    ) : (
                        <NoDataAvailable />
                    )
                ) : (
                    <div className="min-h-[20vh] flex justify-center items-center">
                        <Loader />
                    </div>
                )}
            </Table>
            <div className="flex justify-end">
                {(editThem.length && allHaveRoles) || membersToBeRemoved.length ? (
                    <Modal>
                        <Modal.Button className="flex gap-2 cursor-pointer items-center" opens={'editmemberandroles'}>
                            <Button size="small">Update Roles</Button>
                        </Modal.Button>
                        <Modal.Window name={'editmemberandroles'}>
                            <ConfirmUpdateModal
                                membersGoingToBeEdited={editThem}
                                beforeEditing={initial}
                                allMembers={orgMembers}
                                membersGoingToBeRemoved={membersToBeRemoved}
                                multiSig={particularOrg?.treasurerMultisig}
                            />
                        </Modal.Window>
                    </Modal>
                ) : (
                    <Button
                        disabled={!allHaveRoles ? !allHaveRoles : editThem.length}
                        className={`${
                            allHaveRoles ? editThem.length : allHaveRoles ? '' : '!cursor-not-allowed !bg-gray-400 '
                        }`}
                        size="xsmall"
                    >
                        Update Roles
                    </Button>
                )}
            </div>
        </div>
    );
}

export default EditMembersAndRoles;
