import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import Table from '../../ui/Table';

import { useEffect, useState } from 'react';
import { getBountySubmission, remarkSubmission } from '../../apis/bounties/bounties';
import Input from '../../ui/Input';
import Button from '../../ui/Button';
import RadioButtonComponent from '../../ui/RadioButtonComponent';
import { useForm } from 'react-hook-form';
import { dmyFormat } from '../../helpers/dateInDayMonthYearFormat';
import Loader from '../../ui/Loader';
import NoDataAvailable from '../../ui/NoDataAvailable';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';
import { useLoadingState } from '../../hooks/useLoader';
import useLocalStorage from '../../hooks/useLocalStoarge';
import { formatIntDateAndThenTime } from '../../helpers/formatInDateAndThenTime';
import Heading from '../../ui/Heading';
import { toNormalRepresentation } from '../../helpers/convertIntoDecimals';
import { validatingSingleSubmission } from '../../apis/validator/validating';

function BountySubmission() {
    const { submissionId } = useParams();
    const [submission, setSubmission] = useState({});
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [isRemarking, setIsRemarking] = useState(false);
    const [bountyDetails] = useOutletContext();
    const [storedValue] = useLocalStorage('bountySubmission');
    const [searchParams] = useSearchParams();

    // Get the values of bountyTitle and projectTitle
    const bountyTitle = searchParams.get('bountyTitle');
    const projectTitle = searchParams.get('projectTitle');

    const {
        register,
        handleSubmit,

        reset,
        formState: { errors },
    } = useForm();
    const [isIssueImpact, setIsIssueImpact] = useState('Yes');

    const navigate = useNavigate();

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const data = await getBountySubmission(submissionId);

                    const resetData = data.remark
                        ? {
                              feedback: data?.remark,
                              accuracy: toNormalRepresentation(data?.accuracy * 100),
                          }
                        : {};

                    if (Object.keys(resetData).length > 0) {
                        reset(resetData);
                        if (data.issueImpactCertificate) setIsIssueImpact('Yes');
                        else setIsIssueImpact('No');
                    }
                    setSubmission(data);
                    stopLoading();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [submissionId]
    );

    const onSubmit = async (data) => {
        const payload = {
            remark: data.feedback,
            accuracy: Number(data.accuracy) / 100,
            ...(bountyDetails.hasImpactCertificate && {
                issueImpactCertificate: isIssueImpact === 'Yes' ? true : false,
            }),
            lastUpdateTime: submission.updatedAt,
        };
        setIsRemarking(true);

        if (bountyDetails?.validationType === 'AFTER_EACH_SUBMISSION') {
            const payloadEach = {
                remark: data.feedback,
                accuracy: Number(data.accuracy) / 100,
                ...(bountyDetails.hasImpactCertificate && {
                    issueImpactCertificate: isIssueImpact === 'Yes' ? true : false,
                }),
                tokenRewarded: false,
            };
            const response = await validatingSingleSubmission(submission.submissionId, payloadEach);
            console.log(response, 'this is response');
            setIsRemarking(false);
            navigate(-1);
            return;
        }
        setIsRemarking(true);
        const response = await remarkSubmission(submission.submissionId, payload);

        setIsRemarking(false);
        navigate(-1);
    };

    const isGradable =
        bountyDetails?.validationType === 'AFTER_EACH_SUBMISSION'
            ? !submission.accuracy
            : bountyDetails?.status !== 'Completed';

    return (
        <div>
            <div className="my-6">
                <p>Bounty Title :</p>

                <Heading type={'large'} className={'text-lightBlack font-medium '}>
                    {bountyDetails?.title || bountyTitle || '-'}
                </Heading>
            </div>
            <div className="flex  items-center justify-between">
                <div>
                    <p className="text-xs font-medium ">Project Name</p>
                    <p className="font-medium text-lightBlack">{bountyDetails?.projectTitle || projectTitle || '-'}</p>
                </div>
                <div className="flex items-center gap-10">
                    <div>
                        <p className="text-xs font-medium ">Acceptance Date</p>
                        <p className="font-medium text-lightBlack">
                            {submission?.acceptanceTimestamp
                                ? formatIntDateAndThenTime(submission?.acceptanceTimestamp)
                                : '-'}
                        </p>
                    </div>
                    <div>
                        <p className="text-xs font-medium ">Submission Date</p>
                        <p className="font-medium text-lightBlack">
                            {submission?.completionTimestamp
                                ? formatIntDateAndThenTime(submission?.completionTimestamp)
                                : '-'}
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-layoutBountySubmission gap-8 ">
                <Table className="  gap-4  " cols="grid-cols-3 ">
                    <Table.Header>
                        <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1  ">
                            Data Points
                        </p>
                        <p className="text-sm  justify-self-center font-semibold text-secondaryText flex items-center gap-1  ">
                            Response Type
                        </p>
                        <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1  "> Content</p>
                    </Table.Header>

                    {loading ? (
                        <div className="min-h-[20vh] flex justify-center items-center">
                            <Loader />
                        </div>
                    ) : Object.keys(submission).length > 0 ? (
                        <div>
                            {submission.data.map((taskSubmission, i) => {
                                const x = microtaskTypeData?.find((obj) => obj?.type === taskSubmission.actionType);

                                return (
                                    <>
                                        {!x ? (
                                            <p>no submission for this task available</p>
                                        ) : (
                                            x.getSubmissionDataComponent(taskSubmission)
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    ) : (
                        <NoDataAvailable />
                    )}
                </Table>

                <form onSubmit={handleSubmit(onSubmit)} className="py-6  ">
                    <div className="bg-[#EEEEEE] px-8 py-7 rounded-lg ">
                        <div className="mb-6">
                            <p className="text-xs">Submission ID</p>
                            <p className="font-medium text-lightBlack">{submissionId}</p>
                        </div>
                        <p className="font-medium text-lightBlack ">Validation Feedback</p>
                        <Input
                            {...register('feedback', {
                                required: 'Please enter your Feedback.',
                            })}
                            className={`w-full mt-2 ${!isGradable ? 'cursor-not-allowed   !bg-disabledColor' : ''}`}
                            type="textArea"
                            disabled={!isGradable}
                            error={errors?.feedback?.message}
                            placeholder="Add Comments"
                        />
                        <div className="mt-6">
                            <p className="font-medium text-lightBlack">Grade the Submission</p>
                            <p className="text-sm">Enter an accuracy score between 1 -100</p>
                            <Input
                                {...register('accuracy', {
                                    required: 'Please enter your Score.',
                                })}
                                disabled={!isGradable}
                                className={`w-full mt-2 ${
                                    !isGradable ? 'cursor-not-allowed   !bg-disabledColor' : ''
                                } `}
                                type="number"
                                error={errors?.accuracy?.message}
                                placeholder="0.0"
                            />
                            <p className="text-xs mt-1">
                                Pass threshold set for this bounty is {bountyDetails?.passThreshold * 100}%.
                            </p>
                        </div>
                        {bountyDetails?.hasImpactCertificate && (
                            <div className="mt-6">
                                <p className="font-medium text-lightBlack">Issue Impact Certificate</p>
                                <div className="flex gap-9 items-center ">
                                    <RadioButtonComponent
                                        selectedOption={isIssueImpact}
                                        onSelectOption={setIsIssueImpact}
                                        disabled={!isGradable}
                                        inputClassName={`${!isGradable ? 'cursor-not-allowed   bg-disabledColor' : ''}`}
                                        options={['Yes', 'No']}
                                    />
                                </div>
                            </div>
                        )}
                        {isGradable && (
                            <Button className="mt-8 pl-5 pr-5" variant="primary" size="fit">
                                {isRemarking ? (
                                    <span className="w-32 block">
                                        <Loader />
                                    </span>
                                ) : (
                                    'Validate Submission'
                                )}
                            </Button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default BountySubmission;

// {isLoading ? (
//     <div className="min-h-[20vh] flex justify-center items-center">
//         <Loader />
//     </div>
// ) : submission?.data?.length ? (
//     submission?.data?.map((dataPoint, index) => (
//         <Table.Row>
//             <p className=" text-lightBlack flex flex-col   ">
//                 <span className="font-medium"> Data Point {index + 1}</span>
//                 <span>{dataPoint?.submission.question}</span>
//             </p>
//             <p className=" text-lightBlack justify-self-center  ">{dataPoint?.actionType}</p>
//             <p className=" text-lightBlack  ">{dataPoint?.submission.answer}</p>
//         </Table.Row>
//     ))
// ) : (
//     <NoDataAvailable />
// )}
