import Input from '../../../ui/Input';
import Heading from '../../../ui/Heading';
import UploadLogo from '../UploadLogo';
import { useController } from 'react-hook-form';
import FormWithInstructionField from './FormWithInstructionField';
import { useInstructionTextMessage } from '../bountiesHooks/useInstructionTextMessage';
import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';

const InstructionalText = ({
    register,
    index,
    errors,
    control,
    handleInstructionLinkPayload,
    instructionLinkPayload,
    microtaskEditDetail,
}) => {
    const { isEdit } = useContext(BountiesContext);
    const { field: instructionImageField } = useController({
        name: `microtasks.${index}.instruction_images`,
        control,
        defaultValue: microtaskEditDetail?.instruction_images || '',
    });
    const { instructionMessageField } = useInstructionTextMessage(
        control,
        index,
        isEdit ? microtaskEditDetail?.instruction_message : undefined
    );
    // Use useController to manage the instruction_message field properly
    // const {
    //     field: instructionMessageField,
    //     fieldState: { error },
    // } = useController({
    //     name: `microtasks.${index}.instruction_message`,
    //     control,
    //     rules: {
    //         required: 'Instruction message is required',
    //     },
    // });

    return (
        <div>
            <div className="mb-2">
                <Input
                    {...register(`microtasks.${index}.instruction_title`, {
                        required: 'Please enter a title',
                    })}
                    type="transparent"
                    {...(isEdit && { defaultValue: microtaskEditDetail?.instruction_title || '' })}
                    error={errors?.microtasks && errors?.microtasks[index]?.instruction_title?.message}
                    placeholder={'Title *'}
                    transparentTextSize="text-base"
                    className={'w-[100%]'}
                />
            </div>

            {/* Use the FormWithDescriptionField for the instructional message */}
            <FormWithInstructionField
                handleDescriptionLinkPayload={handleInstructionLinkPayload}
                errors={errors?.microtasks}
                field={instructionMessageField} // Pass the field object from useController
                instructionLinkPayload={instructionLinkPayload}
            />

            <div className="my-4">
                <p className="text-primaryInput text-sm mb-1">
                    Add Image <span className="text-secondaryInput">(Optional)</span>{' '}
                </p>

                {instructionImageField.value ? (
                    <img
                        className="w-20 h-20 rounded-md object-center object-cover"
                        src={instructionImageField.value}
                        alt="instructional"
                    />
                ) : (
                    <UploadLogo outerPadding="" fieldName={instructionImageField} errors={errors} />
                )}
            </div>
        </div>
    );
};

export default InstructionalText;
