import axios from 'axios';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};
export const uploaderApi = async (payload) => {
    try {
        const url = `${BASE_URL}/microtaskdata/upload`;

        const accessGoogle = await auth.currentUser.getIdToken(true);
        const response = await axios.post(url, payload, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getTokensForBudget = async () => {
    const url = `${BASE_URL}/tokeninfo`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getSuperpowers = async () => {
    const url = `${BASE_URL}/superpower`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getImpactPoints = async ({ bountyType, symbol, amount }) => {
    const url = `${BASE_URL}/bounty/impactPoints?bountyType=${bountyType}&rewardToken=${symbol}&maxRewardAmount=${amount}`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getICImageOnBountyCreationScreen = async ({
    coresArray,
    briefMessage,
    sdgsArray,
    tokenSymbol,
    amount,
}) => {
    console.log(amount, 'see the amount');
    const url = `${BASE_URL}/impactCertificate/preview?cores=${coresArray.join(
        ','
    )}&impactBrief=${briefMessage}&SDGs=${sdgsArray
        .join(',')
        .replace(/&/g, '%26')}&rewardTokenSymbol=${tokenSymbol}&rewardTokenAmount=${amount?.toString()}`;
    // const url = `${BASE_URL}/impactCertificate/preview?cores=Water,Earth,Social&impactBrief=GeNeRaTeD from Development Server&SDGs=No poverty,Zero hunger,Quality education`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

// /tokeninfo/getDetails?symbol=<symbol></symbol>

export const getTokenInfo = async (sym) => {
    const url = `${BASE_URL}/tokeninfo/getDetails?symbol=${sym}`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
