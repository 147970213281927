export const plainTextValue = (input) => {
    return input
        .replace(/<p><br\s*\/?><\/p>/gi, '\n') // Replace empty paragraphs with a single newline
        .replace(/<br\s*\/?>/gi, '\n') // Replace <br> or <br/> with newline
        .replace(/<\/p>/gi, '\n') // Replace closing </p> with a single newline for paragraph separation
        .replace(/<p[^>]*>/gi, '') // Remove opening <p> tags
        .replace(/<\/?[^>]+(>|$)/g, '') // Remove any remaining HTML tags
        .trim();
};

// export const textToHtml = (input, linkArray) => {
//     if (input === undefined) {
//         return ''; // Return an empty string if the input is undefined
//     }

//     // Map to hold links by index
//     const linkMap = new Map();
//     linkArray?.forEach(({ startIndex, endIndex, link }) => {
//         for (let i = startIndex; i <= endIndex; i++) {
//             linkMap.set(i, link); // Map word index to link
//         }
//     });

//     // Process each line and apply links
//     let wordIndex = 0; // Initialize word index
//     return input
//         .split('\n') // Split input into lines by newline
//         .map((line) => {
//             if (line.trim() === '') {
//                 return '<p><br/></p>'; // Handle empty lines as <p><br/></p>
//             }

//             const processedLine = line
//                 .split(/\s+/) // Split the line into words
//                 .map((word) => {
//                     if (word.trim() === '') return word; // Keep whitespace untouched

//                     wordIndex++; // Increment index before applying the link

//                     if (linkMap.has(wordIndex)) {
//                         const link = linkMap.get(wordIndex);
//                         return `<a href="${link}" target="_blank">${word}</a>`; // Wrap word in anchor tag
//                     }

//                     return word; // Return word unmodified if no link
//                 })
//                 .join(' '); // Rejoin words into a single line

//             return `<p>${processedLine}</p>`; // Wrap the processed line in <p> tags
//         })
//         .join(''); // Combine all lines into a single string
// };

export const textToHtml = (input, linkArray) => {
    if (input === undefined) {
        return ''; // Return an empty string if the input is undefined
    }

    // Map to hold links by index
    const linkMap = new Map();
    linkArray?.forEach(({ startIndex, endIndex, link }) => {
        for (let i = startIndex; i <= endIndex; i++) {
            linkMap.set(i, link); // Map word index to link
        }
    });

    // Initialize word index (tracks word positions globally across all lines)
    let wordIndex = 0;

    return input
        .split('\n') // Split input into lines by newline
        .map((line) => {
            if (line.trim() === '') {
                return '<p><br/></p>'; // Handle empty lines as <p><br/></p>
            }

            const processedLine = line
                .split(/\s+/) // Split the line into words
                .map((word) => {
                    if (word.trim() === '') return word; // Keep whitespace untouched

                    wordIndex++; // Increment the global word index for each word

                    if (linkMap.has(wordIndex)) {
                        const link = linkMap.get(wordIndex);
                        return `<a href="${link}" target="_blank">${word}</a>`; // Wrap word in anchor tag
                    }

                    return word; // Return word unmodified if no link
                })
                .join(' '); // Rejoin words into a single line

            return `<p>${processedLine}</p>`; // Wrap the processed line in <p> tags
        })
        .join(''); // Combine all lines into a single string
};

export const removeAllDuplicates = (array) => {
    const seen = new Set(); // To track unique objects
    return array.filter((item) => {
        // Create a unique identifier for each object
        const identifier = `${item.startIndex}-${item.endIndex}-${item.link}`;
        if (seen.has(identifier)) {
            return false; // Exclude if already seen
        }
        seen.add(identifier); // Mark as seen
        return true; // Include unique items
    });
};
