import Input from '../../../ui/Input';
import Heading from '../../../ui/Heading';
import UploadLogo from '../UploadLogo';
import { useController } from 'react-hook-form';
import IsSkippable from './IsSkippable';
import Button from '../../../ui/Button';
import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';

const QuestionAndAnswer = ({ register, index, errors, control, microtaskEditDetail }) => {
    const { isEdit } = useContext(BountiesContext);
    const { field: questionAnswerImageField } = useController({
        name: `microtasks.${index}.questionAnswer_image`,
        control,
        defaultValue: microtaskEditDetail?.questionAnswer_image || '',
    });

    // const { field: skippableField } = useController({
    //     name: `microtasks.${index}.skippable`,
    //     control,
    // });

    return (
        <div>
            <div className="mb-2">
                <Input
                    {...register(`microtasks.${index}.questionAnswer_heading`, {
                        required: 'Please enter a title',
                    })}
                    type="transparent"
                    error={errors?.microtasks && errors?.microtasks[index]?.questionAnswer_heading?.message}
                    placeholder={'Title *'}
                    {...(isEdit && { defaultValue: microtaskEditDetail?.questionAnswer_heading || '' })}
                    transparentTextSize="text-base"
                    className={'w-[100%]   '}
                />
            </div>
            <div className="mb-2  ">
                <Input
                    {...register(`microtasks.${index}.questionAnswer_Title`, {
                        required: 'Please enter a question',
                    })}
                    type="transparent"
                    placeholder={'Ask the question here *'}
                    {...(isEdit && { defaultValue: microtaskEditDetail?.questionAnswer_Title || '' })}
                    error={errors?.microtasks && errors?.microtasks[index]?.questionAnswer_Title?.message}
                    transparentTextSize="text-sm"
                    transparentFontWeight={'font-normal'}
                    className={'w-[100%]   '}
                />
            </div>
            <div className="mb-2">
                <Input
                    {...register(`microtasks.${index}.questionAnswer_question`, {
                        required: 'Please enter a hint',
                    })}
                    type="transparent"
                    placeholder={'Type the hint here *'}
                    {...(isEdit && { defaultValue: microtaskEditDetail?.questionAnswer_question || '' })}
                    error={errors?.microtasks && errors?.microtasks[index]?.questionAnswer_question?.message}
                    transparentTextSize="text-sm"
                    transparentFontWeight={'font-normal'}
                    className={'w-[100%] '}
                />
            </div>
            <div className="my-4">
                <p className="text-primaryInput text-sm mb-1">
                    {' '}
                    Add Image <span className="text-secondaryInput">(Optional)</span>{' '}
                </p>

                {questionAnswerImageField.value ? (
                    <img
                        className="w-20 h-20 rounded-md object-center object-cover "
                        src={questionAnswerImageField.value}
                        alt="profile"
                    />
                ) : (
                    <UploadLogo outerPadding="" fieldName={questionAnswerImageField} errors={errors} />
                )}
            </div>
            {/* <div className="flex gap-9  items-center ">
                <IsSkippable
                    selectedOption={skippableField.value}
                    onSelectOption={(value) => {
                        skippableField.onChange(value);
                    }}
                    options={['Yes', 'No']}
                />
            </div> */}
        </div>
    );
};

export default QuestionAndAnswer;
