import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import WalletConnectButton from '../walletconnectUI/WalletConnectButton';

function WalletConnection() {
    return (
        <div className="   ">
            <Heading type="medium" className="capitalize mb-1 text-lightBlack">
                Connect Your Wallet <sup className="text-lg relative top-[1px]">*</sup>
            </Heading>
            <p className="mb-2">To sign and authorize organizational transactions, vote on proposals etc. </p>
            <div>
                <WalletConnectButton widthPer={'267px'} />
            </div>
        </div>
    );
}

export default WalletConnection;
