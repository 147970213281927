import { Outlet } from 'react-router-dom';
import Sidebar from '../ui/sidebar/Sidebar';
import ToptabBar from '../components/TopTab/TopTab';

import { useState } from 'react';
import { NotificationsProvider } from '../contexts/NotificationsContext';
function BasicLayout() {
    const [showSidebar, setShowSidebar] = useState(false);
    const toggle = () => {
        setShowSidebar((i) => !i);
    };
    return (
        <div className="grid relative grid-cols-1 lg:grid-cols-layout h-screen overflow-hidden bg-fixed">
            <Sidebar showSidebar={showSidebar} toggle={toggle} />
            <div className="overflow-y-scroll  ">
                <NotificationsProvider>
                    <ToptabBar showSidebar={showSidebar} toggle={toggle} />
                </NotificationsProvider>
                <Outlet />
            </div>
        </div>
    );
}

export default BasicLayout;
