import { toast } from 'sonner';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';
import axios from 'axios';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const getEvent = async (id) => {
    try {
        const url = `${BASE_URL}/organisation/event/${id}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
