import axios from 'axios';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';
import { toast } from 'sonner';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const acceptInvitationToJoinOrg = async (invId) => {
    try {
        const url = `${BASE_URL}/orgInvitation/${invId}?status=ACCEPTED`;

        const accessToken = await getAccessToken();
        const response = await axios.patch(url, {}, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) toast.success(' Invitation accepted.');
        return response;
    } catch (error) {
        toast.error(error?.response?.data?.message);
    }
};
export const declineInvitationToJoinOrg = async (invId) => {
    try {
        const url = `${BASE_URL}/orgInvitation/${invId}?status=DECLINED`;

        const accessToken = await getAccessToken();
        const response = await axios.patch(url, {}, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) toast.success('	Invitation rejected. ');
        return response;
    } catch (error) {
        toast.error(error?.response?.data?.message);
    }
};
