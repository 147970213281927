import { toast } from 'sonner';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';
import axios from 'axios';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};
// GET /orgRequest?organisationId=<orgId>&requestType=<BOUNTY_CREATION or BOUNTY_VALIDATION>&isProposed=false

export const getCreationOrValidationRequestsOrProposals = async (type, orgId, isProposed) => {
    try {
        const url = `${BASE_URL}/orgRequest?organisationId=${orgId}&requestType=${type}&isProposed=${isProposed}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

// PATCH /orgRequest/<request id>?proposalAddress=<address here>

export const createProposal = async (reqId, proposalAddress, bountyAddress) => {
    try {
        const bountyQueryString = bountyAddress ? `&bountyAddress=${bountyAddress}` : '';
        const url = `${BASE_URL}/orgRequest/${reqId}?proposalAddress=${proposalAddress}${bountyQueryString}`;

        const accessToken = await getAccessToken();
        const response = await axios.patch(url, {}, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) toast.success('Request accepted. ');
        return response;
    } catch (error) {
        toast.error(error);
        return error;
        // toast.error('Sorry unable to create your organization.');
    }
};

//

export const getSummaryOfProposal = async (reqId) => {
    try {
        const url = `${BASE_URL}/orgRequest/summary/${reqId}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
// PATCH /orgRequest/execute/<request id> (Register proposal’s execution)

export const executeProposal = async (reqId) => {
    try {
        const url = `${BASE_URL}/orgRequest/execute/${reqId}`;

        const accessToken = await getAccessToken();
        const response = await axios.patch(url, {}, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) toast.success('Request successfully executed. ');
        return response;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};
