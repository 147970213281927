import { useEffect, useRef, useState } from 'react';
import Overlay from '../../../ui/Overlay';
import { toast } from 'sonner';
import { createProposal } from '../../../apis/treasurer/treasurer';
import { executorForCreatingProposalForBountyCreation } from '../../../blockchainInteraction/proposalForBountyCreation';
import { Connection, PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { getBounty, getValidationPreview } from '../../../apis/bounties/bounties';
import { getProvider } from '../../../helpers/getProvider';
import { executorForCreatingProposalForBountyValidation } from '../../../blockchainInteraction/proposalForBountyValidation';
import { useLoadingState } from '../../../hooks/useLoader';
import Loader from '../../../ui/Loader';
import ModalBox from '../../../ui/ModalBox';
import { getTokensForBudget } from '../../../apis/general/general';
import { RPC_ENDPOINT } from '../../../constants/apiPaths';

function AcceptRequestModal({ close, bountyName, reqId, afterRequestOperation, multiSig, bountyId, reqFor }) {
    const { publicKey } = useWallet();
    const [bountyDetails, setBountyDetails] = useState({});
    const [loadingBountyDetails, setLoadingBountyDetails] = useState(true); // New state for bounty details loading
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [detailsForValidationProposal, setDetailsForValidationProposal] = useState({
        bountyPubkey: '',
        winners: [],
    });
    const myRef = useRef({ close });

    useEffect(() => {
        function handleClick(e) {
            if (myRef.current && !myRef.current.contains(e.target)) {
                close();
            }
        }

        document.addEventListener('click', handleClick, true);

        const fetchData = async () => {
            setLoadingBountyDetails(true); // Start loading state
            try {
                const data = await getBounty(bountyId);

                if (reqFor === 'validation') {
                    const winnersData = await getValidationPreview(bountyId);
                    const winnersDataRequired = winnersData.rewardDistribution.map((winner) => ({
                        pubkey: new PublicKey(winner.walletAddress),
                        amount: winner.rewardAmount,
                    }));
                    setDetailsForValidationProposal((initial) => ({
                        ...initial,
                        bountyPubkey: data.accountAddress,
                        winners: winnersDataRequired,
                    }));
                }

                const tokens = await getTokensForBudget();
                const tokenNeeded = tokens.find((token) => token._id === data.rewardToken.tokenInfoId);
                if (!tokenNeeded) {
                    throw new Error('Matching token not found for the bounty');
                }

                const dataToSet = {
                    title: data.title,
                    description: data.description,
                    rewardTokenAddress: new PublicKey(tokenNeeded.address),
                    totalRewardAmount: data.rewardToken.totalRewardAmount,
                    expirationTimestamp: new Date(data.expirationTimestamp),
                };
                setBountyDetails(dataToSet);
            } catch (error) {
                console.error('Error fetching bounty details:', error);
                toast.error('Failed to load bounty details. Please try again.');
            } finally {
                setLoadingBountyDetails(false); // End loading state
            }
        };

        fetchData();

        return () => document.removeEventListener('click', handleClick, true);
    }, [close, bountyId, reqFor]);

    const onRequest = async () => {
        if (loadingBountyDetails || Object.keys(bountyDetails).length === 0) {
            toast.error('Bounty details are not loaded yet. Please wait.');
            return;
        }

        startLoading();

        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            if (reqFor === 'validation') {
                const { transaction, proposalAddress } = await executorForCreatingProposalForBountyValidation(
                    connection,
                    new PublicKey(multiSig),
                    publicKey,
                    new PublicKey(detailsForValidationProposal.bountyPubkey),
                    detailsForValidationProposal.winners,
                    bountyDetails.rewardTokenAddress
                );
                const { signature } = await getProvider().signAndSendTransaction(transaction);
                await connection.getSignatureStatus(signature);
                const response = await createProposal(reqId, proposalAddress);
                afterRequestOperation(reqId);
            } else {
                const currentDate = new Date();
                currentDate.setMinutes(currentDate.getMinutes() + 5);

                if (new Date(bountyDetails.expirationTimestamp) < currentDate) {
                    throw new Error(
                        'Please increase the date of the Bounty Expiration Time in step 4 of Bounty Creation. The Bounty must be live for at least 5 minutes.'
                    );
                }

                const { transaction, proposalAddress, bountyAddress } =
                    await executorForCreatingProposalForBountyCreation(connection, multiSig, publicKey, bountyDetails);
                const { signature } = await getProvider().signAndSendTransaction(transaction);
                await connection.getSignatureStatus(signature);
                const response = await createProposal(reqId, proposalAddress, bountyAddress);
                afterRequestOperation(reqId);
            }

            close();
        } catch (error) {
            console.error('Error during request submission:', error);
            toast.error(error.message || 'Failed to submit request.');
        } finally {
            stopLoading();
        }
    };

    if (loadingBountyDetails) {
        return <Loader />; // Show a loader while bounty details are being fetched
    }

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[625px] bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <ModalBox
                    close={close}
                    onSubmit={onRequest}
                    primaryBtnText={'Submit'}
                    loading={loading}
                    secondaryBtnText={'Cancel'}
                >
                    <p className="text-xl">
                        Are you sure you want to <span className="font-bold"> Create the Proposal {bountyName}</span>
                    </p>
                </ModalBox>
            </div>
        </Overlay>
    );
}

export default AcceptRequestModal;
