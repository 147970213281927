import { useState, useEffect } from 'react';
import AddProfileImage from '../../assets/icons/AddProfileImage';
import Input from '../../ui/Input';
import Info from '../../assets/icons/Info';
import { uploader } from '../../helpers/uploader';
import { toast } from 'sonner';
import { useLoadingState } from '../../hooks/useLoader';
import EditLogo from '../../assets/icons/EditLogo';

const UploadLogoComponent = ({ fieldName, errorField, errors, isUploadEnable = true, outerPadding = 'p-6' }) => {
    const [uploadPicture, setUploadPicture] = useState({
        image: null,
        imageURL: fieldName.value,
    });
    const [uploadStatus, setUploadStatus] = useState('');
    const { loading: uploading, startLoading: startUploading, stopLoading: stopUploading } = useLoadingState();

    useEffect(() => {
        // Clean up the URL when component unmounts or when image changes
        return () => {
            if (uploadPicture.imageURL) {
                URL.revokeObjectURL(uploadPicture.imageURL);
            }
        };
    }, [uploadPicture.imageURL]);

    useEffect(() => {
        // When isUploadEnable is false, directly update the field after setting the picture
        if (!isUploadEnable && uploadPicture.image) {
            fieldName.onChange(uploadPicture.image);
        }
    }, [isUploadEnable, uploadPicture.image, fieldName]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            await uploadFile(file);
        } else {
            setUploadStatus('Please select a valid file.');
        }
    };

    const uploadFile = async (file) => {
        try {
            startUploading();

            const res = await uploader(file);

            if (res.files.length) {
                setUploadPicture({
                    image: file,
                    imageURL: res.files[0],
                });
                fieldName.onChange(res.files[0]);
                toast.success('Image is successfully uploaded.');
            } else {
                toast.error('Sorry unable to upload logo');
            }
            stopUploading();
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('File upload failed.');
        }
    };

    return (
        <div>
            <div className={`${outerPadding} bg-white flex flex-col text-uploadText items-center shadow-sm rounded-md`}>
                <div className="flex w-full  flex-col text-uploadText items-center">
                    <div className="border  flex flex-col gap-2 border-borderColor border-dashed w-full p-6 rounded-md">
                        <div>
                            <div className="bg-white">
                                {!uploadPicture.imageURL ? (
                                    <>
                                        <label className="flex flex-col gap-2 items-center" htmlFor="avatar">
                                            <AddProfileImage />
                                            <p>Click to choose files</p>
                                        </label>
                                        <Input id="avatar" type="file" onChange={handleFileChange} />
                                    </>
                                ) : (
                                    <div className="relative bg-white ">
                                        <img
                                            className="w-20 h-20 rounded-md object-center object-cover"
                                            src={uploadPicture.imageURL}
                                            alt="profile"
                                        />

                                        <label htmlFor="avatar" className="cursor-pointer absolute top-0 right-0 ">
                                            <EditLogo />
                                            <Input id="avatar" type="file" onChange={handleFileChange} />
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                        {!uploadPicture.imageURL && (
                            <div className="flex gap-2 justify-center items-center">
                                <Info />
                                <p className="font-semibold">Max file size: 2 MB; Ideal dimensions: 670x260 px</p>
                            </div>
                        )}
                    </div>
                </div>

                {errors[errorField]?.message && <p className="text-foundryRed mt-1">{errors[errorField]?.message}</p>}
            </div>
        </div>
    );
};

export default UploadLogoComponent;
