import { useContext, useState } from 'react';

import { Paper } from '@mui/material';
import { sendOtpEmail, verifyOtpEmail } from '../../apis/auth/loginemail';
import { auth } from '../../firebase';

import { GoogleAuthProvider, signInWithCustomToken, signInWithPopup } from 'firebase/auth';
import googleIcon from '../../assets/googleicon.png';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import GreenRight from '../../assets/icons/GreenRight';

import Button from '../../ui/Button';
import { getProfile } from '../../apis/user/user';

import Loader from '../../ui/Loader';
import { useLoadingState } from '../../hooks/useLoader';
import { toast } from 'sonner';

import Input from '../../ui/Input';

const LoginForm = () => {
    const { dispatch } = useContext(AuthContext);
    const [otpView, setOtpView] = useState(false);
    const [email, setEmail] = useState('');

    const [otp, setOtp] = useState('');
    const [otpToken, setOtpToken] = useState('');
    const { loading: requesting, startLoading: startRequesting, stopLoading: stopRequesting } = useLoadingState();
    const [emailError, setEmailError] = useState(false);
    const [OtpError, setOtpError] = useState(false);
    const navigate = useNavigate();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        // if(emailError){
        //   setEmailError(false)
        // }
    };
    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };
    const loginThroughGoogle = async () => {
        try {
            // Initialize GoogleAuthProvider
            const provider = new GoogleAuthProvider();

            // Sign in with a popup window
            const result = await signInWithPopup(auth, provider);

            // The signed-in user info
            const user = result.user;

            // You can access additional Google account details, like tokens
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;

            const profile = await getProfile(); // Fetch user profile

            if (profile.length) {
                dispatch({ type: 'login', payload: profile });
                // if (!profile[0].walletAddress) {
                //     navigate('/connectfirst', { replace: true });
                // } else {
                navigate('/', { replace: true });
                // }
            } else {
                dispatch({ type: 'login', payload: profile });
                navigate('/createprofile', { replace: true });
            }
            // if (profile.length) {
            //     dispatch({ type: 'login', payload: profile });

            //     if (!profile[0].walletAddress) {
            //         navigate('/connectfirst', { replace: true });
            //     } else {
            //         navigate('/', { replace: true });
            //     }
            // } else {
            //     dispatch({ type: 'login', payload: profile });
            //     navigate('/createprofile', { replace: true });
            // }
        } catch (error) {
            toast.error('Google login failed. Please try again!');
        }
    };
    const getOtpCall = async () => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValidEmail = emailRegex.test(email);
        if (isValidEmail) {
            try {
                startRequesting();
                const otpCall = await sendOtpEmail(email);

                setOtpToken(otpCall.otpToken);

                setOtpView(true);
            } catch (err) {
                console.log('error', err);
            } finally {
                stopRequesting();
            }
        } else {
            setEmailError(true);
            setTimeout(() => {
                setEmailError(false);
            }, 3000);
        }
    };

    const verifyOtp = async () => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValidEmail = emailRegex.test(email);
        if (isValidEmail) {
            try {
                startRequesting();
                const verified = await verifyOtpEmail(email, otp, otpToken);
                if (!verified) {
                    const error = new Error('Please enter correct OTP');
                    error.code = 404;
                    throw error;
                }
                if (verified.customToken && verified.customToken.length > 0) {
                    await signInWithCustomToken(auth, verified.customToken)
                        .then(async (user) => {
                            stopRequesting();
                            const profile = await getProfile();

                            if (profile.length) {
                                dispatch({ type: 'login', payload: profile });
                                // if (!profile[0].walletAddress) {
                                //     navigate('/connectfirst', { replace: true });
                                // } else {
                                navigate('/', { replace: true });
                                // }
                            } else {
                                dispatch({ type: 'login', payload: profile });
                                navigate('/createprofile', { replace: true });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    // setLoader(false)
                    alert(verified.message + 'him');
                }
            } catch (err) {
                stopRequesting();
                // setOtpView(false);
                console.log(err.code);
                if (err?.code === 404) {
                    setOtpError(true);
                    // setTimeout(() => {
                    //     setOtpError(false);
                    // }, 3000);
                    setOtp('');
                    toast.error('Invalid OTP , Please enter correct OTP');
                    return;
                }
                toast.error('Mismatch between email and otp,Please enter your email again!');
            } finally {
            }
        } else {
            console.log('Invalid email address');
        }
    };

    return (
        <div className=" place-self-center  min-w-[416px] max-w-lg ml-auto ">
            <div>
                <Paper
                    elevation={3}
                    style={{
                        padding: '20px',

                        backgroundColor: 'white',

                        borderRadius: '8px',
                        alignSelf: 'center',
                    }}
                >
                    <div className="flex items-center  gap-2 ">
                        <GreenRight />
                        <p className="text-lightBlack text-2xl">Sign Up / Login</p>
                    </div>
                    <p className="mt-2 border-b text-secondaryInput border-borderColor pb-6 ">
                        Build your organization using Impact Foundry. <br /> Create immutable impact.
                    </p>

                    {!otpView ? (
                        <div className="pt-6">
                            <Input
                                isMandatory={true}
                                containerClass="mb-4"
                                label="Email"
                                value={email}
                                onChange={handleEmailChange}
                                type="text"
                                className="w-full"
                                placeholder="example@email.com"
                                error={emailError ? ' Please enter a valid email address' : ''}
                            />

                            <div>
                                <p className="text-primaryText block mb-1">
                                    Get OTP
                                    <sup className="text-foundryRed relative -top-[2px] left-1 ">*</sup>
                                </p>

                                <Button
                                    variant="primary"
                                    size="large"
                                    onClick={() => {
                                        getOtpCall();
                                    }}
                                >
                                    {!requesting ? 'Request OTP' : <Loader />}
                                </Button>
                            </div>
                            {/* <p className="text-center my-3">OR</p>
                            <div>
                                <LoginThroughWallet />
                            </div> */}
                            <p className="text-center my-3">OR</p>
                            <div>
                                <Button
                                    radius="rounded-full"
                                    variant="tertiary"
                                    size="large"
                                    className="flex items-center justify-center gap-2"
                                    onClick={() => {
                                        loginThroughGoogle();
                                    }}
                                >
                                    <img src={googleIcon} alt="google icon image" />
                                    <span>Sign in with Google</span>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="pt-6">
                            <Input
                                isMandatory={true}
                                disabled={true}
                                containerClass="mb-4"
                                label="Email"
                                value={email}
                                onChange={handleEmailChange}
                                type="text"
                                className="w-full"
                                placeholder="example@email.com"
                                error={emailError ? ' Please enter a valid email address' : ''}
                            />

                            <Input
                                isMandatory={true}
                                containerClass="mb-4"
                                label="Enter OTP"
                                value={otp}
                                onChange={handleOtpChange}
                                type="number"
                                maxLength={6}
                                className="w-full  "
                                placeholder="enter the OTP sent to your email"
                                error={OtpError ? ' Invalid OTP,please try again' : ''}
                                restrictLength={(e) => {
                                    if (e.target.value.length > 6) {
                                        e.target.value = e.target.value.slice(0, 6);
                                    }
                                }}
                                helpUser={
                                    <span
                                        onClick={() => {
                                            setOtpError(false);
                                            getOtpCall();
                                        }}
                                        className=" ml-auto text-foundryBlue underline cursor-pointer "
                                    >
                                        Resend Code
                                    </span>
                                }
                            />

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="primary"
                                    size="large"
                                    onClick={() => {
                                        verifyOtp();
                                    }}
                                >
                                    {!requesting ? 'Submit' : <Loader />}
                                </Button>
                            </div>
                        </div>
                    )}
                </Paper>
            </div>
        </div>
    );
};

export default LoginForm;
