import { useContext, useEffect, useState } from 'react';
import Button from '../../ui/Button';
import Dropdown from '../../ui/Dropdown';
import Heading from '../../ui/Heading';
import Input from '../../ui/Input';
import { BountiesContext } from '../../contexts/BountiesContext';
import RadioButtonComponent from '../../ui/RadioButtonComponent';
import { useBountyFields } from '../../hooks/useBountyFields';
import RankBasedDistributions from './RankBasedDistributions';
import Checkbox from '../../ui/Checkbox';
import { coresData, coresWithIconData, SUSTAINABLE_DEVELOPMENT_GOALS } from '../../constants/datas';
import { useStepSixInputs } from './bountiesHooks/useStepSixInputs';
import Toggle from '../../ui/Toggle';
import CharacterLimit from '../../ui/CharacterLimit';
import Loader from '../../ui/Loader';
import { getICImageOnBountyCreationScreen, getImpactPoints } from '../../apis/general/general';
import TextWithTooltip from '../../ui/TextWithTooltip';
import { useWatch } from 'react-hook-form';
import FloatingButtons from '../../ui/FloatingButtons';
import DatePicker from 'react-datepicker';

const MAX_SELECTION = 5;

function StepSix({
    setStep,
    register,
    control,
    errors,
    getValues,
    setError,
    clearErrors,
    onSubmit,
    loading,
    reset,
    icImage,
    setIcImage,
    impactPointsData,
    setImpactPointsData,
    startGettingIcImage,
    stopGettingIcImage,
}) {
    const [maxSDGError, setMaxSDGError] = useState('');

    const {
        tokenField,
        tokens,
        totalRewardAmountField,
        noOfWinnersField,
        passThresholdField,
        hasImpactCertificateField,
        impactBriefField,
        coresField,
        SDGsField,
        isAttestableField,
        dominantSDGField,
        distributionModeField,
        ranksField,
        withRewardTokenField,
        appendRank,
        removeRank,
        validationTillDateField,
        daySubField,
        hoursSubField,
        minutesSubField,
    } = useStepSixInputs(control, getValues()?.hasImpactCertificate, getValues, setError, clearErrors);
    const watchedRanks = useWatch({ name: 'ranks', control });

    useEffect(() => {
        // First Payload: For max amount and impact points
        let maxAmount = 0;
        const maxAmountPayload = {};
        if (distributionModeField.value === 'Distribute evenly between all winners') {
            if (noOfWinnersField.value) {
                maxAmount = Number(totalRewardAmountField.value) / noOfWinnersField.value;
            }
        } else if (distributionModeField.value === 'Rank based distribution') {
            const ranks = watchedRanks.filter((i) => i.amount).map((obj) => Number(obj.amount));
            maxAmount = Math.max(...ranks);
        }
        maxAmountPayload.amount = maxAmount;
        maxAmountPayload.symbol = tokenField?.value?.symbol;
        maxAmountPayload.bountyType = getValues('type');

        if (maxAmount && maxAmountPayload.bountyType && maxAmountPayload.symbol) {
            const fetchImpactPoints = async () => {
                try {
                    const data = await getImpactPoints(maxAmountPayload);
                    const sum = data?.impactPoints + data?.participatoryImpactPoints;
                    setImpactPointsData(sum);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchImpactPoints();
        }

        // Second Payload: For IC image generation
        const icImagePayload = {
            coresArray: [...new Set(coresField.value)],
            briefMessage: impactBriefField.value,
            sdgsArray: [...new Set(SDGsField.value)],
            tokenSymbol: tokenField.value?.symbol,
            amount: maxAmount,
        };

        if (
            icImagePayload.coresArray.length > 0 &&
            icImagePayload.briefMessage &&
            icImagePayload.tokenSymbol &&
            icImagePayload.sdgsArray.length > 0
        ) {
            const fetchIcImage = async () => {
                try {
                    startGettingIcImage();
                    const data = await getICImageOnBountyCreationScreen(icImagePayload);
                    setIcImage(data);
                } catch (error) {
                    console.error(error);
                } finally {
                    stopGettingIcImage();
                }
            };
            fetchIcImage();
        } else {
            setIcImage({});
        }
    }, [
        tokenField.value,
        totalRewardAmountField.value,
        distributionModeField.value,
        noOfWinnersField.value,
        watchedRanks,
        coresField.value,
        SDGsField.value,
        impactBriefField.value,
        getValues,
    ]);

    useEffect(
        function () {
            coresField.onChange([...coresField.value, getValues()?.dominantCore]);
        },
        [getValues()?.dominantCore]
    );
    useEffect(
        function () {
            if (getValues()?.dominantSDG) {
                const filteredValues = SDGsField.value.filter((sdg) => sdg !== getValues()?.dominantSDG);
                SDGsField.onChange([...filteredValues, getValues()?.dominantSDG]);
            } else SDGsField.onChange([...SDGsField.value]);
        },
        [getValues()?.dominantSDG]
    );

    const handleUpdateOptions = (isSelected, item) => {
        const currentSelection = SDGsField.value;
        const updatedSelection = isSelected
            ? currentSelection.filter((val) => val !== item) // Remove item if deselected
            : [...currentSelection, item]; // Add item if selected

        if (updatedSelection.length > MAX_SELECTION) {
            setMaxSDGError(`You can only choose upto 4 secondary SDG’s`);
            return; // Prevent further updates
        }

        setMaxSDGError(''); // Clear error if within limit
        updateOptions(isSelected, item); // Call the original update logic
    };

    function updateOptions(value, item) {
        if (value) {
            const removeRoleArr = SDGsField.value.filter((role) => role !== item);
            SDGsField.onChange(removeRoleArr);
        } else {
            SDGsField.onChange([...SDGsField.value, item]);
        }
    }
    function updateCores(value, item) {
        if (value) {
            const removeRoleArr = coresField.value.filter((role) => role !== item);
            coresField.onChange(removeRoleArr);
        } else {
            coresField.onChange([...coresField.value, item]);
        }
    }

    const tokenSymbols = tokens.map((token) => token.symbol);
    const hasError = errors?.daySub?.message || errors?.hoursSub?.message || errors?.minutesSub?.message ? true : false;

    return (
        <div className="relative mb-24">
            <div className="p-12 pb-0  min-h-full flex flex-col justify-between  rounded-md ">
                <div>
                    <div className="mb-6">
                        <div className="flex gap-3">
                            <Heading type="xsmall" className="capitalize  text-primaryInput">
                                Bounty Reward
                            </Heading>
                        </div>
                        <p className="text-sm text-secondaryInput ">
                            Do you wish to have a monetary reward for this bounty?
                        </p>
                    </div>
                    <div>
                        <div className="flex gap-2 items-center">
                            <Toggle
                                valuee={withRewardTokenField.value}
                                onChangeFn={() => {
                                    if (getValues()?.withRewardToken) {
                                        clearErrors('token');
                                        clearErrors('noOfWinners');
                                        clearErrors('totalRewardAmount');

                                        withRewardTokenField.onChange(false);
                                        return;
                                    }
                                    if (!getValues()?.withRewardToken) withRewardTokenField.onChange(true);
                                }}
                            />
                            <Heading type="xsmall" className="  text-primaryInput ">
                                Reward Token
                            </Heading>
                        </div>
                        <p className="text-sm text-secondaryInput mt-1">
                            Choose bounty reward token and set the amount
                        </p>
                    </div>

                    <div className="my-6">
                        <div className="flex gap-3">
                            <Heading type="xsmall" className="capitalize  text-primaryInput">
                                Validation
                            </Heading>
                        </div>
                        <p className="text-sm text-secondaryInput ">
                            For bounties WITH a reward token- all submission have to be graded together
                        </p>
                        <p className="text-sm text-secondaryInput ">
                            For bounties WITHOUT a reward token- submissions have to be graded individually
                        </p>
                    </div>

                    {withRewardTokenField?.value ? (
                        <div className="my-6">
                            <div className="flex gap-3">
                                <TextWithTooltip
                                    heading={
                                        <Heading type="xsmall" className="capitalize  text-primaryInput">
                                            Set Deadline for validating all submissions{' '}
                                            <sup className="text-lg relative top-[1px]">*</sup>
                                        </Heading>
                                    }
                                >
                                    <p className="text-sm">
                                        Validation determines the score of each submission and in turn the winners.
                                    </p>
                                    <p className="text-sm mt-3">
                                        Setting a deadline will inform users how long it will take for the results to be
                                        announced
                                    </p>
                                </TextWithTooltip>
                            </div>
                            <div>
                                <DatePicker
                                    minDate={new Date(getValues('expirationTime'))}
                                    showTimeSelect
                                    className="border border-borderColor w-[230px] shadow-sm text-sm  bg-white placeholder:text-secondaryInput font-semibold rounded-md px-4 py-2"
                                    placeholderText="Set Date & Time"
                                    selected={validationTillDateField.value}
                                    onChange={(date) => {
                                        validationTillDateField.onChange(date);
                                    }}
                                    dateFormat="MMMM d, yyyy h:mmaa"
                                    timeIntervals={15}
                                />
                                {errors.validationTillDate && (
                                    <p className="text-foundryRed">{errors.validationTillDate.message}</p>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="my-6">
                            <div className="flex gap-3">
                                <TextWithTooltip
                                    heading={
                                        <Heading type="xsmall" className="capitalize  text-primaryInput">
                                            Set Deadline for validation time after each submission
                                            <sup className="text-lg relative top-[1px]">*</sup>
                                        </Heading>
                                    }
                                >
                                    <p className="text-sm">
                                        Validation determines the score of each submission and in turn the winners.
                                    </p>
                                    <p className="text-sm mt-3">
                                        Setting a deadline will inform users how long it will take for the results to be
                                        announced
                                    </p>
                                </TextWithTooltip>
                            </div>
                            <div className="flex w-[50%] mt-1 gap-1">
                                <div>
                                    <div>
                                        <Input
                                            {...daySubField}
                                            className="w-full text-sm font-semibold"
                                            type="number"
                                            placeholder={0}
                                        />
                                        <p className="">day</p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <Input
                                            {...hoursSubField}
                                            className="w-full text-sm font-semibold"
                                            type="number"
                                            placeholder={0}
                                        />
                                        <p>hours</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Input
                                            {...minutesSubField}
                                            className="w-full text-sm font-semibold"
                                            type="number"
                                            placeholder={0}
                                        />
                                        <p>minutes</p>
                                    </div>
                                </div>
                            </div>
                            {hasError && (
                                <p className="text-foundryRed">{'Please enter the validation deadline time'}</p>
                            )}
                        </div>
                    )}

                    {withRewardTokenField?.value && (
                        <div className="flex flex-col   ">
                            <div className="w-full">
                                <p className="text-primaryInput">
                                    Reward Token & Budget for this bounty?{' '}
                                    <sup className="text-lg relative top-[1px]">*</sup>{' '}
                                </p>
                                <div className="flex items-center mt-2 w-[100%]  ">
                                    <div className="relative ">
                                        <Dropdown
                                            modifyLabel={(listItem) => {
                                                return (
                                                    <span className="flex items-center gap-5">
                                                        <div className="relative">
                                                            <img
                                                                className="w-5 h-5 rounded-full "
                                                                src={listItem?.image}
                                                                alt=""
                                                            />
                                                            <img
                                                                className="w-2 h-2 absolute top-0 right-0 rounded-full "
                                                                src={listItem?.chainImage}
                                                                alt=""
                                                            />
                                                        </div>

                                                        <span>{listItem?.symbol} </span>
                                                    </span>
                                                );
                                            }}
                                            data={tokens}
                                            isSymbol={true}
                                            selected={tokenField.value}
                                            onSelected={tokenField.onChange}
                                            // className="py-1 bg-red-400"
                                            noSelectedText="Choose Token"
                                            btnClass="rounded-e-none leading-6 "
                                            w="w-40"
                                        />
                                    </div>
                                    <Input
                                        {...totalRewardAmountField}
                                        type="number"
                                        placeholder={'Enter amount'}
                                        className={'w-[100%] rounded-s-none '}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-2  mb-6   ">
                                {
                                    <p className="min-w-40 text-foundryRed ">
                                        {errors?.token?.message && errors?.token?.message}
                                    </p>
                                }
                                {
                                    <p className="text-foundryRed">
                                        {errors?.totalRewardAmount?.message && errors?.totalRewardAmount?.message}
                                    </p>
                                }
                            </div>
                        </div>
                    )}
                    {withRewardTokenField?.value && (
                        <div className="mb-6">
                            <p className="text-primaryInput">
                                How many winners ? <sup className="text-lg relative top-[1px]">*</sup>
                            </p>
                            <Input
                                {...noOfWinnersField}
                                type="number"
                                error={errors?.noOfWinners?.message}
                                onChange={(e) => {
                                    // if (distributionModeField.value === 'Rank based distribution') {
                                    const count = parseInt(e.target.value, 10);
                                    if (!isNaN(count) && count >= 0) {
                                        // Clear existing fields if needed
                                        const currentFields = ranksField.length;

                                        // If the new count is greater than the current count, append fields
                                        for (let i = currentFields; i < count; i++) {
                                            appendRank({ value: '' });
                                        }

                                        // If the new count is less than the current count, remove fields
                                        for (let i = currentFields; i > count; i--) {
                                            removeRank(i - 1);
                                        }
                                        // }
                                    }
                                    noOfWinnersField.onChange(e.target.value);
                                }}
                                placeholder={'Enter number of winners'}
                                className={'w-[100%] mt-2'}
                            />
                        </div>
                    )}
                    {withRewardTokenField?.value && (
                        <div className="mb-6">
                            <p className="text-primaryInput">How would you like to distribute the rewards ?</p>
                            <RadioButtonComponent
                                className={'flex-col mt-2'}
                                selectedOption={distributionModeField.value}
                                onSelectOption={distributionModeField.onChange}
                                options={['Distribute evenly between all winners', 'Rank based distribution']}
                            />
                        </div>
                    )}

                    {withRewardTokenField?.value
                        ? distributionModeField.value === 'Rank based distribution' && (
                              <RankBasedDistributions
                                  errors={errors}
                                  register={register}
                                  ranksField={ranksField}
                                  control={control}
                              />
                          )
                        : null}
                    {withRewardTokenField?.value && (
                        <div className="mb-6">
                            <p className="mt-2 text-primaryInput">
                                Maximum Impact Points allocated:{' '}
                                <span className="font-bold">{impactPointsData ? impactPointsData : null}</span>{' '}
                            </p>
                        </div>
                    )}
                    <div className="mb-6">
                        <TextWithTooltip
                            heading={
                                <p className="text-primaryInput">
                                    Set Pass Threshold <sup className="text-lg relative top-[1px]">*</sup>
                                </p>
                            }
                        >
                            <p className="text-sm">
                                Determines the cut off accuracy for the user to be eligible to win.
                            </p>
                            <p className="text-sm mt-3 ">
                                ex, if 50% is chosen as the Pass Threshold. A users with 40% accuracy upon validation
                                will not be eligible to be a winner.
                            </p>
                        </TextWithTooltip>
                        <Input
                            {...passThresholdField}
                            type="number"
                            step="0.002"
                            error={errors?.passThreshold?.message}
                            placeholder={'Enter the pass %'}
                            className={'w-[100%] mt-2'}
                        />
                    </div>
                    <div className=" mb-6">
                        <div className="flex gap-2 items-center">
                            <Toggle
                                valuee={hasImpactCertificateField.value}
                                onChangeFn={() => {
                                    if (getValues()?.hasImpactCertificate) {
                                        clearErrors('impactBrief');
                                        hasImpactCertificateField.onChange(false);
                                        return;
                                    }
                                    if (!getValues()?.hasImpactCertificate) hasImpactCertificateField.onChange(true);
                                }}
                            />
                            <Heading type="xsmall" className="capitalize  text-primaryInput">
                                Impact Certificate
                            </Heading>
                        </div>
                        <p className="text-sm text-secondaryInput">
                            Do you wish to issue impact certificates for this bounty?
                        </p>
                    </div>
                    {hasImpactCertificateField.value && (
                        <div className="mb-6">
                            <TextWithTooltip
                                heading={
                                    <p className="text-primaryInput">
                                        Impact Certificate Brief <sup className="text-lg relative top-[1px]">*</sup>
                                    </p>
                                }
                            >
                                <p className="text-sm">
                                    Briefly describe the impact created by the user by doing the bounty
                                </p>
                            </TextWithTooltip>

                            <Input
                                {...impactBriefField}
                                type="textArea"
                                name="impactField"
                                placeholder={'Enter a brief description'}
                                className={'w-[100%]'}
                                error={errors?.impactBrief?.message}
                            />
                            <CharacterLimit min={impactBriefField.value?.length} max={100} />
                        </div>
                    )}
                    <div className="mb-6">
                        <TextWithTooltip
                            heading={
                                <Heading type="xsmall" className="capitalize  text-primaryInput">
                                    Additional Impact Cores
                                </Heading>
                            }
                        >
                            <p className="text-sm">
                                You have previously chosen a dominant impact core. Here you can add more impact cores
                                which would be displayed in the impact certificate.
                            </p>
                        </TextWithTooltip>
                        <div className="relative">
                            <Dropdown
                                w="w-full"
                                data={[
                                    getValues()?.dominantCore,
                                    ...coresData.filter((core) => core !== getValues()?.dominantCore),
                                ]}
                                modifySelected={(array) => {
                                    return array.map((text) => (
                                        <span className="flex items-center gap-1">
                                            <img
                                                className={` ${
                                                    text.toLowerCase() === 'water' || text.toLowerCase() === 'social'
                                                        ? 'w-4 mr-1'
                                                        : 'w-5 h-5'
                                                }  `}
                                                src={
                                                    coresWithIconData?.find(
                                                        (obj) => obj.label.toLowerCase() === text.toLowerCase()
                                                    )?.icon
                                                }
                                                alt="image"
                                            />
                                            <span>{text}</span>
                                        </span>
                                    ));
                                }}
                                selected={[...new Set(coresField.value)]}
                                render={(listItem) => {
                                    return (
                                        <Checkbox
                                            key={listItem + Math.random()}
                                            pl="pl-5"
                                            name={listItem}
                                            value={coresField.value.includes(listItem)}
                                            disabling={listItem === getValues()?.dominantCore}
                                            label={listItem}
                                            icon={
                                                <img
                                                    className={` ${
                                                        listItem.toLowerCase() === 'water' ||
                                                        listItem.toLowerCase() === 'social'
                                                            ? 'w-4 mr-1'
                                                            : 'w-5 h-5'
                                                    }  `}
                                                    src={
                                                        coresWithIconData?.find(
                                                            (obj) => obj.label.toLowerCase() === listItem.toLowerCase()
                                                        )?.icon
                                                    }
                                                    alt="image"
                                                />
                                            }
                                            update={() => updateCores(coresField.value.includes(listItem), listItem)}
                                        />
                                    );
                                }}
                                withCheckBox={true}
                            />
                        </div>
                    </div>

                    <div className="mb-6">
                        <div className="mb-6">
                            <TextWithTooltip
                                heading={
                                    <Heading type="xsmall" className="capitalize mb-2 text-primaryInput">
                                        SDGs <sup className="text-lg relative top-[1px]">*</sup>
                                    </Heading>
                                }
                            >
                                <p className="text-sm">Choose the SDGs based on the impact areas of bounty.</p>
                                <p className="mt-3 text-sm">
                                    Sustainable development goals is a framework created by the UN that we're using to
                                    measure impact. You can learn more about it here:{' '}
                                    <a className="text-foundryBlue" href="https://sdgs.un.org/goals" target="_blank">
                                        https://sdgs.un.org/goals
                                    </a>
                                </p>
                            </TextWithTooltip>

                            <div className="relative mb-2">
                                <Dropdown
                                    data={SUSTAINABLE_DEVELOPMENT_GOALS}
                                    selected={dominantSDGField.value}
                                    onSelected={dominantSDGField.onChange}
                                    noSelectedText="Choose Dominant SDG"
                                    error={errors?.dominantSDG?.message}
                                />
                            </div>
                            <div className="relative">
                                <Dropdown
                                    w="w-full"
                                    data={[
                                        getValues()?.dominantSDG,
                                        ...SUSTAINABLE_DEVELOPMENT_GOALS.filter(
                                            (SDG) => SDG !== getValues()?.dominantSDG
                                        ),
                                    ]}
                                    selected={SDGsField.value}
                                    noSelectedText="Choose other SDGs"
                                    render={(listItem) => {
                                        return (
                                            <Checkbox
                                                pl="pl-5"
                                                name={listItem}
                                                value={SDGsField.value.includes(listItem)}
                                                disabling={listItem === getValues()?.dominantSDG}
                                                label={listItem}
                                                update={() =>
                                                    handleUpdateOptions(SDGsField.value.includes(listItem), listItem)
                                                }
                                            />
                                        );
                                    }}
                                    withCheckBox={true}
                                />
                                {maxSDGError && <p className="text-foundryRed mt-2">{maxSDGError}</p>}
                            </div>
                        </div>
                    </div>
                    <div className=" mb-2">
                        <div className="flex gap-2 items-center">
                            <Toggle
                                valuee={isAttestableField.value}
                                onChangeFn={() => {
                                    if (getValues()?.isAttestable) {
                                        isAttestableField.onChange(false);
                                        return;
                                    }
                                    if (!getValues()?.isAttestable) isAttestableField.onChange(true);
                                }}
                            />
                            <TextWithTooltip
                                heading={
                                    <Heading type="xsmall" className="capitalize  text-primaryInput">
                                        Attestation
                                    </Heading>
                                }
                            >
                                <p className="text-sm">
                                    Attesters are those who verify the authenticity of the bounty validation, this is
                                    done to ensure rewards are given to the rightful winners. It's done using Etherium
                                    Attestation Services (EAS)
                                </p>
                            </TextWithTooltip>
                        </div>
                        <p className="text-sm text-secondaryInput">Do you want this bounty to be attestable?</p>
                    </div>
                </div>
            </div>
            <FloatingButtons>
                <Button type="button" onClick={() => setStep(5)} className="bg-white" variant="tertiary" size="small">
                    Back
                </Button>
                <Button size="xsmall" type="submit">
                    {loading ? <Loader /> : '  Publish Bounty'}
                </Button>
            </FloatingButtons>
        </div>
    );
}

export default StepSix;
