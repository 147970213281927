export const FeedbackIcon = () => {
    return (
        <>
            <svg width="25" height="25" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.805 0.408932C17.8075 0.411432 17.81 0.413932 17.81 0.418932C18.13 0.858932 18.05 1.47893 17.62 1.79893C17.4633 1.91722 17.3039 2.03813 17.1435 2.15985C16.7614 2.44975 16.3733 2.74426 16 3.01893C15.56 3.34893 14.95 3.25893 14.62 2.81893C14.62 2.81393 14.6175 2.81143 14.615 2.80893C14.6125 2.80643 14.61 2.80393 14.61 2.79893C14.28 2.36893 14.36 1.74893 14.8 1.41893C15.1312 1.16416 15.4827 0.901281 15.8312 0.640621C16.0299 0.492031 16.2276 0.344163 16.42 0.198932C16.85 -0.131068 17.47 -0.0410684 17.8 0.398932C17.8 0.403932 17.8025 0.406432 17.805 0.408932Z"
                    fill="#969696"
                />
                <path
                    d="M16 7.60889C16 8.15889 16.45 8.60889 17 8.60889H19C19.55 8.60889 20 8.15889 20 7.60889C20 7.05889 19.55 6.60889 19 6.60889H17C16.45 6.60889 16 7.05889 16 7.60889Z"
                    fill="#969696"
                />
                <path
                    d="M14.59 12.4288C14.26 12.8688 14.35 13.4788 14.79 13.7988C15.32 14.1888 15.88 14.6088 16.41 15.0088C16.85 15.3388 17.47 15.2488 17.79 14.8088C17.79 14.8038 17.7925 14.8013 17.795 14.7988C17.7975 14.7963 17.8 14.7938 17.8 14.7888C18.13 14.3488 18.04 13.7288 17.6 13.4088C17.0718 13.0101 16.5137 12.5915 15.9952 12.2026L15.99 12.1988C15.55 11.8688 14.93 11.9688 14.6 12.4088C14.6 12.4188 14.59 12.4288 14.59 12.4288Z"
                    fill="#969696"
                />
                <path
                    d="M6 4.60889H2C0.9 4.60889 0 5.50889 0 6.60889V8.60889C0 9.70889 0.9 10.6089 2 10.6089H3V13.6089C3 14.1589 3.45 14.6089 4 14.6089C4.55 14.6089 5 14.1589 5 13.6089V10.6089H6L11 13.6089V1.60889L6 4.60889Z"
                    fill="#969696"
                />
                <path
                    d="M12 4.25879C12.92 5.07879 13.5 6.27879 13.5 7.60879C13.5 8.93879 12.92 10.1388 12 10.9488V4.25879Z"
                    fill="#969696"
                />
            </svg>
            <span className="">Feedback?</span>
        </>
    );
};

export const HelpIcon = ({ ...props }) => {
    return (
        <div {...props} className="cursor-pointer">
            <svg width="25" height="25" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 0C3.81 0 0 3.81 0 8.5C0 13.19 3.81 17 8.5 17H9V20C13.86 17.66 17 13 17 8.5C17 3.81 13.19 0 8.5 0ZM9.5 14.5H7.5V12.5H9.5V14.5ZM9.9 9.72C9.89 9.73 9.88 9.75 9.87 9.77C9.82 9.85 9.77 9.93 9.73 10.01C9.71 10.04 9.7 10.08 9.69 10.12C9.66 10.19 9.63 10.26 9.61 10.33C9.54 10.54 9.51 10.76 9.51 11.01H7.5C7.5 10.5 7.58 10.07 7.7 9.71C7.7 9.7 7.7 9.69 7.71 9.68C7.72 9.64 7.75 9.62 7.76 9.58C7.82 9.42 7.89 9.28 7.98 9.14C8.01 9.09 8.05 9.04 8.08 8.99C8.11 8.95 8.13 8.9 8.16 8.87L8.17 8.88C9.01 7.78 10.38 7.44 10.49 6.2C10.58 5.22 9.88 4.27 8.92 4.07C7.88 3.85 6.94 4.46 6.62 5.35C6.48 5.71 6.15 6 5.74 6H5.54C4.94 6 4.5 5.41 4.67 4.83C5.22 3.01 7.04 1.74 9.1 2.04C10.79 2.29 12.14 3.68 12.43 5.37C12.87 7.81 10.8 8.4 9.9 9.72Z"
                    fill="#969696"
                />
            </svg>
        </div>
    );
};
export const NotifIcon = ({ notificationsCount, ...props }) => {
    return (
        <div {...props} className="relative cursor-pointer">
            <svg width="25" height="25" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.00005 20C9.10005 20 10.0001 19.1 10.0001 18H6.00005C6.00005 19.1 6.89005 20 8.00005 20ZM14.0001 14V9C14.0001 5.93 12.3601 3.36 9.50005 2.68V2C9.50005 1.17 8.83005 0.5 8.00005 0.5C7.17005 0.5 6.50005 1.17 6.50005 2V2.68C3.63005 3.36 2.00005 5.92 2.00005 9V14L0.710051 15.29C0.0800514 15.92 0.520051 17 1.41005 17H14.5801C15.4701 17 15.9201 15.92 15.2901 15.29L14.0001 14Z"
                    fill="#969696"
                />
            </svg>
            <span className="text-white bg-[#E92C2C] w-4 h-4 flex items-center justify-center  rounded-full -top-1 -right-1 text-[8px]    absolute">
                {notificationsCount < 100 ? notificationsCount : <span className="">99+</span>}
            </span>
        </div>
    );
};
