import { useState } from 'react';

function Toggle({ valuee, onChangeFn, from }) {
    const [value, setValue] = useState(valuee);

    const handleChange = (e) => {
        setValue((prev) => !prev); // Toggle the value
        onChangeFn(e);
    };
    if (from === 'microtask') {
        return (
            <label className="relative flex items-center cursor-pointer">
                <input type="checkbox" checked={value} onChange={handleChange} className="sr-only peer" />
                <span
                    className="w-14 h-8 flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out 
  peer-checked:bg-orange-400 relative"
                >
                    <span
                        className={`w-6 h-6 bg-white rounded-full shadow-md transition-transform duration-300 
    ${value ? 'translate-x-6' : 'translate-x-0'}`}
                    ></span>
                </span>
            </label>
        );
    }
    return (
        <label className="relative flex items-center cursor-pointer">
            <input type="checkbox" checked={value} onChange={handleChange} className="sr-only peer" />
            <span
                className="w-14 h-8 flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out 
          peer-checked:bg-orange-400 relative"
            >
                <span
                    className={`w-6 h-6 bg-white rounded-full shadow-md transition-transform duration-300 
            ${value ? 'translate-x-6' : 'translate-x-0'}`}
                ></span>
            </span>
        </label>
    );
}
export default Toggle;
