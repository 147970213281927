import eventPreviewExploreOne from '../../assets/eventPreviewExploreOne.png';
import eventPreviewExploreTwo from '../../assets/eventPreviewExploreTwo.png';
import noBanner from '../../assets/nobanner.png';
import globalImpactIndex from '../../assets/globalImpactIndex.png';
import AvatarImage from '../../ui/AvatarImage';
import Button from '../../ui/Button';
import LinkRenderer from '../../ui/LinkRenderer';
const mobileFrame = '/assets/images/mobileFrameBlack.png';
function EventPreviewLanding({ imageURL, title, heading, description }) {
    return (
        <div
            className="relative w-[300px] h-[610px]  justify-self-end   "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div className="absolute top-5   rounded-t-3xl pb-1 px-2 pt-12 left-6 right-6 bg-[#1A56DB] ">
                <p className="text-white">{title ? '<- ' + title : '<-' + ' Title Name Comes Here'}</p>
            </div>
            <main className="absolute  top-24 left-6 right-6 h-[70%] overflow-auto main  ">
                <div className="bg-[#EBEBEB] rounded-md overflow-hidden mb-4 ">
                    <div className="flex w-[252px]  h-[363px]  items-center justify-center">
                        {!imageURL ? (
                            <img className="w-8 h-8" src={noBanner} alt="" />
                        ) : (
                            <img className="w-full h-full object-fill " src={imageURL} alt="" />
                        )}
                    </div>
                </div>
                <div>
                    <p className="text-white font-medium text-sm mb-1">{heading || 'heading'}</p>
                    {/* <p className="text-white text-[10px]  ">{description || ' '}</p> */}
                    <LinkRenderer text={description} links={[]} paragraphClass="text-white" dummyText="description" />
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                {/* <Button variant="preview" size="roundPreview">
                    Add Deliverables & Tips
                </Button> */}
                <Button variant="miner" size="largeMiner" className="py-2 text-xs mb-3" radius="rounded-full">
                    Enter {title || '__Title__'} {'->'}
                </Button>
            </footer>
        </div>
    );
}

export default EventPreviewLanding;
