import Heading from '../../ui/Heading';
const atlantislogo = '/assets/images/logo.png';
function LoginText() {
    return (
        <div className="min-h-[calc(100vh-10vh)]  flex flex-col justify-between ">
            <div className="px-6">
                <h1 className={'text-black text-9xl -ml-4 font-major'}>impAct</h1>

                <Heading type={'fulllarge'} className={'text-black   font-black'}>
                    FOUNDRY
                </Heading>

                <p className="text-2xl">create, manage and monitor impact on-chain.</p>
            </div>
            <div className="flex items-center ">
                <div>
                    <img alt="Atlantis logo" src={atlantislogo} />
                </div>
                <div>
                    <Heading type="large" className={'text-black font-major '}>
                        built by Atlantis
                    </Heading>

                    <Heading type="large" className={'text-black font-black  '}>
                        FOR CHANGEMAKERS
                    </Heading>
                </div>
            </div>
        </div>
    );
}
export default LoginText;
