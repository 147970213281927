import 'mapbox-gl/dist/mapbox-gl.css';

import DisplayMap from '../features/homepage/DisplayMap';
import InfoForUser from '../features/homepage/InfoForUser';
import Bg from '../assets/mainBg.png';
import Heading from '../ui/Heading';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Button from '../ui/Button';
const Homepage = () => {
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];
    const navigate = useNavigate();
    if (!user.length) {
        return (
            <div
                style={{
                    backgroundImage: `url(${Bg})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100vh',
                }}
                className="p-9"
            >
                <div className="w-[50%]">
                    Please Go and create your profile first.
                    <Button variant="secondary" onClick={() => navigate('/createprofile')}>
                        Go To Create Profile
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
            className="p-9 extraSize"
        >
            <div className="mb-6">
                <Heading type="xlarge">Welcome back! {userProfile?.userName}</Heading>
            </div>
            <div className="grid grid-cols-2  gap-9 bg-fixed ">
                <InfoForUser />
                <DisplayMap />
            </div>
        </div>
    );
};

export default Homepage;
