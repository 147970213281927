import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../../ui/Table';
import { ValidationOverviewData } from '../../../constants/validationOverviewData';
import Tag from '../../../ui/Tag';
import { getCreationOrValidationRequestsOrProposals } from '../../../apis/treasurer/treasurer';
import { useContext, useEffect, useState } from 'react';
import NoDataAvailable from '../../../ui/NoDataAvailable';
import Loader from '../../../ui/Loader';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import { executorToFindIfUserHasVoted } from '../../../blockchainInteraction/votingOnProposals';
import { PublicKey } from '@solana/web3.js';
import VotedTick from '../../../assets/icons/VotedTick';
import VotedTickRed from '../../../assets/icons/VotedTickRed';
import { VOTING_STATUS_COLOR } from '../../../constants/datas';
import { AuthContext } from '../../../contexts/AuthContext';
import { formatIntDateAndThenTime } from '../../../helpers/formatInDateAndThenTime';
import { RPC_ENDPOINT } from '../../../constants/apiPaths';
// import Button from '../../../ui/Button';

const fetchValueForRow = async (rowId, walletAddress, multiSig) => {
    const connection = new Connection(RPC_ENDPOINT, 'confirmed');
    try {
        const hasUserVoted = await executorToFindIfUserHasVoted(
            connection,
            new PublicKey(rowId),
            new PublicKey(walletAddress),
            multiSig
        );
        // if (data.length) onRowSelection(0, data[0]._id);
        return hasUserVoted;
    } catch (error) {
        return null;
    }
};

function BountyValidationProposals({
    onRowSelection,
    validationProposals,
    handleSetValidationProposals,
    activeValidationProposal,
    fetchedValuesValidation,
    setFetchedValuesValidation,
    multiSig,
}) {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];
    const { id } = useParams();
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getCreationOrValidationRequestsOrProposals('BOUNTY_VALIDATION', id, true);
                    handleSetValidationProposals(data);

                    setIsLoading(false);

                    if (data.length) onRowSelection(0, data[0]._id);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );

    useEffect(() => {
        // This effect will run when the component mounts and whenever `rows` changes.
        validationProposals.forEach(async (row) => {
            if (!fetchedValuesValidation[row.proposalAddress]) {
                const value = await fetchValueForRow(row.proposalAddress, userProfile.walletAddress, multiSig);
                setFetchedValuesValidation((prevValues) => ({
                    ...prevValues,
                    [row.proposalAddress]: value,
                }));
            }
        });
    }, [validationProposals]);

    return (
        <div className="min-h-[60vh]">
            <Table.Header>
                <p className="text-sm   font-semibold text-secondaryText  "> Bounty ID </p>
                <p className="text-sm   font-semibold text-secondaryText  ">Bounty Name</p>
                <p className="text-sm   font-semibold text-secondaryText  ">Proposal Created Time</p>
                <p className="text-sm   font-semibold text-secondaryText  ">Status</p>
            </Table.Header>
            {isLoading ? (
                <div className="min-h-[20vh] flex justify-center items-center">
                    <Loader />
                </div>
            ) : validationProposals.length ? (
                validationProposals.map((overviewrow, index) => (
                    <Table.Row
                        key={overviewrow._id}
                        rowClassName={`cursor-pointer ${activeValidationProposal === index ? 'bg-update' : ''}  `}
                        onClick={() => onRowSelection(index, overviewrow._id)}
                    >
                        <p
                            onClick={() => navigate(`/organizations/${id}/view-bounties/${overviewrow.bountyId}`)}
                            className=" text-primaryColor cursor-pointer truncate w-full uppercase justify-self-start  font-medium "
                        >
                            {overviewrow.bountyId}
                        </p>
                        <p
                            onClick={() => navigate('bounty-submissions')}
                            className=" cursor-pointer text-primaryInput  truncate"
                        >
                            {overviewrow.bountyTitle}
                        </p>
                        <p className="text-primaryInput ">
                            {formatIntDateAndThenTime(overviewrow.proposalCreationTime)}
                        </p>
                        <p className=" ">
                            {fetchedValuesValidation[overviewrow.proposalAddress] ? (
                                <Tag
                                    rounded="rounded-lg "
                                    className="px-2 py-1 leading-3   "
                                    textClassName="text-xs  font-semibold capitalize"
                                    bg={VOTING_STATUS_COLOR[fetchedValuesValidation[overviewrow.proposalAddress]]?.bg}
                                    textColor={
                                        VOTING_STATUS_COLOR[fetchedValuesValidation[overviewrow.proposalAddress]]?.text
                                    }
                                    icon={
                                        fetchedValuesValidation[overviewrow.proposalAddress] === 'not voted' ||
                                        fetchedValuesValidation[overviewrow.proposalAddress] ===
                                            'ready to execute' ? null : fetchedValuesValidation[
                                              overviewrow.proposalAddress
                                          ] === 'voted yes' ? (
                                            <VotedTick />
                                        ) : (
                                            <VotedTickRed />
                                        )
                                    }
                                    text={
                                        fetchedValuesValidation[overviewrow.proposalAddress] === 'ready to execute'
                                            ? 'Ready To Execute'
                                            : fetchedValuesValidation[overviewrow.proposalAddress] === 'not voted'
                                            ? 'Ready for your vote'
                                            : fetchedValuesValidation[overviewrow.proposalAddress].split(' ').join('-')
                                    }
                                />
                            ) : (
                                'Loading...'
                            )}
                        </p>
                    </Table.Row>
                ))
            ) : (
                <NoDataAvailable />
            )}
        </div>
    );
}

export default BountyValidationProposals;
