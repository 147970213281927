import LayoutWithoutSidebar from '../ui/LayoutWithoutSidebar';
import SideLogo from '../features/profilecreation/SideLogo';

import ProfileInput from '../features/profilecreation/ProfileInput';
function CreateProfile() {
    return (
        <div className="flex justify-between w-[65%] mx-auto mt-14 mb-3 min-h-[calc(100vh-15vh)]  ">
            <div className=" justify-self-center w-[80%] ">
                <ProfileInput />
            </div>

            <SideLogo />
        </div>
    );
}

export default CreateProfile;
