import React, { useState } from 'react';
import { NotifIcon } from '../assets/icons/HeaderIcons';

// import { connectToNotificationStream, getNotifications } from '../apis/notifications/notifications';

import NotificationList from '../features/notifications/NotificationList';
import { useNotifications } from '../contexts/NotificationsContext';
import NoDataAvailable from './NoDataAvailable';
import DeleteAllNotifications from '../features/notifications/DeleteAllNotifications';

const Notifications = () => {
    // State to toggle notification panel
    const [isOpen, setIsOpen] = useState(false);
    // const [notifications, setNotifications] = useState([]);
    const { state, onDelete } = useNotifications();
    const notifications = state.notifications;

    // useEffect(() => {
    //     let eventSource;

    //     const initNotificationStream = async () => {
    //         eventSource = await connectToNotificationStream(setNotifications);
    //     };

    //     initNotificationStream();

    //     // Cleanup on component unmount
    //     return () => {
    //         if (eventSource) {
    //             eventSource.close();
    //             console.log('SSE connection closed');
    //         }
    //     };
    // }, []);

    // const onDelete = (id) => {
    //     const afterDelete = notifications.filter((notif) => notif.id !== id);
    //     setNotifications(afterDelete);
    // };

    // Toggle the panel open/close
    const toggleNotificationPanel = () => {
        setIsOpen(!isOpen);
    };

    // const unreadNotifs = notifications?.filter((notification) => !notification.isRead);
    const unreadNotifs = Array.isArray(notifications)
        ? notifications.filter((notification) => !notification.isRead)
        : [];

    return (
        <>
            <div className="">
                <NotifIcon notificationsCount={unreadNotifs.length} onClick={toggleNotificationPanel} />
            </div>

            {isOpen && <div onClick={toggleNotificationPanel} className="fixed inset-0  z-40"></div>}

            <div
                style={{ height: 'calc(100vh - 62px)' }}
                className={`  fixed top-16 right-0  w-[35%] backdrop-blur-lg bg-white bg-opacity-30 shadow-xl z-50 transform transition-transform duration-300 ${
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                } `}
            >
                <div className="p-5 h-full overflow-y-auto">
                    <div className="flex items-center justify-between mb-5">
                        <h2 className="text-xl font-semibold ">Notifications</h2>
                        {/* <DeleteAllNotifications /> */}
                    </div>
                    {notifications?.length > 0 ? (
                        <NotificationList
                            notifications={notifications}
                            onDelete={onDelete}
                            onToggle={toggleNotificationPanel}
                        />
                    ) : (
                        <NoDataAvailable />
                    )}
                </div>
            </div>
        </>
    );
};

export default Notifications;
