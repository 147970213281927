import { useController } from 'react-hook-form';
import Input from '../../../ui/Input';
import GetCoordinates from './GetCoordinates';
import IsSkippable from './IsSkippable';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useContext, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken =
    'pk.eyJ1IjoiZmFpc2FsZHJvcGNoYWluIiwiYSI6ImNsdXJnOWF2czA3NmYyam4xZWkzbmltaHAifQ.IK0wrkOv-H-C57rLCcMBMA';
const VisitLocation = ({ register, index, errors, control, setValue, microtaskEditDetail, currentMt }) => {
    const { isEdit } = useContext(BountiesContext);

    useEffect(() => {
        // Run this logic only once when the component is mounted
        if (isEdit) {
            setValue(`microtasks.${index}.goToLocation_coordinate`, microtaskEditDetail?.gotoLocation_coordinate);
        }
    }, [isEdit, microtaskEditDetail]);
    // const [editLocation, setEditLocation] = useState('');

    // useEffect(() => {
    //     const fetchLocationFromCoordinates = async ([lat, lng]) => {
    //         try {
    //             const response = await fetch(
    //                 `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`
    //             );
    //             const data = await response.json();
    //             console.log(data, 'see after reverse');

    //             if (data.features.length > 0) {
    //                 // Extract the location (e.g., place_name)
    //                 const location = data.features[0].place_name;

    //                 // Optionally, set the search text to this location
    //                 setEditLocation(location);
    //             }
    //         } catch (error) {
    //             console.error('Error reverse geocoding coordinates:', error);
    //         }
    //     };

    //     if (isEdit) fetchLocationFromCoordinates(microtaskEditDetail?.gotoLocation_coordinate?.split(','));
    // }, [isEdit]); // Dependency on searchQuery and citySelected

    return (
        <div>
            <Input
                {...register(`microtasks.${index}.goToLocation_Title`, {
                    required: 'Please enter a title',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.gotoLocation_Title || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.goToLocation_Title?.message}
                placeholder={'Title *'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 '}
            />
            <Input
                {...register(`microtasks.${index}.goToLocation_Description`, {
                    required: 'Please enter a description',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.gotoLocation_Description || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.goToLocation_Description?.message}
                placeholder={'Description *'}
                transparentTextSize="text-sm"
                transparentFontWeight={'font-normal'}
                className={'w-[100%] mb-1 mt-2 '}
            />

            <GetCoordinates
                control={control}
                errors={errors}
                microIndex={index}
                setValue={setValue}
                editCoords={
                    isEdit
                        ? microtaskEditDetail?.gotoLocation_coordinate?.split(',')?.reverse()
                        : Boolean(currentMt?.goToLocation_coordinate)
                        ? currentMt?.goToLocation_coordinate?.split(',')?.reverse()
                        : [77.5115746, 12.972048]
                }
                isEdit={isEdit}
            />
            {/* <div className="flex gap-9 my-2 items-center ">
                <IsSkippable
                    selectedOption={skippableField.value}
                    onSelectOption={(value) => {
                        skippableField.onChange(value);
                    }}
                    options={['Yes', 'No']}
                />
            </div> */}
        </div>
    );
};

export default VisitLocation;
