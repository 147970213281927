import DownloadPDFSD from '../../submissionComponents/DownloadPDFSD';
import GetUserLocationSD from '../../submissionComponents/GetUserLocationSD';
import InstructionalTextSD from '../../submissionComponents/InstructionalTextSD';
import McqSD from '../../submissionComponents/McqSD';
import MultiSelectMCQSD from '../../submissionComponents/MultiSelectMCQSD';
import QuestionAndAnswerSD from '../../submissionComponents/QuestionAndAnswerSD';
import TestMCQSD from '../../submissionComponents/TestMCQSD';
import UploadFileSD from '../../submissionComponents/UploadFileSD';
import UploadMultiplePicturesSD from '../../submissionComponents/UploadMultiplePicturesSD';
import UploadVideoSD from '../../submissionComponents/UploadVideoSD';
import VisitLocationSD from '../../submissionComponents/VisitLocationSD';
import WatchYoutubeVideoSD from '../../submissionComponents/WatchYoutubeVideoSD';
import DownloadPDFSubDetails from '../microTaskSubDetails/DownloadPDFSubDetails';
import GetUserLocationSubDetails from '../microTaskSubDetails/GetUserLocationSubDetails';
import InstructionalTextSubDetails from '../microTaskSubDetails/InstructionalTextSubDetails';
import McqSubDetails from '../microTaskSubDetails/McqSubDetails';
import MultiSelectMCQSubDetails from '../microTaskSubDetails/MultiSelectMCQSubDetails';
import QuestionAndAnswerSubDetails from '../microTaskSubDetails/QuestionAndAnswerSubDetails';
import TestMCQSubDetails from '../microTaskSubDetails/TestMCQSubDetails';
import UploadFileSubDetails from '../microTaskSubDetails/UploadFileSubDetails';
import UploadMultiplePicturesSubDetails from '../microTaskSubDetails/UploadMultiplePicturesSubDetails';
import UploadVideoSubDetails from '../microTaskSubDetails/UploadVideoSubDetails';
import VisitLocationSubDetails from '../microTaskSubDetails/VisitLocationSubDetails';
import WatchYoutubeVideoSubDetails from '../microTaskSubDetails/WatchYoutubeVideoSubDetails';
import PreviewDownloadPDF from '../previews/PreviewDownloadPDF';
import PreviewGetUserLocation from '../previews/PreviewGetUserLocation';
import PreviewInstructionalText from '../previews/PreviewInstructionalText';
import PreviewMcq from '../previews/PreviewMcq';
import PreviewMultiSelectMCQ from '../previews/PreviewMultiSelectMCQ';
import PreviewQuestionAndAnswer from '../previews/PreviewQuestionAndAnswer';
import PreviewTestMCQ from '../previews/PreviewTestMCQ';
import PreviewUploadFile from '../previews/PreviewUploadFile';
import PreviewUploadMultiplePictures from '../previews/PreviewUploadMultiplePictures';
import PreviewUploadPicture from '../previews/PreviewUploadPicture';
import PreviewUploadVideo from '../previews/PreviewUploadVideo';
import PreviewVisitLocation from '../previews/PreviewVisitLocation';
import PreviewWatchYoutubeVideo from '../previews/PreviewWatchYoutubeVideo';
import DownloadPDF from './DownloadPDF';
import GetUserLocation from './GetUserLocation';
import InstructionalText from './InstructionalText';
import Mcq from './Mcq';
import MultiSelectMCQ from './MultiSelectMCQ';
import QuestionAndAnswer from './QuestionAndAnswer';
import TestMCQ from './TestMCQ';
import UploadFile from './UploadFile';
import UploadMultiplePictures from './UploadMultiplePictures';
import UploadPicture from './UploadPicture';
import UploadVideo from './UploadVideo';
import VisitLocation from './VisitLocation';
import WatchYoutubeVideo from './WatchYoutubeVideo';

// export const microtaskTypeData = [
//     {
//         type: 'QUESTION_ANSWER',
//         label: 'Question & Answer',
//         getComponent: (register, index, errors, control) => (
//             <QuestionAndAnswer register={register} index={index} errors={errors} control={control} />
//         ),
//         getFields: () => ({
//             questionAnswer_Title: '',
//             questionAnswer_question: '',
//             questionAnswer_heading: '',
//             questionAnswer_image: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewQuestionAndAnswer data={previewData} />,
//         getSubmissionDataComponent: (taskSubmission) => <QuestionAndAnswerSD dataTask={taskSubmission} />,
//         getMicroTaskSubDetailsComponent : (dataSubDetails)=> <QuestionAndAnswerSubDetails data={dataSubDetails}  />
//     },
//     {
//         type: 'UPLOAD_FILE',
//         label: 'Upload File',
//         getComponent: (register, index, errors, control) => (
//             <UploadFile register={register} index={index} errors={errors} control={control} />
//         ),
//         getFields: () => ({
//             uploadFile_Title: '',
//             uploadFile_Description: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewUploadFile data={previewData} />,
//         getSubmissionDataComponent: (taskSubmission) => <UploadFileSD dataTask={taskSubmission} />,
//     },
//     {
//         type: 'MULTI_SELECT_MULTIPLE_CHOICE_QUESTION',
//         label: 'Multi-Select MCQ',
//         getComponent: (register, index, errors, control, setValue, getValues) => (
//             <MultiSelectMCQ
//                 register={register}
//                 index={index}
//                 errors={errors}
//                 control={control}
//                 setValue={setValue}
//                 getValues={getValues}
//             />
//         ),
//         getFields: () => ({
//             multiSelect_title: '',
//             multiSelect_question: '',
//             multiSelect_answers: '', // "answer1,answer2,answer3"
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewMultiSelectMCQ data={previewData} />,
//         getSubmissionDataComponent: (taskSubmission) => <MultiSelectMCQSD dataTask={taskSubmission} />,
//     },
//     {
//         type: 'GOTO_LOCATION',
//         label: 'Visit Location',
//         getComponent: (register, index, errors, control, setValue) => (
//             <VisitLocation register={register} index={index} errors={errors} control={control} setValue={setValue} />
//         ),
//         getFields: () => ({
//             goToLocation_Title: '',
//             goToLocation_Description: '',
//             goToLocation_coordinate: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewVisitLocation data={previewData} />,
//         // getPreview: (previewData) => <p>Preview coming</p>,
//         getSubmissionDataComponent: (taskSubmission) => <VisitLocationSD dataTask={taskSubmission} />,
//     },
//     // {
//     //     type: 'UPLOAD_PHOTO',
//     //     label: 'Upload Picture',
//     //     getComponent: (register, index, errors, control) => (
//     //         <UploadPicture register={register} index={index} errors={errors} control={control} />
//     //     ),
//     //     getFields: () => ({
//     //         uploadPicture_Title: '',
//     //         skippable: true,
//     //     }),
//     //     getPreview: (previewData) => <PreviewUploadPicture data={previewData} />,
//     // },

//     {
//         type: 'MULTI_CHOICE_QUESTIONs',
//         label: 'MCQ',
//         getComponent: (register, index, errors, control, setValue, getValues) => (
//             <Mcq
//                 register={register}
//                 index={index}
//                 errors={errors}
//                 control={control}
//                 setValue={setValue}
//                 getValues={getValues}
//             />
//         ),
//         getFields: () => ({
//             multiChoiceQuestions_Title: '',
//             multiChoiceQuestions_question: '',
//             multiChoiceQuestions_answers: '',
//             multiChoiceQuestions_instructionHeading: '',
//             multiChoiceQuestions_instructions: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewMcq data={previewData} />,
//         getSubmissionDataComponent: (taskSubmission) => <McqSD dataTask={taskSubmission} />,
//     },
//     {
//         type: 'UPLOAD_VIDEO',
//         label: 'Upload Video',
//         getComponent: (register, index, errors, control) => (
//             <UploadVideo register={register} index={index} errors={errors} control={control} />
//         ),
//         getFields: () => ({
//             uploadVideo_Title: '',
//             uploadVideo_Description: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewUploadVideo data={previewData} />,
//         getSubmissionDataComponent: (taskSubmission) => <UploadVideoSD dataTask={taskSubmission} />,
//     },
//     {
//         type: 'GET_LOCATION',
//         label: 'Get User Location',
//         getComponent: (register, index, errors, control) => (
//             <GetUserLocation register={register} index={index} errors={errors} control={control} />
//         ),
//         getFields: () => ({
//             getLocation_Title: '',
//             getLocation_Subheading: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewGetUserLocation data={previewData} />,
//         getSubmissionDataComponent: (taskSubmission) => <GetUserLocationSD dataTask={taskSubmission} />,
//     },
//     {
//         type: 'UPLOAD_MULTIPLE_PHOTOS',
//         label: 'Upload Multiple Pictures',
//         getComponent: (register, index, errors, control) => (
//             <UploadMultiplePictures register={register} index={index} errors={errors} control={control} />
//         ),
//         getFields: () => ({
//             uploadMultiplePictures_Title: '',
//             uploadMultiplePictures_ButtonText: 'Upload Pictures',
//             uploadMultiplePictures_Description: '',
//             uploadMultiplePictures_MinFiles: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewUploadMultiplePictures data={previewData} />,
//         getSubmissionDataComponent: (taskSubmission) => <UploadMultiplePicturesSD dataTask={taskSubmission} />,
//     },
//     {
//         type: 'DOWNLOAD_PDF',
//         label: 'Download PDF',
//         getComponent: (register, index, errors, control, setValue, getValues) => (
//             <DownloadPDF register={register} index={index} errors={errors} control={control} getValues={getValues} />
//         ),
//         getFields: () => ({
//             downloadPDF_Title: '',
//             downloadPDF_Description: '',
//             downloadPDF_Files: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewDownloadPDF data={previewData} />,
//         getSubmissionDataComponent: (previewData) => <DownloadPDFSD dataTask={previewData} />,
//     },
//     {
//         type: 'YOUTUBE_VIDEO',
//         label: 'Watch Youtube Video',
//         getComponent: (register, index, errors, control) => (
//             <WatchYoutubeVideo register={register} index={index} errors={errors} control={control} />
//         ),
//         getFields: () => ({
//             youtubeVideos_Title: '',
//             youtubeVideos_Description: '',
//             youtubeVideos_Link: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewWatchYoutubeVideo data={previewData} />,
//         getSubmissionDataComponent: (taskSubmission) => <WatchYoutubeVideoSD dataTask={taskSubmission} />,
//     },
//     {
//         type: 'INSTRUCTION',
//         label: 'Instructional Text',
//         getComponent: (register, index, errors, control, setValue, getValues, handleInstructionLinkPayload) => (
//             <InstructionalText
//                 register={register}
//                 index={index}
//                 errors={errors}
//                 control={control}
//                 setValue={setValue}
//                 getValues={getValues}
//                 handleInstructionLinkPayload={handleInstructionLinkPayload}
//             />
//         ),
//         getFields: () => ({
//             instruction_title: '',
//             instruction_message: '',
//             instruction_links: '',
//             instruction_images: '',
//             skippable: true,
//         }),
//         getPreview: (previewData, instructionLinkPayload) => (
//             <PreviewInstructionalText data={previewData} instructionLinkPayload={instructionLinkPayload} />
//         ),
//         getSubmissionDataComponent: (taskSubmission) => <p>coming</p>,
//     },
//     {
//         type: 'TEST_MCQ',
//         label: 'Test MCQ',
//         getComponent: (register, index, errors, control, setValue, getValues, microtaskFields) => (
//             <TestMCQ
//                 register={register}
//                 index={index}
//                 errors={errors}
//                 control={control}
//                 setValue={setValue}
//                 getValues={getValues}
//                 microtaskFields={microtaskFields}
//             />
//         ),
//         getFields: () => ({
//             testMCQ_Title: '',
//             testMCQ_question: '',
//             testMCQ_answers: '',
//             testMCQ_correctAnswer: '0',
//             testMCQ_instructionHeading: '',
//             testMCQ_instructions: '',
//             skippable: true,
//         }),
//         getPreview: (previewData) => <PreviewTestMCQ data={previewData} />,
//         // getSubmissionDataComponent: (taskSubmission) => <p>coming soons</p>,
//         getSubmissionDataComponent: (taskSubmission) => <TestMCQSD dataTask={taskSubmission} />,
//     },
// ];

// Note : Is this a mandatory task(by default it should be true(skippable by default should be false)) ? => through toggle or checkbox
// QUESTION_ANSWER ( Question & Answer ),
//   "Title",
//   "question",
//   "heading",
//   "image" (optional)=>This is link(string) we will get this on uploading.
//   [
//     {
//       "actions": [

//         {"actionType":"QUESTION_ANSWER",
//         "action":[{
//                     "name": "Title",
//                     "value": "title "
//                   },
//                   {
//                     "name": "question",
//                     "value": "question"
//                   },
//                   {
//                     "name": "heading",
//                     "value": "heading"
//                     },
//                     {
//                     "name": "image",
//                     "value": null }
//                   ]
//         }

//         ],
//       "skippable":false
//     }
// ]

// UPLOAD_FILE (Upload File)
//     "Title",
//     "Description"
//     [
//       {
//         "actions": [

//           {"actionType":"UPLOAD_FILE",
//           "action":[{
//                       "name": "Title",
//                       "value": "title "
//                     },
//                     {
//                       "name": "Description",
//                       "value": "question"
//                     }
//                     ]
//           }

//           ],
//         "skippable":false
//       }
//   ]

// MULTI_SELECT_MULTIPLE_CHOICE_QUESTION (Multi-Select MCQ),
//   "title",
//   "question",
//   "answers" refer (add ctiteria or add microtask)

//   [
//     {
//       "actions": [

//         {"actionType":"MULTI_SELECT_MULTIPLE_CHOICE_QUESTION",
//         "action":[{
//                     "name": "title",
//                     "value": "title "
//                   },
//                   {
//                     "name": "question",
//                     "value": "question"
//                   },
//                   {
//                     "name": "answers",
//                     "value": "answer1,answer2,answer3"
//                     },

//                   ]
//         }

//         ],
//       "skippable":false
//     }
// ]
// GOTO_LOCATION (Visit Location)
//   "Title",
//   "Description",
//   "coordinate" (Refer a map implemented on selecting physical bounty)
//   [
//     {
//       "actions": [

//         {"actionType":"GOTO_LOCATION",
//         "action":[{
//                     "name": "Title",
//                     "value": "title "
//                   },
//                   {
//                     "name": "Description",
//                     "value": "question"
//                   },
//                   {
//                     "name": "coordinate",
//                     "value": "12.34,26.8889"
//                     },

//                   ]
//         }

//         ],
//       "skippable":false
//     }
// ]

// UPLOAD_PHOTO (Upload Picture)
//     "Title"
//     [
//       {
//         "actions": [

//           {"actionType":"UPLOAD_PHOTO",
//           "action":[{
//                       "name": "Title",
//                       "value": "title "
//                     }
//                     ]
//           }

//           ],
//         "skippable":false
//       }
//   ]

// MULTI_CHOICE_QUESTIONs (MCQ)
//   "Title", -string
//   "question",-string
//   "answers", (refer criteria)
//   "instructionHeading", (Optional)-string
//   "instructions" (Optional) (refer criteria)

//   [
//     {
//       "actions": [

//         {"actionType":"MULTI_CHOICE_QUESTIONs",
//         "action":[{
//                     "name": "Title",
//                     "value": "title "
//                   },
//                   {
//                     "name": "question",
//                     "value": "question"
//                   },
//                   {
//                     "name": "answers",
//                     "value": "answer1,answer2,answer3"
//                   },
//                   {
//                     "name": "instructionHeading",
//                     "value": "instruction heading"
//                   },
//                   {
//                     "name": "instructions",
//                     "value": "instruction1,instruction2,instruction3"
//                   }
//                   ]
//         }

//         ],
//       "skippable":false
//     }
// ]
// UPLOAD_VIDEO (Upload Video)
//   "Title"
//   "Description"

//   [
//     {
//       "actions": [

//         {"actionType":"UPLOAD_VIDEO",
//         "action":[{
//                     "name": "Title",
//                     "value": "title "
//                   },
//                   {
//                     "name": "Description",
//                     "value": "description"
//                   }
//                   ]
//         }

//         ],
//       "skippable":false
//     }
// ]

// GET_LOCATION (Get User Location)
//     "Title",
//     "Subheading"

//   [
//     {
//       "actions": [

//         {"actionType":"GET_LOCATION",
//         "action":[{
//                     "name": "Title",
//                     "value": "title "
//                   },
//                   {
//                     "name": "Subheading",
//                     "value": "description"
//                   }
//                   ]
//         }

//         ],
//       "skippable":false
//     }
// ]

// UPLOAD_MULTIPLE_PHOTOS (Upload Multiple Pictures)
//   "Title",
//   "ButtonText",
//   "Description"
//   "MinFiles" - number

//   [
//     {
//       "actions": [

//         {"actionType":"UPLOAD_MULTIPLE_PHOTOS",
//         "action":[{
//                     "name": "Title",
//                     "value": "title "
//                   },
//                   {
//                     "name":"ButtonText",
//                     "value":"btn text"

//                   }
//                   {
//                     "name": "Description",
//                     "value": "description"
//                   },
//                   {
//                     "name":"MinFiles",
//                     "value":"4"
//                   }
//                   ]
//         }

//         ],
//       "skippable":false
//     }
// ]

// DOWNLOAD_PDF (Download PDF)
//   "Title", - string
//   "Description", -  string
//   "Files" -  "value can be >=1"
//         need a file link and a file name >>>
//         basically 2 input (1 is for uploading pdf and another is for file name) + we also need to add more file functionality
//         "file1~https://download1.com,file2~https://download2.com"

//         [
//           {
//             "actions": [

//               {"actionType":"DOWNLOAD_PDF",
//               "action":[{
//                           "name": "Title",
//                           "value": "title "
//                         }
//                         {
//                           "name": "Description",
//                           "value": "description"
//                         },
//                         {
//                           "name":"Files",
//                           "value":"file1~https://download1.com,file2~https://download2.com,file2~https://download1.com,file2~https://download2.com"
//                         }
//                         ]
//               }

//               ],
//             "skippable":false
//           }
//       ]

// YOUTUBE_VIDEO (Watch Youtube Video)

// "Title",
// "Description",
// "Link" string -- > also implement play youtube video in foundry
// [
//   {
//     "actions": [

//       {"actionType":"DOWNLOAD_PDF",
//       "action":[{
//                   "name": "Title",
//                   "value": "title "
//                 }
//                 {
//                   "name": "Description",
//                   "value": "description"
//                 },
//                 {
//                   "name":"Link",
//                   "value":"https://download1.com"
//                 }
//                 ]
//       }

//       ],
//     "skippable":false
//   }
// ]

// INSTRUCTION (Instructional Text)
//   "message" -  type textArea
//   "links" - not taking any input right now will send empty string
//   [
//     {
//       "actions": [

//         {"actionType":"INSTRUCTION",
//         "action":[{
//                     "name": "message",
//                     "value": "title "
//                   },
//                   {
//                     "name": "links",
//                     "value": ""
//                   }
//                   ]
//         }

//         ],
//       "skippable":false
//     }
// ]

// TEST_MCQ (Test MCQ)

// "Title",
// "question",
// "answers", (refer criteria)
// "correctAnswer",
// "instructionHeading", -optional
// "instructions" - optional (refer criteria)

// "microtasks":
// [
//     {
//       "actions": [

//         {"actionType":"TEST_MCQ",
//         "action":[{
//                     "name": "Title",
//                     "value": "title "
//                   },
//                   {
//                     "name": "question",
//                     "value": "question"
//                   },
//                   {
//                     "name": "answers",
//                     "value": "answer1,answer2"
//                     },
//                     {
//                       "name": "correctAnswer",
//                       "value": "answer"
//                       },
//                     {
//                     "name": "instructionHeading",
//                     "value": null | "instruction heading" }
//                     {
//                       "name": "instructions",
//                       "value": null | "instruction1,instruction2" }
//                   ]
//         }

//         ],
//       "skippable":false
//     }
// ]
export const microtaskTypeData = [
    {
        type: 'QUESTION_ANSWER',
        label: 'Ask a question',
        tooltipText: 'Users answer your question in a text box.',
        category: 'input',
        getComponent: (register, index, errors, control, skip1, skip2, skip3, skip4, microtaskEditDetail) => {
            return (
                <QuestionAndAnswer
                    register={register}
                    index={index}
                    errors={errors}
                    control={control}
                    microtaskEditDetail={microtaskEditDetail}
                />
            );
        },
        getFields: () => ({
            questionAnswer_Title: '',
            questionAnswer_question: '',
            questionAnswer_heading: '',
            questionAnswer_image: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewQuestionAndAnswer data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <QuestionAndAnswerSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <QuestionAndAnswerSubDetails data={dataSubDetails} />,
    },
    {
        type: 'UPLOAD_FILE',
        label: 'Upload File',
        category: 'input',
        tooltipText: 'Users upload file to complete the task.',
        getComponent: (register, index, errors, control, skip1, skip2, skip3, skip4, microtaskEditDetail) => (
            <UploadFile
                register={register}
                index={index}
                errors={errors}
                control={control}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            uploadFile_Title: '',
            uploadFile_Description: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewUploadFile data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <UploadFileSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <UploadFileSubDetails data={dataSubDetails} />,
    },
    {
        type: 'MULTI_SELECT_MULTIPLE_CHOICE_QUESTION',
        label: 'Multiple Answers',
        category: 'input',
        tooltipText: 'Users select multiple options (multiple correct answers)',
        getComponent: (register, index, errors, control, setValue, getValues, skip1, skip2, microtaskEditDetail) => (
            <MultiSelectMCQ
                register={register}
                index={index}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            multiSelect_title: '',
            multiSelect_question: '',
            multiSelect_answers: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewMultiSelectMCQ data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <MultiSelectMCQSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <MultiSelectMCQSubDetails data={dataSubDetails} />,
    },
    {
        type: 'GOTO_LOCATION',
        label: 'Location embed',
        category: 'instructional',
        tooltipText: 'Users visit a specific location you provide.',
        getComponent: (
            register,
            index,
            errors,
            control,
            setValue,
            skip1,
            skip2,
            skip3,
            microtaskEditDetail,
            currentMt
        ) => (
            <VisitLocation
                register={register}
                index={index}
                errors={errors}
                control={control}
                setValue={setValue}
                microtaskEditDetail={microtaskEditDetail}
                currentMt={currentMt}
            />
        ),
        getFields: () => ({
            goToLocation_Title: '',
            goToLocation_Description: '',
            goToLocation_coordinate: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewVisitLocation data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <VisitLocationSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <VisitLocationSubDetails data={dataSubDetails} />,
    },
    {
        type: 'MULTI_CHOICE_QUESTIONs',
        label: 'Choose one option',
        category: 'input',
        tooltipText: 'Users pick one option from a list (no correct answer).',
        getComponent: (register, index, errors, control, setValue, getValues, skip1, skip2, microtaskEditDetail) => (
            <Mcq
                register={register}
                index={index}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            multiChoiceQuestions_Title: '',
            multiChoiceQuestions_question: '',
            multiChoiceQuestions_answers: '',
            multiChoiceQuestions_instructionHeading: '',
            multiChoiceQuestions_instructions: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewMcq data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <McqSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <McqSubDetails data={dataSubDetails} />,
    },
    {
        type: 'UPLOAD_VIDEO',
        label: 'Upload Video',
        category: 'input',
        tooltipText: 'Users upload video as part of the task.',
        getComponent: (register, index, errors, control, skip1, skip2, skip3, skip4, microtaskEditDetail) => (
            <UploadVideo
                register={register}
                index={index}
                errors={errors}
                control={control}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            uploadVideo_Title: '',
            uploadVideo_Description: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewUploadVideo data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <UploadVideoSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <UploadVideoSubDetails data={dataSubDetails} />,
    },
    {
        type: 'GET_LOCATION',
        label: 'Get location',
        category: 'input',
        tooltipText: "Get the user's location coordinate.",
        getComponent: (register, index, errors, control, skip1, skip2, skip3, skip4, microtaskEditDetail) => (
            <GetUserLocation
                register={register}
                index={index}
                errors={errors}
                control={control}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            getLocation_Title: '',
            getLocation_Subheading: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewGetUserLocation data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <GetUserLocationSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <GetUserLocationSubDetails data={dataSubDetails} />,
    },
    {
        type: 'UPLOAD_MULTIPLE_PHOTOS',
        label: 'Upload Photo(s)',
        category: 'input',
        tooltipText: 'Users upload picture(s) for the task.',
        getComponent: (register, index, errors, control, setValue, skip2, skip3, skip4, microtaskEditDetail) => (
            <UploadMultiplePictures
                register={register}
                index={index}
                errors={errors}
                control={control}
                setValue={setValue}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            uploadMultiplePictures_Title: '',
            uploadMultiplePictures_ButtonText: 'Upload Pictures',
            uploadMultiplePictures_Description: '',
            uploadMultiplePictures_MinFiles: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewUploadMultiplePictures data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <UploadMultiplePicturesSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <UploadMultiplePicturesSubDetails data={dataSubDetails} />,
    },
    {
        type: 'DOWNLOAD_PDF',
        label: 'Upload PDF(s)',
        category: 'instructional',
        tooltipText: 'Provide PDF(s) for users to download.',
        getComponent: (register, index, errors, control, setValue, getValues, skip1, skip2, microtaskEditDetail) => (
            <DownloadPDF
                register={register}
                index={index}
                errors={errors}
                control={control}
                getValues={getValues}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            downloadPDF_Title: '',
            downloadPDF_Description: '',
            downloadPDF_Files: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewDownloadPDF data={previewData} />,
        getSubmissionDataComponent: (previewData) => <DownloadPDFSD dataTask={previewData} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <DownloadPDFSubDetails data={dataSubDetails} />,
    },
    {
        type: 'YOUTUBE_VIDEO',
        label: 'YouTube link(s)',
        category: 'instructional',
        tooltipText: 'Share a YouTube link for users to watch.',
        getComponent: (register, index, errors, control, skip1, skip2, skip3, skip4, microtaskEditDetail) => (
            <WatchYoutubeVideo
                register={register}
                index={index}
                errors={errors}
                control={control}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            youtubeVideos_Title: '',
            youtubeVideos_Description: '',
            youtubeVideos_Link: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewWatchYoutubeVideo data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <WatchYoutubeVideoSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <WatchYoutubeVideoSubDetails data={dataSubDetails} />,
    },
    {
        type: 'INSTRUCTION',
        label: 'Read Instructions',
        tooltipText: 'Add text with instructions and links.',
        category: 'instructional',
        getComponent: (
            register,
            index,
            errors,
            control,
            setValue,
            getValues,
            handleInstructionLinkPayload,
            instructionLinkPayload,
            microtaskEditDetail
        ) => (
            <InstructionalText
                register={register}
                index={index}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
                handleInstructionLinkPayload={handleInstructionLinkPayload}
                instructionLinkPayload={instructionLinkPayload}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            instruction_title: '',
            instruction_message: '',
            instruction_links: '',
            instruction_images: '',
            skippable: true,
        }),
        getPreview: (previewData, instructionLinkPayload, control, activeMicrotask) => (
            <PreviewInstructionalText
                data={previewData}
                instructionLinkPayload={instructionLinkPayload}
                control={control}
                index={activeMicrotask}
            />
        ),
        getSubmissionDataComponent: (taskSubmission) => <InstructionalTextSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <InstructionalTextSubDetails data={dataSubDetails} />,
    },
    {
        type: 'TEST_MCQ',
        label: 'Quiz Question',
        tooltipText: 'Users answer a multiple-choice question with one correct answer.',
        category: 'input',
        getComponent: (
            register,
            index,
            errors,
            control,
            setValue,
            getValues,
            microtaskFields,
            skip1,
            microtaskEditDetail
        ) => (
            <TestMCQ
                register={register}
                index={index}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
                microtaskFields={microtaskFields}
                microtaskEditDetail={microtaskEditDetail}
            />
        ),
        getFields: () => ({
            testMCQ_Title: '',
            testMCQ_question: '',
            testMCQ_answers: '',
            testMCQ_correctAnswer: '0',
            testMCQ_instructionHeading: '',
            testMCQ_instructions: '',
            skippable: true,
        }),
        getPreview: (previewData) => <PreviewTestMCQ data={previewData} />,
        getSubmissionDataComponent: (taskSubmission) => <TestMCQSD dataTask={taskSubmission} />,
        getMicroTaskSubDetailsComponent: (dataSubDetails) => <TestMCQSubDetails data={dataSubDetails} />,
    },
];
// {
//      <GetUserLocationSD dataTask={taskSubmission} />
// }
