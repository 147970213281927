import { useContext, useEffect, useState } from 'react';
import { executeProposal, getSummaryOfProposal } from '../../../apis/treasurer/treasurer';
import Button from '../../../ui/Button';
import Heading from '../../../ui/Heading';
import Loader from '../../../ui/Loader';
import { toast } from 'sonner';
import NoDataAvailable from '../../../ui/NoDataAvailable';
import VotedTick from '../../../assets/icons/VotedTick';
import VotedTickRed from '../../../assets/icons/VotedTickRed';
import { clusterApiUrl, Connection, sendAndConfirmTransaction } from '@solana/web3.js';
import { executorToFetchThreshold } from '../../../blockchainInteraction/fetchThreshold';
import {
    executorToCheckSufficientSOLBalance,
    executorToCheckTreasurySOLBalance,
    executorToCheckTreasuryTokenBalance,
    executorToExecuteProposal,
    executorToFindIfUserHasVoted,
    executorToReadVotingCount,
    executorToVoteOnProposal,
} from '../../../blockchainInteraction/votingOnProposals';
import { PublicKey } from '@solana/web3.js';
import { AuthContext } from '../../../contexts/AuthContext';
import OwnButton from '../../walletconnectUI/OwnButton';
import { useWallet } from '@solana/wallet-adapter-react';
import { getProvider } from '../../../helpers/getProvider';
import WalletConnectCustomButton from '../../walletconnectUI/WalletCustomText';
import ProgressBar from '../../../ui/ProgressBar';
import { useLoadingState } from '../../../hooks/useLoader';
import { RPC_ENDPOINT } from '../../../constants/apiPaths';

function VotingCardForBountyCreation({
    activeCreationProposal,
    reqId,
    afterExecutionOperation,
    multiSig,
    changeVotingStatusForCreation,
}) {
    const { publicKey } = useWallet();
    const [summary, setSummary] = useState({});
    const { loading: executing, startLoading: startExecuting, stopLoading: stopExecuting } = useLoadingState();
    const {
        loading: loadingVoteCount,
        startLoading: startLoadingVoteCount,
        stopLoading: stopLoadingVoteCount,
    } = useLoadingState();
    const [isUserVoted, setIsUserVoted] = useState(false);

    const [votingStatus, setVotingStatus] = useState('');
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];
    const [blockchainData, setBlockchainData] = useState({
        thresholdVoteCount: 0,
        yesVoteCount: 0,
        noVoteCount: 0,
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(
        function () {
            const fetchData = async () => {
                const connection = new Connection(RPC_ENDPOINT, 'confirmed');
                try {
                    setIsLoading(true);
                    const data = await getSummaryOfProposal(reqId);

                    setSummary(data);
                    setIsLoading(false);
                    startLoadingVoteCount();
                    const fetchedThreshold = await executorToFetchThreshold(multiSig, connection);

                    const votingCount = await executorToReadVotingCount(
                        connection,
                        new PublicKey(data.proposalAddress)
                    );
                    stopLoadingVoteCount();

                    const hasUserVoted = await executorToFindIfUserHasVoted(
                        connection,
                        new PublicKey(data.proposalAddress),
                        new PublicKey(userProfile.walletAddress),
                        multiSig
                    );

                    setIsUserVoted(hasUserVoted);
                    setVotingStatus(hasUserVoted);
                    setBlockchainData((initial) => ({
                        ...initial,
                        thresholdVoteCount: fetchedThreshold,
                        yesVoteCount: votingCount.yesVoteCount,
                        noVoteCount: votingCount.noVoteCount,
                    }));
                } catch (error) {
                    console.log(error);
                }
            };

            if (reqId) fetchData();
            if (!reqId) {
                setIsLoading(false);
                setSummary({});
            }

            return () =>
                setBlockchainData((initial) => ({
                    ...initial,
                    thresholdVoteCount: 0,
                    yesVoteCount: 0,
                    noVoteCount: 0,
                }));
        },
        [reqId]
    );

    const onExecution = async () => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            const currentDate = new Date();
            currentDate.setMinutes(currentDate.getMinutes() + 5);

            if (new Date(summary.expirationTimestamp) < currentDate)
                throw new Error(
                    'Please increase the date of the Bounty Expiration Time in step 4 of Bounty Creation. The Bounty must be live for at least 5 minutes. '
                );

            const isNotEnoughSOLBalance = await executorToCheckSufficientSOLBalance(
                connection,
                userProfile?.walletAddress,
                new PublicKey(multiSig),
                new PublicKey(summary.proposalAddress)
            );
            if (isNotEnoughSOLBalance)
                throw new Error('You do not have enough SOL in your wallet to execute this transaction. ');

            const isNotEnoughTreasuryTokenBalance = await executorToCheckTreasuryTokenBalance(
                connection,
                new PublicKey(multiSig),
                new PublicKey(summary.rewardTokenInfo.address),
                summary.totalRewardAmount
            );
            if (isNotEnoughTreasuryTokenBalance)
                throw new Error('Your Organization’s treasury does not enough tokens to fund this bounty');

            const isNotEnoughTreasurySOLBalance = await executorToCheckTreasurySOLBalance(
                connection,
                new PublicKey(multiSig),
                summary.bountyTitle,
                summary.bountyDescription
            );
            if (isNotEnoughTreasurySOLBalance)
                throw new Error('Your Organization does not have enough SOL in its treasury.');

            startExecuting();
            const transaction = await executorToExecuteProposal(
                connection,
                userProfile?.walletAddress,
                new PublicKey(multiSig),
                new PublicKey(summary.proposalAddress)
            );

            const signedTransaction = await getProvider().signTransaction(transaction);

            const signature = await sendAndConfirmTransaction(connection, signedTransaction);

            const response = await executeProposal(reqId);
            afterExecutionOperation(reqId);
        } catch (error) {
            toast.error(error);
        }
        stopExecuting();
    };

    const onVoting = async (vote) => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            const transaction = await executorToVoteOnProposal(
                connection,
                new PublicKey(multiSig),
                new PublicKey(summary.proposalAddress),
                new PublicKey(userProfile.walletAddress),
                vote
            );

            const { signature } = await getProvider().signAndSendTransaction(transaction);

            await connection.getSignatureStatus(signature);
            const votingCount = await executorToReadVotingCount(connection, new PublicKey(summary.proposalAddress));

            setBlockchainData((initial) => ({
                ...initial,
                ...(vote
                    ? {
                          yesVoteCount: initial.yesVoteCount + 1,
                      }
                    : { noVoteCount: initial.noVoteCount + 1 }),
            }));

            const isExecutable = vote ? blockchainData.yesVoteCount + 1 >= blockchainData.thresholdVoteCount : false;

            const status = isExecutable ? 'ready to execute' : vote ? 'voted yes' : 'voted no';

            changeVotingStatusForCreation(status, summary.proposalAddress);

            setVotingStatus(status);
        } catch (error) {
            console.log(error);
        }
    };
    const address = publicKey;

    return (
        <div className="bg-white shadow-md h-full max-h-[85vh]  rounded-md  p-6 border border-borderColor min-w-full ">
            {isLoading ? (
                <div className="min-h-[20vh] flex justify-center items-center">
                    <Loader />
                </div>
            ) : Object.keys(summary).length ? (
                <div className="flex  flex-col h-full justify-between ">
                    <div className="">
                        <div className="flex w-full justify-between items-center">
                            <div className="w-[50%]">
                                <Heading type={'large'} className={'text-lightBlack truncate'}>
                                    {summary.bountyTitle}
                                </Heading>
                                <p className="text-xs truncate text-secondaryText">{summary.bountyId}</p>
                            </div>
                            {blockchainData.yesVoteCount >= blockchainData.thresholdVoteCount &&
                                blockchainData.thresholdVoteCount !== 0 && (
                                    <div className="30%">
                                        {address ? (
                                            <Button onClick={onExecution} size="small">
                                                {executing ? <Loader /> : 'Execute'}
                                            </Button>
                                        ) : (
                                            <div className="w-full flex items-center">
                                                <WalletConnectCustomButton
                                                    customText={'Execute'}
                                                    width="128px"
                                                    customStyles={{
                                                        backgroundColor: 'var(--primary-color)',
                                                        color: '#fff',
                                                        border: '1px solid #dcdcdc',
                                                        lineHeight: 'normal',
                                                        fontWeight: '500',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        height: '40px',
                                                        borderRadius: '8px',
                                                        flexGrow: 1,
                                                    }}
                                                    connectedText={'Execute'}
                                                    disconnectedText="Execute"
                                                    removeIconOnDisconnect={true}
                                                    removeIconOnConnect={true}
                                                    removeIconOnWalletSelect={true}
                                                    removeIconOnConnecting={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                        </div>

                        <div className=" flex flex-col  ">
                            <p className="font-medium mt-6 mb-4">Required Votes </p>
                            <div className="flex items-center gap-5 justify-between">
                                <ProgressBar
                                    color={'bg-[#0085FF]'}
                                    width={`${
                                        (blockchainData.yesVoteCount / blockchainData.thresholdVoteCount) * 100
                                    }%`}
                                    className=""
                                    colorb={'bg-red-400'}
                                    colorc={'bg-green-400 '}
                                    colord={'bg-blue-200'}
                                    // multiple={true}
                                    bgDefault={'bg-[#E5F3FF]'}
                                />
                                <p className="flex items-center gap-1">
                                    <span className="font-bold">
                                        {blockchainData.yesVoteCount}/{blockchainData.thresholdVoteCount}
                                    </span>{' '}
                                    <span>Votes</span>
                                </p>
                            </div>
                        </div>
                        <div className="flex  gap-2 items-center pb-3 mt-5 border-b border-borderColor">
                            <p className="bg-foundryGreenL sm:text-xs  flex items-center gap-1 border border-green-200 rounded-lg sm:px-2 px-4  py-1  text-foundryGreen capitalize  font-medium ">
                                <span>
                                    <VotedTick />
                                </span>
                                <span> Voted - Yes : {blockchainData.yesVoteCount}</span>
                            </p>
                            <p className="bg-foundryRedL flex sm:text-xs items-center gap-1  border border-red-200 rounded-lg sm:px-2 px-4  py-1 text-foundryRed capitalize  font-medium ">
                                <span>
                                    <VotedTickRed />
                                </span>
                                <span> Voted - No : {blockchainData.noVoteCount}</span>
                            </p>
                        </div>
                        <ul className="py-3">
                            <li className="flex justify-between mb-3">
                                <span className="text-secondaryText">Bounty Type</span>
                                <span className="capitalize">{summary.type}</span>
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="text-secondaryText">Bounty Mode</span>
                                <span className="capitalize">{summary.mode}</span>
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="text-secondaryText">Microtasks</span>
                                <span>{summary.microtaskCount}</span>
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="text-secondaryText">Accuracy Reward Recipients</span>
                                <span>{summary.winnerCount}</span>
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="text-secondaryText">Accuracy Reward Threshold</span>
                                <span>{summary.passThreshold * 100}%</span>
                            </li>
                        </ul>
                    </div>
                    {blockchainData.yesVoteCount < blockchainData.thresholdVoteCount && (
                        <div className="flex  mt-5 justify-between">
                            <div>
                                {address ? (
                                    <Button
                                        disabled={votingStatus !== 'not voted'}
                                        onClick={() => onVoting(true)}
                                        className={`font-bold ${
                                            votingStatus !== 'not voted' ? '!cursor-not-allowed ' : ''
                                        } transition-colors duration-2000 ease-in-out `}
                                        variant="approveVoting"
                                        size="small"
                                        fadeOut={votingStatus === 'voted no'}
                                    >
                                        Vote-Yes
                                    </Button>
                                ) : (
                                    <div className="w-full flex items-center">
                                        <WalletConnectCustomButton
                                            customText={'Vote-Yes'}
                                            width="128px"
                                            customStyles={{
                                                backgroundColor: 'var(--alert-green)',
                                                color: '#fff',
                                                border: '1px solid #dcdcdc',
                                                lineHeight: 'normal',
                                                fontWeight: '700',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                height: '40px',
                                                borderRadius: '8px',
                                                flexGrow: 1,
                                            }}
                                            connectedText={'Vote-Yes'}
                                            disconnectedText="Vote-Yes"
                                            removeIconOnDisconnect={true}
                                            removeIconOnConnect={true}
                                            removeIconOnWalletSelect={true}
                                            removeIconOnConnecting={true}
                                        />
                                    </div>
                                )}
                            </div>

                            <div>
                                {address ? (
                                    <Button
                                        disabled={votingStatus !== 'not voted'}
                                        onClick={() => onVoting(false)}
                                        className={`font-bold ${
                                            votingStatus !== 'not voted' ? '!cursor-not-allowed' : ''
                                        }  transition-colors duration-2000 ease-in-out  `}
                                        variant="denyVoting"
                                        size="small"
                                        fadeOut={votingStatus === 'voted yes'}
                                    >
                                        Vote-No
                                    </Button>
                                ) : (
                                    <div className="w-full flex items-center">
                                        <WalletConnectCustomButton
                                            customText={'Vote-No'}
                                            width="128px"
                                            customStyles={{
                                                backgroundColor: 'var(--alert-red)',
                                                color: '#fff',
                                                border: '1px solid #dcdcdc',
                                                lineHeight: 'normal',
                                                fontWeight: '700',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                height: '40px',
                                                borderRadius: '8px',
                                                flexGrow: 1,
                                            }}
                                            connectedText={'Vote-No'}
                                            disconnectedText="Vote-No"
                                            removeIconOnDisconnect={true}
                                            removeIconOnConnect={true}
                                            removeIconOnWalletSelect={true}
                                            removeIconOnConnecting={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <NoDataAvailable minHeight="" />
            )}
        </div>
    );
}

export default VotingCardForBountyCreation;
