import { Outlet, useNavigate } from 'react-router-dom';
import BackButton from '../assets/icons/BackButton';
import Heading from '../ui/Heading';

const FAQScreen = () => {
    const navigate = useNavigate();
    return (
        <div className="p-9">
            <div className="flex items-center mb-6">
                <BackButton onClick={() => navigate(-1)} />
                <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                    Frequently Asked Questions
                </Heading>
            </div>
            <Outlet />
        </div>
    );
};

export default FAQScreen;
