import { useEffect, useRef } from 'react';
import Overlay from '../../../ui/Overlay';
import Button from '../../../ui/Button';
import Feedbackclose from '../../../assets/icons/Feedbackclose';
import FormForCreatingProject from '../../projects/FormForCreatingProject';
import FormForSendingRequestToJoin from './FormForSendingRequestToJoin';

function SendRequestToJoin({ close, setIsRequested }) {
    const myRef = useRef({ close });
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );
    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[625px]  p-9   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div>
                    <Button
                        onClick={close}
                        radius="rounded-full"
                        className=" border-0 absolute top-3  right-4 "
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                </div>
                <FormForSendingRequestToJoin setIsRequested={setIsRequested} close={close} />
            </div>
        </Overlay>
    );
}

export default SendRequestToJoin;
