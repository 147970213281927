import { useController, useFieldArray } from 'react-hook-form';
import DeleteIcon from '../../../assets/icons/Delete';
import InputForDragAndDrop from '../../../ui/InputForDragAndDrop';
import Input from '../../../ui/Input';
import Button from '../../../ui/Button';
import IsSkippable from './IsSkippable';
import { useContext, useEffect } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';

const MultiSelectMCQ = ({ register, index, errors, control, setValue, getValues, microtaskEditDetail }) => {
    const { isEdit } = useContext(BountiesContext);
    const {
        fields: multiSelect_answers_Fields,
        append,
        remove,
    } = useFieldArray({
        name: `microtasks[${index}].multiSelect_answers`,
        control,
    });

    useEffect(() => {
        // Run this logic only once when the component is mounted
        if (isEdit && multiSelect_answers_Fields.length === 0) {
            // If in edit mode, populate the answers from microtaskEditDetail
            const editAnswers = microtaskEditDetail?.multiSelectMultipleChoiceQuestion_answers?.split(',') || [];
            editAnswers.forEach((a) => {
                append({ answerName: a });
            });
        } else if (!isEdit && multiSelect_answers_Fields.length === 0) {
            // If not in edit mode, ensure there is at least one empty answer field
            append({ answerName: '' });
        }
    }, [isEdit, multiSelect_answers_Fields.length, append, microtaskEditDetail]);
    // if (isEdit) {
    //     editAnswers.forEach((a) => {
    //         append({ answerName: a });
    //     });
    // } else {
    //     if (multiSelect_answers_Fields.length === 0) {
    //         append({ answerName: '' });
    //     }
    // }

    return (
        <div>
            <Input
                {...register(`microtasks.${index}.multiSelect_title`, {
                    required: 'Please enter a title',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.multiSelectMultipleChoiceQuestion_title || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.multiSelect_title?.message}
                placeholder={'Title *'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 '}
            />
            <Input
                {...register(`microtasks.${index}.multiSelect_question`, {
                    required: 'Please enter a question',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.multiSelectMultipleChoiceQuestion_question || '' })}
                transparentFontWeight={'font-normal'}
                error={errors?.microtasks && errors?.microtasks[index]?.multiSelect_question?.message}
                placeholder={'Ask the question here *'}
                transparentTextSize="text-sm"
                className={'w-[100%] mb-1 mt-2 '}
            />

            <div className="mt-4  items-center">
                {multiSelect_answers_Fields.map((multiSelectField, i) => {
                    return (
                        <>
                            <div key={multiSelectField.id} className="flex  gap-3 mb-3 w-full">
                                <p className="w-[80px] relative top-3 ">
                                    <span className="text-primaryInput">Option {i + 1} :</span>
                                </p>
                                <div className="flex-1">
                                    <InputForDragAndDrop
                                        name={`microtasks[${index}].multiSelect_answers[${i}].answerName`}
                                        control={control}
                                        index={i}
                                        width="w-full"
                                        placeholder="Enter an option"
                                        // {...(isEdit && { defaultValue: editAnswers[i] || '' })}
                                        error={
                                            errors?.microtasks
                                                ? errors?.microtasks[index]?.multiSelect_answers
                                                    ? errors?.microtasks[index]?.multiSelect_answers[i]?.answerName
                                                          ?.message
                                                    : ''
                                                : ''
                                        }
                                        validateObject={{
                                            required: 'Please enter an option',
                                            // maxLength: {
                                            //     value: 200,
                                            //     message: 'The maximum length is 200 characters',
                                            // },
                                        }}
                                    />
                                </div>
                                {
                                    <DeleteIcon
                                        className={`${i > 0 ? 'cursor-pointer relative top-3' : 'invisible'}`}
                                        onClick={() => remove(i)}
                                    />
                                }
                            </div>
                        </>
                    );
                })}

                <Button
                    type="button"
                    onClick={() => {
                        append({
                            answerName: '',
                        });
                    }}
                    size="none"
                    className="  font-semibold !text-lightBlack text-sm"
                    variant="transparent"
                >
                    + Add Option
                </Button>
                <div>
                    {errors?.microtasks && (
                        <p className="text-foundryRed">
                            {errors?.microtasks[index]?.multiSelect_answers?.root?.message}
                        </p>
                    )}
                </div>
            </div>
            {/* <div className="flex gap-9 my-2 items-center ">
                <IsSkippable
                    selectedOption={skippableField.value}
                    onSelectOption={(value) => {
                        skippableField.onChange(value);
                    }}
                    options={['Yes', 'No']}
                />
            </div> */}
        </div>
    );
};

export default MultiSelectMCQ;
