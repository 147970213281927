import { useContext, useEffect, useState } from 'react';

import Table from '../../ui/Table';

import { getBounties, publishOrUnpublishBounty } from '../../apis/bounties/bounties';
import { useNavigate, useParams } from 'react-router-dom';
import AvatarImage from '../../ui/AvatarImage';
import Menus from '../../ui/Menus';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import DeleteBountyModal from './DeleteBountyModal';
import DuplicateMenuIcon from '../../assets/icons/DuplicateMenuIcon';
import PublishMenuIcon from '../../assets/icons/PublishMenuIcon';

import EditMenuIcon from '../../assets/icons/EditMenuIcon';
import DuplicateBountyModal from './DuplicateBountyModal';
import Input from '../../ui/Input';
import search from '../../assets/search.png';

import { BOUNTY_STATUS_TAG_COLOR } from '../../constants/datas';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import NoDataAvailable from '../../ui/NoDataAvailable';
import RequestTreasuryApprovalModal from './RequestTreasuryApprovalModal';
import PopOver from '../../ui/PopOver';
import { toast } from 'sonner';
import StartBountyModal from './StartBountyModal';
import { AuthContext } from '../../contexts/AuthContext';

function BountiesList() {
    const [bounties, setBounties] = useState([]);
    const [searchString, setSearchString] = useState('');
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];
    const [isModalOpen, setModalOpen] = useState(false); // Modal visibility state
    const [modalAction, setModalAction] = useState(''); // Track action type: 'duplicate' or 'delete'
    const [selectedBounty, setSelectedBounty] = useState(null); // Track selected bounty for modal
    const { id } = useParams();
    const navigate = useNavigate();
    let filteredData = bounties;
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const data = await getBounties(id);
                    setBounties(data);
                    stopLoading();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );

    const handleTogglePublish = async (oppStatus, id) => {
        try {
            const afterToggling = bounties.map((bounty) => {
                if (bounty._id === id) return { ...bounty, status: oppStatus };
                return bounty;
            });
            setBounties(afterToggling);
            const res = await publishOrUnpublishBounty(id);
        } catch (error) {}
    };

    const handleOpenModal = (action, bounty) => {
        setModalAction(action); // Set the action type ('duplicate' or 'delete')
        setSelectedBounty(bounty); // Set the selected bounty
        setModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setModalOpen(false); // Close the modal
        setModalAction(''); // Clear action type
        setSelectedBounty(null); // Clear selected bounty
    };

    const handleConfirmAction = (newBounty) => {
        if (modalAction === 'duplicate') {
            setBounties((prev) => {
                const index = prev.findIndex((bounty) => bounty._id === selectedBounty._id);
                const updatedBounties = [...prev];
                updatedBounties.splice(index + 1, 0, {
                    ...newBounty,
                    creatorProfileImage: userProfile?.profileImage,
                    creatorUsername: userProfile?.userName,
                    projectTitle: selectedBounty?.projectTitle,
                }); // Insert the duplicate right after the original
                return updatedBounties;
            });
            // Perform duplication logic here
        } else if (modalAction === 'delete') {
            setBounties((prev) => prev.filter((bounty) => bounty._id !== selectedBounty._id));
        } else if (modalAction === 'request') {
            setBounties((prev) =>
                prev.map((bounty) => {
                    if (bounty._id === selectedBounty._id) {
                        return { ...bounty, status: 'Creation Requested' };
                    }
                    return bounty;
                })
            );
        } else if (modalAction === 'start') {
            setBounties((prev) =>
                prev.map((bounty) => {
                    if (bounty._id === selectedBounty._id) {
                        return { ...bounty, status: 'Live and Published' };
                    }
                    return bounty;
                })
            );
        }
        handleCloseModal();
    };

    if (searchString) {
        filteredData = filteredData.filter(({ title, projectTitle }) => {
            return (
                title.toLowerCase().includes(searchString.toLowerCase()) ||
                projectTitle?.toLowerCase().includes(searchString.toLowerCase())
            );
        });
    }

    return (
        <>
            <Menus>
                <Table whiteCardClassName="" className="gap-8 " cols="grid-cols-tableBounties">
                    <Table.Description
                        description="Bounties"
                        primaryBtn="+ Add Bounty"
                        clickOnPrimary={() => navigate(`/${id}/createBounty`)}
                    />
                    <div className="flex items-center w-[441px] gap-3 py-2 px-3 mb-4 border border-borderColor rounded-lg ">
                        <img className="w-6 h-6  object-cover" src={search} alt="" />
                        <Input
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            placeholder="Search Bounty Title or Project Title"
                            className=" !w-full !border-0 !px-0 !py-0 outline-none "
                            containerClass="flex-1"
                            type="text"
                        />
                    </div>
                    <Table.Header>
                        <p className="text-sm justify-self-start  font-semibold text-secondaryText ">Bounty ID</p>
                        <p className="text-sm font-semibold text-secondaryText ">Bounty Title</p>
                        <p className="text-sm  font-semibold text-secondaryText ">Project Title</p>
                        <p className="text-sm  font-semibold text-secondaryText ">Status</p>
                        <p className="text-sm  font-semibold text-secondaryText ">Created By</p>
                    </Table.Header>
                    {loading ? (
                        <div className="min-h-[20vh] flex justify-center items-center">
                            <Loader />
                        </div>
                    ) : filteredData.length ? (
                        filteredData.map((bounty) => (
                            <Table.Row key={bounty._id} rowClassName="relative">
                                <p
                                    onClick={() => navigate(`${bounty._id}`)}
                                    className="text-primaryColor cursor-pointer truncate w-full uppercase justify-self-start font-medium"
                                >
                                    {bounty._id}
                                </p>
                                <p className="truncate text-primaryInput">{bounty.title}</p>

                                <p
                                    onClick={() => {
                                        if (bounty.projectId)
                                            navigate(`/organizations/${id}/view-projects/${bounty.projectId}`);
                                    }}
                                    className="text-primaryColor truncate cursor-pointer  "
                                >
                                    {bounty.projectTitle || '-'}
                                </p>
                                {/* <Tag
                                    rounded="rounded-lg "
                                    className="px-2 py-1 leading-3 self-center   "
                                    textClassName="text-xs font-semibold"
                                    bg={BOUNTY_STATUS_TAG_COLOR[bounty.status]?.bg}
                                    textColor={BOUNTY_STATUS_TAG_COLOR[bounty.status]?.text}
                                    text={bounty.status}
                                /> */}
                                <PopOver
                                    rounded="rounded-lg "
                                    className="px-2 py-1 leading-3 self-center   "
                                    textClassName="text-xs font-semibold"
                                    bg={BOUNTY_STATUS_TAG_COLOR[bounty.status]?.bg}
                                    textColor={BOUNTY_STATUS_TAG_COLOR[bounty.status]?.text}
                                    borderColor={BOUNTY_STATUS_TAG_COLOR[bounty.status]?.border}
                                    text={bounty.status}
                                    active={
                                        bounty.status === 'Live and Published' ||
                                        bounty.status === 'Live and Unpublished'
                                            ? 'Live'
                                            : bounty.status
                                    }
                                />
                                <div className="flex items-center gap-4 ">
                                    <AvatarImage w="w-8" h="h-8" url={bounty.creatorProfileImage} />
                                    <p className="text-primaryInput  ">{bounty.creatorUsername}</p>
                                </div>

                                <Menus.Toggle id={bounty._id} />
                                <Menus.List id={bounty._id}>
                                    <Menus.Button
                                        shouldCloseMenu={true}
                                        onClick={(fn) => {
                                            handleOpenModal('duplicate', bounty);
                                            fn();
                                        }}
                                        icon={<DuplicateMenuIcon />}
                                    >
                                        Duplicate
                                    </Menus.Button>
                                    {/* Delete Action */}
                                    {bounty.status === 'Draft' && (
                                        <>
                                            <Menus.Button
                                                onClick={(fn) => {
                                                    handleOpenModal('delete', bounty);
                                                    fn();
                                                }}
                                                icon={<DeleteIcon />}
                                            >
                                                Delete
                                            </Menus.Button>
                                            <Menus.Button
                                                onClick={(fn) => {
                                                    navigate(`/${id}/createBounty?bountyId=${bounty._id}`);
                                                    fn();
                                                }}
                                                icon={<EditMenuIcon />}
                                            >
                                                Edit
                                            </Menus.Button>
                                            <Menus.Button
                                                onClick={(fn) => {
                                                    handleOpenModal('start', bounty);
                                                    fn();
                                                }}
                                                icon={<PublishMenuIcon />}
                                            >
                                                Publish
                                            </Menus.Button>
                                        </>
                                    )}
                                    {/* {bounty.status === 'Draft' && (
                                        <Menus.Button
                                            onClick={(fn) => {
                                                handleOpenModal('request', bounty);
                                                fn();
                                            }}
                                            icon={<RequestTreasuryMenuIcon />}
                                        >
                                            Request Treasury Approval
                                        </Menus.Button>
                                    )} */}
                                    {bounty.status === 'Live and Published' && (
                                        <>
                                            <Menus.Button
                                                onClick={() => handleTogglePublish('Live and Unpublished', bounty._id)}
                                                icon={<PublishMenuIcon />}
                                            >
                                                Unpublish
                                            </Menus.Button>
                                            <Menus.Button
                                                onClick={(fn) => {
                                                    toast.error('Please unpublish the bounty first');
                                                    fn();
                                                }}
                                                icon={<EditMenuIcon />}
                                            >
                                                Edit
                                            </Menus.Button>
                                        </>
                                    )}
                                    {bounty.status === 'Live and Unpublished' && (
                                        <>
                                            <Menus.Button
                                                onClick={() => handleTogglePublish('Live and Published', bounty._id)}
                                                icon={<PublishMenuIcon />}
                                            >
                                                Publish
                                            </Menus.Button>
                                            <Menus.Button
                                                onClick={() => navigate(`/${id}/createBounty?bountyId=${bounty._id}`)}
                                                icon={<EditMenuIcon />}
                                            >
                                                Edit
                                            </Menus.Button>
                                        </>
                                    )}
                                </Menus.List>
                            </Table.Row>
                        ))
                    ) : (
                        <NoDataAvailable />
                    )}
                </Table>
            </Menus>
            {isModalOpen && (
                <div>
                    {modalAction === 'duplicate' && (
                        <DuplicateBountyModal
                            handleConfirmAction={handleConfirmAction}
                            bountyName={selectedBounty.title}
                            bountyId={selectedBounty._id}
                            close={handleCloseModal}
                        />
                    )}
                    {modalAction === 'start' && (
                        <StartBountyModal
                            handleConfirmAction={handleConfirmAction}
                            bountyName={selectedBounty.title}
                            bountyId={selectedBounty._id}
                            close={handleCloseModal}
                        />
                    )}
                    {modalAction === 'delete' && (
                        <DeleteBountyModal
                            onDelete={handleConfirmAction}
                            bountyName={selectedBounty.title}
                            bountyId={selectedBounty._id}
                            close={handleCloseModal}
                        />
                    )}
                    {modalAction === 'request' && (
                        <RequestTreasuryApprovalModal
                            handleConfirmAction={handleConfirmAction}
                            bountyName={selectedBounty.title}
                            bountyId={selectedBounty._id}
                            updatedTime={selectedBounty.updatedAt}
                            close={handleCloseModal}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default BountiesList;
