import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MetaMaskProvider } from '@metamask/sdk-react';
ReactDOM.render(
    <React.StrictMode>
        <MetaMaskProvider
            sdkOptions={{
                dappMetadata: {
                    name: 'Example React Dapp',
                    url: window.location.href,
                },
                infuraAPIKey: '3db89a53d501426f86f05484a866f68c',
                // Other options.
            }}
        >
            <App />
        </MetaMaskProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
