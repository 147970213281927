export const isEvenlyDistributed = (arr) => {
    if (arr.length === 0) {
        return false; // Handle empty arrays
    }

    const firstElement = arr[0];
    return arr.every((num) => num === firstElement);
};

export const extractTimeComponents = (timestamp) => {
    if (typeof timestamp !== 'number' || timestamp < 0) {
        throw new Error('Timestamp must be a non-negative number.');
    }

    const totalMinutes = Math.floor(timestamp / 60000); // Convert milliseconds to minutes
    const days = Math.floor(totalMinutes / 1440); // 1440 minutes in a day
    const hours = Math.floor((totalMinutes % 1440) / 60); // Remaining hours
    const minutes = totalMinutes % 60; // Remaining minutes

    return { days, hours, minutes };
};
