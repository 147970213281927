import { useLocation, useOutletContext } from 'react-router-dom';
import { useActive } from '../../hooks/useActive';
import TabSwitching from '../../ui/TabSwitching';
import InvitationsList from './InvitationsList';
import RequestList from './RequestList';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

function InvitationsAndRequestsToJoinOrganization({ screen }) {
    const location = useLocation();
    const [activeTab, handleActiveTab] = useActive(() => {
        const searchParams = new URLSearchParams(location.search);

        // Decode the URL-encoded parameters
        const notifScreen = decodeURIComponent(searchParams.get('notifScreen') || '');

        return notifScreen ? notifScreen : screen;
    });
    const [particularOrg] = useOutletContext();
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];

    return (
        <div>
            <div className="flex gap-10 mt-6 ">
                <TabSwitching
                    activeTab={activeTab}
                    onSelectingActive={handleActiveTab}
                    firstTab={'Invitations'}
                    secondTab={'Requests'}
                    activeStyling={'text-primaryColor border-b-2 border-primaryColor '}
                    className={'text-xl font-medium pb-2'}
                />
            </div>
            {activeTab.toLowerCase() === 'invitations' && (
                <InvitationsList organisation={particularOrg} user={userProfile} screen={'Sent'} />
            )}
            {activeTab.toLowerCase() === 'requests' && <RequestList organisation={particularOrg} user={userProfile} />}
        </div>
    );
}

export default InvitationsAndRequestsToJoinOrganization;
