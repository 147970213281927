import { useController } from 'react-hook-form';

export const useInstructionTextMessage = (control, index, editMessage = '') => {
    const {
        field: instructionMessageField,
        fieldState: { error },
    } = useController({
        name: `microtasks.${index}.instruction_message`,
        control,
        defaultValue: editMessage || '', // Set the default value
        rules: {
            required: 'Instruction message is required',
        },
    });
    return { instructionMessageField, error };
};
