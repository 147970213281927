import { useEffect, useState } from 'react';
import Heading from '../../ui/Heading';
import Input from '../../ui/Input';
import WhiteCard from '../../ui/WhiteCard';
import MapWithSearch from './SearchMap';
import { toast } from 'sonner';
import { usePhysicalBountyInputs } from './bountiesHooks/usePhysicalBountyInputs';
import PhysicalBountyMap from './PhysicalBountyMap';
import TextWithTooltip from '../../ui/TextWithTooltip';

function OnSelectingPhysicalBounty({
    core,
    control,
    errors,
    getValues,
    placeholder = 'Enter City...',
    setRadiusPreview,
}) {
    const { cityField, bountyRadiusField, locationField } = usePhysicalBountyInputs(control);
    const [hasStartedTyping, setHasStartedTyping] = useState(false);
    console.log(locationField, 'location field');
    const coordinates = locationField.value ? locationField.value.coordinates : [];
    useEffect(
        function () {
            if (!core) toast.error('Please choose a dominant core first.');
        },
        [core]
    );

    const handleCityFieldChange = (e) => {
        cityField.onChange(e.target.value);
        if (e.target.value.length > 0) {
            setHasStartedTyping(true); // Mark as typing when there is input
        } else {
            setHasStartedTyping(false); // Reset typing if input is cleared
        }
    };

    return core ? (
        <div>
            {' '}
            <TextWithTooltip
                heading={
                    <Heading type="xsmall" className="capitalize  text-primaryInput">
                        Enter Location
                    </Heading>
                }
            >
                <p className="text-sm">
                    These bounties can be viewed on the Map section in Impact Miner, and the user needs to be within a
                    certain range to accept the bounty. You can decide the range based on the nature of the bounty. Eg:
                    Bounty to map groundwater wells in a specific region.
                </p>
            </TextWithTooltip>
            <div className="px-5 py-3 mt-1 mb-6 border border-borderColor rounded-md">
                <div>
                    <Heading type="xsmall" className="capitalize mb-2 text-primaryInput">
                        City/Town where the bounty is live ? <sup className="text-lg relative top-[1px]">*</sup>
                    </Heading>
                    <Input
                        // {...register('orgName', {
                        //     required: 'Please enter your organization title.',
                        // })}

                        type="text"
                        value={cityField.value}
                        onChange={handleCityFieldChange}
                        error={errors?.city?.message}
                        placeholder={placeholder}
                        className={'w-[100%]'}
                    />
                </div>
                <div className="  ">
                    <PhysicalBountyMap
                        core={core}
                        circleRadius={bountyRadiusField.value}
                        searchQuery={cityField.value}
                        setSearchText={cityField.onChange}
                        hasStartedTyping={hasStartedTyping}
                        setCoordinates={locationField.onChange}
                        coordinates={coordinates}
                    />
                </div>
                <div className="mt-6">
                    <Heading type="xsmall" className="capitalize mb-2 text-primaryInput">
                        Enter Bounty Radius (in Kms) <sup className="text-lg relative top-[1px]">*</sup>
                    </Heading>
                    <Input
                        // {...register('orgName', {
                        //     required: 'Please enter your organization title.',
                        // })}
                        type="number"
                        value={bountyRadiusField.value}
                        onChange={(e) => {
                            bountyRadiusField.onChange(e.target.value);
                            setRadiusPreview(e.target.value);
                        }}
                        min="0"
                        error={errors?.bountyRadius?.message}
                        placeholder={'50'}
                        className={'w-[100%]'}
                    />
                </div>

                <Input
                    // {...register('orgName', {
                    //     required: 'Please enter your organization title.',
                    // })}
                    type="text"
                    // error={errors?.orgName?.message}
                    placeholder={'Enter Latitude...'}
                    className={'w-[100%] hidden'}
                />

                <Input
                    // {...register('orgName', {
                    //     required: 'Please enter your organization title.',
                    // })}
                    type="text"
                    // value={bountyTitle}
                    // onChange={(e) => setBountyTitle(e.target.value)}
                    // error={errors?.orgName?.message}
                    placeholder={'Enter Longitude...'}
                    className={'w-[100%] hidden'}
                />
            </div>
        </div>
    ) : null;
}

export default OnSelectingPhysicalBounty;
