import { createContext, useContext } from 'react';
import WhiteCard from './WhiteCard';
import Heading from './Heading';
import Button from './Button';
import Modal from './Modal';

const TableContext = createContext();
function Table({ children, cols = 'grid-cols-tableLists', className = '', whiteCardClassName = '' }) {
    return (
        <TableContext.Provider value={{ cols, className }}>
            <WhiteCard className={`p-6 ${whiteCardClassName} min-h-[70vh]  overflow-x-hidden overflow-y-auto main `}>
                {children}
            </WhiteCard>
        </TableContext.Provider>
    );
}

function TableDescription({
    description = '',
    secondaryBtn = '',
    primaryBtn = '',
    tertiaryBtn = '',
    clickOnPrimary,
    clickOnSecondary,
    children,
    descriptionClass = '',
    clickOnTertiary,
    primaryBtnModal = '',
    primaryBtnBlueModal = '',
    modalNeedToOpen,
}) {
    if (!description) return null;
    return (
        <div className={`flex mb-5 justify-between ${descriptionClass}  `}>
            <Heading className={`text-primaryInput font-medium  `} type={'large'}>
                {description}
            </Heading>
            <div className="flex divide-x-2 divide-solid divide-borderColor ">
                {children}
                {tertiaryBtn && (
                    <Button variant="transparent" className="pr-7" size="none" onClick={clickOnTertiary}>
                        {tertiaryBtn}
                    </Button>
                )}

                {secondaryBtn && (
                    <Button variant="transparent" size="none" className="px-7" radius="" onClick={clickOnSecondary}>
                        {secondaryBtn}
                    </Button>
                )}
                {primaryBtn && (
                    // <Button onClick={clickOnPrimary} className="pl-7" variant="transparent" size="none">
                    //     {primaryBtn}
                    // </Button>
                    <Button onClick={clickOnPrimary} size="xsmall">
                        {' '}
                        {primaryBtn}
                    </Button>
                )}
                {primaryBtnModal && (
                    <Modal>
                        <Modal.Button className="flex gap-2 cursor-pointer items-center" opens={primaryBtnModal}>
                            <Button className="pl-7" variant="transparent" size="none">
                                {primaryBtnModal}
                            </Button>
                        </Modal.Button>
                        <Modal.Window name={primaryBtnModal}>{modalNeedToOpen}</Modal.Window>
                    </Modal>
                )}
                {primaryBtnBlueModal && (
                    <Modal>
                        <Modal.Button className="flex gap-2 cursor-pointer items-center" opens={primaryBtnBlueModal}>
                            <Button onClick={clickOnPrimary} size="xsmall">
                                {primaryBtnBlueModal}
                            </Button>
                        </Modal.Button>
                        <Modal.Window name={primaryBtnBlueModal}>{modalNeedToOpen}</Modal.Window>
                    </Modal>
                )}
            </div>
        </div>
    );
}

function TableHeader({ children }) {
    const { cols, className } = useContext(TableContext);

    return <div className={`grid ${cols} py-3 border-b border-borderColor  ${className} `}>{children}</div>;
}

function TableRow({ children, rowClassName, ...props }) {
    const { cols, className } = useContext(TableContext);

    return (
        <div {...props} className={`grid ${cols}  py-3 border-b border-borderColor  ${className} ${rowClassName} `}>
            {children}
        </div>
    );
}
Table.Description = TableDescription;
Table.Header = TableHeader;
Table.Row = TableRow;

export default Table;
