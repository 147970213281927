import { useEffect, useState } from 'react';

import EventInput from '../features/events/EventInput';
import EventPreview from '../features/events/EventPreview';

import LayoutWithoutSidebar from '../ui/LayoutWithoutSidebar';
import { useSearchParams } from 'react-router-dom';
import { getEvent } from '../apis/events/getEvent';
import EventPreviewLanding from '../features/events/EventPreviewLanding';
import { useController, useForm } from 'react-hook-form';

function CreateEvent() {
    const [searchParams] = useSearchParams();
    const [event, setEvent] = useState({});
    const eventId = searchParams.get('eventId');
    const [imageObject, setImage] = useState(() => {
        return eventId ? { image: null, imageURL: event?.bannerImages?.[0] } : { image: null, imageURL: '' };
    });
    const [landingImageObject, setLandingImage] = useState(() => {
        return eventId ? { image: null, imageURL: event?.image } : { image: null, imageURL: '' };
    });
    // const {
    //     handleSubmit,
    //     reset,
    //     control,
    //     formState: { errors },
    // } = useForm(
    //     eventId && {
    //         defaultValues: {
    //             eventHeading: event?.sections?.[0]?.title,
    //             eventName: event?.name,
    //             eventDescription: event?.sections?.[0]?.content,
    //             image: event?.image,
    //         },
    //     }
    // );
    const {
        handleSubmit,
        reset,
        register,
        control,
        formState: { errors },
    } = useForm();

    const { field: eventHeadingField } = useController({
        name: 'eventHeading',
        control,
        rules: {
            required: 'Please enter a heading.',
            maxLength: {
                value: 200,
                message: 'The maximum length is 200 characters.',
            },
        },
    });
    const { field: eventNameField } = useController({
        name: 'eventName',
        control,
        rules: {
            required: 'Please enter a title',
            maxLength: {
                value: 20,
                message: 'The maximum length is 20 characters',
            },
        },
    });
    const { field: eventDescriptionField } = useController({
        name: 'eventDescription',
        control,
        rules: {
            required: 'Please enter a brief description',
            maxLength: {
                value: 1000,
                message: 'The maximum length is 1,000 characters.',
            },
        },
    });
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getEvent(eventId);
                    // setIsPublished(() => (data?.isLive ? 'Live' : 'Unpublished'));
                    setEvent(data);
                    setImage((prev) => ({ ...prev, imageURL: data?.image }));
                    setLandingImage((prev) => ({ ...prev, imageURL: data?.bannerImages?.[0] }));
                    reset({
                        eventHeading: data?.sections[0]?.title,
                        eventName: data?.name,
                        eventDescription: data?.sections[0]?.content,
                    });
                } catch (error) {
                    console.log(error);
                }
            };
            if (eventId) fetchData();
        },
        [eventId]
    );

    return (
        <LayoutWithoutSidebar marginV="" className="grid py-8 grid-cols-createEvent h-[100vh] overflow-hidden ">
            <EventInput
                errors={errors}
                eventNameField={eventNameField}
                eventHeadingField={eventHeadingField}
                eventDescriptionField={eventDescriptionField}
                imageObject={imageObject}
                reset={reset}
                handleSubmit={handleSubmit}
                setImage={setImage}
                editId={eventId}
                register={register}
                landingImageObject={landingImageObject}
                setLandingImage={setLandingImage}
            />
            <div className="overflow-auto main">
                <EventPreview imageURL={imageObject?.imageURL} />
                <EventPreviewLanding
                    title={eventNameField?.value}
                    heading={eventHeadingField?.value}
                    imageURL={landingImageObject?.imageURL}
                    description={eventDescriptionField?.value}
                />
            </div>
        </LayoutWithoutSidebar>
    );
}

export default CreateEvent;
