import mobileFrame from '../../assets/mobileFrame.png';
import eventPreviewExploreOne from '../../assets/eventPreviewExploreOne.png';
import eventPreviewExploreTwo from '../../assets/eventPreviewExploreTwo.png';
import noBanner from '../../assets/nobanner.png';
import globalImpactIndex from '../../assets/globalImpactIndex.png';
import AvatarImage from '../../ui/AvatarImage';
function EventPreview({ imageURL }) {
    return (
        <div
            className="relative w-[300px] h-[610px]  justify-self-end  mb-6  "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-24 left-6 right-6 ">
                <div className="mt-1 mb-3">
                    <p className="text-xs  text-[#79747E] ">Welcome</p>
                    <div className="flex items-center gap-1">
                        <AvatarImage w="w-[20px]" h="h-[20px]" />
                        <p className="font-medium">Ninja_Blue33</p>
                    </div>
                </div>
                <div className="mb-3">
                    <p className="text-xs mb-1 text-[#79747E] font-medium">Global Impact Index</p>
                    <div className=" h-[56px] rounded-md overflow-hidden">
                        <img className="h-full w-full object-cover" src={globalImpactIndex} alt="impact index" />
                    </div>
                </div>
                <div className="bg-[#EBEBEB] rounded-md overflow-hidden mb-4 ">
                    <div className="flex h-[95px]  items-center justify-center">
                        {!imageURL ? (
                            <img className="w-8 h-8" src={noBanner} alt="" />
                        ) : (
                            <img className="w-full h-full object-cover " src={imageURL} alt="" />
                        )}
                    </div>
                </div>
                <div>
                    <p className="text-xs mb-1 text-[#79747E] font-medium">Explore</p>

                    <div className="flex flex-col gap-3">
                        <div className="relative h-[100px] rounded-md overflow-hidden">
                            <img className="h-full object-cover" src={eventPreviewExploreOne} alt="explore 1" />
                            <p className="absolute px-2 py-1 text-[#F9FAFB] font-semibold text-xs bottom-0 left-0">
                                Bounties based on your Superpowers
                            </p>
                        </div>
                        <div className="relative h-[100px] rounded-md overflow-hidden">
                            <img className="h-full w-full object-cover" src={eventPreviewExploreTwo} alt="explore 1" />
                            <p className="absolute px-2 py-1 text-[#F9FAFB] font-semibold text-xs bottom-0 left-0">
                                Bounties around you
                            </p>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                {/* <Button variant="preview" size="roundPreview">
                    Add Deliverables & Tips
                </Button> */}
            </footer>
        </div>
    );
}

export default EventPreview;
