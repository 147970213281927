import { useContext, useState } from 'react';
import AvatarImage from '../../../ui/AvatarImage';
import Button from '../../../ui/Button';
import Input from '../../../ui/Input';
import SubHeading from '../../../ui/SubHeading';
import { AuthContext } from '../../../contexts/AuthContext';
import { useController, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import walletimage from '../../../assets/walletimage.png';
import CharacterLimit from '../../../ui/CharacterLimit';
import { EditIcon } from '../../../assets/icons/UserProfileIcons';
import { toast } from 'sonner';
import { uploader } from '../../../helpers/uploader';
import { useLoadingState } from '../../../hooks/useLoader';
import { editProfile } from '../../../apis/user/user';
import Loader from '../../../ui/Loader';
function EditProfileData() {
    const { selectedPowers } = useOutletContext();
    const { dispatch } = useContext(AuthContext);
    const { userProfile } = useOutletContext();
    const { loading: saving, startLoading: startSaving, stopLoading: stopSaving } = useLoadingState();
    const [imageObject, setImage] = useState({ image: null, imageURL: userProfile?.profileImage });

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            bio: userProfile.bio,
        },
    });
    const { field: bioField } = useController({
        name: 'bio',
        control,
        rules: {
            required: 'Please enter your bio',
            maxLength: {
                value: 130,
                message: 'The maximum length is 130 characters',
            },
        },
    });

    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            setImage({
                image: file,
                imageURL: URL.createObjectURL(event.target.files[0]),
            });
            // URL.createObjectURL(profileImageField.value)
            // await uploadFile(file);
        }
    };

    const onSubmit = async (data) => {
        const profileToEdit = new FormData();
        profileToEdit.append('bio', data.bio);
        if (imageObject.image) {
            profileToEdit.append('profileImage', imageObject.image);
        }
        selectedPowers.forEach((skill, index) => {
            profileToEdit.append(`primarySkills[${index}]`, skill);
        });

        try {
            startSaving();
            const response = await editProfile(profileToEdit, userProfile?.uid);

            if (response.code === 400) {
                throw new Error(response.message);
            }
            if (response?._id) {
                toast.success('Profile successfully updated. ');

                dispatch({ type: 'editProfile', payload: response });
                return;
            }
            // if(Object.keys())
        } catch (error) {
            console.log(error);
            toast.error(error);
        } finally {
            stopSaving();
        }
    };

    return (
        <div>
            <div className="flex  items-center mb-6">
                <div className="relative w-[76px] h-[76px]   ">
                    <AvatarImage w="w-full" h="h-full" className=" " url={imageObject?.imageURL} />
                    <div className="absolute bottom-2 -right-2  rounded-full border  border-borderColor bg-white hover:bg-gray-50">
                        <div className="flex items-center justify-center w-7 h-7   ">
                            <label className="cursor-pointer  " for="avatar">
                                <EditIcon />
                            </label>
                            <Input type="file" {...register('profileImage')} onChange={(e) => handleFileChange(e)} />
                        </div>
                    </div>
                    {/* <Button
                        variant="tertiary"
                        radius="rounded-full"
                        onClick={() => {}}
                        className=" border-0 hover:!bg-gray-50  !bg-white absolute bottom-3  right-0 "
                        size="circle"
                    >
                       
                    </Button> */}
                </div>
            </div>
            <div className="flex flex-col gap-6  ">
                <div className="">
                    <SubHeading className={'text-primaryInput mb-1'} type={'large'}>
                        Username
                    </SubHeading>

                    <div className="flex gap-3 py-2 px-3 border cursor-not-allowed rounded-lg border-disabledBorder bg-disabledColor   ">
                        <p className={` text-[#404040]   leading-6 `}>
                            <span>{userProfile?.userName}</span>
                        </p>
                    </div>
                </div>
                {/* <div className="">
                    <SubHeading className={'text-primaryInput mb-1'} type={'large'}>
                        Wallet
                    </SubHeading>
                    <div className="flex gap-3 py-2 px-3 border cursor-not-allowed rounded-lg border-disabledBorder bg-disabledColor   ">
                        <img className="w-7 h-7 object-cover rounded-full" src={walletimage} alt="" />

                        <p className={` text-[#404040]   leading-6 `}>
                            <span>
                                {userProfile?.walletAddress?.slice(0, 4)}...{userProfile?.walletAddress?.slice(-4)}
                            </span>
                        </p>
                    </div>
                </div> */}
                <div className="">
                    <SubHeading className={'text-primaryInput mb-1'} type={'large'}>
                        Email
                    </SubHeading>
                    <div className="flex gap-3 py-2 px-3 border cursor-not-allowed rounded-lg border-disabledBorder bg-disabledColor   ">
                        <p className={` text-[#404040]   leading-6 `}>
                            <span>{userProfile?.email}</span>
                        </p>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-6 ">
                <div className="">
                    <SubHeading className={'text-primaryInput mb-1'} type={'large'}>
                        Bio
                    </SubHeading>
                    <div>
                        <Input
                            {...bioField}
                            error={errors?.bio?.message}
                            type="textArea"
                            placeholder={'Tell us something about yourself'}
                            className={'w-[100%]'}
                        />
                        <CharacterLimit min={bioField?.value ? bioField.value?.length : 0} max={130} />
                    </div>
                </div>
                <div className="mt-24 fixed right-14 bottom-4 ">
                    <Button type="submit" size="round">
                        {saving ? <Loader /> : 'Save'}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default EditProfileData;
