import React from 'react';

function InputText(
    {
        type = 'text',
        label,
        transparentTextSize = 'text-3xl',
        transparentFontWeight = 'font-semibold',
        isMandatory = false,
        placeholder = '',
        error = '',
        containerClass = '',
        step = '.000000001',
        className,
        helpUser,
        restrictLength,
        ...props
    },
    ref
) {
    switch (type) {
        case 'search':
            return (
                <div>
                    <label className="mb-1" htmlFor={label}></label>
                    <input
                        id={label}
                        type="text"
                        ref={ref}
                        placeholder={placeholder}
                        className={`   bg-transparent  outline-none rounded-md  ${className}`}
                        {...props}
                    />
                    {error && <p className="text-foundryRed">{error}</p>}
                </div>
            );
        case 'text':
            return (
                <div className={`${containerClass}`}>
                    <label className="text-primaryText block mb-1" htmlFor={label}>
                        {label}
                        {isMandatory && <sup className="text-foundryRed relative -top-[2px] left-1 ">*</sup>}
                    </label>
                    <input
                        id={label}
                        type="text"
                        ref={ref}
                        placeholder={placeholder}
                        className={` border border-borderColor text-primaryInput
                             placeholder:text-tertiaryInput   bg-white rounded-lg px-3 py-2 ${className}`}
                        {...props}
                    />
                    {error && (
                        <div className="flex items-center gap-1">
                            <p className="text-foundryRed">{error}</p>
                            {helpUser && <span>{helpUser}</span>}
                        </div>
                    )}
                </div>
            );
        case 'number':
            return (
                <div className={`${containerClass}`}>
                    <label className="text-primaryText block mb-1" htmlFor={label}>
                        {label}
                        {isMandatory && <sup className="text-foundryRed relative -top-[2px] left-1 ">*</sup>}
                    </label>
                    <input
                        type="number"
                        min={'0'}
                        ref={ref}
                        step={step}
                        onWheel={(event) => event.target.blur()}
                        onInput={restrictLength}
                        placeholder={placeholder}
                        className={` border border-borderColor text-primaryInput shadow-sm placeholder:text-tertiaryInput   bg-white rounded-md px-3 py-2 ${className}`}
                        {...props}
                    />
                    {error && (
                        <div className="flex items-center justify-between gap-1">
                            <p className="text-foundryRed">{error}</p>
                            {helpUser && <span>{helpUser}</span>}
                        </div>
                    )}
                </div>
            );
        case 'textArea':
            return (
                <>
                    <textarea
                        rows="3"
                        ref={ref}
                        placeholder={placeholder}
                        {...props}
                        className={`  border border-borderColor text-primaryInput placeholder:text-tertiaryInput shadow-sm  bg-white rounded-md py-2 px-3 ${className}`}
                        id=""
                    />
                    {error && <p className="text-foundryRed">{error}</p>}
                </>
            );
        case 'file':
            return (
                <>
                    <input
                        ref={ref}
                        type="file"
                        id="avatar"
                        name="avatar"
                        accept="image/png, image/jpeg"
                        className="hidden"
                        {...props}
                    />
                    {error && <p className="text-foundryRed">{error}</p>}
                </>
            );
        case 'transparent':
            return (
                <>
                    <input
                        ref={ref}
                        type="text"
                        placeholder={placeholder}
                        className={`  ${transparentTextSize} outline-none text-primaryInput placeholder:text-tertiaryInput bg-transparent ${transparentFontWeight}  ${className}`}
                        {...props}
                    />
                    {error && <p className="text-foundryRed">{error}</p>}
                </>
            );
        case 'transparentNumber':
            return (
                <>
                    <input
                        ref={ref}
                        type="number"
                        placeholder={placeholder}
                        min={props.min}
                        className={`  ${transparentTextSize} outline-none placeholder:text-tertiaryInput bg-transparent ${transparentFontWeight}  ${className}`}
                        {...props}
                    />
                    {error && <p className="text-foundryRed">{error}</p>}
                </>
            );
        case 'hidden':
            return (
                <>
                    <input
                        ref={ref}
                        type="text"
                        placeholder={placeholder}
                        className={`  ${transparentTextSize} outline-none placeholder:text-uploadText bg-transparent ${transparentFontWeight}  ${className}`}
                        {...props}
                    />
                    {error && <p className="text-foundryRed">{error}</p>}
                </>
            );
        default:
            return (
                <>
                    <input
                        ref={ref}
                        type="text"
                        placeholder="Add Bio"
                        className={` border border-borderColor   bg-white rounded-md p-4 ${className}`}
                        {...props}
                    />
                    {error && (
                        <div>
                            <p className="text-foundryRed">{error}</p>
                            {helpUser && <span>{helpUser}</span>}
                        </div>
                    )}
                </>
            );
    }
}
const Input = React.forwardRef(InputText);
export default Input;
