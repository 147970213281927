import { toast } from 'sonner';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';
import axios from 'axios';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};
export const createProject = async (project) => {
    try {
        const url = `${BASE_URL}/project`;

        const accessToken = await getAccessToken();
        const response = await axios.post(url, project, getConfig(accessToken));

        if (response.status === 201) toast.success('Project successfully created. ');
        return response.status;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};

export const getProjects = async (id) => {
    try {
        const url = `${BASE_URL}/project?organisationId=${id}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
export const getParticularProject = async (id) => {
    try {
        const url = `${BASE_URL}/project/${id}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const updateProject = async (id, project) => {
    try {
        const url = `${BASE_URL}/project/${id}`;

        const accessToken = await getAccessToken();
        const response = await axios.put(url, project, getConfig(accessToken));

        if (response.status === 200) toast.success('Your project has been updated. ');
        return response.status;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};

export const deleteProject = async (id) => {
    try {
        const url = `${BASE_URL}/project/${id}`;

        const accessToken = await getAccessToken();
        const response = await axios.delete(url, getConfig(accessToken));

        // if (response.status === 201) toast.success('Project is successfully created !');
        return response.data;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};

export const getBountiesInProject = async (id) => {
    try {
        const url = `${BASE_URL}/organisation/bounty?projectId=${id}&page=1&entriesPerPage=10`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
