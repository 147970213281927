import React, { useContext, useEffect, useState } from 'react';
import Input from '../../../ui/Input';
import InputForDragAndDrop from '../../../ui/InputForDragAndDrop';
import Button from '../../../ui/Button';
import DeleteIcon from '../../../assets/icons/Delete';
import { useController, useFieldArray } from 'react-hook-form';
import InputForUploadingPDF from '../../../ui/InputForUploadingPDF';
import AddProfileImage from '../../../assets/icons/AddProfileImage';
import DeleteUploadedDataIcon from '../../../assets/icons/DeleteUploadedDataIcon';
import InputForUploadingPDFWithLimit from '../../../ui/InputForUploadingPDFWithLimit';
import { BountiesContext } from '../../../contexts/BountiesContext';

const DownloadPDF = ({ register, index, errors, control, getValues, microtaskEditDetail }) => {
    const { isEdit } = useContext(BountiesContext);
    // const { field: questionAnswerImageField } = useController({
    //     name: `microtasks.${index}.questionAnswer_image`,
    //     control,
    // });
    const {
        fields: downloadPDF_Fields,
        append,
        remove,
    } = useFieldArray({
        name: `microtasks[${index}].downloadPDF_Files`,
        control,
    });
    const [uploadError, setUploadError] = useState('');

    useEffect(() => {
        // Run this logic only once when the component is mounted
        if (isEdit && downloadPDF_Fields.length === 0) {
            // If in edit mode, populate the answers from microtaskEditDetail
            const editFiles = microtaskEditDetail?.downloadPdf_Files?.split(',') || [];
            editFiles.forEach((f) => {
                const [editFileName, editFileLink] = f.split('~');
                append({ fileTitle: editFileName, link: editFileLink });
            });
        } else if (!isEdit && downloadPDF_Fields.length === 0) {
            // If not in edit mode, ensure there is at least one empty answer field
            append({ answerName: '' });
        }
    }, [isEdit, downloadPDF_Fields.length, append, microtaskEditDetail]);
    // downloadPDF_Title: '',
    // downloadPDF_Description: '',
    // downloadPDF_Files: '',
    // if (downloadPDF_Fields.length === 0) {
    //     append({ fileTitle: '', link: '' }); // Append default value if no fields exist
    // }

    return (
        <div>
            <Input
                {...register(`microtasks.${index}.downloadPDF_Title`, {
                    required: 'Please enter a title.',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.downloadPdf_Title || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.downloadPDF_Title?.message}
                placeholder={'Title *'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 '}
            />
            <Input
                {...register(`microtasks.${index}.downloadPDF_Description`, {
                    required: 'Please enter a description.',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.downloadPdf_Description || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.downloadPDF_Description?.message}
                placeholder={'Task Description *'}
                transparentTextSize="text-sm"
                transparentFontWeight={'font-normal'}
                className={'w-[100%] mb-1 mt-2 '}
            />
            <div className="mt-4  items-center">
                {downloadPDF_Fields?.map((downloadPDF_Field, i) => {
                    const hasError =
                        errors?.microtasks?.[index]?.downloadPDF_Files?.[i]?.fileTitle?.message ||
                        errors?.microtasks?.[index]?.downloadPDF_Files?.[i]?.link?.message
                            ? true
                            : false;
                    return (
                        <div className="mb-4">
                            <div key={downloadPDF_Field.id} className="flex items-center  gap-3  w-full">
                                <div className="flex-1">
                                    <InputForDragAndDrop
                                        name={`microtasks[${index}].downloadPDF_Files[${i}].fileTitle`}
                                        control={control}
                                        index={i}
                                        width="w-full"
                                        placeholder="File Name"
                                        validateObject={{
                                            required: 'Please enter a file name',
                                        }}
                                    />
                                </div>

                                <div className="">
                                    <InputForUploadingPDFWithLimit
                                        isUploaded={getValues('microtasks')[index].downloadPDF_Files[i].link}
                                        errors={errors}
                                        name={`microtasks[${index}].downloadPDF_Files[${i}].link`}
                                        control={control}
                                        uploadError={uploadError}
                                        setUploadError={setUploadError}
                                        index={i}
                                        width="w-[50%]"
                                        placeholder="File Name"
                                        validateObject={{
                                            required: 'Please upload a pdf',
                                        }}
                                    />
                                </div>

                                {
                                    <DeleteIcon
                                        className={`${i > 0 ? 'cursor-pointer' : 'invisible'}`}
                                        onClick={() => remove(i)}
                                    />
                                }
                            </div>
                            {uploadError ? (
                                <p className="text-foundryRed">{uploadError}</p>
                            ) : (
                                hasError && <p className="text-foundryRed">Please enter a file name and upload a pdf</p>
                            )}
                        </div>
                    );
                })}

                <Button
                    type="button"
                    onClick={() => {
                        append({
                            fileTitle: '',
                            link: '',
                        });
                    }}
                    size="none"
                    className="  font-semibold !text-lightBlack text-sm"
                    variant="transparent"
                >
                    + Add File
                </Button>
                <div>
                    {errors?.microtasks && (
                        <p className="text-foundryRed">{errors?.microtasks[index]?.downloadPDF_Files?.root?.message}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DownloadPDF;
