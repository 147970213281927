const atlantislogo = '/assets/images/logo.png';

function SideLogo() {
    return (
        <div className="">
            <img className="w-20 h-20" alt="Atlantis logo" src={atlantislogo} />
        </div>
    );
}

export default SideLogo;
