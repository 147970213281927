import LeftPreviewArrow from '../../../assets/icons/LeftPreviewArrow';
import mobileFrame from '../../../assets/mobileFrame.png';

import Button from '../../../ui/Button';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
function PreviewQuestionAndAnswer({ data }) {
    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 ">
                <div className="my-4">
                    <Heading className={'text-minerTitle'} type={'xsmall'}>
                        {data.questionAnswer_heading || 'Heading'}
                    </Heading>
                    <SubHeading className={'!text-xs text-minerDescription font-semibold mt-2'} type="xsmall">
                        {data.questionAnswer_Title || 'Title'}{' '}
                    </SubHeading>
                    <div className="border border-[#737373] rounded-md p-2 my-3">
                        <p className="text-xs text-minerSecondary">{data.questionAnswer_question || 'Hint'}</p>
                    </div>
                    {data?.questionAnswer_image && (
                        <div className="overflow-hidden">
                            <img
                                className="w-full h-40 object-contain rounded-md"
                                src={data?.questionAnswer_image}
                                alt=""
                            />
                        </div>
                    )}
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex !text-[#1A56DB] text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" !text-[#1A56DB] text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" size="largeMiner" className="py-2 text-xs " radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewQuestionAndAnswer;
