import { toast } from 'sonner';

export const isDateAndTimeValidForStartingBounty = (expirationTime, validationTillDate) => {
    const expirationDate = new Date(expirationTime);
    const validationDate = new Date(validationTillDate);
    const currentDate = new Date();
    // Validation: expirationTime should be in the future
    if (expirationDate <= currentDate) {
        toast.error('Expiration time must be in the future.');
        return true;
    }

    // Validation: validationTillDate should be after expirationTime
    if (validationDate <= expirationDate) {
        toast.error('Validation till date must be after the expiration time.');
        return true;
    }
};
