export const extractStringAfterColon = (input) => {
    // Find the position of the colon
    const colonIndex = input.indexOf(':');

    // If colon exists, extract and trim the string after it
    if (colonIndex !== -1) {
        return input.substring(colonIndex + 1).trim();
    }

    // If no colon is found, return an empty string or a suitable message
    return '';
};
