import React, { useState } from 'react';
import { faqData } from '../../constants/datas';
import AccordionDown from '../../assets/icons/AccordionDown';

const Faqs = () => {
    const [openFaq, setOpenFaq] = useState(null); // Track which FAQ is open

    const toggleFaq = (id) => {
        setOpenFaq(openFaq === id ? null : id); // Open the clicked FAQ, close if already open
    };

    return (
        <div id="accordion-collapse" data-accordion="collapse">
            {faqData.map((faq, index) => (
                <div className="pb-6 flex  " key={faq.id}>
                    <p className="text-lg  text-secondaryInput ">
                        <span>{'<'}</span>{' '}
                        <span className="inline-block px-1">{index < 9 ? '0' + (index + 1) : index + 1}</span>{' '}
                        <span>{'>'}</span>
                    </p>
                    <div className="flex-1">
                        <h2 id={`accordion-collapse-heading-${faq.id}`}>
                            <button
                                type="button"
                                className={`flex items-center justify-between w-full px-5 font-medium rtl:text-right   ${
                                    openFaq === faq.id ? 'rounded-t-xl' : ''
                                }    hover:bg-gray-100  gap-1`}
                                aria-expanded={openFaq === faq.id}
                                onClick={() => toggleFaq(faq.id)}
                            >
                                <span className="text-xl font-medium text-primaryInput">{faq.question}</span>
                                <AccordionDown
                                    className={` shrink-0 transform ${openFaq === faq.id ? 'rotate-180' : ''}`}
                                />
                            </button>
                        </h2>
                        {openFaq === faq.id && (
                            <div id={`accordion-collapse-body-${faq.id}`} className="px-5 ">
                                {faq.answer.map((paragraph, index) => (
                                    <p key={index} className="mb-2 text-primaryInput ">
                                        {paragraph}
                                        {faq.links && faq.links[index] && (
                                            <p>
                                                {' '}
                                                <a
                                                    href={faq.links[index].href}
                                                    target="_blank"
                                                    className="text-foundryBlue  hover:underline"
                                                >
                                                    {faq.links[index].text}
                                                </a>
                                            </p>
                                        )}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Faqs;
