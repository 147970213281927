import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Table from '../../ui/Table';

import CreateProjectModal from './CreateProjectModal';
import Menus from '../../ui/Menus';
import { useEffect, useState } from 'react';

import { getProjects } from '../../apis/projects/projects';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import UpdateIcon from '../../assets/icons/UpdateIcon';
import DeleteProjectModal from './DeleteProjectModal';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import NoDataAvailable from '../../ui/NoDataAvailable';
import AvatarImage from '../../ui/AvatarImage';

function ProjectsList() {
    const navigate = useNavigate();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [isModalOpen, setModalOpen] = useState(false); // Modal visibility state
    const [modalAction, setModalAction] = useState(''); // Track action type: 'duplicate' or 'delete'
    const [selectedProject, setSelectedProject] = useState(null); // Track selected bounty for modal
    const { id } = useParams();
    const location = useLocation();
    let relativeAddress = location.pathname.split('/');
    relativeAddress.pop();
    relativeAddress = relativeAddress.join('/');
    const [projects, setProjects] = useState([]);

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const data = await getProjects(id);

                    setProjects(data);
                    stopLoading();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );

    const handleOpenModal = (action, project) => {
        setModalAction(action); // Set the action type ('duplicate' or 'delete')
        setSelectedProject(project); // Set the selected bounty
        setModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setModalOpen(false); // Close the modal
        setModalAction(''); // Clear action type
        setSelectedProject(null); // Clear selected bounty
    };

    const handleConfirmAction = (id, data) => {
        if (modalAction === 'update') {
            const afterUpdation = projects.map((project) => {
                if (project._id === id) return { ...project, ...data };
                return project;
            });

            setProjects(afterUpdation);

            // Perform duplication logic here
        } else if (modalAction === 'delete') {
            const afterDelete = projects.filter((project) => project._id !== id);
            setProjects(afterDelete);
        }
        handleCloseModal();
    };

    return (
        <>
            <Menus>
                <Table className="items-center" cols="grid-cols-tableProjects gap-14">
                    <Table.Description
                        descriptionClass=" !mb-0"
                        description="Projects in the Organisation "
                        primaryBtnBlueModal="+ Add Project"
                        modalNeedToOpen={
                            <CreateProjectModal
                                startCreating={startLoading}
                                stopCreating={stopLoading}
                                setProjects={setProjects}
                            />
                        }
                    />

                    <p className="mb-4">Click on a project to add new bounties or make modifications to the project</p>

                    <Table.Header>
                        <p className="text-sm   font-semibold text-secondaryText ">Project ID</p>
                        <p className="text-sm font-semibold text-secondaryText ">Project Title</p>
                        <p className="text-sm   font-semibold text-secondaryText ">Bounties Live</p>
                        <p className="text-sm   font-semibold text-secondaryText ">Created By</p>
                        <p className="text-sm  font-semibold text-secondaryText "></p>
                    </Table.Header>
                    {!loading ? (
                        projects.length > 0 ? (
                            projects.map((project) => (
                                <Table.Row key={project._id} rowClassName="relative">
                                    <p
                                        onClick={() => navigate(project._id)}
                                        className="text-primaryColor cursor-pointer truncate w-full uppercase justify-self-start font-medium"
                                    >
                                        {project._id}
                                    </p>
                                    <p className="text-primaryInput">{project.title}</p>
                                    <p className="text-primaryInput">
                                        {project.liveBountyCount}/{project.totalBountyCount}
                                    </p>
                                    <div className="flex items-center gap-4 ">
                                        <AvatarImage w="w-8" h="h-8" url={project.creatorProfileImage} />
                                        <p className="text-lightBlack font-medium ">{project.creatorUsername}</p>
                                    </div>

                                    <Menus.Toggle id={project._id} />

                                    <Menus.List id={project._id}>
                                        <Menus.Button
                                            icon={<UpdateIcon />}
                                            shouldCloseMenu={true}
                                            onClick={(fn) => {
                                                handleOpenModal('update', project);
                                                fn();
                                            }}
                                        >
                                            Update
                                        </Menus.Button>
                                        <Menus.Button
                                            icon={<DeleteIcon />}
                                            shouldCloseMenu={true}
                                            onClick={(fn) => {
                                                handleOpenModal('delete', project);
                                                fn();
                                            }}
                                        >
                                            Delete
                                        </Menus.Button>
                                    </Menus.List>
                                </Table.Row>
                            ))
                        ) : (
                            <NoDataAvailable />
                        )
                    ) : (
                        <div className="min-h-[20vh] flex justify-center items-center">
                            <Loader />
                        </div>
                    )}
                </Table>
            </Menus>
            {isModalOpen && (
                <div>
                    {modalAction === 'update' && (
                        <CreateProjectModal
                            onUpdation={handleConfirmAction}
                            edit={true}
                            projectDetails={selectedProject}
                            close={handleCloseModal}
                        />
                    )}
                    {modalAction === 'delete' && (
                        <DeleteProjectModal
                            onDeletion={handleConfirmAction}
                            projectName={selectedProject.title}
                            projectId={selectedProject._id}
                            close={handleCloseModal}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default ProjectsList;
