import {
    BTCode,
    BTCommunity,
    BTContent,
    BTDataScience,
    BTDesign,
    BTFunding,
    BTGardenening,
    BTGeneral,
    BTGHGRemoval,
    BTHarvest,
    BTLearn,
    BTPromotion,
    BTRecycle,
    BTRegenerate,
    BTRegistration,
    BTResearch,
    BTRestoration,
    BTScouting,
    BTSurvey,
    BTTraining,
    BTValidate,
    BTVolunteer,
    BTWriting,
} from '../assets/icons/bountyTypeIcons';

import waterDrop from '../assets/waterdrop.png';
import sun from '../assets/sun.png';
import fist from '../assets/fist.png';
import earth from '../assets/earth.png';

const nopoverty = '/assets/sdgs/E-WEB-Goal-01.png';
const zerohunger = '/assets/sdgs/E-WEB-Goal-02.png';
const goodhealth = '/assets/sdgs/E-WEB-Goal-03.png';
const education = '/assets/sdgs/E-WEB-Goal-04.png';
const genderequality = '/assets/sdgs/E-WEB-Goal-05.png';
const cleanwater = '/assets/sdgs/E-WEB-Goal-06.png';
const cleanenergy = '/assets/sdgs/E-WEB-Goal-07.png';
const economicgrowth = '/assets/sdgs/E-WEB-Goal-08.png';
const industry = '/assets/sdgs/E-WEB-Goal-09.png';
const inequalities = '/assets/sdgs/E-WEB-Goal-10.png';
const sustainablecities = '/assets/sdgs/E-WEB-Goal-11.png';
const consumptionandproduction = '/assets/sdgs/E-WEB-Goal-12.png';
const climateaction = '/assets/sdgs/E-WEB-Goal-13.png';
const lifebelowwater = '/assets/sdgs/E-WEB-Goal-14.png';
const lifeonland = '/assets/sdgs/E-WEB-Goal-15.png';
const peace = '/assets/sdgs/E-WEB-Goal-16.png';
const partnershipgoals = '/assets/sdgs/E-WEB-Goal-17.png';
export const bountyTypesData = [
    'Design',
    'Code',
    'Survey',
    'Scouting',
    'Writing',
    'Validate',
    'Volunteer',
    'Content',
    'Research',
    'Learning',
    'Harvesting',
    'GHG Removal',
    'Recycle',
    'Restoration',
    'Gardening',
    'Data Science',
    'Promotion',
    'Regenerate',
    'Training',
    'General',
    'Funding',
    'Registration',
    'Community',
];
export const bountyTypesWithIconData = [
    { label: 'Design', icon: <BTDesign /> },
    { label: 'Code', icon: <BTCode /> },
    { label: 'Survey', icon: <BTSurvey /> },
    { label: 'Scouting', icon: <BTScouting /> },
    { label: 'Writing', icon: <BTWriting /> },
    { label: 'Validate', icon: <BTValidate /> },
    { label: 'Volunteer', icon: <BTVolunteer /> },
    { label: 'Content', icon: <BTContent /> },
    { label: 'Research', icon: <BTResearch /> },
    { label: 'Learning', icon: <BTLearn /> },
    { label: 'Harvesting', icon: <BTHarvest /> },
    { label: 'GHG Removal', icon: <BTGHGRemoval /> },
    { label: 'Recycle', icon: <BTRecycle /> },
    { label: 'Restoration', icon: <BTRestoration /> },
    { label: 'Gardening', icon: <BTGardenening /> },
    { label: 'Data Science', icon: <BTDataScience /> },
    { label: 'Promotion', icon: <BTPromotion /> },
    { label: 'Regenerate', icon: <BTRegenerate /> },
    { label: 'Training', icon: <BTTraining /> },
    { label: 'General', icon: <BTGeneral /> },
    { label: 'Funding', icon: <BTFunding /> },
    { label: 'Registration', icon: <BTRegistration /> },
    { label: 'Community', icon: <BTCommunity /> },
];

export const coresData = ['Water', 'Earth', 'Energy', 'Social'];

export const coresWithIconData = [
    {
        label: 'Water',
        icon: waterDrop,
    },
    {
        label: 'Earth',
        icon: earth,
    },
    {
        label: 'Energy',
        icon: sun,
    },
    {
        label: 'Social',
        icon: fist,
    },
];

export const SUSTAINABLE_DEVELOPMENT_GOALS = [
    'No poverty',
    'Zero hunger',
    'Good health & well-being',
    'Quality education',
    'Gender equality',
    'Clean water & sanitation',
    'Affordable & clean energy',
    'Decent work & economic growth',
    'Industry, innovation & infrastructure',
    'Reduced inequalities',
    'Sustainable cities & communities',
    'Responsible consumption & production',
    'Climate action',
    'Life below water',
    'Life on land',
    'Peace, justice & strong institutions',
    'Partnerships for the goals',
];
export const DOUGHNUT_METRICS = [
    'water',
    'freshwater withdrawals',
    'ocean acidification',
    'housing',
    'food',
    'climate change',
    'biodiversity loss',
    'land conversion',
    'energy',
    'air pollution',
    'ozone layer depletion',
    'education',
    'income & work',
    'gender equality',
    'networks',
    'political voice',
    'health',
    'social equity',
    'peace & justice',
    'fertilizer use',
    'chemical pollution',
];

// ‘Draft', 'Creation Requested', 'Live and Published‘, 'Unvalidated', ‘Live and Unpublished’, ’Validation Requested', 'Completed'

export const BOUNTY_STATUS_TAG_COLOR = {
    Draft: { bg: 'bg-foundryYellowL', text: 'text-foundryYellow', border: 'ring-foundryYellow' },
    'Creation Requested': {
        bg: 'bg-foundryBlueL',
        text: 'text-foundryBlue',
        border: 'ring-foundryBlue',
    },
    'Live and Published': { bg: 'bg-foundryGreenL', text: 'text-foundryGreen', border: 'ring-foundryGreen' },
    'Live and Unpublished': { bg: 'bg-foundryYellowL', text: 'text-foundryYellow', border: 'ring-foundryYellow' },
    'Validation Requested': { bg: 'bg-foundryBlueL', text: 'text-foundryBlue', border: 'ring-foundryBlue' },
    Unvalidated: { bg: 'bg-foundryYellowL', text: 'text-foundryYellow', border: 'ring-foundryYellow' },
    Completed: { bg: 'bg-foundryGreenL', text: 'text-foundryGreen', border: 'ring-foundryGreen' },
};
export const EVENT_STATUS_TAG_COLOR = {
    Live: { bg: 'bg-foundryGreenL', text: 'text-foundryGreen', border: 'ring-foundryGreen' },
    Unpublished: { bg: 'bg-foundryYellowL', text: 'text-foundryYellow', border: 'ring-foundryYellow' },
};

export const VOTING_STATUS_COLOR = {
    'voted yes': { bg: 'bg-foundryGreenL', text: 'text-foundryGreen' },
    'voted no': { bg: 'bg-foundryRedL', text: 'text-foundryRed' },
    'not voted': {
        bg: 'bg-foundryYellowL',
        text: 'text-foundryYellow',
    },
    'ready to execute': { bg: 'bg-foundryGreenL', text: 'text-foundryGreen' },
};

export const SDG_IMAGES = {
    'No poverty': nopoverty,
    'Zero hunger': zerohunger,
    'Good health & well-being': goodhealth,
    'Quality education': education,
    'Gender equality': genderequality,
    'Clean water & sanitation': cleanwater,
    'Affordable & clean energy': cleanenergy,
    'Decent work & economic growth': economicgrowth,
    'Industry, innovation & infrastructure': industry,
    'Reduced inequalities': inequalities,
    'Sustainable cities & communities': sustainablecities,
    'Responsible consumption & production': consumptionandproduction,
    'Climate action': climateaction,
    'Life below water': lifebelowwater,
    'Life on land': lifeonland,
    'Peace, justice & strong institutions': peace,
    'Partnerships for the goals': partnershipgoals,
};

// export const MICROTASK_RESPONSE_TYPE_DATA = [

// {
//      "QUESTION_ANSWER":"Question Answer"
//      'Short Answer',
//      'Long Answer',
//      'Upload Media',
//      'Instructional Youtube',
//      'Instructional Navigateion',
//      'Get Location',
//      'Q-A Multiple Choice',
//      'Q-A Chechboxes',
//      'Rating',
// }

// ];

export const faqData = [
    {
        id: 1,
        question: 'What is Impact Foundry?',
        answer: [
            "Impact Foundry is a platform designed for organisations to create and manage climate-positive initiatives. Through projects and bounties, organisations can engage users in meaningful actions, reward their efforts, and track the collective impact. Impact Foundry also enables organisations to provide verifiable proof of their contributors' actions through on-chain attestations and Impact Certificates (NFTs). It's a comprehensive tool for driving measurable and transparent environmental and social change.",
        ],
        links: [
            // {
            //     text: 'Step by step walkthrough',
            //     href: 'https://app.tango.us/app/workflow/Creating-a-New-Organization-d173295344464bfa96bedd949d8b3873',
            // },
        ],
    },
    {
        id: 2,
        question: 'What is the purpose of creating an organisation?',
        answer: [
            'The organisation you create represents your group, its goals, and its unique approach to driving positive impact. This profile becomes the central hub for managing your projects, bounties, and team members.',
        ],
        links: [
            {
                text: 'Step by step walkthrough',
                href: 'https://app.tango.us/app/workflow/Creating-a-New-Organization-d173295344464bfa96bedd949d8b3873',
            },
        ],
    },
    {
        id: 3,
        question: 'What are the roles within an organisation?',
        answer: [
            'Admin: As the creator of the organisation, they can edit its profile details, send/accept invitations to join the organisation, and edit the roles of or remove members. Admin can also perform the roles of Bounty Manager and  Validator. Bounty Manager: Create and oversee bounties. Bounty Validator: Evaluate completed bounties.',
        ],
        links: [
            {
                text: 'Step by step walkthrough',
                href: 'https://app.tango.us/app/workflow/Inviting-members-and-setting-up-roles-b08442ecf3174bcdb1a4e305c087acfd',
            },
        ],
    },
    {
        id: 4,
        question: 'What are projects?',
        answer: [
            'Projects are a group of bounties under an organisation with a specific objective. It is not required for a bounty to be under a project. Projects help users find bounties with the same goals. For example, you could have a project that focuses on water scarcity and another project that focuses on water pollution.',
        ],
        links: [
            {
                text: 'Step by step walkthrough',
                href: 'https://app.tango.us/app/workflow/Creating-a-New-Project-99790b7db3104de3b65718c7c1454ecf',
            },
        ],
    },
    {
        id: 5,
        question: 'What are bounties?',
        answer: [
            "Bounties are climate-positive tasks or challenges available on the Impact Miner App for users to explore, participate in, and earn rewards. Bounties can range from providing proof of composting setup at home to building smart contracts that can help fight climate change. The bounty type hints users on the Impact Miner as to what the bounty is about. Users choose bounties based on their strengths, or as we say 'superpowers'. There are currently two types of bounties available: Physical and Digital Bounties.",
        ],
        links: [
            {
                text: 'Step by step walkthrough',
                href: 'https://app.tango.us/app/workflow/Creating-a-Bounty-66f7b7731281479f82a73681136d010c',
            },
        ],
    },
    {
        id: 6,
        question: 'What are physical bounties?',
        answer: [
            'Physical bounties require the user to be at a specific location to accept and complete the bounty. These bounties can be viewed on the Map section, and the user needs to be within a certain range to accept the bounty. The bounty creator decides the range based on the nature of the bounty. For example: Bounty to map groundwater wells in a specific region.',
        ],
        links: [],
    },
    {
        id: 7,
        question: 'What are digital bounties?',
        answer: [
            'Digital bounties are those that a user can take up from anywhere in the world. For example: Providing ideas for a community outreach programme.',
        ],
        links: [],
    },
    // {
    //     id: 8,
    //     question: 'What is a proposal and why is it required?',
    //     answer: [
    //         'A proposal is a formal request created within an organisation to initiate or validate a bounty. Proposals are essential for maintaining accountability and transparency as a majority of members must vote ‘Yes’ for the proposal to go through. For creating bounties: A proposal ensures that all members are aligned on the task, its purpose, and the allocated budget. For validating bounties: Proposals allow Treasurers to review and approve the validation process before rewards are distributed. Proposals can only be voted on by the Treasurer, ensuring that financial and operational decisions are thoroughly vetted.',
    //     ],
    //     links: [
    //         {
    //             text: 'Step by step walkthrough',
    //             href: 'https://app.tango.us/app/workflow/Requests-and-Proposals-1be088efece2403cade4d716c45e58da',
    //         },
    //     ],
    // },
    {
        id: 9,
        question: 'How do I validate completed bounties?',
        answer: [
            'Validation determines the score of each submission and in turn the winners. A Bounty Validator reviews the submission against the criteria set during bounty creation. They provide feedback, grade the submission, and mark if an Impact Certificate should be issued if it meets the standards.',
        ],
        links: [
            {
                text: 'Step by step walkthrough',
                href: 'https://app.tango.us/app/workflow/Validating-a-bounty-be53cdde5e4f49d2afc806d374cda4f0',
            },
        ],
    },
    // {
    //     id: 10,
    //     question: 'What is attestation?',
    //     answer: [
    //         'Attesters are those who verify the authenticity of the bounty validation to ensure rewards are given to the rightful winners. This is done using Ethereum Attestation Services (EAS).',
    //     ],
    //     links: [],
    // },
    {
        id: 11,
        question: 'How do I reward completed bounties?',
        answer: [
            'Users can earn in up to three types of rewards: Impact Points: XPs earned based on applied skills (superpowers) and the accuracy of the bounty submission. These are used to rank citizens on the Global Impact Index. Crypto Tokens: Popular EVM and Solana-compatible tokens. Impact Certificates: Immutable proof of impact minted as NFTs, providing verifiable proof of contributions.',
        ],
        links: [],
    },
    {
        id: 12,
        question: 'How do Impact Certificates (NFTs) work for my organisation?',
        answer: [
            'Impact Certificates are blockchain-based NFTs that serve as verifiable proof of contributions and achievements. To create a Project Impact Certificate, go to the project’s landing page and click the ‘Generate’ button to create a certificate. The certificate showcases the title, project brief, initiator, backers, start date, estimated end date, total funds deployed, and the Impact Cores and SDGs supported.',
        ],
        links: [],
    },
    {
        id: 13,
        question: 'What are Impact Cores?',
        answer: [
            'Impact Cores is a framework Atlantis has developed to measure impact. There are 4 types of Impact Cores: Water, Earth, Energy, and Social. For example: The dominant Impact Core to map groundwater wells in a specific region would be Water.',
        ],
        links: [],
    },
];
