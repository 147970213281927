const ToNormalBounty = () => {
    return (
        <svg width="21" height="9" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.60094 3.6C5.45094 2 7.85094 1 10.5009 1C15.1509 1 19.0809 4.03 20.4609 8.22L18.1009 9C17.5745 7.39983 16.5564 6.00666 15.1917 5.01907C13.8271 4.03147 12.1855 3.49986 10.5009 3.5C8.55094 3.5 6.77094 4.22 5.38094 5.38L9.00094 9H0.000938416V0L3.60094 3.6Z"
                fill="#5E5E5E"
            />
        </svg>
    );
};

export default ToNormalBounty;
