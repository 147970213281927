import { useEffect, useState } from 'react';
import WhiteCard from '../../ui/WhiteCard';

import { microtaskTypeData } from './microtasktypes/microtaskTypeData';
import { formatIntDateAndThenTime } from '../../helpers/formatInDateAndThenTime';
import { convertMilliseconds } from '../../helpers/convertMillisecondsToDaysHoursAndMinutes';
import { getICImageOnBountyCreationScreen, getTokenInfo, getTokensForBudget } from '../../apis/general/general';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import { toNormalRepresentation } from '../../helpers/convertIntoDecimals';

const MicroTaskTab = ({ microtasksDetails }) => {
    const renderData = microtasksDetails.map((microtask) => microtask.actions);

    const microtasksData = renderData.flatMap((microtaskArray) => {
        return microtaskArray.map((microtask) => microtask);
    });

    return (
        <div className="grid grid-cols-2   gap-4">
            {microtasksData.map((microtask, index) => {
                const foundAction = microtask.action.find((a) => a.name.toLowerCase() === 'title');

                return (
                    <div key={foundAction._id} className="py-4 px-6 border border-borderColor rounded-lg">
                        {microtaskTypeData
                            .find((obj) => obj.type === microtask.actionType)
                            ?.getMicroTaskSubDetailsComponent({
                                action: microtask.action,
                                label: microtask.actionType,
                            })}
                    </div>
                );
            })}
        </div>
    );
};

const RewardTab = ({ rewardsTabDetails }) => {
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [icImage, setIcImage] = useState({});
    const [token, setToken] = useState({});
    const [tokenImage, setTokenImage] = useState('');

    useEffect(
        function () {
            const payload = {
                coresArray: [...new Set(rewardsTabDetails.cores)],
                briefMessage: rewardsTabDetails.briefMessage,
                sdgsArray: [...new Set(rewardsTabDetails.sdgs)],
                tokenSymbol: rewardsTabDetails?.rewardToken?.tokenInfoId,
            };

            const fetchTokenData = async () => {
                try {
                    const tokens = await getTokensForBudget();

                    const tokenNeeded = tokens.find((token) => token._id === payload?.tokenSymbol);

                    setToken(tokenNeeded);
                } catch (error) {
                    console.log(error);
                }
            };
            const fetchData = async () => {
                try {
                    startLoading();
                    const tokens = await getTokensForBudget();

                    const tokenNeeded = tokens.find((token) => token._id === payload?.tokenSymbol);

                    const data = await getICImageOnBountyCreationScreen({
                        ...payload,
                        tokenSymbol: tokenNeeded?.symbol,
                        amount: Math.max(...rewardsTabDetails?.rewardToken?.distribution),
                    });
                    // setToken(tokenNeeded);
                    setIcImage(data);
                } catch (error) {
                    console.log(error);
                }
                stopLoading();
            };

            if (
                payload.coresArray.length > 0 &&
                payload.briefMessage &&
                payload.tokenSymbol &&
                payload.sdgsArray.length > 0 &&
                rewardsTabDetails.hasImpactCertificate
            ) {
                fetchData();
            } else {
                setIcImage({});
            }
            fetchTokenData();
        },
        [rewardsTabDetails]
    );

    return (
        <div className="grid grid-cols-2 gap-4 items-start  ">
            {rewardsTabDetails?.withReward && (
                <div className="py-4 px-6 border border-borderColor rounded-lg">
                    <p className="font-semibold capitalize text-lightBlack">Reward Tokens</p>
                    <p className="flex items-center gap-1 ">
                        {/* <span>icon</span> */}
                        {token?.image && <img className="w-4 h-4 object-contain " src={token?.image} alt="token" />}
                        <span className="text-secondaryInput">{token?.symbol}</span>
                        {rewardsTabDetails?.rewardToken?.totalRewardAmount && (
                            <span className="text-primaryInput">
                                {toNormalRepresentation(rewardsTabDetails?.rewardToken?.totalRewardAmount)}
                            </span>
                        )}
                    </p>
                </div>
            )}
            <div className="py-4 px-6 border border-borderColor rounded-lg">
                <p className="font-semibold capitalize text-lightBlack">Maximum Impact Points allocated</p>
                <p className="flex gap-1 ">
                    <span className="text-primaryInput">{rewardsTabDetails.impactPoints || '-'}</span>
                </p>
            </div>
            {rewardsTabDetails?.withReward && (
                <div className="py-4 px-6 border border-borderColor rounded-lg">
                    <p className="font-semibold capitalize text-lightBlack">Number of winners</p>
                    <p className="flex gap-1 ">
                        <span className="text-primaryInput">{rewardsTabDetails.rewardToken?.distribution.length}</span>
                    </p>
                </div>
            )}
            <div className="py-4 px-6 border border-borderColor rounded-lg">
                <p className="font-semibold capitalize text-lightBlack">Pass Threshold</p>
                <p className="flex gap-1 ">
                    <span className="text-primaryInput">{rewardsTabDetails.passThreshold * 100}%</span>
                </p>
            </div>

            {rewardsTabDetails?.withReward && (
                <div className="py-4 px-6 border border-borderColor rounded-lg">
                    <p className="font-semibold capitalize text-lightBlack">Each winner will be awarded</p>
                    <div>
                        {rewardsTabDetails?.rewardToken?.distribution.map((amount, index) => {
                            return (
                                <p key={amount + index + Math.random()} className="flex gap-1 items-center ">
                                    <span className="text-primaryInput font-medium">Rank {index + 1} :</span>
                                    {token?.image && (
                                        <img className="w-4 h-4 object-contain " src={token?.image} alt="token" />
                                    )}
                                    <span className="text-secondaryInput">{token?.symbol}</span>
                                    <span className="text-primaryInput">{amount || '-'}</span>
                                </p>
                            );
                        })}
                    </div>
                </div>
            )}

            <div className="py-4 px-6 border border-borderColor rounded-lg">
                <p className="font-semibold capitalize text-lightBlack">Attestation</p>
                <p className="flex gap-1 ">
                    <span className="text-primaryInput"> {rewardsTabDetails.isAttestable ? 'Yes' : 'No'} </span>
                </p>
            </div>

            {rewardsTabDetails.hasImpactCertificate && (
                <div className="py-4 px-6 border border-borderColor rounded-lg">
                    <p className="font-semibold capitalize text-lightBlack">Impact Certificate being issued</p>

                    {loading ? (
                        <Loader />
                    ) : Object.keys(icImage).length > 0 ? (
                        <img className="w-80 rounded-md mt-3" src={icImage?.sampleImage} alt="" />
                    ) : (
                        <p>Sorry ! not able to fetch IC</p>
                    )}
                </div>
            )}
            {rewardsTabDetails?.totalRewardedAmount && (
                <div className="py-4 px-6 border border-borderColor rounded-lg">
                    <p className="font-semibold capitalize text-lightBlack">Total amount disbursed after validation</p>
                    <p className="flex gap-1 ">
                        <p className="flex gap-1 items-center ">
                            {token?.image && <img className="w-4 h-4 object-contain " src={token?.image} alt="token" />}
                            <span className="text-secondaryInput">{token?.symbol}</span>

                            <span className="text-primaryInput">{rewardsTabDetails?.totalRewardedAmount}</span>
                        </p>
                    </p>
                </div>
            )}
        </div>
    );
};

const SponsorTab = ({ sponsorTabDetails }) => {
    return (
        <div className="grid grid-cols-2 gap-4">
            <div className="py-4 px-6 border border-borderColor rounded-lg">
                <p className="font-semibold capitalize text-lightBlack">Sponsor</p>
                <p className="flex items-center gap-1 ">
                    {/* <span>icon</span> */}
                    {sponsorTabDetails?.image ? (
                        <img src={sponsorTabDetails.image} className="w-6 h-6 rounded-full" alt="backer-image" />
                    ) : (
                        '-'
                    )}

                    <span>{sponsorTabDetails?.name || '-'}</span>
                </p>
            </div>
            <div className="py-4 px-6 border border-borderColor rounded-lg">
                <p className="font-semibold capitalize text-lightBlack">Initiator</p>
                <p className="flex items-center gap-1 ">
                    <span>{'-'}</span>
                </p>
            </div>
            <div className="py-4 px-6 border border-borderColor rounded-lg">
                <p className="font-semibold capitalize text-lightBlack">Sponsor Message</p>
                <p className="flex items-center gap-1 ">
                    <span>{sponsorTabDetails?.message || '-'}</span>
                </p>
            </div>
        </div>
    );
};

const DateTimeTab = ({ dateandtimedetails }) => {
    console.log(dateandtimedetails, 'see the details here');
    return (
        <div className="grid grid-cols-2 gap-4">
            <div className="py-4 px-6 border border-borderColor rounded-lg">
                <p className="font-semibold capitalize text-lightBlack">Bounty live till</p>
                <p className="flex items-center gap-1 ">
                    <span>{formatIntDateAndThenTime(dateandtimedetails.expirationTime)}</span>
                </p>
            </div>
            <div className="py-4 px-6 border border-borderColor rounded-lg">
                <p className="font-semibold capitalize text-lightBlack">Estimated time to complete bounty</p>
                <p className="flex items-center gap-1 ">
                    <span>{convertMilliseconds(dateandtimedetails.estimatedTime)}</span>
                </p>
            </div>
            {!dateandtimedetails?.withReward ? (
                <div className="py-4 px-6 border border-borderColor rounded-lg">
                    <p className="font-semibold capitalize text-lightBlack">Deadline for validating each submission</p>
                    <p className="flex items-center gap-1 ">
                        <span>{convertMilliseconds(dateandtimedetails?.maxValidationDuration)}</span>
                    </p>
                </div>
            ) : (
                <div className="py-4 px-6 border border-borderColor rounded-lg">
                    <p className="font-semibold capitalize text-lightBlack">Deadline for validating all submissions</p>
                    <p className="flex items-center gap-1 ">
                        <span>{formatIntDateAndThenTime(dateandtimedetails.validationDeadline)}</span>
                    </p>
                </div>
            )}
        </div>
    );
};

const CriteriaTab = ({ criteriaTabDetails }) => {
    return (
        <div className="grid grid-cols-2 gap-4">
            {criteriaTabDetails.map((criteria, index) => {
                return (
                    <div
                        key={criteria + index + Math.random()}
                        className="py-4 px-6 border border-borderColor rounded-lg"
                    >
                        <p className="font-semibold capitalize text-lightBlack">criteria {index + 1}</p>
                        <p className="flex items-center gap-1 ">
                            <span>{criteria}</span>
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

const IndividualBountySubDetails = ({
    microtasksDetails,
    rewardsTabDetails,
    sponsorTabDetails,
    dateandtimedetails,
    criteriaTabDetails,
}) => {
    const [activeTab, setActiveTab] = useState('microtasks');

    return (
        <WhiteCard className="py-9 px-8">
            <div className="flex gap-6 items-center mb-8">
                <p
                    className={`cursor-pointer ${
                        activeTab === 'microtasks' ? 'text-primaryColor font-bold' : 'font-medium text-secondaryInput '
                    } `}
                    onClick={() => setActiveTab('microtasks')}
                >
                    Microtasks
                </p>
                <p
                    className={`cursor-pointer ${
                        activeTab === 'Reward' ? 'text-primaryColor font-bold' : 'font-medium text-secondaryInput'
                    }`}
                    onClick={() => setActiveTab('Reward')}
                >
                    Reward
                </p>
                <p
                    className={`cursor-pointer ${
                        activeTab === 'Sponsor' ? 'text-primaryColor font-bold' : 'font-medium text-secondaryInput'
                    }`}
                    onClick={() => setActiveTab('Sponsor')}
                >
                    Sponsor
                </p>
                <p
                    className={`cursor-pointer ${
                        activeTab === 'Date & Time' ? 'text-primaryColor font-bold' : 'font-medium text-secondaryInput'
                    }`}
                    onClick={() => setActiveTab('Date & Time')}
                >
                    Date & Time
                </p>
                <p
                    className={`cursor-pointer ${
                        activeTab === 'Criteria' ? 'text-primaryColor font-bold' : 'font-medium text-secondaryInput'
                    }`}
                    onClick={() => setActiveTab('Criteria')}
                >
                    Criteria
                </p>
            </div>
            {activeTab === 'microtasks' && <MicroTaskTab microtasksDetails={microtasksDetails} />}
            {activeTab === 'Reward' && <RewardTab rewardsTabDetails={rewardsTabDetails} />}
            {activeTab === 'Sponsor' && <SponsorTab sponsorTabDetails={sponsorTabDetails} />}
            {activeTab === 'Date & Time' && <DateTimeTab dateandtimedetails={dateandtimedetails} />}
            {activeTab === 'Criteria' && <CriteriaTab criteriaTabDetails={criteriaTabDetails} />}
        </WhiteCard>
    );
};

export default IndividualBountySubDetails;
