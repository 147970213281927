import { useContext, useState } from 'react';
import Button from '../../ui/Button';
import Dropdown from '../../ui/Dropdown';
import Heading from '../../ui/Heading';
import Input from '../../ui/Input';
import { BountiesContext } from '../../contexts/BountiesContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useBountyFields } from '../../hooks/useBountyFields';
import { parseISO, format } from 'date-fns';
import { useStepFourInputs } from './bountiesHooks/useStepFourInputs';
import CustomDateInput from '../../ui/CustomDateInput';
import TextWithTooltip from '../../ui/TextWithTooltip';
import FloatingButtons from '../../ui/FloatingButtons';
import RadioButtonComponent from '../../ui/RadioButtonComponent';
import RadioButtonComponentWithDifferentLabel from '../../ui/RadioButtonComponentWithDifferentLabel';

function StepFour({ setStep, register, control, errors, getValues }) {
    const [date, setDate] = useState(new Date());
    // const { expirationTimeField, estimatedCompletionTimeField, validationTillDateField } = useBountyFields();
    const { expirationTimeField, dayField, hoursField, minutesField } = useStepFourInputs(control);

    const hasError = errors?.day?.message || errors?.hours?.message || errors?.minutes?.message ? true : false;

    return (
        <div className="relative">
            <div className="p-12 pb-0 flex flex-col justify-between min-h-full h-  rounded-md ">
                <div>
                    <div className="mb-6">
                        <div className="flex gap-3">
                            <TextWithTooltip
                                heading={
                                    <Heading type="xsmall" className="capitalize  text-primaryInput">
                                        Once published, how long should the bounty be live?{' '}
                                        <sup className="text-lg relative top-[1px]">*</sup>
                                    </Heading>
                                }
                            >
                                <p className="text-sm">
                                    This is the bounty expiration time after which it will disappear from Impact Miner
                                    and users will not be able to participate
                                </p>
                            </TextWithTooltip>
                        </div>

                        <div>
                            <DatePicker
                                showTimeSelect
                                minDate={new Date()}
                                className="border border-borderColor w-[230px] shadow-sm text-sm  bg-white placeholder:text-secondaryInput font-semibold rounded-md px-4 py-2"
                                // minTime={new Date()}
                                // maxTime={new Date(0, 0, 0, 19, 0)}
                                placeholderText="Set Date & Time"
                                selected={expirationTimeField.value}
                                onChange={(date) => expirationTimeField.onChange(date)}
                                dateFormat="MMMM d, yyyy h:mmaa"
                                timeIntervals={1}

                                // customInput={<CustomDateInput placeholder="Set Date & Time" className="bg-white" />}
                            />
                            {errors.expirationTime && (
                                <p className="text-foundryRed">{errors.expirationTime.message}</p>
                            )}
                        </div>
                    </div>
                    <div className="my-6">
                        <div className="flex gap-3">
                            <TextWithTooltip
                                heading={
                                    <Heading type="xsmall" className="capitalize  text-primaryInput">
                                        Estimated time to complete bounty{' '}
                                        <sup className="text-lg relative top-[1px]">*</sup>
                                    </Heading>
                                }
                            >
                                <p className="text-sm">
                                    An estimation of time will help users on Impact Miner understand how long it will
                                    take to complete the bounty
                                </p>
                            </TextWithTooltip>
                        </div>
                        <div className="flex w-[50%] mt-1 gap-1">
                            <div>
                                <div>
                                    <Input
                                        {...dayField}
                                        className="w-full text-sm font-semibold"
                                        type="number"
                                        placeholder={0}
                                    />
                                    <p className="">day</p>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <Input
                                        {...hoursField}
                                        className="w-full text-sm font-semibold"
                                        type="number"
                                        placeholder={0}
                                    />
                                    <p>hours</p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Input
                                        {...minutesField}
                                        className="w-full text-sm font-semibold"
                                        type="number"
                                        placeholder={0}
                                    />
                                    <p>minutes</p>
                                </div>
                            </div>
                        </div>
                        {hasError && <p className="text-foundryRed">{'Please enter the estimated time'}</p>}
                    </div>
                </div>
            </div>
            <FloatingButtons>
                <Button type="button" onClick={() => setStep(3)} className="bg-white" variant="tertiary" size="small">
                    Back
                </Button>
                <Button type="button" onClick={() => setStep(5)} size="small">
                    Next
                </Button>
            </FloatingButtons>
        </div>
    );
}

export default StepFour;
