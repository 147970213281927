import React, { useContext, useEffect } from 'react';
import Input from '../../../ui/Input';
import IsSkippable from './IsSkippable';
import { useController, useFieldArray } from 'react-hook-form';
import InputForDragAndDrop from '../../../ui/InputForDragAndDrop';

import Button from '../../../ui/Button';
import DeleteIcon from '../../../assets/icons/Delete';
import { BountiesContext } from '../../../contexts/BountiesContext';

const WatchYoutubeVideo = ({ register, index, errors, control, microtaskEditDetail }) => {
    const { isEdit } = useContext(BountiesContext);
    // const { field: skippableField } = useController({
    //     name: `microtasks.${index}.skippable`,
    //     control,
    // });
    const {
        fields: youtubeVideos_Fields,
        append: appendLink,
        remove: removeLink,
    } = useFieldArray({
        name: `microtasks[${index}].youtubeVideos_Link`,
        control,
    });

    useEffect(() => {
        // Run this logic only once when the component is mounted
        if (isEdit && youtubeVideos_Fields.length === 0) {
            // If in edit mode, populate the answers from microtaskEditDetail

            microtaskEditDetail?.youtubeVideoLink.forEach((l) => {
                appendLink({ link: l?.link, description: l?.description });
            });
        } else if (!isEdit && youtubeVideos_Fields.length === 0) {
            // If not in edit mode, ensure there is at least one empty answer field
            appendLink({ link: '', description: '' });
        }
    }, [isEdit, youtubeVideos_Fields.length, appendLink, microtaskEditDetail]);

    return (
        <div>
            <Input
                {...register(`microtasks.${index}.youtubeVideos_Title`, {
                    required: 'Please enter a title',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.youtubeVideoLink[0]?.title || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.youtubeVideos_Title?.message}
                placeholder={'Title *'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 '}
            />
            {/* <Input
                {...register(`microtasks.${index}.youtubeVideos_Description`, {
                    required: 'Please enter description for watching youtube video.',
                })}
                type="transparent"
                error={errors?.microtasks && errors?.microtasks[index]?.youtubeVideos_Description?.message}
                placeholder={'Description for watching youtube video...'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 mt-2 '}
            /> */}
            {/* <Input
                {...register(`microtasks.${index}.youtubeVideos_Link`, {
                    required: 'Please enter link for watching youtube video.',
                    pattern: {
                        value: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm,
                        message: 'Please enter valid link of youtube video', // JS only: <p>error message</p> TS only support string
                    },
                })}
                type="transparent"
                error={errors?.microtasks && errors?.microtasks[index]?.youtubeVideos_Link?.message}
                placeholder={'Link for watching youtube video...'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 mt-2 '}
            /> */}
            <div className="mt-2  items-center">
                {youtubeVideos_Fields?.map((youtubeVideoField, i) => {
                    return (
                        <>
                            <div className="mb-2">
                                <div key={youtubeVideoField.id} className="flex flex-col items-center gap-1  w-full">
                                    <div className="flex items-center w-full gap-1">
                                        <div className="flex-1">
                                            <InputForDragAndDrop
                                                name={`microtasks[${index}].youtubeVideos_Link[${i}].link`}
                                                control={control}
                                                index={i}
                                                width="w-full"
                                                placeholder="Enter a youtube video link"
                                                validateObject={{
                                                    required: 'Please enter a youtube video link ',
                                                    pattern: {
                                                        value: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm,
                                                        message: 'Please enter valid link of youtube video', // JS only: <p>error message</p> TS only support string
                                                    },
                                                }}
                                            />
                                        </div>
                                        {
                                            <div>
                                                <DeleteIcon
                                                    className={`${i > 0 ? 'cursor-pointer' : 'invisible'}`}
                                                    onClick={() => removeLink(i)}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <Input
                                        {...register(`microtasks.${index}.youtubeVideos_Link[${i}].description`)}
                                        type="transparent"
                                        placeholder={'Task description (Optional)'}
                                        transparentTextSize="text-sm"
                                        transparentFontWeight={'font-normal'}
                                        className={'w-[100%] mb-1 mt-1 '}
                                    />
                                </div>

                                {errors?.microtasks?.[index]?.youtubeVideos_Link?.[i]?.link && (
                                    <p className="text-foundryRed">
                                        {errors.microtasks[index].youtubeVideos_Link[i].link.message}
                                    </p>
                                )}
                            </div>
                        </>
                    );
                })}

                <Button
                    type="button"
                    onClick={() => {
                        appendLink({
                            link: '',
                        });
                    }}
                    size="none"
                    className="  font-semibold !text-lightBlack text-sm"
                    variant="transparent"
                >
                    + Add Link
                </Button>
                <div>
                    {errors?.microtasks && (
                        <p className="text-foundryRed">
                            {errors?.microtasks[index]?.youtubeVideos_Link?.root?.message}
                        </p>
                    )}
                </div>
            </div>
            {/* <div className="flex gap-9 my-2 items-center ">
                <IsSkippable
                    selectedOption={skippableField.value}
                    onSelectOption={(value) => {
                        skippableField.onChange(value);
                    }}
                    options={['Yes', 'No']}
                />
            </div> */}
        </div>
    );
};

export default WatchYoutubeVideo;
