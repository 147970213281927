import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Homepage from '../screens/Homepage';

import BasicLayout from '../basicLayout';
import ProtectedRoute from './ProtectedRoute';
import CreateProfile from '../screens/CreateProfile';
import LoginPage from '../screens/LoginPage';
import Organizations from '../screens/Organizations';
import { Toaster } from 'sonner';
import CreateOrganization from '../screens/CreateOrganization';
import UserProfile from '../screens/UserProfile';
import User from '../screens/UserPage';
import Leaderboard from '../screens/Leaderboard';
import Gallery from '../screens/Gallery';
import ConnectFirst from '../screens/ConnectFirst';
import EditProfile from '../screens/EditProfile';
import ParticularOrganization from '../screens/ParticularOrganization';
import AllOrganizations from '../features/Organization/AllOrganizations';
import AddBounty from '../screens/AddBounty';
import AddMember from '../screens/AddMember';
import AddProject from '../screens/AddProject';
import ViewMembersList from '../screens/ViewMembersList';
import ViewBountiesList from '../screens/ViewBountiesList';
import ViewProjectsList from '../screens/ViewProjectsList';
import OrganizationHomePage from '../screens/OrganizationHomePage';
import ProjectsList from '../features/projects/ProjectsList';
import ParticularProject from '../features/projects/ParticularProject';
import MembersList from '../features/members/MembersList';
import EditMembers from '../screens/EditMembers';
import MyOrganizations from '../screens/MyOrganizations';
import MyOrganizationsList from '../features/Organization/MyOrganizationsList';
import MyParticularApplication from '../features/Organization/MyParticularApplication';
import Validation from '../screens/Validation';
import ValidationOverview from '../features/validations/ValidationOverview';
import ValidateBountySubmissions from '../features/validations/ValidateBountySubmissions';
import BountySubmission from '../features/validations/BountySubmission';
import BountySubmissions from '../features/validations/BountySubmissions';
import TreasurerRequestsAndProposals from '../screens/TreasurerRequestsAndProposals';
import InvitationsAndRequestsToJoinOrganization from '../features/members/InvitationsAndRequestsToJoinOrganization';
import RequestValidation from '../features/validations/RequestValidation';
import IndividualBounty from '../features/bounties/IndividualBounty';
import ValidationRequestConfirmationScreen from '../features/treasurer/requests/ValidationRequestConfirmationScreen';
import AttestInorganisation from '../screens/AttestInOrganisation';
import { ElementHiderProvider } from '../contexts/ElementHider';
import AttestationRequestsInsideOrganisation from '../features/attestationRequestor/AttestationRequestsInsideOrganisation';
import Attestors from '../screens/Attestors';
import AttestationOverview from '../features/attestors/AttestationOverview';
import AttesterProfileCreation from '../features/attestors/AttesterProfileCreation';
import AttesterInput from '../features/attestors/AttesterInput';
import MyOrganisationsNew from '../screens/MyOrganisationsNew';
import ErrorPage from '../ui/ErrorPage';
import OrganisationPublicView from '../screens/OrganisationPublicView';
import EditOrganisation from '../screens/EditOrganisation';
import ToastIcon from '../assets/icons/ToastIcon';
import { ProfileProvider } from '../contexts/ProfileContext';
import MobileUI from '../screens/MobileUI';
import { useEffect, useState } from 'react';
import FAQScreen from '../screens/FAQScreen';
import Faqs from '../features/faqs/Faqs';
import ViewEventsList from '../screens/ViewEventsList';
import EventsList from '../features/events/EventsList';
import ParticularEvent from '../features/events/ParticularEvent';
import CreateEvent from '../screens/CreateEvent';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <ProtectedRoute>
                <BasicLayout />
            </ProtectedRoute>
        ),
        children: [
            {
                path: '',
                element: <Homepage />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'myorganizations',
                element: <MyOrganisationsNew />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'myapplications',
                element: <MyOrganizations />,
                children: [
                    {
                        index: true,
                        element: <MyOrganizationsList />,
                    },
                    {
                        path: ':id',
                        element: <MyParticularApplication />,
                    },
                ],
                errorElement: <ErrorPage />,
            },
            {
                path: 'organizations',
                element: (
                    <ElementHiderProvider>
                        <Organizations />
                    </ElementHiderProvider>
                ),
                children: [
                    {
                        index: true,
                        element: <AllOrganizations />,
                    },
                    {
                        path: ':id',
                        element: <OrganizationHomePage />,
                        children: [
                            {
                                index: true,
                                element: <ParticularOrganization />,
                            },
                            {
                                path: 'view-proposalsandrequests',

                                element: <TreasurerRequestsAndProposals screen="requests" />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'public-view',

                                element: <OrganisationPublicView />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'edit-org',
                                element: <EditOrganisation />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'view-proposalsandrequests/request',
                                element: <ValidationRequestConfirmationScreen />,
                                errorElement: <ErrorPage />,
                            },
                            // work here now
                            {
                                path: 'validation',
                                element: <Validation />,
                                children: [
                                    {
                                        index: true,
                                        element: <ValidationOverview />,
                                    },
                                    {
                                        path: `bounty-submissions/:bountyId`,
                                        element: <BountySubmissions />,
                                        children: [
                                            {
                                                index: true,
                                                element: <ValidateBountySubmissions />,
                                            },
                                            {
                                                path: ':submissionId',
                                                element: <BountySubmission />,
                                            },
                                            {
                                                path: 'request',
                                                element: <RequestValidation />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'attest',
                                element: <AttestInorganisation />,
                                children: [
                                    {
                                        index: true,
                                        element: <AttestationRequestsInsideOrganisation />,
                                    },
                                    {
                                        path: 'attestations',
                                        element: 'Inside attest home',
                                    },
                                ],
                            },
                            {
                                path: 'add-member',
                                element: <AddMember />,
                            },
                            {
                                path: 'edit-member',
                                element: <EditMembers />,
                            },
                            {
                                path: 'add-bounty',
                                element: <AddBounty />,
                            },
                            {
                                path: 'add-project',
                                element: <AddProject />,
                            },
                            {
                                path: 'view-members',
                                element: <ViewMembersList />,
                                children: [
                                    {
                                        index: true,
                                        element: <MembersList />,
                                    },
                                    {
                                        path: 'invitations',
                                        element: <InvitationsAndRequestsToJoinOrganization screen={'invitations'} />,
                                    },
                                ],
                            },
                            {
                                path: 'view-bounties',
                                element: <ViewBountiesList />,
                            },
                            {
                                path: 'view-bounties/:bountyId',
                                element: <IndividualBounty />,
                            },
                            {
                                path: 'view-projects',
                                element: <ViewProjectsList />,
                                children: [
                                    {
                                        index: true,
                                        element: <ProjectsList />,
                                    },
                                    { path: ':projectId', element: <ParticularProject /> },
                                ],
                            },
                            {
                                path: 'view-events',
                                element: <ViewEventsList />,
                                children: [
                                    {
                                        index: true,
                                        element: <EventsList />,
                                    },
                                    { path: ':eventId', element: <ParticularEvent /> },
                                ],
                            },
                        ],
                        errorElement: <ErrorPage />,
                    },
                ],
                errorElement: <ErrorPage />,
            },
            {
                path: 'attestation',
                element: <Attestors />,
                children: [
                    {
                        index: true,
                        element: <AttestationOverview />,
                    },
                    {
                        path: 'createAttester',
                        element: <AttesterProfileCreation />,
                    },
                ],
                errorElement: <ErrorPage />,
            },
            {
                path: 'faqs',
                element: <FAQScreen />,
                children: [
                    {
                        index: true,
                        element: <Faqs />,
                    },
                ],
                errorElement: <ErrorPage />,
            },
            {
                path: 'profile',
                element: (
                    <ProfileProvider>
                        <User />
                    </ProfileProvider>
                ),
                children: [
                    {
                        index: true,
                        element: <UserProfile />,
                    },
                    { path: 'leaderboard', element: <Leaderboard /> },
                    { path: 'gallery', element: <Gallery /> },
                    { path: 'editprofile', element: <EditProfile /> },
                ],
                errorElement: <ErrorPage />,
            },
        ],
    },

    {
        path: '/login',
        element: <LoginPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/mobile',
        element: <MobileUI />,
        errorElement: <ErrorPage />,
    },

    {
        path: '/createprofile',
        element: (
            <ProtectedRoute>
                <CreateProfile />
            </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/attestation/createAttester/details',
        element: <AttesterInput />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/connectfirst',
        element: (
            <ProtectedRoute>
                <ConnectFirst />
            </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/createorganization',
        element: (
            <ProtectedRoute>
                <CreateOrganization />
            </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: ':id/createevent',
        element: (
            <ProtectedRoute>
                <CreateEvent />
            </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: ':id/createBounty',
        element: (
            <ProtectedRoute>
                <AddBounty />
            </ProtectedRoute>
        ),
        errorElement: <p></p>,
    },
]);

function RouteNav() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust threshold for screen size
        };

        handleResize(); // Set initial state
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isMobile) {
        // Render only the mobile message if on a smaller screen
        return <MobileUI />;
    }
    return (
        <>
            <Toaster
                toastOptions={{
                    className:
                        'p-3 bg-[#fff] text-primaryInput border border-[#A1D2FF] shadow-tooltipShadow font-medium text-xs',
                }}
                position="bottom-center"
                icons={{
                    success: <ToastIcon />,
                    info: <ToastIcon />,
                    warning: <ToastIcon />,
                    error: <ToastIcon />,
                    loading: <ToastIcon />,
                }}
            />
            <RouterProvider router={router} />
        </>
    );
}

export default RouteNav;
