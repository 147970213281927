import axios from 'axios';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const connectToNotificationStream = async (setNotifications) => {
    const accessToken = await getAccessToken(); // Obtain the Firebase access token

    const eventSourceUrl = `${BASE_URL}/notification/stream`;

    // Append the access token to the URL as a query parameter or header
    const eventSource = new EventSource(`${eventSourceUrl}?token=${accessToken}`);

    // Listen for 'message' events from the SSE stream
    eventSource.onmessage = (event) => {
        const newNotifications = JSON.parse(event.data); // Assuming data comes in JSON format
        setNotifications((prevNotifications) => {
            // Remove duplicates based on notification `id`
            const uniqueNotifications = newNotifications.filter((newNotification) => {
                return !prevNotifications.some((prevNotification) => prevNotification.id === newNotification.id);
            });
            return [...prevNotifications, ...uniqueNotifications];
        });
    };

    // Handle errors
    eventSource.onerror = (error) => {
        eventSource.close(); // Optionally close the connection on error
    };

    return eventSource;
};

export const getNotifications = async () => {
    const url = `${BASE_URL}/notification`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

// PATCH /notification/<id>?isRead=<boolean></boolean>

export const changeReadStatus = async (id, boolean) => {
    try {
        const url = `${BASE_URL}/notification/${id}?isRead=${boolean}`;

        const accessGoogle = await auth.currentUser.getIdToken(true);

        const response = await axios.patch(url, undefined, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteNotification = async (id) => {
    try {
        const url = `${BASE_URL}/notification/${id}`;

        const accessGoogle = await auth.currentUser.getIdToken(true);

        const response = await axios.delete(url, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteAllNotifications = async () => {
    try {
        const url = `${BASE_URL}/notification`;

        const accessGoogle = await auth.currentUser.getIdToken(true);

        const response = await axios.delete(url, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};
