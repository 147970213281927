import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import defaultMarker from '../../assets/defaultMarker.png';

mapboxgl.accessToken =
    'pk.eyJ1IjoiZmFpc2FsZHJvcGNoYWluIiwiYSI6ImNsdXJnOWF2czA3NmYyam4xZWkzbmltaHAifQ.IK0wrkOv-H-C57rLCcMBMA';

function MapWithSearch({
    searchQuery,
    setSearchText,
    circleRadius,
    core,
    setCoordinates,
    hasStartedTyping,
    editMarkPosition,
}) {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const [suggestions, setSuggestions] = useState([]);
    const [markerPosition, setMarkerPosition] = useState(editMarkPosition);
    const [citySelected, setCitySelected] = useState(false);
    const [zoom, setZoom] = useState(8);

    // Initialize map on mount
    useEffect(() => {
        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v9',
            center: markerPosition,
            zoom: zoom,
        });

        // Create a draggable marker
        markerRef.current = new mapboxgl.Marker({
            draggable: true,
            element: createCustomMarkerElement(),
        })
            .setLngLat(markerPosition)
            .addTo(mapRef.current);

        // Update marker position on drag end
        markerRef.current.on('dragend', () => {
            const lngLat = markerRef.current.getLngLat();
            setMarkerPosition([lngLat.lng, lngLat.lat]);
            setCoordinates({ coordinates: [lngLat.lat, lngLat.lng] });
        });

        // Clean up on unmount
        return () => mapRef.current.remove();
    }, []);

    // Fetch suggestions based on searchQuery with debouncing
    useEffect(() => {
        // Reset citySelected whenever a new query is typed
        if (searchQuery) setCitySelected(false);

        if (!searchQuery || citySelected) {
            setSuggestions([]);
            return;
        }

        // Debounce API call
        const delayDebounceFn = setTimeout(async () => {
            try {
                const response = await fetch(
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json?access_token=${mapboxgl.accessToken}`
                );
                const data = await response.json();
                setSuggestions(data.features);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        }, 300); // 300ms debounce time

        // Clear the timeout if searchQuery changes before the delay is over
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchQuery]); // Dependency on searchQuery and citySelected

    // When a suggestion is selected, update marker position and center map
    const handleSelectSuggestion = (lng, lat, city) => {
        setMarkerPosition([lng, lat]);
        setCoordinates({ coordinates: [lat, lng] });
        setCitySelected(true);
        setSearchText(city);
        setSuggestions([]); // Clear suggestions after selection
        markerRef.current.setLngLat([lng, lat]);
        mapRef.current.setCenter([lng, lat]);
    };

    // Helper function to create custom marker element
    const createCustomMarkerElement = () => {
        const el = document.createElement('div');
        el.className = 'custom-marker';
        el.style.backgroundImage = `url(${defaultMarker})`;
        el.style.backgroundSize = 'contain';
        el.style.backgroundRepeat = 'no-repeat';
        el.style.width = '20px';
        el.style.height = '20px';
        return el;
    };

    return (
        <div>
            {hasStartedTyping && suggestions.length > 0 && (
                <ul className="suggestions">
                    {suggestions.map((suggestion) => (
                        <li
                            className="cursor-pointer px-2 py-2 hover:bg-gray-50"
                            key={suggestion.id}
                            onClick={() =>
                                handleSelectSuggestion(
                                    suggestion.geometry.coordinates[0],
                                    suggestion.geometry.coordinates[1],
                                    suggestion.place_name
                                )
                            }
                        >
                            {suggestion.place_name}
                        </li>
                    ))}
                </ul>
            )}

            <div>
                <div className="map-container" ref={mapContainerRef} style={{ height: '30vh', width: '100%' }} />
            </div>
        </div>
    );
}

export default MapWithSearch;
