const EventsIcon = ({ fillColor }) => {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3333 14.4H1.66667V5.6H13.3333M10.8333 0V1.6H4.16667V0H2.5V1.6H1.66667C0.75 1.6 0 2.32 0 3.2V14.4C0 14.8243 0.175595 15.2313 0.488155 15.5314C0.800716 15.8314 1.22464 16 1.66667 16H13.3333C14.2583 16 15 15.288 15 14.4V3.2C15 2.77565 14.8244 2.36869 14.5118 2.06863C14.1993 1.76857 13.7754 1.6 13.3333 1.6H12.5V0M6.56667 8.8H3.55833L5.99167 10.488L5.06667 13.248L7.5 11.544L9.93333 13.248L9 10.496L11.4333 8.8H8.43333L7.5 6.048L6.56667 8.8Z"
                fill={fillColor}
            />
        </svg>
    );
};

export default EventsIcon;
