import { toast } from 'sonner';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';
import axios from 'axios';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const updateEvent = async (event, eventId) => {
    try {
        const url = `${BASE_URL}/event/${eventId}`;

        const accessToken = await getAccessToken();

        const response = await axios.put(url, event, getConfig(accessToken));

        if (response.data.status === 'SUBMITTED') toast.success('An event has been successfully created');
        return response.data;
    } catch (error) {
        toast.error('Sorry unable to create an event.');
    }
};
