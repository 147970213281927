import { useEffect, useRef, useState } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import Heading from '../../ui/Heading';
import Input from '../../ui/Input';
import AvatarImage from '../../ui/AvatarImage';
import { searchUsers, searchUsersViaEmail, sendingInvitation, sendingInvitationViaEmail } from '../../apis/user/user';
import Dropdown from '../../ui/Dropdown';
import Checkbox from '../../ui/Checkbox';
import { containsObjectWithKeyAndString } from '../../helpers/isObjectContainsKeyAndString';

import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import Tag from '../../ui/Tag';
import CrossChip from '../../assets/icons/CrossChip';
import { isValidEmail } from '../../helpers/IsEmail';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
const nomembersearched = '/assets/images/nomembersearched.png';
// const data = ['BOUNTY_MANAGER', 'BOUNTY_VALIDATOR', `TREASURER`];
const data = ['BOUNTY_MANAGER', 'BOUNTY_VALIDATOR'];

function InviteMemberModal({ close, orgMembers }) {
    const myRef = useRef({ close });
    const [searchQuery, setSearchQuery] = useState(''); // search text
    const [userSuggestions, setUserSuggestions] = useState([]); // search suggestions
    const [emailExist, setEmailExist] = useState('');
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { id } = useParams();
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [membersToBeInvited, setMembersToBeInvited] = useState([]); // members added in list
    const [emailsToBeInvited, setEmailsToBeInvited] = useState([]); // members added in list

    useEffect(() => {
        // If searchText is empty, clear suggestions
        if (!searchQuery) {
            setUserSuggestions([]);
            return;
        }
        const delayDebounceFn = setTimeout(async () => {
            const response = await searchUsers(searchQuery);

            setUserSuggestions(response);
        }, 300); // 300ms debounce time
        if (isValidEmail(searchQuery)) {
            clearTimeout(delayDebounceFn);
            const fetchUsers = async () => {
                try {
                    const response = await searchUsersViaEmail(searchQuery);

                    setUserSuggestions([response]);
                    setEmailExist('');
                    return;
                } catch (error) {
                    if (error.code === 404) setEmailExist(searchQuery);
                }
            };
            fetchUsers();
        }
        if (!isValidEmail(searchQuery)) {
            setEmailExist('');
        }
        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery]); // Dependency array to trigger the effect when searchText changes
    const addMemberToListForInvitation = async (invitedMember) => {
        const isEmail = isValidEmail(invitedMember);

        if (isEmail) {
            const shouldInviteEmail = !emailsToBeInvited.some((invited) => invited === invitedMember);
            if (shouldInviteEmail) setEmailsToBeInvited((initial) => [...initial, invitedMember]);
            else toast.error('This user is already present in the list !');

            setEmailExist('');
            return;
        }
        const shouldInvite = !membersToBeInvited.some((invited) => invited.uid === invitedMember.uid);
        // earlier there was an array but currently I am getting an object -> This was the problem because of outlet context now getting as a prop

        const isPartOfOrg = Boolean(orgMembers.find((userToBeInvited) => userToBeInvited.uid === invitedMember.uid));

        if (isPartOfOrg) {
            toast.error('Member is already part of the Organization. ');
            return;
        }
        if (shouldInvite) setMembersToBeInvited((initial) => [...initial, invitedMember]);
        else toast.error('Member is already added in the list !');
    };
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );

    function updateOptions(value, item) {
        if (value) {
            const removeRoleArr = selectedRoles.filter((role) => role !== item);
            setSelectedRoles(removeRoleArr);
        } else {
            setSelectedRoles((i) => [...i, item]);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedRoles.length) {
            toast.error('Please assign at least one role. ');
            return;
        }

        const payloadForExistingMembers = {
            organisationId: id,
            invitations: membersToBeInvited.map((member) => ({ uid: member.uid, roles: selectedRoles })),
        };
        const payloadForNonExistingMembers = {
            organisationId: id,
            emails: emailsToBeInvited,
            roles: selectedRoles,
        };

        let promises = [];
        if (membersToBeInvited.length) {
            promises.push(() => sendingInvitation(payloadForExistingMembers));
        }
        if (emailsToBeInvited.length) {
            promises.push(() => sendingInvitationViaEmail(payloadForNonExistingMembers));
        }

        try {
            startLoading();
            const [response1, response2] = await Promise.all(promises.map((promiseFn) => promiseFn()));

            if (response1?.success || response2?.success) {
                toast.success('Invitations successfully sent. ');
                stopLoading();
                close();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[40rem] h-[40.625rem]  p-9    bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div>
                    <Button
                        onClick={close}
                        radius="rounded-full"
                        className=" border-0 absolute top-3  right-4 "
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                </div>

                <div className=" h-[30rem]   relative">
                    <Heading className={'text-black font-semibold mb-2 '} type={'xsmall'}>
                        Invite to your Organization
                    </Heading>
                    <div className=" relative shadow-sm  border-2 border-borderColor rounded-md py-2 px-4 ">
                        <Input
                            type="search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-[60%]"
                            placeholder="Search by  username or email"
                        />
                        <div className="flex items-center mt-1 gap-1 flex-wrap">
                            {membersToBeInvited.map((member, index) => (
                                <Tag
                                    key={member.uid + index + Math.random()}
                                    onClickOnIcon={() =>
                                        setMembersToBeInvited(membersToBeInvited.filter((z) => z.uid !== member.uid))
                                    }
                                    rounded="rounded-lg "
                                    className="px-2 py-1 leading-3 flex items-center gap-1  "
                                    textClassName="text-xs font-semibold"
                                    bg="bg-gray-200"
                                    textColor="text-lightBlack"
                                    text={member.userName}
                                    icon={<CrossChip />}
                                />
                            ))}
                            {emailsToBeInvited.map((member, index) => (
                                <Tag
                                    key={member.uid + index + Math.random()}
                                    onClickOnIcon={() =>
                                        setEmailsToBeInvited(emailsToBeInvited.filter((z) => z !== member))
                                    }
                                    rounded="rounded-lg "
                                    className="px-2 py-1 leading-3 flex items-center gap-1  "
                                    textClassName="text-xs font-semibold"
                                    bg="bg-gray-200"
                                    textColor="text-lightBlack"
                                    text={member}
                                    icon={<CrossChip />}
                                />
                            ))}
                        </div>
                    </div>
                    {emailExist && (
                        <div className="z-[2000] w-full h-[50px] flex justify-between items-center px-4 overflow-auto top-20 absolute  bg-white divide-y divide-gray-100 rounded-lg  shadow-md">
                            <p className="flex-1">{emailExist}</p>
                            <Button
                                variant="transparent"
                                onClick={() => {
                                    addMemberToListForInvitation(emailExist);
                                    setSearchQuery('');
                                }}
                                className=" w-36 text-end  cursor-pointer"
                            >
                                Send Email
                            </Button>
                        </div>
                    )}
                    {userSuggestions.length === 0 && <img className="my-20 mx-auto" src={nomembersearched} alt="" />}
                    {userSuggestions.length > 0 && (
                        <div
                            id="dropdown"
                            className={`z-[2000] w-full h-[380px] overflow-auto bottom-0  ${
                                searchQuery ? 'absolute' : 'hidden'
                            }  bg-white divide-y divide-gray-100 rounded-lg  shadow-md   modal`}
                        >
                            <ul class="  " aria-labelledby="dropdownDefaultButton">
                                {userSuggestions.map((member, index) => (
                                    <li
                                        onClick={() => {
                                            addMemberToListForInvitation(member);
                                            setSearchQuery('');
                                        }}
                                        key={member.uid + index + Math.random()}
                                        className=" flex items-center border-b border-borderColor  gap-3 cursor-pointer px-4 py-2 hover:bg-gray-100 "
                                    >
                                        <AvatarImage w="w-[32px]" h="h-[32px]" url={member.profileImage} />
                                        <div className="flex items-center ">
                                            <div className="flex flex-col ">
                                                <span className="text-lightBlack capitalize ">{member.userName}</span>
                                                <span className="text-sm">Citizen Id : {member.uid}</span>
                                            </div>
                                            {/* <p>Email Id : {member.eamil}</p> */}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div>
                    <div className="relative">
                        <Dropdown
                            noSelectedText="Choose Role(s)"
                            w="w-full"
                            data={data}
                            selected={selectedRoles}
                            render={(listItem, index) => {
                                return (
                                    <Checkbox
                                        key={listItem + index + Math.random()}
                                        pl="pl-5"
                                        name={listItem}
                                        value={selectedRoles.includes(listItem)}
                                        label={listItem.split('_').join(' ').toLowerCase()}
                                        update={() => updateOptions(selectedRoles.includes(listItem), listItem)}
                                    />
                                );
                            }}
                            withCheckBox={true}
                        />
                    </div>
                    <div className="flex mt-6 justify-end">
                        <Button onClick={handleSubmit} type={'submit'} className={``} size="small">
                            {loading ? <Loader /> : 'Send Invite'}
                        </Button>
                    </div>
                </div>
            </div>
        </Overlay>
    );
}

export default InviteMemberModal;
