import { useController, useFieldArray } from 'react-hook-form';

export const usePhysicalBountyInputs = (control, isBackerRequired) => {
    const { field: cityField } = useController({
        name: 'city',
        control,
        rules: {
            required: 'Please enter a location',
        },
    });

    const { field: locationField } = useController({
        name: 'location',
        control,
    });

    const { field: bountyRadiusField } = useController({
        name: 'bountyRadius',
        control,
        rules: {
            required: 'Please enter a radius',
            min: {
                value: 10,
                message: 'Please enter radius greater than or equal to 10',
            },
        },
    });

    return {
        cityField,
        locationField,
        bountyRadiusField,
    };
};
