import { toast } from 'sonner';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';
import axios from 'axios';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const toggleEvent = async (eventId) => {
    try {
        const url = `${BASE_URL}/event/toggleLive/${eventId}`;

        const accessToken = await getAccessToken();

        const response = await axios.patch(url, {}, getConfig(accessToken));
        if (response.status === 201 || response.status === 200) toast.success(' Event status changed !');
        return response.data;
    } catch (error) {
        toast.error(error);
    }
};
