import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import Dropdown from '../../ui/Dropdown';
import DragAndDropIcon from '../../assets/icons/DragAndDropIcon';
import DeleteIcon from '../../assets/icons/Delete';
import { useStepThreeInputs } from './bountiesHooks/useStepThree';
import { microtaskTypeData } from './microtasktypes/microtaskTypeData';
import { useContext, useEffect, useState } from 'react';
import { BountiesContext } from '../../contexts/BountiesContext';
import FloatingButtons from '../../ui/FloatingButtons';

function StepThree({
    setStep,
    register,
    control,
    errors,
    setValue,
    getValues,
    activeMicrotask,
    setActiveMicrotask,
    getDataForMicroTask,
    microtasksValues,
    clearErrors,
    trigger,
    handleManualValueSetting,
    handleInstructionLinkPayload,
    instructionLinkPayload,

    microtaskEditDetails,
}) {
    const { microtaskFields, append, remove, update, swap } = useStepThreeInputs(control);
    const { isEdit } = useContext(BountiesContext);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    const handleSelectMicrotaskType = (value, name, index) => {
        clearErrors('microtasks');
        setValue(name, value);

        // Create the new microtask object based on the selected type
        const newMicrotask = {
            microtaskType: value,
            ...microtaskTypeData.find((obj) => obj.type === value).getFields(),
        };

        // Use the update method to modify the specific microtask
        update(index, newMicrotask);
    };

    const handleDragStart = (index) => {
        setDraggedIndex(index);
        setIsDragging(true);
    };
    const handleDragEnter = (index) => {
        if (draggedIndex !== null && draggedIndex !== index) {
            // Update your state to reflect the new order visually, if necessary
        }
    };

    const handleDrop = (index) => {
        if (draggedIndex !== null) {
            swap(draggedIndex, index);
            setDraggedIndex(null);
            setIsDragging(false);
        }
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        setDraggedIndex(null);
    };

    return (
        <div className="relative">
            <div className="p-12 pb-16  flex flex-col justify-between min-h-full   rounded-md ">
                <div>
                    <div className="mb-6">
                        <div className="flex gap-3">
                            <Heading type="xsmall" className="capitalize text-primaryInput">
                                Add Microtask to your Bounty
                            </Heading>
                        </div>
                        <p className="text-sm text-secondaryInput">
                            A microtask is an element of a bounty, a group of microtasks forms a bounty. <br />
                            There are different types of microtasks that can be used to share instructions as well to
                            capture impact data.
                        </p>
                    </div>

                    {microtaskFields?.map((microtaskField, index) => {
                        if (isEdit) {
                            return (
                                <div
                                    onClick={async () => {
                                        // Ensure that there is an active microtask before checking its validity
                                        if (activeMicrotask !== undefined) {
                                            const isValid = await trigger(); // Manually trigger validation for all fields

                                            // If active microtask doesn't have a selected microtask type, set an error
                                            if (!microtasksValues[activeMicrotask]?.microtaskType) {
                                                handleManualValueSetting(
                                                    false,
                                                    `microtasks[${activeMicrotask}].choose_microTaskType`,
                                                    microtasksValues[activeMicrotask]?.microtaskType
                                                );
                                                return; // Prevent switching microtasks if the current one is incomplete
                                            }

                                            // Clear any errors related to the current active microtask before switching
                                            clearErrors(`microtasks[${activeMicrotask}].choose_microTaskType`);
                                        }

                                        // Set the clicked microtask as active
                                        setActiveMicrotask(index);
                                    }}
                                    key={microtaskField.id}
                                    className="mt-6"
                                >
                                    <div className="flex items-center justify-between">
                                        <Heading type="xsmall" className="capitalize text-primaryInput">
                                            Microtask {index + 1}
                                        </Heading>
                                        <DeleteIcon
                                            onClick={() => {
                                                // Clear errors related to this specific microtask when deleting
                                                clearErrors(`microtasks[${activeMicrotask}].choose_microTaskType`);
                                                clearErrors(`microtasks[${activeMicrotask}]`);
                                                remove(index); // Remove the microtask

                                                // Reset the activeMicrotask state if the active one is deleted
                                                if (activeMicrotask === index) {
                                                    setActiveMicrotask(undefined);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="px-12 py-6 shadow-md  rounded-md ">
                                        <div className="relative mb-4">
                                            <p className="text-primaryInput text-sm mb-1">Microtask Type:</p>
                                            <Dropdown
                                                withDivision={true}
                                                withTooltip={true}
                                                modifyLabel={(item) =>
                                                    microtaskTypeData.find((obj) => obj.type === item).label
                                                }
                                                modifyTooltiptext={(item) =>
                                                    microtaskTypeData.find((obj) => obj.type === item).tooltipText
                                                }
                                                data1={microtaskTypeData
                                                    .filter((obj) => obj.category === 'input')
                                                    .map((obj) => obj.type)}
                                                data1Heading="User input"
                                                data2={microtaskTypeData
                                                    .filter((obj) => obj.category === 'instructional')
                                                    .map((obj) => obj.type)}
                                                data2Heading="Instructional"
                                                selected={
                                                    microtaskTypeData.find(
                                                        (obj) => obj.type === microtasksValues[index]?.microtaskType
                                                    )?.label
                                                }
                                                onSelected={handleSelectMicrotaskType}
                                                name={`microtasks.${index}.microtaskType`}
                                                indexType={index}
                                                noSelectedText="Choose Input Type"
                                            />
                                            {microtaskTypeData
                                                .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                                ?.label?.toLowerCase() === 'upload file' && (
                                                <p className="text-end w-64  mt-[5px] text-secondaryInput font-semibold text-sm">
                                                    Max upload size : 8MB
                                                </p>
                                            )}
                                            {microtaskTypeData
                                                .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                                ?.label?.toLowerCase() === 'upload video' && (
                                                <p className="text-end w-64  mt-[5px] text-secondaryInput font-semibold text-sm">
                                                    Max upload size : 10MB
                                                </p>
                                            )}
                                            {microtaskTypeData
                                                .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                                ?.label?.toLowerCase() === 'upload multiple pictures' && (
                                                <p className="text-end w-64  mt-[5px] text-secondaryInput font-semibold text-sm">
                                                    Max upload size : 10MB
                                                </p>
                                            )}
                                            {microtaskTypeData
                                                .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                                ?.label?.toLowerCase() === 'get user location' && (
                                                <p className="text-sm text-secondaryInput mt-1">
                                                    This microtask will prompt the user to share their location
                                                    co-ordinates.
                                                </p>
                                            )}
                                        </div>

                                        {microtaskTypeData
                                            .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                            ?.getComponent(
                                                register,
                                                index,
                                                errors,
                                                control,
                                                setValue,
                                                getValues,
                                                handleInstructionLinkPayload,
                                                instructionLinkPayload,
                                                isEdit ? microtaskEditDetails?.microtasks[index] : undefined
                                            )}
                                    </div>
                                </div>
                            );
                        }

                        return activeMicrotask !== index ? (
                            <div
                                key={index}
                                onClick={async () => {
                                    // Ensure that there is an active microtask before checking its validity
                                    if (activeMicrotask !== undefined) {
                                        const isValid = await trigger(); // Manually trigger validation for all fields

                                        // If active microtask doesn't have a selected microtask type, set an error
                                        if (!microtasksValues[activeMicrotask]?.microtaskType) {
                                            handleManualValueSetting(
                                                false,
                                                `microtasks[${activeMicrotask}].choose_microTaskType`,
                                                microtasksValues[activeMicrotask]?.microtaskType
                                            );
                                            return; // Prevent switching microtasks if the current one is incomplete
                                        }

                                        // Clear any errors related to the current active microtask before switching
                                        clearErrors(`microtasks[${activeMicrotask}].choose_microTaskType`);
                                    }

                                    // Set the clicked microtask as active
                                    setActiveMicrotask(index);
                                }}
                            >
                                <div
                                    draggable
                                    onDragStart={() => handleDragStart(index)}
                                    onDragEnter={() => handleDragEnter(index)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={() => handleDrop(index)}
                                    onDragEnd={handleDragEnd}
                                    className="flex items-center gap-6 mb-6 w-full shadow-md rounded-md p-4"
                                >
                                    <DragAndDropIcon />
                                    <div className="w-full">
                                        <Heading type="xsmall" className="capitalize text-primaryInput">
                                            Microtask {index + 1} : {microtasksValues[index]?.microtaskTitle}
                                        </Heading>
                                        <div className="flex items-center">
                                            <p className="text-sm">Response Type: </p>
                                            <p className="text-sm font-semibold">
                                                {microtaskTypeData.find(
                                                    (obj) => obj.type === microtasksValues[index]?.microtaskType
                                                )?.label || ''}
                                            </p>
                                        </div>
                                    </div>
                                    <DeleteIcon onClick={() => null} />
                                </div>
                            </div>
                        ) : (
                            <div key={microtaskField.id} className="mt-6">
                                <div className="flex items-center justify-between">
                                    <Heading type="xsmall" className="capitalize text-primaryInput">
                                        Microtask {index + 1}
                                    </Heading>
                                    <DeleteIcon
                                        onClick={() => {
                                            // Clear errors related to this specific microtask when deleting
                                            clearErrors(`microtasks[${activeMicrotask}].choose_microTaskType`);
                                            clearErrors(`microtasks[${activeMicrotask}]`);
                                            remove(index); // Remove the microtask

                                            // Reset the activeMicrotask state if the active one is deleted
                                            if (activeMicrotask === index) {
                                                setActiveMicrotask(undefined);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="px-12 py-6 shadow-md  rounded-md ">
                                    <div className="relative mb-4">
                                        <p className="text-primaryInput text-sm mb-1">Microtask Type:</p>
                                        <Dropdown
                                            withDivision={true}
                                            withTooltip={true}
                                            modifyLabel={(item) =>
                                                microtaskTypeData.find((obj) => obj.type === item).label
                                            }
                                            modifyTooltiptext={(item) =>
                                                microtaskTypeData.find((obj) => obj.type === item).tooltipText
                                            }
                                            data1={microtaskTypeData
                                                .filter((obj) => obj.category === 'input')
                                                .map((obj) => obj.type)}
                                            data1Heading="User input"
                                            data2={microtaskTypeData
                                                .filter((obj) => obj.category === 'instructional')
                                                .map((obj) => obj.type)}
                                            data2Heading="Instructional"
                                            selected={
                                                microtaskTypeData.find(
                                                    (obj) => obj.type === microtasksValues[index]?.microtaskType
                                                )?.label
                                            }
                                            onSelected={handleSelectMicrotaskType}
                                            name={`microtasks.${index}.microtaskType`}
                                            indexType={index}
                                            noSelectedText="Choose Input Type"
                                        />
                                        {microtaskTypeData
                                            .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                            ?.label?.toLowerCase() === 'upload file' && (
                                            <p className="text-end w-64  mt-[5px] text-secondaryInput font-semibold text-sm">
                                                Max upload size : 8MB
                                            </p>
                                        )}
                                        {microtaskTypeData
                                            .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                            ?.label?.toLowerCase() === 'upload video' && (
                                            <p className="text-end w-64  mt-[5px] text-secondaryInput font-semibold text-sm">
                                                Max upload size : 10MB
                                            </p>
                                        )}
                                        {microtaskTypeData
                                            .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                            ?.label?.toLowerCase() === 'upload multiple pictures' && (
                                            <p className="text-end w-64  mt-[5px] text-secondaryInput font-semibold text-sm">
                                                Max upload size : 10MB
                                            </p>
                                        )}
                                        {microtaskTypeData
                                            .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                            ?.label?.toLowerCase() === 'get user location' && (
                                            <p className="text-sm text-secondaryInput mt-1">
                                                This microtask will prompt the user to share their location
                                                co-ordinates.
                                            </p>
                                        )}
                                    </div>

                                    {microtaskTypeData
                                        .find((obj) => obj.type === microtasksValues[index]?.microtaskType)
                                        ?.getComponent(
                                            register,
                                            index,
                                            errors,
                                            control,
                                            setValue,
                                            getValues,
                                            handleInstructionLinkPayload,
                                            instructionLinkPayload,
                                            isEdit ? microtaskEditDetails?.microtasks[index] : undefined,
                                            microtasksValues[activeMicrotask]
                                        )}
                                </div>
                            </div>
                        );
                    })}
                    <p className="text-foundryRed">
                        {errors?.microtasks && errors.microtasks[activeMicrotask]?.choose_microTaskType?.message}
                    </p>
                    <div className="flex items-center justify-end mb-6  ">
                        <Button
                            type="button"
                            onClick={async () => {
                                // Reset errors for deleted tasks and inactive microtasks
                                if (activeMicrotask !== undefined && !microtaskFields[activeMicrotask]?.microtaskType) {
                                    handleManualValueSetting(
                                        false,
                                        `microtasks[${activeMicrotask}].choose_microTaskType`,
                                        microtaskFields[activeMicrotask]?.microtaskType
                                    );
                                    return; // Prevent adding a new task if the current one is incomplete
                                }

                                // Clear any lingering errors for the deleted task
                                clearErrors(`microtasks[${activeMicrotask}]`);

                                if (!microtaskFields.length) {
                                    setActiveMicrotask(microtaskFields.length);
                                    append({
                                        microtaskType: '',
                                    });
                                    return;
                                }

                                const isValid = await trigger(); // Manually trigger validation for all fields
                                if (isValid) {
                                    setActiveMicrotask(microtaskFields.length); // Set the new microtask as active
                                    append({
                                        microtaskType: '',
                                    });
                                } else {
                                    console.log('Validation failed in adding microtask');
                                }
                            }}
                            className={`w-36 text-end mt-4`}
                            size="none"
                            variant="transparent"
                        >
                            + Add Microtask
                        </Button>
                    </div>
                    <p className="text-foundryRed">{errors.microtasks?.root?.message}</p>
                </div>
            </div>
            <FloatingButtons>
                <Button type="button" onClick={() => setStep(2)} className="bg-white" variant="tertiary" size="small">
                    Back
                </Button>

                <Button
                    type="button"
                    onClick={async () => {
                        // Ensure the active microtask is valid if not deleted
                        if (activeMicrotask !== undefined && !microtaskFields[activeMicrotask]?.microtaskType) {
                            handleManualValueSetting(
                                false,
                                `microtasks[${activeMicrotask}].choose_microTaskType`,
                                microtaskFields[activeMicrotask]?.microtaskType
                            );
                            return; // Prevent navigating to the next step if validation fails
                        }

                        // Proceed to the next step
                        setStep(4, activeMicrotask, microtaskFields[activeMicrotask]?.microtaskType);
                    }}
                    size="small"
                >
                    Next
                </Button>
            </FloatingButtons>
        </div>
    );
}

export default StepThree;

// useEffect(() => {
//     if (isEdit) {
//         microtaskEditDetails?.microtasks?.forEach((m, index) => {
//             const microtaskType = m?.microtaskType;
//             const fieldsToRegister =
//                 microtaskTypeData.find((obj) => obj.type === microtaskType)?.getFields(m) || {};

//             Object.keys(fieldsToRegister).forEach((fieldName) => {
//                 register(`microtasks.${index}.${fieldName}`, {
//                     value: fieldsToRegister[fieldName],
//                 });
//             });
//         });
//     }
// }, [isEdit, microtaskFields, register, microtaskEditDetails, microtasksValues]);
