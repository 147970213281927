import { useOutletContext, useParams } from 'react-router-dom';
import TreasuryBullet from '../../../assets/icons/TreasuryBullet';
import Button from '../../../ui/Button';
import Heading from '../../../ui/Heading';
import ImpactFields from '../../../ui/ImpactFields';
import PointsWithGrayText from '../../../ui/PointsWithGrayText';
import WhiteCard from '../../../ui/WhiteCard';
import Loader from '../../../ui/Loader';

import { clusterApiUrl, PublicKey } from '@solana/web3.js';
import { useEffect, useState } from 'react';

import { Connection } from '@solana/web3.js';
import { RPC_ENDPOINT } from '../../../constants/apiPaths';
import { fetchOwnedTokens } from '../../../blockchainInteraction/getOrganisationFunds';
import { getOrganisationFunds } from '../../../apis/oragnisations/organisation';
import Modal from '../../../ui/Modal';
import AddFundsInOrganisation from './AddFundsInOrganisation';
import NoTokenAvailable from '../../../assets/icons/NoTokenAvailable';
import TrophyIcon from '../../../assets/icons/TrophyIcon';
import NewDropIcon from '../../../assets/icons/NewDropIcon';
import { SDG_IMAGES } from '../../../constants/datas';
import { useLoadingState } from '../../../hooks/useLoader';
import WalletConnectCustomButton from '../../walletconnectUI/WalletCustomText';
import AddSOLInOrganisation from './AddSOLInOrganisation';
import { useWallet } from '@solana/wallet-adapter-react';
import { executorToReadSOL } from '../../../blockchainInteraction/addFunds';

const connection = new Connection(RPC_ENDPOINT);
function RightSection({ fundsAllocated, organisation }) {
    const [orgFunds, setOrgFunds] = useState([]);
    const { publicKey } = useWallet();
    const [solBalance, setSolBalance] = useState(0);
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { id } = useParams();
    // const [{ fundAllocation, impactPoints, impactRank }] = useOutletContext();
    // const impactSoFar = fundAllocation.reduce((acc, i) => acc + i.rewardAllocation, 0);
    // console.log(fundAllocation, 'here ');
    const address = publicKey;
    // const fetchData = async () => {
    //     try {
    //         // https://explorer.solana.com/address/TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6
    //         startLoading();
    //         const data = await getOrganisationFunds(id);

    //         setOrgFunds(data);
    //         stopLoading();
    //         return data;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    // useEffect(
    //     function () {
    //         fetchData();
    //     },
    //     [id]
    // );

    // useEffect(function () {
    //     const fetchData = async () => {
    //         const connection = new Connection(RPC_ENDPOINT, 'confirmed');
    //         try {
    //             const data = await executorToReadSOL(connection, new PublicKey(organisation.treasurerMultisig));

    //             setSolBalance(data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    const { totalFundAllocationUSD, impactRank, impactPoints, allocation } = fundsAllocated;

    const coreWiseDistribution = allocation?.map((obj) => ({ ...obj, impactPoints: obj?.allocationUSD }));

    return (
        <div className="">
            <WhiteCard className="py-3 px-6 ">
                <div className="flex items-center border-b  border-dashed border-borderColor pb-2 justify-between">
                    <Heading className={'text-lightBlack leading-[58px]  '} type="pageHeading">
                        Org Impact
                    </Heading>

                    <Heading className={'text-lightBlack leading-[58px]  '} type="pageHeading">
                        ${fundsAllocated?.totalFundAllocationUSD?.toFixed(2)}
                    </Heading>
                </div>
                <div className="flex py-6 justify-between border-dashed  border-b border-borderColor gap-12">
                    <div className="flex flex-col  gap-1 ">
                        <p className={`text-sm text-secondaryText`}>SDGs:</p>

                        <div className="mb-5">
                            <div className="grid grid-cols-3 gap-1">
                                {fundsAllocated.SDGs.map((sdg) => (
                                    <img className="w-14 h-14" src={SDG_IMAGES[sdg]} alt="" />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 items-end  ">
                        <p className={`text-sm text-secondaryText`}>Impact Points</p>
                        <div className="flex  gap-1 items-center  ">
                            <TrophyIcon />
                            <p className={`text-2xl text-lightBlack font-medium`}>{impactPoints?.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <ImpactFields className="pt-6" coreWiseDistribution={coreWiseDistribution} isDollar={true} />
            </WhiteCard>
            {/* <WhiteCard className="py-3 px-6 ">
                <div className="flex justify-between">
                    <Heading className={'text-lightBlack leading-[58px]  '} type="pageHeading">
                        Org Treasury
                    </Heading>
                    <PointsWithGrayText
                        text="Treasury Balance in $"
                        sign="$"
                        points={
                            orgFunds.length
                                ? orgFunds
                                      .reduce((acc, curr) => {
                                          if (curr) {
                                              return acc + Number(curr.priceUSD);
                                          }
                                      }, 0)
                                      .toFixed(2)
                                : 0
                        }
                        signClass="text-xs"
                        pointsClass="text-lg"
                        textClass="text-sm text-secondaryText pt-3"
                    />
                </div>
                <ul className="mt-6">
                    {!loading ? (
                        orgFunds.length ? (
                            orgFunds.map((fund) => {
                                return (
                                    <li key={fund.mint} className="flex items-center py-2 ">
                                        <div className="pb-2">
                                            {fund.image ? (
                                                <img className="w-5 object-contain h-5" src={fund.image} alt="" />
                                            ) : (
                                                <NoTokenAvailable />
                                            )}
                                        </div>
                                        <div className=" flex flex-1 pb-2  justify-between text-primaryInput border-b border-borderColor">
                                            <div className="flex items-center gap-1 pl-4 ">
                                                <span>{Number(fund?.amount)?.toFixed(3)}</span>
                                                <a
                                                    href={`https://explorer.solana.com/address/${fund.mint}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {fund.symbol || 'Token'}
                                                </a>
                                            </div>
                                            <p className=" text-lightBlack font-medium">${fund.priceUSD || 0}</p>
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            <p className="flex items-center justify-center pb-6">No Funds yet</p>
                        )
                    ) : (
                        <div className="min-h-[20vh] flex justify-center items-center">
                            <Loader />
                        </div>
                    )}
                </ul>
            </WhiteCard> */}

            {/* <WhiteCard className="py-3 px-6  ">
                <div className="flex flex-col gap-1 justify-between">
                    <Heading className={'text-lightBlack leading-[58px]  '} type="pageHeading">
                        SOL Balance
                    </Heading>
                    <div className="flex items-center text-lightBlack justify-between">
                        <p>{solBalance}</p>
                        <p>$0</p>
                    </div>
                </div>
            </WhiteCard> */}
            {/* <div className="flex items-center justify-end">
                <div className="flex gap-3  ">
                    <div className="flex items-center justify-end">
                        {address ? (
                            <Modal>
                                <Modal.Button className="flex gap-2   cursor-pointer items-center" opens={'addfunds'}>
                                    <Button size="xsmall">Add Funds</Button>
                                </Modal.Button>
                                <Modal.Window name={'addfunds'}>
                                    <AddFundsInOrganisation
                                        fetchFunds={fetchData}
                                        setOrgFunds={setOrgFunds}
                                        orgFunds={orgFunds}
                                        multisig={organisation.treasurerMultisig}
                                    />
                                </Modal.Window>
                            </Modal>
                        ) : (
                            <div className=" flex items-center">
                                <WalletConnectCustomButton
                                    customText={'Add Funds'}
                                    width="144px"
                                    customStyles={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: '#fff',
                                        border: '1px solid #dcdcdc',
                                        lineHeight: 'normal',
                                        fontWeight: '500',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        height: '40px',
                                        borderRadius: '8px',
                                        flexGrow: 1,
                                    }}
                                    connectedText={'Add SOL'}
                                    disconnectedText="Add SOL"
                                    removeIconOnDisconnect={true}
                                    removeIconOnConnect={true}
                                    removeIconOnWalletSelect={true}
                                    removeIconOnConnecting={true}
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex items-center w-full justify-end">
                        {address ? (
                            <Modal>
                                <Modal.Button className="flex gap-2   cursor-pointer items-center" opens={'addfunds'}>
                                    <Button size="xsmall">Add SOL</Button>
                                </Modal.Button>
                                <Modal.Window name={'addfunds'}>
                                    <AddSOLInOrganisation
                                        solBalace={solBalance}
                                        setSolBalance={setSolBalance}
                                        orgFunds={orgFunds}
                                        multisig={organisation.treasurerMultisig}
                                    />
                                </Modal.Window>
                            </Modal>
                        ) : (
                            <div className=" flex items-center">
                                <WalletConnectCustomButton
                                    customText={'Add SOL'}
                                    width="144px"
                                    customStyles={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: '#fff',
                                        border: '1px solid #dcdcdc',
                                        lineHeight: 'normal',
                                        fontWeight: '500',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        height: '40px',
                                        borderRadius: '8px',
                                        flexGrow: 1,
                                    }}
                                    connectedText={'Add SOL'}
                                    disconnectedText="Add SOL"
                                    removeIconOnDisconnect={true}
                                    removeIconOnConnect={true}
                                    removeIconOnWalletSelect={true}
                                    removeIconOnConnecting={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default RightSection;
