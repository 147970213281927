import { toast } from 'sonner';
import { BASE_URL } from '../../constants/apiPaths';
import { getAccessToken } from '../../firebase';
import axios from 'axios';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const submitFeedback = async (payload) => {
    try {
        const url = `${BASE_URL}/citizen/sendFeedback
`;

        const accessToken = await getAccessToken();

        const response = await axios.post(url, payload, getConfig(accessToken));

        return response.data;
    } catch (error) {
        toast.error('Sorry unable to send a feedback');
    }
};
