import { desiredInstructionalLinks } from './desiredInstructionalLinks';

const structurizePayload = (filteredObj, microtaskType, instructionLinkPayload) => {
    if (microtaskType === 'INSTRUCTION') {
        const structuredObj = {
            ...filteredObj,
            instruction_message: filteredObj.instruction_message,
            instruction_links: desiredInstructionalLinks(instructionLinkPayload),
        };

        return structuredObj;
    }

    if (microtaskType === 'TEST_MCQ') {
        const structuredObj = {
            ...filteredObj,
            testMCQ_correctAnswer: filteredObj.testMCQ_answers[filteredObj.testMCQ_correctAnswer].answer,
            testMCQ_answers: filteredObj.testMCQ_answers
                ?.map((ele) => {
                    return ele.answer;
                })
                .join(',,'),
        };
        return structuredObj;
    }

    if (microtaskType === 'DOWNLOAD_PDF') {
        const structuredObj = {
            ...filteredObj,
            downloadPDF_Files: filteredObj.downloadPDF_Files?.map((item) => `${item.fileTitle}~${item.link}`).join(','),
        };
        return structuredObj;
    }

    if (microtaskType === 'MULTI_SELECT_MULTIPLE_CHOICE_QUESTION') {
        const structuredObj = {
            ...filteredObj,
            multiSelect_answers: filteredObj.multiSelect_answers
                ?.map((ele) => {
                    return ele.answerName;
                })
                .join(','),
        };
        return structuredObj;
    }

    if (microtaskType === 'MULTI_CHOICE_QUESTIONs') {
        const structuredObj = {
            ...filteredObj,
            multiChoiceQuestions_answers: filteredObj.multiChoiceQuestions_answers?.map((ele) => ele.answer).join(',,'),
        };
        return structuredObj;
    }

    return filteredObj;
};

export const getMicroTaskFieldsAndValues = (obj, microtaskType, instructionLinkPayload) => {
    let arr = [];

    for (const [key, value] of Object.entries(structurizePayload(obj, microtaskType, instructionLinkPayload))) {
        const keyToPush = key.split('_')[1];

        const objToPush = {
            name: keyToPush,
            value: value,
        };
        arr.push(objToPush);
    }

    return arr;
};
