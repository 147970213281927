import axios from 'axios';
import { BASE_URL } from '../../constants/apiPaths';
import { getAccessToken } from '../../firebase';
import { toast } from 'sonner';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const getAttester = async () => {
    try {
        const url = `${BASE_URL}/attestation/attestor`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error.response.status;
    }
};

// const ATTESTOR_PROFILE_STATUS = ['INVITATION_SENT', 'UNSUBMITTED', 'APPROVAL_PENDING', 'APPROVED'];

// if unsubmitted - going away patch with false otherwise post with false
// if unsubmitted - on click event patch with true else patch with  true

export const createAttesterPost = async (bool, payload) => {
    try {
        const url = `${BASE_URL}/attestation/attestor?submit=${bool}`;

        const accessToken = await getAccessToken();

        const response = await axios.post(url, payload, getConfig(accessToken));

        if (response.status === 201) toast.success('Attester Profile is successfully created !');
        return response.status;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};

export const createAttesterPatch = async (id, bool, payload) => {
    try {
        const url = `${BASE_URL}/attestation/attestor/${id}?submit=${bool}`;

        const accessToken = await getAccessToken();
        const response = await axios.put(url, payload, getConfig(accessToken));

        if (response.status === 201) toast.success('Project is successfully created !');
        return response.status;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};

// GET / attestation;

// export const getAttestationsForAnAttester = async () => {
//     try {
//         const url = `${BASE_URL}/attestation`;
//         const accessToken = await getAccessToken();
//         const response = await axios.get(url, getConfig(accessToken));

//         if (response.status === 200 || response.status === 201) {
//             return response.data;
//         } else {
//             console.log('HTTP status code:', response.status);

//             return response; // or throw an error, depending on your error handling strategy
//         }
//     } catch (error) {
//         console.log(error, 'line 28');
//         return error.response.status;
//     }
// };
