import axios from 'axios';
import { auth, getAccessToken } from '../../firebase';
import { BASE_URL } from '../../constants/apiPaths';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const getProfile = async () => {
    try {
        const uid = await auth.currentUser.uid;
        const url = `${BASE_URL}/citizen/${uid}`;
        const accessGoogle = await auth.currentUser.getIdToken(true);

        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const createProfileAtDb = async (profile) => {
    try {
        const url = `${BASE_URL}/citizen/`;

        const accessGoogle = await auth.currentUser.getIdToken(true);
        const response = await axios.post(url, profile, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const updateProfile = async (address) => {
    try {
        const url = `${BASE_URL}/citizen?walletAddress=${address}`;

        const accessGoogle = await auth.currentUser.getIdToken(true);

        const response = await axios.patch(url, undefined, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const getLeaderBoard = async () => {
    try {
        const url = `${BASE_URL}/citizen/getLeaderboard`;

        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const searchUsers = async (searchString) => {
    try {
        const url = `${BASE_URL}/citizen?userName=${searchString}`;

        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const searchUsersViaEmail = async (searchString) => {
    try {
        const url = `${BASE_URL}/citizen/withEmail?email=${searchString}`;

        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const sendingInvitation = async (payload) => {
    try {
        const url = `${BASE_URL}/orgInvitation`;

        const accessToken = await getAccessToken();

        const response = await axios.post(url, payload, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const sendingInvitationViaEmail = async (payload) => {
    try {
        const url = `${BASE_URL}/orgInvitation/email`;

        const accessToken = await getAccessToken();

        const response = await axios.post(url, payload, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getAllInvitations = async (id) => {
    try {
        const url = `${BASE_URL}/orgInvitation?organisationId=${id}`;

        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const deleteInvitation = async (id) => {
    try {
        const url = `${BASE_URL}/orgInvitation/${id}`;

        const accessToken = await getAccessToken();

        const response = await axios.delete(url, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const executeInvitation = async (payload) => {
    try {
        const url = `${BASE_URL}/orgInvitation/execute`;
        const accessToken = await getAccessToken();

        const response = await axios.patch(url, payload, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getYourImpacts = async (id) => {
    try {
        const url = `${BASE_URL}/citizen/${id}`;

        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const getCoreWiseDistribution = async () => {
    try {
        const url = `${BASE_URL}/citizen/impactPoints`;

        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const editProfile = async (payload, uid) => {
    const url = `${BASE_URL}/citizen/${uid}`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.put(url, payload, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error?.response?.data;
    }
};
