import { useRef, useState } from 'react';
import { textToHtml } from '../helpers/plainText';

import ReactQuill from 'react-quill';
import Heading from './Heading';
import CharacterLimit from './CharacterLimit';

const RichTextEditorBubbleLinkOnly = ({ descriptionField }) => {
    const modules = {
        toolbar: false,
    };
    const quillRef = useRef(null);
    // Local state to manage HTML content with tags for the editor
    const [htmlContent, setHtmlContent] = useState(() => textToHtml(descriptionField?.value, []) || '');

    const handleContentChange = (newHtmlValue) => {
        setHtmlContent(newHtmlValue); // Update local state with HTML content

        // Remove HTML tags to get plain text
        // const plainTextValue = newHtmlValue.replace(/<\/?[^>]+(>|$)/g, '').trim();

        const plainTextValue = newHtmlValue
            .replace(/<p><br\s*\/?><\/p>/gi, '\n') // Replace empty paragraphs with a single newline
            .replace(/<br\s*\/?>/gi, '\n') // Replace <br> or <br/> with newline
            .replace(/<\/p>/gi, '\n') // Replace closing </p> with a single newline for paragraph separation
            .replace(/<p[^>]*>/gi, '') // Remove opening <p> tags
            .replace(/<\/?[^>]+(>|$)/g, '') // Remove any remaining HTML tags
            .trim();
        // Update the form field with plain text content only

        descriptionField.onChange(plainTextValue);
    };

    return (
        <div>
            {/* ReactQuill editor with Bubble theme */}
            <ReactQuill
                ref={quillRef}
                value={htmlContent} // HTML content with tags for the editor
                onChange={handleContentChange}
                className="border border-borderColor shadow-sm bg-white rounded-md px-3 main"
                theme="bubble"
                onBlur={descriptionField.onBlur}
                modules={modules} // Pass custom toolbar with link only
                placeholder="Please enter a short description that will be displayed on Impact Miner"
            />
            <div></div>
        </div>
    );
};

const DescriptionWithPara = ({ errors, field, errorField }) => {
    return (
        <div className="my-6">
            <Heading type="medium" className="capitalize text-primaryInput mb-2">
                Brief Description <sup className="text-lg relative top-[1px]">*</sup>
            </Heading>
            {/* Use RichTextEditorBubbleLinkOnly and pass the descriptionField */}
            <RichTextEditorBubbleLinkOnly descriptionField={field} />

            <div className="flex items-center justify-between mt-1">
                {/* Character limit uses plain text length */}
                <CharacterLimit min={field.value ? field.value.length : 0} max={1000} />
            </div>

            {errors?.[errorField] && <p className="text-red-500">{errors[errorField].message}</p>}
        </div>
    );
};

export default DescriptionWithPara;
