// import { useFieldArray } from 'react-hook-form';
// import DeleteIcon from '../../../assets/icons/Delete';
// import InputForDragAndDrop from '../../../ui/InputForDragAndDrop';
// import Input from '../../../ui/Input';
// import Button from '../../../ui/Button';
// import IsSkippable from './IsSkippable';
// import { useContext, useEffect } from 'react';
// import { BountiesContext } from '../../../contexts/BountiesContext';

// const TestMCQ = ({ register, index, errors, control, setValue, getValues, microtaskFields, microtaskEditDetail }) => {
//     const { isEdit } = useContext(BountiesContext);
//     const {
//         fields: testMCQ_Answers_Fields,
//         append: appendAnswer,
//         remove: removeAnswer,
//     } = useFieldArray({
//         name: `microtasks[${index}].testMCQ_answers`,
//         control,
//     });
//     useEffect(() => {
//         // Run this logic only once when the component is mounted
//         if (isEdit && testMCQ_Answers_Fields.length === 0) {
//             // If in edit mode, populate the answers from microtaskEditDetail
//             const editAnswers = microtaskEditDetail?.testMcq_answers?.split(',') || [];
//             editAnswers.forEach((a) => {
//                 appendAnswer({ answer: a });
//             });
//         } else if (!isEdit && testMCQ_Answers_Fields.length === 0) {
//             // If not in edit mode, ensure there is at least one empty answer field
//             appendAnswer({ answer: '' });
//         }
//     }, [isEdit, testMCQ_Answers_Fields.length, appendAnswer, microtaskEditDetail]);
//     // if (testMCQ_Answers_Fields.length === 0) {
//     //     appendAnswer({ answer: '' }); // Append default value if no fields exist
//     // }
//     console.log(
//         microtaskEditDetail?.testMcq_answers
//             ?.split(',')
//             .findIndex((i) => i.toLowerCase() === microtaskEditDetail?.testMcq_correctAnswer?.toLowerCase())
//             ?.toString(),
//         'see the index'
//     );
//     return (
//         <div>
//             <Input
//                 {...register(`microtasks.${index}.testMCQ_Title`, {
//                     required: 'Please enter a title',
//                 })}
//                 type="transparent"
//                 {...(isEdit && { defaultValue: microtaskEditDetail?.testMcq_Title || '' })}
//                 error={errors?.microtasks && errors?.microtasks[index]?.testMCQ_Title?.message}
//                 placeholder={'Title *'}
//                 transparentTextSize="text-base"
//                 className={'w-[100%] mb-1 '}
//             />
//             <Input
//                 {...register(`microtasks.${index}.testMCQ_question`, {
//                     required: 'Please enter a question ',
//                 })}
//                 type="transparent"
//                 {...(isEdit && { defaultValue: microtaskEditDetail?.testMcq_question || '' })}
//                 error={errors?.microtasks && errors?.microtasks[index]?.testMCQ_question?.message}
//                 placeholder={'Question *'}
//                 transparentTextSize="text-sm"
//                 transparentFontWeight={'font-normal'}
//                 className={'w-[100%] mb-1 mt-2 '}
//             />

//             <div className="mt-2  items-center">
//                 <div className="flex items-center justify-end  gap-3  w-full">
//                     <p>Correct Answer</p>
//                 </div>
//                 {testMCQ_Answers_Fields?.map((answerField, i) => {
//                     return (
//                         <div key={answerField.id} className="flex  gap-3 mb-3 w-full">
//                             <p className="relative top-3">
//                                 <span className="text-primaryInput">Option {i + 1} :</span>
//                             </p>
//                             <div className="flex-1">
//                                 <InputForDragAndDrop
//                                     name={`microtasks[${index}].testMCQ_answers[${i}].answer`}
//                                     control={control}
//                                     index={i}
//                                     width="w-full"
//                                     placeholder="Enter an option"
//                                     error={
//                                         errors?.microtasks
//                                             ? errors?.microtasks[index]?.testMCQ_answers
//                                                 ? errors?.microtasks[index]?.testMCQ_answers[i]?.answer?.message
//                                                 : ''
//                                             : ''
//                                     }
//                                     validateObject={{
//                                         required: 'Please enter an option',
//                                         // maxLength: {
//                                         //     value: 200,
//                                         //     message: 'The maximum length is 200 characters',
//                                         // },
//                                     }}
//                                 />
//                             </div>
//                             <div className="relative top-3">
//                                 <div className="flex  items-center  justify-between w-[70px]">
//                                     <DeleteIcon
//                                         className={`${i > 0 ? 'cursor-pointer ' : 'invisible'}`}
//                                         onClick={() => removeAnswer(i)}
//                                     />
//                                     <input
//                                         type="radio"
//                                         name={`microtasks[${index}].testMCQ_correctAnswer`}
//                                         value={
//                                             isEdit
//                                                 ? microtaskEditDetail?.testMcq_answers
//                                                       ?.split(',')
//                                                       .findIndex(
//                                                           (i) =>
//                                                               i.toLowerCase() ===
//                                                               microtaskEditDetail?.testMcq_correctAnswer?.toLowerCase()
//                                                       )
//                                                       ?.toString()
//                                                 : i
//                                         }
//                                         // {...(isEdit && { defaultValue: microtaskEditDetail?.testMcq_question || '' })}
//                                         {...register(`microtasks.${index}.testMCQ_correctAnswer`, {
//                                             required: 'Please select the correct answer.',
//                                         })}
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     );
//                 })}

//                 <Button
//                     type="button"
//                     onClick={() => appendAnswer({ answer: '' })}
//                     size="none"
//                     className="  font-semibold !text-lightBlack text-sm"
//                     variant="transparent"
//                 >
//                     + Add Option
//                 </Button>
//                 <div>
//                     {errors?.microtasks && (
//                         <p className="text-foundryRed">{errors?.microtasks[index]?.testMCQ_answers?.root?.message}</p>
//                     )}
//                 </div>
//             </div>

//             {/*
//             <Input
//                 {...register(`microtasks.${index}.testMCQ_instructionHeading`, {
//                     required: 'Please enter heading for Test MCQ.',
//                 })}
//                 type="transparent"
//                 error={
//                     errors?.microtasks && errors?.microtasks[index]?.testMCQ_instructionHeading?.message
//                 }
//                 placeholder={'Heading for Test MCQ...'}
//                 transparentTextSize="text-base"
//                 className={'w-[100%] mb-1 mt-2 '}
//             />

//             <div className="mt-2  items-center">
//                 {microtaskFields?.map((field, i) => {
//                     return (
//                         <div key={field.id} className="flex items-center gap-3 mb-3 w-full">
//                             <p>
//                                 <span>Instruction :</span> <span>{i + 1}</span>
//                             </p>
//                             <InputForDragAndDrop
//                                 name={`microtasks[${index}].testMCQ_instructions[${i}].instruction`}
//                                 control={control}
//                                 index={i}
//                                 width="w-[50%]"
//                                 placeholder="instruction"
//                             />
//                             <DeleteIcon onClick={() => removeAnswer(i)} />
//                         </div>
//                     );
//                 })}

//                 <Button
//                     type="button"
//                     onClick={() => appendAnswer({ instruction: '' })}
//                     size="none"
//                     className="  font-semibold !text-lightBlack text-sm"
//                     variant="transparent"
//                 >
//                     + Add Instruction
//                 </Button>
//                 <div>
//                     {errors?.microtasks && (
//                         <p className="text-foundryRed">{errors?.microtasks[index]?.testMCQ_instructions?.root?.message}</p>
//                     )}
//                 </div>
//             </div>
//             */}

//             {/* Skippable Field */}
//             {/* <div className="flex gap-9 my-2 items-center ">
//                 <IsSkippable
//                     selectedOption={skippableField.value}
//                     onSelectOption={(value) => {
//                         skippableField.onChange(value);
//                     }}
//                     options={['Yes', 'No']}
//                 />
//             </div> */}
//         </div>
//     );
// };

// export default TestMCQ;

import { useFieldArray } from 'react-hook-form';
import DeleteIcon from '../../../assets/icons/Delete';
import InputForDragAndDrop from '../../../ui/InputForDragAndDrop';
import Input from '../../../ui/Input';
import Button from '../../../ui/Button';
import IsSkippable from './IsSkippable';
import { useContext, useEffect } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';

const TestMCQ = ({ register, index, errors, control, setValue, getValues, microtaskFields, microtaskEditDetail }) => {
    const { isEdit } = useContext(BountiesContext);
    const {
        fields: testMCQ_Answers_Fields,
        append: appendAnswer,
        remove: removeAnswer,
    } = useFieldArray({
        name: `microtasks[${index}].testMCQ_answers`,
        control,
    });

    useEffect(() => {
        // Populate answers and correctAnswer index during edit mode
        if (isEdit && testMCQ_Answers_Fields.length === 0) {
            const editAnswers = microtaskEditDetail?.testMcq_answers?.split(',,') || [];
            editAnswers.forEach((a) => {
                appendAnswer({ answer: a });
            });

            setValue(`microtasks.${index}.testMCQ_instructionHeading`, microtaskEditDetail?.testMcq_instructionHeading);
            setValue(`microtasks.${index}.testMCQ_instructions`, microtaskEditDetail?.testMcq_instructions);

            // Find the correct answer index
            const correctAnswerIndex = editAnswers.findIndex(
                (a) => a.toLowerCase().trim() === microtaskEditDetail?.testMcq_correctAnswer?.toLowerCase().trim()
            );

            // Set the correct answer in the form
            if (correctAnswerIndex >= 0) {
                setValue(`microtasks.${index}.testMCQ_correctAnswer`, correctAnswerIndex.toString());
            }
        } else if (!isEdit && testMCQ_Answers_Fields.length === 0) {
            appendAnswer({ answer: '' }); // Append an empty answer field for new microtasks
        }
    }, [isEdit, testMCQ_Answers_Fields.length, appendAnswer, microtaskEditDetail, setValue]);

    return (
        <div>
            <Input
                {...register(`microtasks.${index}.testMCQ_Title`, {
                    required: 'Please enter a title',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.testMcq_Title || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.testMCQ_Title?.message}
                placeholder={'Title *'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 '}
            />
            <Input
                {...register(`microtasks.${index}.testMCQ_question`, {
                    required: 'Please enter a question ',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.testMcq_question || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.testMCQ_question?.message}
                placeholder={'Question *'}
                transparentTextSize="text-sm"
                transparentFontWeight={'font-normal'}
                className={'w-[100%] mb-1 mt-2 '}
            />

            <div className="mt-2  items-center">
                <div className="flex items-center justify-end  gap-3  w-full">
                    <p>Correct Answer</p>
                </div>
                {testMCQ_Answers_Fields?.map((answerField, i) => {
                    return (
                        <div key={answerField.id} className="flex  gap-3 mb-3 w-full">
                            <p className="relative top-3">
                                <span className="text-primaryInput">Option {i + 1} :</span>
                            </p>
                            <div className="flex-1">
                                <InputForDragAndDrop
                                    name={`microtasks[${index}].testMCQ_answers[${i}].answer`}
                                    control={control}
                                    index={i}
                                    width="w-full"
                                    placeholder="Enter an option"
                                    error={
                                        errors?.microtasks
                                            ? errors?.microtasks[index]?.testMCQ_answers
                                                ? errors?.microtasks[index]?.testMCQ_answers[i]?.answer?.message
                                                : ''
                                            : ''
                                    }
                                    validateObject={{
                                        required: 'Please enter an option',
                                    }}
                                />
                            </div>
                            <div className="relative top-3">
                                <div className="flex  items-center  justify-between w-[70px]">
                                    <DeleteIcon
                                        className={`${i > 0 ? 'cursor-pointer ' : 'invisible'}`}
                                        onClick={() => removeAnswer(i)}
                                    />
                                    <input
                                        type="radio"
                                        name={`microtasks[${index}].testMCQ_correctAnswer`}
                                        value={i}
                                        {...register(`microtasks.${index}.testMCQ_correctAnswer`, {
                                            required: 'Please select the correct answer.',
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}

                <Button
                    type="button"
                    onClick={() => appendAnswer({ answer: '' })}
                    size="none"
                    className="  font-semibold !text-lightBlack text-sm"
                    variant="transparent"
                >
                    + Add Option
                </Button>
                <div>
                    {errors?.microtasks && (
                        <p className="text-foundryRed">{errors?.microtasks[index]?.testMCQ_answers?.root?.message}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TestMCQ;
