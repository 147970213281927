import mobileFrame from '../../../assets/mobileFrame.png';

function NoPreviewAvailable() {
    return (
        <div
            className="relative w-[300px] h-[610px]  justify-self-end   "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 ">
                <div>
                    <p className="text-xs italic mt-2  "> No Preview Available ,add microtask</p>
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                {/* <Button variant="preview" size="roundPreview">
                    Add Deliverables & Tips
                </Button> */}
            </footer>
        </div>
    );
}

export default NoPreviewAvailable;
