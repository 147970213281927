import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { getBountySubmissions, getValidationPreview } from '../../apis/bounties/bounties';
import Table from '../../ui/Table';
import Legend from '../../ui/Legend';
import NotIssuedIcon from '../../assets/icons/NotIssuedIcon';
import IssuedIcon from '../../assets/icons/IssuedIcon';
import RankOneIcon from '../../assets/icons/RankOneIcon';
import RankTwoIcon from '../../assets/icons/RankTwoIcon';
import RankThreeIcon from '../../assets/icons/RankThreeIcon';
import Button from '../../ui/Button';
import { toast } from 'sonner';
import Loader from '../../ui/Loader';
import ValidationCreationModal from './ValidationCreationModal';
import Modal from '../../ui/Modal';
import { useLoadingState } from '../../hooks/useLoader';
import { FirstRank, SecondRank, ThirdRank } from '../../assets/icons/RankIcons';
import NoDataAvailable from '../../ui/NoDataAvailable';
import { toNormalRepresentation } from '../../helpers/convertIntoDecimals';
import WalletConnectCustomButton from '../walletconnectUI/WalletCustomText';
import ConnectMetamaskWallet from '../../ui/ConnectMetamaskBtn';
import { useSDK } from '@metamask/sdk-react';
import { validating } from '../../apis/validator/validating';
import {
    fetchChainDetails,
    finaliseGrading,
    getERC20Balance,
    getMetaMaskProvider,
} from '../../blockchainInteraction/finaliseGrading';
import WhiteCard from '../../ui/WhiteCard';
import TextWithTooltip from '../../ui/TextWithTooltip';

const rank = {
    1: <FirstRank />,
    2: <SecondRank />,
    3: <ThirdRank />,
};
function RequestValidation() {
    const { bountyId } = useParams();
    const [bountyDetails] = useOutletContext();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { id } = useParams();
    const { loading: processing, startLoading: startProcessing, stopLoading: stopProcessing } = useLoadingState();
    const [account, setAccount] = useState();
    const [preview, setPreview] = useState({});
    const navigate = useNavigate();
    const { sdk, connected, connecting, provider, chainId } = useSDK();

    // useEffect(async () => {
    //     try {
    //         const accounts = await sdk?.connect();
    //         console.log(accounts);
    //         setAccount(accounts?.[0]);
    //     } catch (err) {
    //         console.warn('failed to connect..', err);
    //     }
    // }, [sdk]);
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();

                    const data = await getValidationPreview(bountyId);

                    setPreview(data);
                    stopLoading();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [bountyId]
    );
    const totalRewardAmount = preview?.rewardDistribution?.reduce((acc, item) => acc + item?.rewardAmount, 0);
    // const onSubmittingRequest = async () => {
    //     try {
    //         startProcessing();
    //         const accounts = await sdk?.connect();
    //         // if chain id is not same , we have to prompt the user to change the network right now just displaying a toast message
    //         console.log(preview?.tokenInfo?.chainId, chainId, accounts);
    //         if (preview?.tokenInfo?.chainId != chainId) {
    //             toast.error('Please change your chain network');
    //             return;
    //         }

    //         const payload = {
    //             tokenAddress: preview?.tokenInfo.address,
    //             rewardsDistribution: preview?.rewardDistribution,
    //             provider: provider,
    //             decimals: preview?.tokenInfo.decimals,
    //             sdk: sdk,
    //             wallet: preview?.wallet,
    //             platformFee: preview?.platformFee,
    //             bountyId: bountyId,
    //             chainId: preview?.tokenInfo?.chainId,
    //         };
    //         // wallet , platformFee

    //         const balance = await getERC20Balance(payload?.tokenAddress, payload?.wallet, payload?.chainId);

    //         if (balance > totalRewardAmount + payload?.platformFee) {
    //             toast.error('Not enough token in your wallet');
    //             return;
    //         }
    //         console.log(balance, 'see the balance');
    //         const response2 = await finaliseGrading(payload);
    //         const response = await validating(bountyId);

    //         console.log(accounts, response);
    //         setAccount(accounts?.[0]);
    //     } catch (err) {
    //         console.warn('failed to connect..', err);
    //     } finally {
    //         stopProcessing();
    //     }
    // };

    const onSubmittingRequest = async () => {
        try {
            startProcessing();

            // Get MetaMask provider
            const metaMaskProvider = getMetaMaskProvider();
            if (!metaMaskProvider) {
                const installUrl = 'https://metamask.io/download.html';
                toast.info('Opening MetaMask installation page in a new tab...');
                window.open(installUrl, '_blank'); // Open the URL in a new tab
                // toast.error('MetaMask is not installed or unavailable');
                return;
            }

            // Request accounts using MetaMask provider
            const accounts = await metaMaskProvider.request({ method: 'eth_requestAccounts' });
            if (!accounts || accounts.length === 0) {
                toast.error('Failed to connect wallet');
                return;
            }

            // Detect chain ID
            const userChainId = await metaMaskProvider.request({ method: 'eth_chainId' });
            const targetChainId = `0x${parseInt(preview?.tokenInfo?.chainId, 10).toString(16)}`; // Convert to hex string

            if (userChainId !== targetChainId) {
                try {
                    // Attempt to switch to the correct chain
                    await metaMaskProvider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: targetChainId }],
                    });
                    toast.success(`Switched to chain ID: ${targetChainId}`);
                } catch (switchError) {
                    // Handle the case where the chain is not added or user rejects the switch

                    if (switchError.code === 4902) {
                        // 4902: Chain not added to MetaMask
                        try {
                            const chainDetails = await fetchChainDetails(preview?.tokenInfo?.chainId);
                            await metaMaskProvider.request({
                                method: 'wallet_addEthereumChain',
                                params: [chainDetails],
                            });
                            toast.success(`Added and switched to chain ID: ${targetChainId}`);
                        } catch (addError) {
                            toast.error('Failed to add or switch to the required chain');
                            return;
                        }
                    } else {
                        toast.error('Please change your chain network manually');
                        return;
                    }
                }
            }

            // Prepare payload
            const payload = {
                tokenAddress: preview?.tokenInfo?.address,
                rewardsDistribution: preview?.rewardDistribution,
                provider: provider,
                decimals: preview?.tokenInfo.decimals,
                sdk: sdk,
                wallet: preview?.wallet,
                platformFee: Number(toNormalRepresentation(preview?.platformFee)),
                bountyId: bountyId,
                chainId: preview?.tokenInfo?.chainId,
            };

            // Fetch balance
            // const balance = await getERC20Balance(payload?.tokenAddress, accounts?.[0], preview?.tokenInfo?.chainId);
            // console.log(balance);
            // if (balance < totalRewardAmount + payload?.platformFee) {
            //     toast.error('Not enough token in your wallet');
            //     return;
            // }

            // console.log(balance, 'see the balance');
            const { success, code } = await finaliseGrading(payload);

            if (!success) {
                // toast.error(`May be winner has not linked metamask wallet in his "Impact Miner" account`);
                toast.error(code);
                return;
            }
            if (success) {
                const response = await validating(bountyId);

                setAccount(accounts?.[0]);
                navigate(-2);
            }
        } catch (err) {
            console.warn('Failed to connect..', err);
            toast.error('An error occurred while processing your request');
        } finally {
            stopProcessing();
        }
    };

    return (
        <div>
            {loading ? (
                <div className="h-40 col-span-2 bg-white flex mt-6 items-center justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <Table className=" items-center gap-4 " cols="grid-cols-12">
                        <Table.Header>
                            <p className="text-sm   font-semibold text-secondaryText "> Rank </p>
                            <p className="text-sm col-span-2  font-semibold text-secondaryText "> Submission ID </p>
                            <p className="text-sm justify-self-center  font-semibold text-secondaryText ">Score</p>
                            <p className="text-sm justify-self-center col-span-2 font-semibold text-secondaryText ">
                                Reward Token
                            </p>
                            <p className="text-sm justify-self-center col-span-2  font-semibold text-secondaryText ">
                                Impact Points
                            </p>
                            <p className="text-sm col-span-3  font-semibold text-secondaryText ">Impact Certificate</p>
                        </Table.Header>
                        {preview?.rewardDistribution?.length > 0 ? (
                            preview?.rewardDistribution?.map((submission, index) => (
                                <Table.Row key={submission.submissionId}>
                                    <p className="     uppercase justify-self-start  ">
                                        <span>{index + 1 <= 3 && rank[index + 1]}</span>
                                    </p>
                                    <p
                                        onClick={() =>
                                            navigate(
                                                `/organizations/${id}/validation/bounty-submissions/${bountyId}/${submission.submissionId}`
                                            )
                                        }
                                        className=" col-span-2 text-primaryColor flex items-center cursor-pointer  uppercase justify-self-start font-medium  "
                                    >
                                        <span className="truncate w-32">{submission.submissionId} </span>{' '}
                                        {/* <span>{index + 1 <= 3 && rank[index + 1]}</span> */}
                                    </p>
                                    <p className="justify-self-center  ">
                                        {submission.accuracy
                                            ? toNormalRepresentation(Number(submission.accuracy) * 100)
                                            : '-'}
                                    </p>
                                    <p className="justify-self-center col-span-2  ">
                                        {' '}
                                        <span>{toNormalRepresentation(submission.rewardAmount)} </span>{' '}
                                        <span>{preview.rewardTokenSymbol}</span>{' '}
                                    </p>
                                    <p className="justify-self-center col-span-2  ">
                                        {submission.impactPoints ? Number(submission.impactPoints).toFixed(2) : '-'}
                                    </p>
                                    <p className="col-span-3 ">
                                        {submission?.issueImpactCertificate ? <IssuedIcon /> : <NotIssuedIcon />}
                                    </p>
                                </Table.Row>
                            ))
                        ) : (
                            <NoDataAvailable text="No person will be awarded upon validation" />
                        )}
                    </Table>
                    {preview?.rewardDistribution?.length > 0 && (
                        <WhiteCard className="px-6 py-5">
                            <div className="mb-3">
                                <div className="flex items-center mb-2 justify-between">
                                    <p className="text-primaryInput text-xl">Amount disbursed</p>
                                    <div className="flex items-center gap-1">
                                        <div className="relative">
                                            <img
                                                className="w-5 h-5 rounded-full "
                                                src={preview?.tokenInfo?.image}
                                                alt=""
                                            />
                                            <img
                                                className="w-2 h-2 absolute top-0 right-0 rounded-full "
                                                src={preview?.tokenInfo?.chainImage}
                                                alt=""
                                            />
                                        </div>
                                        <p className="text-primaryInput font-medium text-xl">{totalRewardAmount}</p>
                                    </div>
                                </div>
                                <div className="flex items-center  mb-2 justify-between">
                                    <TextWithTooltip
                                        heading={<p className="text-primaryInput text-xl">Platform Fee</p>}
                                    >
                                        <p className="text-sm">
                                            We charge a 0.5% platform fee on the total disbursed amount
                                        </p>
                                    </TextWithTooltip>

                                    <div className="flex items-center gap-1">
                                        <div className="relative">
                                            <img
                                                className="w-5 h-5 rounded-full "
                                                src={preview?.tokenInfo?.image}
                                                alt=""
                                            />
                                            <img
                                                className="w-2 h-2 absolute top-0 right-0 rounded-full "
                                                src={preview?.tokenInfo?.chainImage}
                                                alt=""
                                            />
                                        </div>
                                        <p className="text-primaryInput font-medium text-xl">{preview?.platformFee}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center pt-3 border-t border-borderColor   justify-between">
                                <p className="text-primaryInput text-2xl font-semibold">Total</p>
                                <div className="flex items-center gap-1">
                                    <div className="relative">
                                        <img className="w-5 h-5 rounded-full " src={preview?.tokenInfo?.image} alt="" />
                                        <img
                                            className="w-2 h-2 absolute top-0 right-0 rounded-full "
                                            src={preview?.tokenInfo?.chainImage}
                                            alt=""
                                        />
                                    </div>

                                    <p className="text-primaryInput font-bold text-xl">
                                        {totalRewardAmount + preview?.platformFee}
                                    </p>
                                </div>
                            </div>
                        </WhiteCard>
                    )}
                    <div className="flex justify-end items-center">
                        <div className="30% ">
                            <Button onClick={onSubmittingRequest} size="xsmall">
                                {processing ? <Loader /> : 'Process Payout'}
                            </Button>
                        </div>
                    </div>
                    <div className="flex items-center justify-end mt-6">
                        <div className="flex items-center gap-3 ">
                            <p className="text-sm font-bold">Impact Certificate</p>
                            <Legend shield={<NotIssuedIcon />} text={'Not Issued'} />
                            <Legend shield={<IssuedIcon />} text={'Issued'} />
                        </div>
                    </div>
                    <div className="flex justify-end items-center">
                        {/* <Modal>
                            <Modal.Button
                                className="flex gap-2 cursor-pointer items-center"
                                opens={'validationrequest'}
                            >
                                <Button size="xsmall">Submit Request</Button>
                            </Modal.Button>
                            <Modal.Window name={'validationrequest'}>
                                <ValidationCreationModal
                                    infoRequired={{ lastUpdateTime: bountyDetails?.maxUpdateTime }}
                                />
                            </Modal.Window>
                        </Modal> */}

                        {/* <div className="30%">
                            {false ? (
                                <Button onClick={() => {}} size="small">
                                    {false ? <Loader /> : 'Submit Request'}
                                </Button>
                            ) : (
                                <div className="w-full flex items-center">
                                    <ConnectMetamaskWallet />
                                </div>
                            )}
                        </div> */}
                    </div>
                </>
            )}
        </div>
    );
}

export default RequestValidation;
// {
// 	"organisationId": "6606d2c0ed51ae4d31a51ae3",
// 	"requestType": "BOUNTY_VALIDATION", // OR "BOUNTY_CREATION
// 	"bountyId": "6606d2c0ed51ae4d31a51gdsg",
// 	// need to send the max value of `lastUpdateTime` from the response of GET /organisation/validation/submission/<submissionId>
// 	"lastUpdateTime": "2024-03-29T14:40:00.179Z"
// }
