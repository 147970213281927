import { useController, useFieldArray } from 'react-hook-form';
import DeleteIcon from '../../../assets/icons/Delete';
import InputForDragAndDrop from '../../../ui/InputForDragAndDrop';
import Input from '../../../ui/Input';
import Button from '../../../ui/Button';
import IsSkippable from './IsSkippable';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useContext, useEffect } from 'react';

const Mcq = ({ register, index, errors, control, setValue, getValues, microtaskEditDetail }) => {
    const { isEdit } = useContext(BountiesContext);
    const {
        fields: mcqAnswers_Fields,
        append: appendAnswer,
        remove: removeAnswer,
    } = useFieldArray({
        name: `microtasks[${index}].multiChoiceQuestions_answers`,
        control,
    });
    useEffect(() => {
        // Run this logic only once when the component is mounted
        if (isEdit && mcqAnswers_Fields.length === 0) {
            // If in edit mode, populate the answers from microtaskEditDetail
            const editAnswers = microtaskEditDetail?.multiChoiceQuestions_answers?.split(',,') || [];
            editAnswers.forEach((a) => {
                appendAnswer({ answer: a });
            });
            // microtasks[${index}].multiChoiceQuestions_answers = "j"

            setValue(
                `microtasks.${index}.multiChoiceQuestions_instructionHeading`,
                microtaskEditDetail?.multiChoiceQuestions_instructionHeading
            );
            setValue(
                `microtasks.${index}.multiChoiceQuestions_instructions`,
                microtaskEditDetail?.multiChoiceQuestions_instructions
            );
        } else if (!isEdit && mcqAnswers_Fields.length === 0) {
            // If not in edit mode, ensure there is at least one empty answer field
            appendAnswer({ answer: '' });
        }
    }, [isEdit, mcqAnswers_Fields.length, appendAnswer, microtaskEditDetail]);
    // if (mcqAnswers_Fields.length === 0) {
    //     appendAnswer({ answer: '' }); // Append default value if no fields exist
    // }

    return (
        <div>
            <Input
                {...register(`microtasks.${index}.multiChoiceQuestions_Title`, {
                    required: 'Please enter a title ',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.multiChoiceQuestions_Title || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.multiChoiceQuestions_Title?.message}
                placeholder={'Title *'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 '}
            />
            <Input
                {...register(`microtasks.${index}.multiChoiceQuestions_question`, {
                    required: 'Please enter a question',
                })}
                type="transparent"
                error={errors?.microtasks && errors?.microtasks[index]?.multiChoiceQuestions_question?.message}
                {...(isEdit && { defaultValue: microtaskEditDetail?.multiChoiceQuestions_question || '' })}
                placeholder={'Ask a Question *'}
                transparentTextSize="text-sm"
                transparentFontWeight={'font-normal'}
                className={'w-[100%] mb-1 mt-2 '}
            />

            <div className="mt-2  items-center">
                {mcqAnswers_Fields?.map((mcqAnswerField, i) => {
                    return (
                        <>
                            <div key={mcqAnswerField.id} className="flex  gap-3 mb-3 w-full">
                                <p className="w-[85px] relative top-3 ">
                                    <span>Option {i + 1} :</span>
                                    {/* <span>{i + 1}</span> */}
                                </p>
                                <div className="flex-1">
                                    <InputForDragAndDrop
                                        name={`microtasks[${index}].multiChoiceQuestions_answers[${i}].answer`}
                                        control={control}
                                        index={i}
                                        width="w-full"
                                        placeholder="Enter an option"
                                        error={
                                            errors?.microtasks
                                                ? errors?.microtasks[index]?.multiChoiceQuestions_answers
                                                    ? errors?.microtasks[index]?.multiChoiceQuestions_answers[i]?.answer
                                                          ?.message
                                                    : ''
                                                : ''
                                        }
                                        validateObject={{
                                            required: 'Please enter an option',
                                            // maxLength: {
                                            //     value: 200,
                                            //     message: 'The maximum length is 200 characters',
                                            // },
                                        }}
                                    />
                                </div>

                                <DeleteIcon
                                    className={`${i > 0 ? 'cursor-pointer relative top-3 ' : 'invisible'}`}
                                    onClick={() => removeAnswer(i)}
                                />
                            </div>
                        </>
                    );
                })}

                <Button
                    type="button"
                    onClick={() => {
                        appendAnswer({
                            answer: '',
                        });
                    }}
                    size="none"
                    className="  font-semibold !text-lightBlack text-sm"
                    variant="transparent"
                >
                    + Add Option
                </Button>
                <div>
                    {errors?.microtasks && (
                        <p className="text-foundryRed">
                            {errors?.microtasks[index]?.multiChoiceQuestions_answers?.root?.message}
                        </p>
                    )}
                </div>
            </div>
            {/* 
            <Input
                {...register(`microtasks.${index}.multiChoiceQuestions_instructionHeading`, {
                    required: 'Please enter heading for  MCQ.',
                })}
                type="transparent"
                error={
                    errors?.microtasks && errors?.microtasks[index]?.multiChoiceQuestions_instructionHeading?.message
                }
                placeholder={'Heading for  MCQ...'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 mt-2 '}
            /> */}

            {/* <div className="mt-2  items-center">
                {mcq_Fields?.map((mcqField, i) => {
                    return (
                        <>
                            <div key={mcqField.id} className="flex items-center gap-3 mb-3 w-full">
                                <p>
                                    <span>Instruction :</span> <span>{i + 1}</span>
                                </p>
                                <InputForDragAndDrop
                                    name={`microtasks[${index}].multiChoiceQuestions_instructions[${i}].instruction`}
                                    control={control}
                                    index={i}
                                    width="w-[50%]"
                                    placeholder="instruction"
                                />
                                <DeleteIcon onClick={() => removeInstruction(i)} />
                            </div>
                        </>
                    );
                })}

                <Button
                    type="button"
                    onClick={() => {
                        appendInstruction({
                            instruction: '',
                        });
                    }}
                    size="none"
                    className="  font-semibold !text-lightBlack text-sm"
                    variant="transparent"
                >
                    + Add Instruction
                </Button>
                <div>
                    {errors?.microtasks && (
                        <p className="text-foundryRed">{errors?.microtasks[index]?.instruction_links?.root?.message}</p>
                    )}
                </div>
            </div> */}
            {/* <div className="flex gap-9 my-2 items-center ">
                <IsSkippable
                    selectedOption={skippableField.value}
                    onSelectOption={(value) => {
                        skippableField.onChange(value);
                    }}
                    options={['Yes', 'No']}
                />
            </div> */}
        </div>
    );
};

export default Mcq;
