import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Table from '../../ui/Table';
import AvatarImage from '../../ui/AvatarImage';
import Tag from '../../ui/Tag';
import Dropdown from '../../ui/Dropdown';
import { useEffect, useState } from 'react';
import { deleteInvitation, executeInvitation, getAllInvitations, getInvitationHistory } from '../../apis/user/user';
import Button from '../../ui/Button';
import TabSwitching from '../../ui/TabSwitching';
import { useActive } from '../../hooks/useActive';
import Checkbox from '../../ui/Checkbox';
import DeleteIcon from '../../assets/icons/Delete';
import { toast } from 'sonner';
import Modal from '../../ui/Modal';
import DeleteInvitationModal from './DeleteInvitationModal';
import { dmyFormat } from '../../helpers/dateInDayMonthYearFormat';
import { executorToAddMemberToOrg } from '../../blockchainInteraction/addMembers';
import { Connection } from '@solana/web3.js';
import { clusterApiUrl } from '@solana/web3.js';
import { getProvider } from '../../helpers/getProvider';
import ExecuteButton from '../../ui/ExecuteButton';
import { useWallet } from '@solana/wallet-adapter-react';
import Loader from '../../ui/Loader';
import NoDataAvailable from '../../ui/NoDataAvailable';
import OwnButton from '../walletconnectUI/OwnButton';
import WalletConnectCustomButton from '../walletconnectUI/WalletCustomText';
import { RPC_ENDPOINT } from '../../constants/apiPaths';

const selectRoles = ['Bounty Manager', 'Validator', 'Bug Report', 'UI Suggestion'];
const selectStatus = ['Accepted', 'Pending', 'Declined'];

function InvitationsList({ screen, organisation, user }) {
    const [activeTab, handleActiveTab] = useActive(screen);
    const { publicKey } = useWallet();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [invitationsHistory, setInvitationsHistory] = useState([]);
    const [filteredInvitations, setFilteredInvitations] = useState([]);
    const [selected, setSelected] = useState([]);
    const { id } = useParams();
    const address = publicKey;

    useEffect(function () {
        const fetchData = async () => {
            try {
                const data = await getAllInvitations(id);

                setInvitationsHistory(data);
                const acceptedInvitations = data.filter((invite) => {
                    return invite.status === activeTab.toUpperCase();
                });

                setFilteredInvitations(acceptedInvitations);
                setSelected(acceptedInvitations);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);
    function updateValue(value, name) {
        if (value) setSelected([...selected, name]);
        else {
            setSelected(selected.filter((item) => item.uid !== name.uid));
        }
    }

    const onExecutionToAddTreasurers = async () => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        const payloadArray = selected.map((item) => item._id);
        const walletAdresses = selected.map((item) => item.walletAddress);
        const payload = {
            organisationId: id,
            invitations: payloadArray,
        };

        try {
            const transaction = await executorToAddMemberToOrg(
                walletAdresses,
                user?.walletAddress,
                organisation?.treasurerMultisig,
                connection
            );

            const { signature } = await getProvider().signAndSendTransaction(transaction);

            await connection.getSignatureStatus(signature);

            const response = await executeInvitation(payload);
            toast.success('The member is now added to organisation as treasury. ');
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    };

    const handleTabFilter = (tab) => {
        handleActiveTab(tab);

        const invitationsAsActiveTab = invitationsHistory.filter((invite, index) => {
            return invite.status === tab.toUpperCase();
        });

        setFilteredInvitations(invitationsAsActiveTab);
    };

    const onDelete = (id) => {
        setFilteredInvitations((initial) => initial.filter((member) => member._id !== id));
        setInvitationsHistory((initial) => initial.filter((member) => member._id !== id));
    };
    function selectAll(value) {
        if (value) setSelected(filteredInvitations.map((member) => member));
        else setSelected([]);
    }

    return (
        <>
            <Table
                cols={` ${
                    activeTab === 'Accepted'
                        ? 'grid-cols-tableInvitations'
                        : '  grid-cols-tableInvitationsWithoutCheckbox '
                }              `}
            >
                <div className="flex mb-3   ">
                    <TabSwitching
                        activeTab={activeTab}
                        onSelectingActive={handleTabFilter}
                        // firstTab={'Accepted'}
                        // secondTab={'Sent'}
                        // thirdTab={'Expired'}
                        // fourthTab={'Declined'}
                        firstTab={'Sent'}
                        secondTab={'Expired'}
                        thirdTab={'Declined'}
                        activeStyling={'text-lightBlack border-primaryColor  '}
                        firstTabStyle={'rounded-e-none  '}
                        secondTabStyle={'rounded-none  '}
                        thirdTabStyle="rounded-none "
                        fourthTabStyle="rounded-s-none "
                        className={'text-sm   font-medium border-2 border-borderColor p-1 leading-6  px-4 rounded-md '}
                    />
                </div>
                <div className="flex justify-between rounded">
                    <Table.Description description="Invitations" />
                </div>
                <Table.Header>
                    {activeTab === 'Accepted' && (
                        <Checkbox
                            name="all"
                            value={selected.length === filteredInvitations.length}
                            update={() => selectAll(!(selected.length === filteredInvitations.length))}
                        />
                    )}
                    <p className="text-sm font-semibold text-secondaryText ">Citizen ID</p>
                    <p className="text-sm font-semibold text-secondaryText ">Member Username</p>
                    <p className="text-sm  font-semibold text-secondaryText ">Roles</p>
                    <p className="text-sm  font-semibold text-secondaryText ">Invited On</p>
                    <p className="text-sm  font-semibold text-secondaryText ">Remove</p>
                </Table.Header>
                {isLoading ? (
                    <div className="min-h-[20vh] flex justify-center items-center">
                        <Loader />
                    </div>
                ) : filteredInvitations.length ? (
                    filteredInvitations.map((member, index) => {
                        return (
                            <Table.Row key={member._id + index}>
                                {activeTab === 'Accepted' && (
                                    <Checkbox
                                        name={member.userName}
                                        value={selected.map((user) => user.uid).includes(member.uid)}
                                        update={() => {
                                            updateValue(!selected.map((user) => user.uid).includes(member.uid), member);
                                        }}
                                    />
                                )}
                                <p className="truncate w-28 ">{member.uid || '-'}</p>
                                <div className="flex items-center gap-1 ">
                                    {member.uid ? (
                                        <>
                                            <AvatarImage w="w-8" h="h-8" url={member.profileImage} />
                                            <p className="text-lightBlack font-medium ">{member.userName}</p>
                                        </>
                                    ) : (
                                        <p className="text-lightBlack font-medium ">{member.email}</p>
                                    )}
                                </div>

                                <div className="flex items-center  gap-6 ">
                                    {member.roles.includes('BOUNTY_VALIDATOR') && (
                                        <Tag
                                            rounded="rounded-lg "
                                            className="px-2 py-1 leading-3   "
                                            textClassName="text-xs font-semibold"
                                            bg="bg-foundryGreenL"
                                            textColor="text-foundryGreen"
                                            text={'Validator'}
                                        />
                                    )}
                                    {member.roles.includes('BOUNTY_MANAGER') && (
                                        <Tag
                                            rounded="rounded-lg"
                                            className="px-2 py-1 leading-3   "
                                            textClassName="text-xs font-semibold"
                                            bg="bg-foundryRedL"
                                            textColor="text-foundryRed"
                                            text={'Bounty Manager'}
                                        />
                                    )}
                                    {member.roles.includes('TREASURER') && (
                                        <Tag
                                            rounded="rounded-lg"
                                            className="px-2 py-1 leading-3   "
                                            textClassName="text-xs font-semibold"
                                            bg="bg-treasurerBg"
                                            textColor="text-treasurerText"
                                            text={'Treasurer'}
                                        />
                                    )}
                                </div>
                                <p>{dmyFormat(member.createdAt)}</p>

                                <Modal>
                                    <Modal.Button
                                        className="flex gap-2 cursor-pointer items-center"
                                        opens={'deleteinvitation'}
                                    >
                                        <p className=" place-self-center cursor-pointer ">
                                            <DeleteIcon />
                                        </p>
                                    </Modal.Button>
                                    <Modal.Window name={'deleteinvitation'}>
                                        <DeleteInvitationModal onDelete={onDelete} member={member} />
                                    </Modal.Window>
                                </Modal>
                            </Table.Row>
                        );
                    })
                ) : (
                    <NoDataAvailable />
                )}
            </Table>

            {/* {activeTab === 'Accepted' && (
                <div className="flex justify-end">
                    {address ? (
                        <div>
                            <Button
                                onClick={onExecutionToAddTreasurers}
                                disabled={!selected.length}
                                className={` px-5   ${
                                    selected.length ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-400 '
                                }`}
                            >
                                Add to Organisation
                            </Button>
                        </div>
                    ) : (
                        // <OwnButton className={'pl-3 pr-3'} text="Add to Organisation" />

                        <div className="w-full flex items-center justify-end">
                            <WalletConnectCustomButton
                                customText={' Add to Organisation'}
                                width="174px"
                                customStyles={{
                                    backgroundColor: 'var(--primary-color)',
                                    color: '#fff',
                                    border: '1px solid #dcdcdc',
                                    lineHeight: 'normal',
                                    fontWeight: '400',
                                    paddingLeft: '12px',
                                    paddingRight: '12px',
                                    height: '40px',
                                    borderRadius: '8px',
                                    flexGrow: 1,
                                }}
                                connectedText={' Add to Organisation'}
                                disconnectedText=" Add to Organisation"
                                removeIconOnDisconnect={true}
                                removeIconOnConnect={true}
                                removeIconOnWalletSelect={true}
                                removeIconOnConnecting={true}
                            />
                        </div>
                    )}
                </div>
            )} */}
        </>
    );
}

export default InvitationsList;
