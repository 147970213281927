import StepCompleted from '../assets/icons/StepCompleted';
const data = [
    {
        label: 'Draft',
        definition: ' Bounty details pending, complete draft to propose bounty creation',
    },
    // {
    //     label: 'Creation Requested',
    //     definition: 'Proposal to create the bounty has been shared for voting',
    // },
    {
        label: 'Live',
        definition:
            'Bounty is deployed and live, ready to receive submissions. Bounty can be published (default) or unpublished',
    },

    {
        label: 'Unvalidated',
        definition: 'Bounty expired, validations are pending',
    },
    // {
    //     label: 'Validation Requested',
    //     definition: ' Proposal to validate the grading done on bounty submissions has been shared for voting ',
    // },
    {
        label: 'Completed',
        definition: 'Bounty has been executed, submissions paid and impact created',
    },
];

const Stepper = ({ rounded, className, bg, textColor, textClassName, text, borderColor, active }) => {
    return (
        <ol class="relative  text-gray-500  text-secondaryInput border-s border-gray-200 ">
            {data.map((status, index) => {
                const isActive = active === status.label;
                const activeIndex = data.findIndex((item) => item.label === active);
                return (
                    <li key={status.label} className="mb-6 last:mb-4 first:mt-1 ms-6">
                        <span
                            className={`absolute  flex ${
                                activeIndex === index
                                    ? ` ${borderColor} font-semibold  ${textColor}  `
                                    : 'ring-borderColor font-medium text-primaryInput '
                            }   items-center  justify-center w-8 h-8 bg-white rounded-full -start-4 ring-1  `}
                        >
                            {activeIndex <= index ? index + 1 : <StepCompleted fillColor="rgba(64, 64, 64, 0.6)" />}
                        </span>

                        <p
                            className={`font-medium text-sm pt-1 leading-normal ${
                                isActive ? textColor : 'text-[#A1A1AA'
                            } `}
                        >
                            <span
                                className={` text-sm  ${
                                    isActive ? ` underline  underline-offset-4  font-semibold  ` : ''
                                } `}
                            >
                                {' '}
                                {status.label}
                                {isActive && ':'}
                            </span>
                            {isActive && <span className="text-sm"> {status.definition}</span>}
                        </p>
                    </li>
                );
            })}
        </ol>
    );
};

export default Stepper;
