import { useEffect, useState } from 'react';
import AvatarImage from '../../ui/AvatarImage';
import Heading from '../../ui/Heading';
import Table from '../../ui/Table';
import Tag from '../../ui/Tag';
import WhiteCard from '../../ui/WhiteCard';

import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../ui/Loader';
import { EarthIcon, FistIcon, SunIcon, WaterIcon } from '../../assets/icons/ProgressBarIcons';
import { EVENT_STATUS_TAG_COLOR, SDG_IMAGES } from '../../constants/datas';
import BountiesInEvent from './BountiesInEvent';
import { getEvent } from '../../apis/events/getEvent';
import Toggle from '../../ui/Toggle';
import { toggleEvent } from '../../apis/events/togglePublish';
import Button from '../../ui/Button';
import { toast } from 'sonner';
import LinkRenderer from '../../ui/LinkRenderer';

const coreIcons = {
    Earth: <EarthIcon key={'earth-icon'} color="" />,
    Energy: <SunIcon key="sun-icon" color="" />,
    Social: <FistIcon key={'fist-icon'} color="" />,
    Water: <WaterIcon key={'water-icon'} color="" />,
};
function ParticularEvent() {
    const [event, setEvent] = useState({});
    const [isPublished, setIsPublished] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();
    const { eventId } = useParams();

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getEvent(eventId);
                    setIsPublished(() => (data?.isLive ? 'Live' : 'Unpublished'));
                    setEvent(data);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [eventId]
    );

    if (!Object.keys(event).length) return <Loader />;
    return (
        <>
            <WhiteCard className="p-6 grid grid-cols-4">
                <div className="col-span-4 mb-6">
                    <div className="flex  items-start justify-between mb-6">
                        <div>
                            <p className="font-semibold text-secondaryInput">Event Title</p>
                            <Heading className={'text-primaryInput font-medium'} type={'large'}>
                                {event?.name}
                            </Heading>
                        </div>
                        {isPublished === 'Unpublished' && (
                            <Button
                                onClick={() => navigate(`/${id}/createEvent?eventId=${eventId}`)}
                                className=" w-44 text-end"
                                size="none"
                                variant="transparent"
                            >
                                Edit Event {'->'}
                            </Button>
                        )}
                        {isPublished === 'Live' && (
                            <Button
                                onClick={() => toast.error('Please unpublish the Event first')}
                                className=" w-44 text-end"
                                size="none"
                                variant="transparent"
                            >
                                Edit Event {'->'}
                            </Button>
                        )}
                    </div>
                    {/* <div className="flex items-center gap-9">
                        <div className="">
                            <p className="font-semibold text-secondaryInput">event ID</p>
                            <p className="font-semibold text-primaryInput uppercase">{event._id}</p>
                        </div>
                    </div> */}
                </div>

                <div className="col-span-2 mr-8">
                    <p className="font-semibold text-secondaryInput">Description on Impact Miner</p>
                    <LinkRenderer
                        text={event?.sections[0]?.content}
                        links={[]}
                        paragraphClass="text-primaryInput !text-base"
                        dummyText="description"
                    />
                </div>
                <div className="col-span-2 flex gap-8">
                    <div className="">
                        <p className="font-semibold text-secondaryInput">Status</p>

                        <Tag
                            rounded="rounded-lg "
                            className="px-2 py-1 leading-3 self-center   "
                            textClassName="text-xs font-semibold"
                            bg={EVENT_STATUS_TAG_COLOR[isPublished]?.bg}
                            textColor={EVENT_STATUS_TAG_COLOR[isPublished]?.text}
                            text={isPublished}
                        />
                    </div>

                    <div className="">
                        <p className="font-semibold text-secondaryInput">Publish</p>
                        <Toggle
                            valuee={isPublished === 'Live' ? true : false}
                            onChangeFn={async () => {
                                if (isPublished === 'Live') {
                                    setIsPublished('Unpublished');
                                }
                                if (isPublished === 'Unpublished') {
                                    setIsPublished('Live');
                                }

                                try {
                                    const res = await toggleEvent(event._id);
                                } catch (error) {
                                    console.log(error);
                                }
                            }}
                        />
                    </div>
                    <div className=" w-[166px]">
                        <p className="font-semibold text-secondaryInput">Banner</p>
                        <div className=" rounded-md overflow-hidden h-16">
                            <img className="w-full h-full " src={event?.image} alt="" />
                        </div>
                    </div>
                </div>
            </WhiteCard>
            <BountiesInEvent eventTitle={event?.name} />
        </>
    );
}

export default ParticularEvent;
