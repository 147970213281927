import { useWallet } from '@solana/wallet-adapter-react';
import WalletConnectButton from '../features/walletconnectUI/WalletConnectButton';
import { useLoadingState } from '../hooks/useLoader';
import Button from '../ui/Button';
import Loader from '../ui/Loader';
import { updateProfile } from '../apis/user/user';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import AvatarImage from '../ui/AvatarImage';
import { toast } from 'sonner';
import walletimage from '../assets/walletimage.png';
import { auth } from '../firebase';
function ConnectFirst() {
    const { publicKey, disconnect, connect } = useWallet();
    const [walletLinked, setWalletLinked] = useState(false);
    const { loading: requesting, startLoading: startRequesting, stopLoading: stopRequesting } = useLoadingState();
    const { state, dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const address = publicKey?.toBase58();
    const userProfile = state.user[0];
    // if  have wallet address redirect to home page

    const walletLinkedRef = useRef(false); // Tracks profile creation state across renders

    useEffect(() => {
        // Update the ref whenever `walletLinked` state changes
        walletLinkedRef.current = walletLinked;
    }, [walletLinked]);
    useEffect(function () {
        return () => {
            // If profile has not been created, log out user on component unmount
            if (!walletLinkedRef.current) {
                const handleLogout = async () => {
                    try {
                        await auth.signOut();
                        await disconnect();

                        dispatch({ type: 'logout' });
                    } catch (error) {
                        console.error('Error signing out:', error);
                    }
                };
                handleLogout();
            }
        };
    }, []);

    const handleChangeWalletAddress = async () => {
        try {
            await disconnect(); // Disconnect the current wallet address
        } catch (error) {
            console.error('Failed to change wallet address:', error);
        }
    };

    return (
        <div className="grid min-h-screen grid-cols-1 place-items-center justify-items-center">
            <div className="w-[480px]">
                {address ? (
                    <div className="flex flex-col items-center">
                        <div className="flex justify-center mb-[37px] gap-3 items-center">
                            <AvatarImage w="w-7" h="h-7" url={userProfile.profileImage} />
                            <p className="capitalize  font-medium">{userProfile.userName}</p>
                        </div>

                        <p className={`text-xl text-center ${address ? '' : ''} mb-[24px] `}>
                            In order to access the foundry you need to connect a wallet and link it to your profile.{' '}
                        </p>
                        {address && (
                            <div className="flex gap-2  mb-11 ">
                                <img className="w-7 h-7 object-cover rounded-full" src={walletimage} alt="" />
                                <div>
                                    <p className={`   text-center `}>
                                        <span className=" font-semibold">Linked Wallet:</span>
                                        <span>
                                            {address.slice(0, 4)}...{address.slice(-4)}
                                        </span>
                                        <span
                                            onClick={handleChangeWalletAddress}
                                            className="text-primaryColor cursor-pointer ml-2 underline font-bold"
                                        >
                                            change
                                        </span>{' '}
                                    </p>
                                </div>
                            </div>
                        )}
                        <Button
                            className="self-center !w-[80%]"
                            onClick={async () => {
                                try {
                                    startRequesting();
                                    const response = await updateProfile(address);

                                    if (response.code === 400) throw new Error(response.message);
                                    if (response) {
                                        dispatch({ type: 'updateThroughMiner', payload: address });
                                        setWalletLinked(true);
                                        // navigate(-1);
                                        navigate('/');
                                    }
                                } catch (error) {
                                    toast.error(error);
                                    await disconnect();
                                }
                                stopRequesting();
                            }}
                            size="large"
                        >
                            {!requesting ? 'Link Wallet to Profile' : <Loader />}{' '}
                        </Button>
                    </div>
                ) : (
                    <div className="flex flex-col items-center ">
                        <div className="flex justify-center mb-[37px] gap-3 items-center">
                            <AvatarImage w="w-7" h="h-7" url={userProfile.profileImage} />
                            <p className="capitalize  font-medium">{userProfile.userName}</p>
                        </div>

                        <p className={`text-xl text-center ${address ? '' : ''} mb-[44px] `}>
                            In order to access the foundry you need to connect a wallet and link it to your profile.{' '}
                        </p>

                        <WalletConnectButton widthPer={'249px'} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ConnectFirst;
