import { useEffect, useState } from 'react';
import SortIcon from '../../assets/icons/SortIcon';
import Loader from '../../ui/Loader';
import NoDataAvailable from '../../ui/NoDataAvailable';
import Table from '../../ui/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { dmyFormat } from '../../helpers/dateInDayMonthYearFormat';
import { getSubmissionsForAttestation } from '../../apis/needAttestation/needAttestation';
import { ATTESTATION_STATUS } from '../../iconsBasedOnStatus/attestationStatus';
import GreyThumbIcon from '../../assets/icons/GreyThumbIcon';
import Legend from '../../ui/Legend';
import OrangeThumbIcon from '../../assets/icons/OrangeThumbIcon';
import GreenThumbIcon from '../../assets/icons/GreenThumbIcon';
import RequestForAttestationModal from './RequestForAttestationModal';
import Modal from '../../ui/Modal';

function AttestationRequestsInsideOrganisation() {
    const [attestations, setAttestations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getSubmissionsForAttestation(id);
                    setAttestations(data);
                    setIsLoading(false);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );

    const updateAttestationsAfterRequest = (id) => {
        const attestationsAfterRequest = attestations.map((attestation) => {
            if (attestation.submissionId === id) attestation.attestationStatus = 'REQUESTED';
            return attestation;
        });

        setAttestations(attestationsAfterRequest);
    };

    return (
        <div>
            <Table className="gap-8" cols="grid-cols-tableAttestation">
                <Table.Header>
                    <p className="text-sm   font-semibold text-secondaryText   ">
                        {' '}
                        <span>Claim ID</span>{' '}
                    </p>
                    <p className="text-sm   font-semibold text-secondaryText     ">
                        {' '}
                        <span> Bounty Title </span>{' '}
                    </p>
                    <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1    ">
                        {' '}
                        <span> Attestation Requested On </span>{' '}
                        <span>
                            <SortIcon />
                        </span>{' '}
                    </p>
                    <p className="text-sm   font-semibold text-secondaryText      ">
                        {' '}
                        <span> Validation Score </span>{' '}
                    </p>
                    <p className="text-sm font-semibold text-secondaryText flex items-center gap-1    ">
                        {' '}
                        <span> Attestation </span>{' '}
                        <span>
                            <SortIcon />
                        </span>{' '}
                    </p>
                </Table.Header>
                {isLoading ? (
                    <div className="min-h-[20vh] flex justify-center items-center">
                        <Loader />
                    </div>
                ) : attestations.length ? (
                    attestations.map((submission) => (
                        <Table.Row>
                            <p className="text-primaryColor cursor-pointer truncate w-full uppercase justify-self-start font-bold">
                                {submission.submissionId}
                            </p>
                            <p className=" cursor-pointer text-primaryColor">{submission.bountyTitle}</p>
                            <p className="text-lightBlack ">
                                {' '}
                                {submission.attestationRequestedOn ? dmyFormat(submission.attestationRequestedOn) : '-'}
                            </p>
                            <p className="text-lightBlack">{submission.accuracy * 100}</p>
                            {submission.attestationStatus === 'NOT_REQUESTED' ? (
                                <Modal>
                                    <Modal.Button
                                        className="flex gap-2 cursor-pointer items-center"
                                        opens={'updateproject'}
                                    >
                                        {ATTESTATION_STATUS[submission.attestationStatus]}
                                    </Modal.Button>
                                    <Modal.Window name={'updateproject'}>
                                        <RequestForAttestationModal
                                            updateStatusColumn={updateAttestationsAfterRequest}
                                            subId={submission.submissionId}
                                        />
                                    </Modal.Window>
                                </Modal>
                            ) : (
                                <p> {ATTESTATION_STATUS[submission.attestationStatus]}</p>
                            )}
                            {/* <p className=" text-lightBlack cursor-pointer "></p> */}
                        </Table.Row>
                    ))
                ) : (
                    <NoDataAvailable />
                )}
                <div className="flex items-center justify-end mt-6">
                    <div className="flex items-center gap-3 ">
                        <p className="text-sm font-bold">Attestation</p>
                        <Legend shield={<GreyThumbIcon />} text={'Not Done'} />
                        <Legend shield={<OrangeThumbIcon />} text={'Requested'} />
                        <Legend shield={<GreenThumbIcon />} text={'Done'} />
                    </div>
                </div>
            </Table>
        </div>
    );
}

export default AttestationRequestsInsideOrganisation;
