import { useEffect, useRef } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { sendSubmissionForAttestation } from '../../apis/needAttestation/needAttestation';

function RequestForAttestationModal({ close, subId, updateStatusColumn }) {
    const myRef = useRef({ close });

    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[625px]  p-14   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div>
                    <Button
                        onClick={close}
                        className=" border-0 absolute top-3  right-4 "
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                </div>
                <p className="text-xl">
                    Attestation includes associated monetary costs and is{' '}
                    <span className="font-bold">billed monthly</span> . Are you sure you want to request for
                    attestation?
                </p>
                <div className="flex items-center mt-8 gap-3">
                    <Button
                        onClick={async () => {
                            const response = await sendSubmissionForAttestation(subId);
                            updateStatusColumn(subId);
                        }}
                        size="round"
                        variant="primary"
                    >
                        Submit
                    </Button>
                    <Button onClick={close} size="round" variant="tertiary">
                        Cancel
                    </Button>
                </div>
            </div>
        </Overlay>
    );
}

export default RequestForAttestationModal;
