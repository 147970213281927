import { useEffect, useState } from 'react';

import Heading from '../../ui/Heading';

import WhiteCard from '../../ui/WhiteCard';
import BountiesInProject from './BountiesInProject';
import { getParticularProject } from '../../apis/projects/projects';
import { useParams } from 'react-router-dom';
import Loader from '../../ui/Loader';
import { EarthIcon, FistIcon, SunIcon, WaterIcon } from '../../assets/icons/ProgressBarIcons';
import { SDG_IMAGES } from '../../constants/datas';

const coreIcons = {
    Earth: <EarthIcon key={'earth-icon'} color="" />,
    Energy: <SunIcon key="sun-icon" color="" />,
    Social: <FistIcon key={'fist-icon'} color="" />,
    Water: <WaterIcon key={'water-icon'} color="" />,
};
function ParticularProject() {
    const [project, setProject] = useState({});

    const { projectId } = useParams();

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getParticularProject(projectId);

                    setProject(data);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [projectId]
    );

    if (!Object.keys(project).length) return <Loader />;
    return (
        <>
            <WhiteCard className="p-6 grid grid-cols-4">
                <div className="col-span-4 mb-6">
                    <div className="mb-6">
                        <p className="font-semibold text-secondaryInput">Project Title</p>
                        <Heading className={'text-primaryInput font-medium'} type={'large'}>
                            {project?.title}
                        </Heading>
                    </div>
                    <div className="flex items-center gap-9">
                        <div className="">
                            <p className="font-semibold text-secondaryInput">Project ID</p>
                            <p className="font-semibold text-primaryInput uppercase">{project._id}</p>
                        </div>
                    </div>
                </div>

                <div className="col-span-2">
                    <p className="font-semibold text-secondaryInput">Project Description</p>
                    <p className="text-primaryInput ">{project.description}</p>
                </div>
                <div className="col-span-2 flex justify-between gap-16">
                    <div className=" w-[50%]">
                        <p className="font-semibold text-secondaryInput">Impact Cores</p>
                        <div className="flex  items-center">
                            {project?.impactMetrics.cores?.map((core) => coreIcons[core])}
                        </div>
                    </div>

                    <div className=" w-[50%]">
                        <p className="font-semibold text-secondaryInput">SDGs</p>
                        <div className="grid grid-cols-3 gap-[5px]">
                            {project?.impactMetrics?.SDGs.map((sdg, index) => (
                                <img key={sdg + index + Math.random()} className=" " src={SDG_IMAGES[sdg]} alt="" />
                            ))}
                        </div>
                    </div>
                </div>
            </WhiteCard>
            <BountiesInProject projectTitle={project?.title} />
        </>
    );
}

export default ParticularProject;
