import { useNavigate } from 'react-router-dom';
import Button from '../../ui/Button';
import SubHeading from '../../ui/SubHeading';
import SummaryCard from '../../ui/SummaryCard';
import WhiteCard from '../../ui/WhiteCard';
import { useEffect, useState } from 'react';
import { getExploreOrg } from '../../apis/oragnisations/organisation';
import Loader from '../../ui/Loader';
import NoDataAvailable from '../../ui/NoDataAvailable';
import { useLoadingState } from '../../hooks/useLoader';

function ExploreImpactOrganizations({ heading = ' Explore Impact Organisations' }) {
    const navigate = useNavigate();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [impactOrganizations, setImpactOrganization] = useState([]);
    useEffect(function () {
        const fetchData = async () => {
            try {
                startLoading();
                const data = await getExploreOrg(3);
                setImpactOrganization(data);
            } catch (error) {
                console.log(error);
            } finally {
                stopLoading();
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            <div className="flex justify-between items-center">
                <SubHeading type="large" className={'text-lightBlack'}>
                    {heading}
                </SubHeading>

                {impactOrganizations.length > 0 && (
                    <p className="cursor-pointer underline" onClick={() => navigate('/organizations')}>
                        View All
                    </p>
                )}
            </div>

            <WhiteCard className="mt-1 px-6 py-5 ">
                {loading ? (
                    <div className="h-40 flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {impactOrganizations.length ? (
                            impactOrganizations.map((organization) => (
                                <SummaryCard
                                    redirectTo={() => {
                                        navigate(`/organizations/${organization._id}`);
                                    }}
                                    key={organization.name + organization.tagline}
                                    organization={organization}
                                />
                            ))
                        ) : (
                            <div className="h-auto min-h-40 flex items-center justify-center">
                                <NoDataAvailable marginTop="mt-8" imgClassName="w-36" className="mb-8" />
                            </div>
                        )}
                    </>
                )}
            </WhiteCard>
        </div>
    );
}

export default ExploreImpactOrganizations;
