// import { useEffect, useRef, useState } from 'react';
// import Overlay from '../../ui/Overlay';
// import Button from '../../ui/Button';
// import Heading from '../../ui/Heading';
// import feedback from '../../assets/feedback.png';
// import Input from '../../ui/Input';
// import Dropdown from '../../ui/Dropdown';
// import Feedbackclose from '../../assets/icons/Feedbackclose';

// import sgMail from '@sendgrid/mail';
// const issuesData = [
//     '  General Feedback',
//     'Feature Request',
//     'Bug Report',
//     'User Interface (UI) Suggestions',
//     'User Experience (UX) Improvements',
//     'Performance Issues',
//     'Content Feedback',
//     'Integration Issues',
//     'Security Concerns',
//     'Billing and Payments',
//     'Accessibility Suggestions',
//     'Other',
// ];

// function FeedbackModal({ close }) {
//     const myRef = useRef({ close });
//     const [selectedIssue, setSelectedIssue] = useState('');

//     const handleSelectIssue = (value) => {
//         if (value === null) {
//             setSelectedIssue('');
//             return;
//         }

//         if (value.toLowerCase() === selectedIssue.toLowerCase()) {
//             setSelectedIssue('');
//             return;
//         }

//         setSelectedIssue(value);
//     };
//     useEffect(
//         function () {
//             function handleClick(e) {
//                 if (myRef.current && !myRef.current.contains(e.target)) {
//                     close();
//                 }
//             }

//             document.addEventListener('click', handleClick, true);

//             return () => document.removeEventListener('click', handleClick, true);
//         },
//         [close]
//     );
//     return (
//         <Overlay>
//             <div
//                 ref={myRef}
//                 className="absolute rounded-lg left-[50%] w-[588px]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
//             >
//                 <div className="relative modal   overflow-hidden p-7  ">
//                     <Button
//                         radius="rounded-full"
//                         onClick={close}
//                         className=" border-0 absolute top-3  right-4 "
//                         variant="tertiary"
//                         size="circle"
//                     >
//                         <Feedbackclose />
//                     </Button>
//                     <div className="rounded-md  overflow-hidden ">
//                         <img className="w-[100%]" src={feedback} alt="feedback-banner" />
//                     </div>
//                     <div className="flex flex-col gap-4 mt-6 ">
//                         <div>
//                             <Heading className={'font-bold text-modalHeading'} type={'small'}>
//                                 We'd Love to Hear from you
//                             </Heading>
//                         </div>
//                         <div className="relative">
//                             <Dropdown
//                                 w="w-full"
//                                 data={issuesData}
//                                 selected={selectedIssue}
//                                 onSelected={handleSelectIssue}
//                                 noSelectedText={
//                                     <span className="flex items-center gap-[7px]">
//                                         <span>Select issue</span>
//                                     </span>
//                                 }
//                             />
//                         </div>
//                         <div>
//                             <Input type="textArea" placeholder="Tell us more about it" className="w-[100%] h-44" />
//                         </div>
//                         <div className="flex justify-end">
//                             <Button radius="rounded-full" size="round">
//                                 Submit
//                             </Button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </Overlay>
//     );
// }

// export default FeedbackModal;

// const FeedbackForm = () => {
//     const [email, setEmail] = useState('');
//     const [feedback, setFeedback] = useState('');
//     const [status, setStatus] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         sgMail.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY);

//         const msg = {
//             to: 'your-receiver-email@example.com', // Replace with the email to receive feedback
//             from: { email: process.env.REACT_APP_SENDGRID_EMAIL, name: 'Feedback Form' },
//             subject: 'User Feedback',
//             html: `
//         <p><b>From:</b> ${email}</p>
//         <p><b>Feedback:</b></p>
//         <p>${feedback}</p>
//       `,
//         };

//         try {
//             await sgMail.send(msg);
//             setStatus('Feedback sent successfully!');
//             setEmail('');
//             setFeedback('');
//         } catch (error) {
//             console.error(error);
//             setStatus('Failed to send feedback. Please try again.');
//         }
//     };

//     return (
//         <div className="feedback-form">
//             <h2>Send Feedback</h2>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label htmlFor="email">Email:</label>
//                     <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
//                 </div>
//                 <div>
//                     <label htmlFor="feedback">Feedback:</label>
//                     <textarea id="feedback" value={feedback} onChange={(e) => setFeedback(e.target.value)} required />
//                 </div>
//                 <button type="submit">Send</button>
//             </form>
//             {status && <p>{status}</p>}
//         </div>
//     );
// };

import { useEffect, useRef, useState } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import feedback from '../../assets/feedback.png';
import Input from '../../ui/Input';
import Dropdown from '../../ui/Dropdown';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { submitFeedback } from '../../apis/feedback/submitFeedback';
import { useController, useForm } from 'react-hook-form';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import { toast } from 'sonner';
// import sgMail from '@sendgrid/mail';

const issuesData = [
    'General Feedback',
    'Feature Request',
    'Bug Report',
    'User Interface (UI) Suggestions',
    'User Experience (UX) Improvements',
    'Performance Issues',
    'Content Feedback',
    'Integration Issues',
    'Security Concerns',
    'Billing and Payments',
    'Accessibility Suggestions',
    'Other',
];

function FeedbackModal({ close }) {
    const myRef = useRef(null);
    const { loading, startLoading, stopLoading } = useLoadingState();
    const {
        register,
        handleSubmit,
        getValues,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const { field: issueField } = useController({
        name: 'issue',
        control,
        rules: { required: 'Please choose an issue' },
    });
    const { field: feedbackField } = useController({
        name: 'feedback',
        control,
        rules: {
            required: 'Please enter a feedback',
            maxLength: {
                value: 1000,
                message: 'The maximum length is 1,000 characters',
            },
        },
    });
    // const [selectedIssue, setSelectedIssue] = useState('');
    // const [feedbackContent, setFeedbackContent] = useState('');
    // const [email, setEmail] = useState('');
    // const [status, setStatus] = useState('');

    useEffect(() => {
        function handleClick(e) {
            if (myRef.current && !myRef.current.contains(e.target)) {
                close();
            }
        }

        document.addEventListener('click', handleClick, true);
        return () => document.removeEventListener('click', handleClick, true);
    }, [close]);

    const onSubmit = async (data) => {
        try {
            startLoading();
            const res = await submitFeedback({
                feedback: `${data.issue}: ${data.feedback}`,
            });

            if (res?.success) {
                toast?.success('Feedback submitted successfully');
            }
        } catch (error) {
            console.error(error);
        } finally {
            stopLoading();

            close();
        }
    };

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[588px] bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div className="relative modal overflow-hidden p-7">
                    <Button
                        radius="rounded-full"
                        onClick={close}
                        className="border-0 absolute top-3 right-4"
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                    <div className="rounded-md overflow-hidden">
                        <img className="w-[100%]" src={feedback} alt="feedback-banner" />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 mt-6">
                        <Heading className="font-bold text-modalHeading" type="small">
                            We'd Love to Hear from you
                        </Heading>

                        <div className="relative">
                            <Dropdown
                                data={issuesData}
                                w="w-full"
                                selected={issueField.value}
                                onSelected={issueField.onChange}
                                noSelectedText={
                                    <span className="flex items-center gap-[7px]">
                                        <span>Select issue</span>
                                    </span>
                                }
                            />
                            <p className="text-foundryRed w-64 ">{errors?.issue?.message && errors?.issue?.message}</p>
                        </div>

                        <div>
                            <Input
                                {...feedbackField}
                                type="textArea"
                                placeholder="Tell us more about it"
                                className="w-[100%] h-44"
                            />
                            <p className="text-foundryRed w-64 ">
                                {errors?.feedback?.message && errors?.feedback?.message}
                            </p>
                        </div>

                        <div className="flex justify-end">
                            <Button type="submit" radius="rounded-full" size="round">
                                {loading ? <Loader /> : 'Submit'}
                            </Button>
                        </div>
                        {/* {status && <p className="text-sm text-center mt-4">{status}</p>} */}
                    </form>
                </div>
            </div>
        </Overlay>
    );
}

export default FeedbackModal;
