import Heading from '../ui/Heading';
const mobileUi = '/assets/images/mobileUi.png';
const atlantislogo = '/assets/images/logo.png';
const MobileUI = () => {
    return (
        <div className=" px-6 py-5 box-border  h-screen flex flex-col justify-between ml-auto min-w-[320px] max-w-[620px] mobile-css ">
            <div className="">
                <h1 className={'text-black text-[72px] -ml-2 font-major'}>impAct</h1>

                <Heading type={'fulllarge'} className={'text-black !text-[55px]   font-black'}>
                    FOUNDRY
                </Heading>

                <p className="text-base">create, manage and monitor impact on-chain.</p>
            </div>
            <div className="flex flex-col items-center justify-center">
                <img className="w-[224px] h-[161px] " src={mobileUi} alt="mobile-ui" />
                <p>Open on desktop to login</p>
            </div>
            <div className="flex items-center justify-center ">
                <div>
                    <img className="w-[57px] h-[57px]" alt="Atlantis logo" src={atlantislogo} />
                </div>
                <div>
                    <Heading type="xsmall" className={'text-black font-major '}>
                        built by Atlantis
                    </Heading>

                    <Heading type="xsmall" className={'text-black !font-black '}>
                        FOR CHANGEMAKERS
                    </Heading>
                </div>
            </div>
        </div>
    );
};

export default MobileUI;
