import Input from '../../ui/Input';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import AddProfileImage from '../../assets/icons/AddProfileImage';
import Info from '../../assets/icons/Info';
import BackButton from '../../assets/icons/BackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import Loader from '../../ui/Loader';
import { useLoadingState } from '../../hooks/useLoader';
import { uploader } from '../../helpers/uploader';
import { toast } from 'sonner';
import EditLogo from '../../assets/icons/EditLogo';
import CharacterLimit from '../../ui/CharacterLimit';
import { createEvent } from '../../apis/events/createEvent';
import { updateEvent } from '../../apis/events/updateEvent';
import DescriptionWithPara from '../../ui/DescriptionWithPara';

function EventInput({
    errors,
    eventNameField,
    eventHeadingField,
    eventDescriptionField,
    handleSubmit,
    imageObject,
    setImage,
    editId,
    landingImageObject,
    register,

    setLandingImage,
}) {
    const { id } = useParams();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [uploadStatus, setUploadStatus] = useState('');
    const { loading: uploading, startLoading: startUploading, stopLoading: stopUploading } = useLoadingState();

    const navigate = useNavigate();

    const { image, imageURL } = imageObject;
    const onSubmit = async (data) => {
        data.eventBanner = landingImageObject.imageURL;
        data.eventLandingImage = imageObject.imageURL;
        // data.eventLandingImage = landingImageObject.imageURL;
        const payloadData = {
            name: data.eventName,
            bannerImages: [data.eventBanner],
            sections: [
                {
                    title: data.eventHeading,
                    content: data.eventDescription,
                },
            ],
            image: data.eventLandingImage,
            buttonPosition: 1,
            organisationId: id,
        };

        if (editId) {
            const { organisationId, ...editData } = payloadData;
            startLoading();
            const response = await updateEvent(editData, editId);
            stopLoading();
            if (Object.keys(response).length > 0) navigate(-1);

            return;
        }

        startLoading();

        const response = await createEvent(payloadData);

        stopLoading();
        if (Object.keys(response).length > 0) navigate(-1);
    };
    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            await uploadFile(file);
        } else {
            setUploadStatus('Please select a valid  file.');
        }
    };

    const handleFileLandingChange = async (event) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            await uploadFileLanding(file);
        } else {
            setUploadStatus('Please select a valid  file.');
        }
    };
    const uploadFile = async (file) => {
        try {
            startUploading();

            const res = await uploader(file);

            if (res.files.length) {
                setImage({
                    image: file,
                    imageURL: res.files[0],
                });

                toast.success('Image is successfully uploaded.');
            } else {
                toast.error('Sorry unable to upload logo');
            }
            stopUploading();
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('File upload failed.');
        }
    };
    const uploadFileLanding = async (file) => {
        try {
            startUploading();

            const res = await uploader(file);

            if (res.files.length) {
                setLandingImage({
                    image: file,
                    imageURL: res.files[0],
                });

                toast.success('Image is successfully uploaded.');
            } else {
                toast.error('Sorry unable to upload logo');
            }
            stopUploading();
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('File upload failed.');
        }
    };

    if (editId) {
        if (!eventDescriptionField?.value) return <Loader />;
        else
            return (
                <div className=" flex gap-3 items-start py-6  overflow-auto main pr-[55px] ">
                    <BackButton className={'relative top-2'} onClick={() => navigate(-1)} />
                    <form onSubmit={handleSubmit(onSubmit)} className=" w-[100%] ">
                        <div className="mb-7 ">
                            <Input
                                type="transparent"
                                {...eventNameField}
                                error={errors?.eventName?.message}
                                placeholder={'Event Title*...'}
                                className={'w-[100%]'}
                            />
                            <p className=" text-secondaryInput text-xs">
                                <span className="font-semibold ">({'character limit'}</span> :{' '}
                                <span>{eventNameField.value ? eventNameField.value.length : 0}/20)</span>
                            </p>
                        </div>
                        <div className="mb-7">
                            <div className="p-6 pt-5 bg-white shadow-sm rounded-md ">
                                <Heading type="medium" className="text-primaryInput leading-tight mb-2">
                                    Upload an image for the banner <sup className="text-lg relative top-[1px]">*</sup>
                                </Heading>
                                <div className="flex flex-col text-uploadText items-center">
                                    <div className="border flex flex-col gap-2 border-borderColor border-dashed w-full p-6 rounded-md">
                                        <div>
                                            <div>
                                                {!imageObject.imageURL ? (
                                                    <>
                                                        <label
                                                            className="flex flex-col gap-2 items-center"
                                                            htmlFor="bannerInput"
                                                        >
                                                            <AddProfileImage />
                                                            <p>Click to choose files</p>
                                                        </label>
                                                        <Input
                                                            id="bannerInput"
                                                            type="file"
                                                            {...register('eventBanner', {
                                                                required: 'Please upload an event banner.',
                                                            })}
                                                            onChange={(e) => handleFileChange(e)}
                                                        />
                                                    </>
                                                ) : (
                                                    <div className="relative">
                                                        <img
                                                            className="w-20 h-20 rounded-md object-center object-cover"
                                                            src={imageObject?.imageURL}
                                                            alt="profile"
                                                        />
                                                        <label
                                                            htmlFor="bannerInput"
                                                            className="cursor-pointer absolute top-0 right-0 "
                                                        >
                                                            <EditLogo />
                                                            <Input
                                                                id="bannerInput"
                                                                type="file"
                                                                onChange={(e) => handleFileChange(e)} // No need to register here
                                                            />
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {!imageURL && (
                                            <div className="flex gap-2 justify-center items-center">
                                                <Info />
                                                <p className="font-semibold">
                                                    Max file size: 2 MB; Ideal dimensions: 670x260 px
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <p className="text-foundryRed mt-1">{errors?.eventBanner?.message}</p>
                        </div>
                        <div className="mb-7">
                            <div className="p-6 pt-5 bg-white shadow-sm rounded-md ">
                                <Heading type="medium" className="text-primaryInput leading-tight mb-2">
                                    Upload an image for the landing page{' '}
                                    <sup className="text-lg relative top-[1px]">*</sup>
                                </Heading>
                                <div className="flex flex-col text-uploadText items-center">
                                    <div className="border flex flex-col gap-2 border-borderColor border-dashed w-full p-6 rounded-md">
                                        <div>
                                            <div>
                                                {!landingImageObject.imageURL ? (
                                                    <>
                                                        <label
                                                            className="flex flex-col gap-2 items-center"
                                                            htmlFor="landingInput"
                                                        >
                                                            <AddProfileImage />
                                                            <p>Click to choose files</p>
                                                        </label>

                                                        <Input
                                                            id="landingInput"
                                                            type="file"
                                                            {...register('eventLandingImage', {
                                                                required: 'Please upload a landing page image.',
                                                            })}
                                                            onChange={(e) => handleFileLandingChange(e)}
                                                        />
                                                    </>
                                                ) : (
                                                    <div className="relative">
                                                        <img
                                                            className="w-20 h-20 rounded-md object-center object-cover"
                                                            src={landingImageObject.imageURL}
                                                            alt="profile"
                                                        />
                                                        <label
                                                            htmlFor="landingInput"
                                                            className="cursor-pointer absolute top-0 right-0 "
                                                        >
                                                            <EditLogo />
                                                            <Input
                                                                id="landingInput"
                                                                type="file"
                                                                onChange={(e) => handleFileLandingChange(e)} // No need to register here
                                                            />
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {!landingImageObject?.imageURL && (
                                            <div className="flex gap-2 justify-center items-center">
                                                <Info />
                                                <p className="font-semibold">
                                                    Max file size: 2 MB; Ideal dimensions: 433x623 px
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <p className="text-foundryRed mt-1">{errors?.eventLandingImage?.message}</p>
                        </div>
                        <div className="mb-7">
                            <Heading type="medium" className="capitalize text-primaryInput  mb-2">
                                Heading <sup className="text-lg relative top-[1px]">*</sup>
                            </Heading>
                            <Input
                                {...eventHeadingField}
                                error={errors?.eventHeading?.message}
                                placeholder={'Please enter a short heading that will be displayed on Impact Miner'}
                                className={'w-[100%]'}
                            />
                            <CharacterLimit min={eventHeadingField?.value?.length} max={200} />
                        </div>
                        <div className="mb-7">
                            <DescriptionWithPara
                                errors={errors}
                                field={eventDescriptionField}
                                errorField={'eventDescription'}
                            />
                        </div>

                        <div>
                            {editId ? (
                                <Button type="submit" variant="secondary" width="w-[50%]" size="large">
                                    {loading ? <Loader /> : 'Update Event'}
                                </Button>
                            ) : (
                                <Button type="submit" variant="secondary" size="large" width="w-[50%]">
                                    {!loading ? ' Create Event' : <Loader />}
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
            );
    }

    return (
        <div className=" flex gap-3 items-start py-6  overflow-auto main pr-[55px] ">
            <BackButton className={'relative top-2'} onClick={() => navigate(-1)} />
            <form onSubmit={handleSubmit(onSubmit)} className=" w-[100%] ">
                <div className="mb-7 ">
                    <Input
                        type="transparent"
                        {...eventNameField}
                        error={errors?.eventName?.message}
                        placeholder={'Event Title*...'}
                        className={'w-[100%]'}
                    />
                    <p className=" text-secondaryInput text-xs">
                        <span className="font-semibold ">({'character limit'}</span> :{' '}
                        <span>{eventNameField.value ? eventNameField.value.length : 0}/20)</span>
                    </p>
                </div>
                <div className="mb-7">
                    <div className="p-6 pt-5 bg-white shadow-sm rounded-md ">
                        <Heading type="medium" className="text-primaryInput leading-tight mb-2">
                            Upload an image for the banner <sup className="text-lg relative top-[1px]">*</sup>
                        </Heading>
                        <div className="flex flex-col text-uploadText items-center">
                            <div className="border flex flex-col gap-2 border-borderColor border-dashed w-full p-6 rounded-md">
                                <div>
                                    <div>
                                        {!imageObject.imageURL ? (
                                            <>
                                                <label
                                                    className="flex flex-col gap-2 items-center"
                                                    htmlFor="bannerInput"
                                                >
                                                    <AddProfileImage />
                                                    <p>Click to choose files</p>
                                                </label>
                                                <Input
                                                    id="bannerInput"
                                                    type="file"
                                                    {...register('eventBanner', {
                                                        required: 'Please upload an event banner.',
                                                    })}
                                                    onChange={(e) => handleFileChange(e)}
                                                />
                                            </>
                                        ) : (
                                            <div className="relative">
                                                <img
                                                    className="w-20 h-20 rounded-md object-center object-cover"
                                                    src={imageObject?.imageURL}
                                                    alt="profile"
                                                />
                                                <label
                                                    htmlFor="bannerInput"
                                                    className="cursor-pointer absolute top-0 right-0 "
                                                >
                                                    <EditLogo />
                                                    <Input
                                                        id="bannerInput"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e)} // No need to register here
                                                    />
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!imageURL && (
                                    <div className="flex gap-2 justify-center items-center">
                                        <Info />
                                        <p className="font-semibold">
                                            Max file size: 2 MB; Ideal dimensions: 670x260 px
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <p className="text-foundryRed mt-1">{errors?.eventBanner?.message}</p>
                </div>
                <div className="mb-7">
                    <div className="p-6 pt-5 bg-white shadow-sm rounded-md ">
                        <Heading type="medium" className="text-primaryInput leading-tight mb-2">
                            Upload an image for the landing page <sup className="text-lg relative top-[1px]">*</sup>
                        </Heading>
                        <div className="flex flex-col text-uploadText items-center">
                            <div className="border flex flex-col gap-2 border-borderColor border-dashed w-full p-6 rounded-md">
                                <div>
                                    <div>
                                        {!landingImageObject.imageURL ? (
                                            <>
                                                <label
                                                    className="flex flex-col gap-2 items-center"
                                                    htmlFor="landingInput"
                                                >
                                                    <AddProfileImage />
                                                    <p>Click to choose files</p>
                                                </label>

                                                <Input
                                                    id="landingInput"
                                                    type="file"
                                                    {...register('eventLandingImage', {
                                                        required: 'Please upload a landing page image.',
                                                    })}
                                                    onChange={(e) => handleFileLandingChange(e)}
                                                />
                                            </>
                                        ) : (
                                            <div className="relative">
                                                <img
                                                    className="w-20 h-20 rounded-md object-center object-cover"
                                                    src={landingImageObject.imageURL}
                                                    alt="profile"
                                                />
                                                <label
                                                    htmlFor="landingInput"
                                                    className="cursor-pointer absolute top-0 right-0 "
                                                >
                                                    <EditLogo />
                                                    <Input
                                                        id="landingInput"
                                                        type="file"
                                                        onChange={(e) => handleFileLandingChange(e)} // No need to register here
                                                    />
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!landingImageObject?.imageURL && (
                                    <div className="flex gap-2 justify-center items-center">
                                        <Info />
                                        <p className="font-semibold">
                                            Max file size: 2 MB; Ideal dimensions: 433x623 px
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <p className="text-foundryRed mt-1">{errors?.eventLandingImage?.message}</p>
                </div>
                <div className="mb-7">
                    <Heading type="medium" className="capitalize text-primaryInput  mb-2">
                        Heading <sup className="text-lg relative top-[1px]">*</sup>
                    </Heading>
                    <Input
                        {...eventHeadingField}
                        error={errors?.eventHeading?.message}
                        placeholder={'Please enter a short heading that will be displayed on Impact Miner'}
                        className={'w-[100%]'}
                    />
                    <CharacterLimit min={eventHeadingField?.value?.length} max={200} />
                </div>
                <div className="mb-7">
                    <DescriptionWithPara
                        errors={errors}
                        field={eventDescriptionField}
                        errorField={'eventDescription'}
                    />
                </div>

                <div>
                    {editId ? (
                        <Button type="submit" variant="secondary" width="w-[50%]" size="large">
                            {loading ? <Loader /> : 'Update Event'}
                        </Button>
                    ) : (
                        <Button type="submit" variant="secondary" size="large" width="w-[50%]">
                            {!loading ? ' Create Event' : <Loader />}
                        </Button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default EventInput;
