import { useController } from 'react-hook-form';

export const useGetCoordinates = (control, isEdit) => {
    const { field: placeField } = useController({
        name: 'place',
        control,

        rules: {
            required: isEdit ? false : 'Please enter a location',
        },
    });

    const { field: placeCoordsField } = useController({
        name: 'placeCoords',
        control,
        defaultValue: { coordinates: [] },
    });

    const { field: radiusField } = useController({
        name: 'radius',
        control,
        defaultValue: 50,
    });

    return {
        placeField,
        placeCoordsField,
        radiusField,
    };
};
