import { useNavigate } from 'react-router-dom';
import LoginForm from '../features/authentication/LoginForm';
import LoginText from '../features/authentication/LoginText';
import LayoutWithoutSidebar from '../ui/LayoutWithoutSidebar';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';

function LoginPage() {
    // const navigate = useNavigate();

    //   loading user

    const { state } = useContext(AuthContext);
    const { isAuthenticated, user } = state;

    //   if not authenticated redirect to login page
    // useEffect(
    //     function () {
    //         if (isAuthenticated && user.length) navigate('/');
    //     },
    //     [isAuthenticated, navigate, user]
    // );
    return (
        <div className=" flex  px-[5vw] py-[5vh] justify-between  ">
            <LoginText />
            <LoginForm />
        </div>
    );
}

export default LoginPage;
