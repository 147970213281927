import { useController } from 'react-hook-form';

export const useStepFourInputs = (control) => {
    const { field: expirationTimeField } = useController({
        name: 'expirationTime',
        control,
        rules: {
            required: 'Please enter a date',
        },
    });

    // const { field: validationTillDateField } = useController({
    //     name: 'validationTillDate',
    //     control,
    //     rules: {
    //         required: 'Please enter a date',
    //         validate: (value) => {
    //             if (!expirationTimeField.value) {
    //                 return 'Please select the first date before selecting the second date.';
    //             }

    //             return true;
    //         },
    //     },
    // });

    const { field: dayField } = useController({
        name: 'day',
        control,
        rules: {
            required: 'Day is required',
        },
    });
    const { field: hoursField } = useController({
        name: 'hours',
        control,
        rules: {
            required: 'Hours are required',
        },
    });
    const { field: minutesField } = useController({
        name: 'minutes',
        control,
        rules: {
            required: 'Minutes are required',
        },
    });

    return { expirationTimeField, dayField, hoursField, minutesField };
};
