const FloatingButtons = ({ children }) => {
    return (
        <div className="fixed bottom-0 z-[9999] left-0 right-0  bg-[#f6f6f6] py-6 pt-3 ">
            <div className="grid  grid-cols-createBounty pl-9  ">
                <div className=" pr-0 "></div>
                <div className="flex px-12  items-center  pt-0 justify-between">
                    <div className=" px-12 w-full  z-0    ">
                        <div className="flex justify-between items-center ">{children}</div>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    );
};

export default FloatingButtons;
