import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Button from '../../ui/Button';
import AttesterInputStepOne from './AttesterInputStepOne';
import AttesterInputStepTwo from './AttesterInputStepTwo';
import AttesterInputStepThree from './AttesterInputStepThree';
import { useEffect, useRef, useState } from 'react';
import AttesterInputStepFour from './AttestationInputStepFour';
import { superpowers } from '../../constants/powersData';
import { createAttesterPatch, createAttesterPost, getAttester } from '../../apis/attester/attester';

function AttesterInput() {
    const [step, setStep] = useState(1);
    const buttonClickedRef = useRef(false);
    const [selectedPowers, setSelectedPowers] = useState([]);
    const [powers, setPowers] = useState(superpowers);
    const [attester, setAttester] = useState({});
    // const [attester] = useOutletContext();
    const {
        register,
        handleSubmit,
        getValues,
        trigger,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
    } = useForm();

    const navigate = useNavigate();

    useEffect(() => {
        // Cleanup function
        return () => {
            if (!buttonClickedRef.current) {
                // Component is unmounting without a button click
                const fetchOnUnmount = async () => {
                    try {
                        const values = getValues();
                        const {
                            link1,
                            link2,
                            link3,
                            message,
                            proofofwork1,
                            proofofwork2,
                            proofofwork3,
                            evmAdress = '',
                        } = values;

                        let payload = {};
                        if (!Object.keys(values).length) {
                            payload = {
                                superPowers: [],
                                proofOfWork: [],
                                motivationToJoin: '',
                                socialLinks: [],
                                evmAddress: '',
                            };
                        } else {
                            payload = {
                                superPowers: values.superPowers.map((power) => power.item),
                                proofOfWork: [proofofwork1, proofofwork2, proofofwork3].filter((proof) =>
                                    Boolean(proof)
                                ),
                                motivationToJoin: message,
                                socialLinks: [link1, link2, link3].filter((link) => Boolean(link)),
                                evmAddress: typeof evmAdress === 'string' ? evmAdress : evmAdress[0],
                            };
                        }

                        const data = await getAttester();

                        if (data?.status === 'UNSUBMITTED') {
                            const responsePatch = await createAttesterPatch(data.id, false, payload);
                        } else {
                            const responsePost = await createAttesterPost(false, payload);
                        }
                    } catch (error) {
                        console.error('Unmount API error:', error);
                    }
                };

                fetchOnUnmount();
            }
        };
    }, []);

    const handleStepChange = async (nextStep) => {
        if (nextStep === 2) {
            handleManualValueSetting(nextStep, 'superPowers', selectedPowers);
            return;
        }
        if (nextStep < step) {
            setStep(nextStep);
            return;
        }
        const isValid = await trigger(); // Manually trigger validation for all fields
        if (isValid) {
            setStep(nextStep);
        } else {
            console.log('Validation failed');
        }
    };

    const handleManualValueSetting = (nextStep, field, value) => {
        const isValid = value.length ? true : false;
        if (!isValid) {
            setError(field, {
                type: 'manual',
                message: 'Please Select atleast one superpower',
            });
        } else {
            clearErrors(field); // Clear error if validation passes
            setFieldValue(field, value);
            if (nextStep) setStep(nextStep);
        }
    };

    const setFieldValue = (field, value) => {
        setValue(field, value, { shouldValidate: true }); // Set new value and trigger validation
    };
    const onSubmit = async (data) => {
        buttonClickedRef.current = true;

        try {
            const values = getValues();
            const { link1, link2, link3, message, proofofwork1, proofofwork2, proofofwork3, evmAdress = '' } = values;
            const payload = {
                superPowers: values.superPowers.map((power) => power.item),
                proofOfWork: [proofofwork1, proofofwork2, proofofwork3].filter((proof) => Boolean(proof)),
                motivationToJoin: message,
                socialLinks: [link1, link2, link3].filter((link) => Boolean(link)),
                evmAddress: typeof evmAdress === 'string' ? evmAdress : evmAdress[0],
            };

            const data = await getAttester();

            if (data?.status === 'UNSUBMITTED') {
                const responsePatch = await createAttesterPatch(data.id, true, payload);
            } else {
                const responsePost = await createAttesterPost(true, payload);
            }

            navigate(-1);
        } catch (error) {
            console.error('Button API error:', error);
        }
    };

    return (
        <div className="w-8/12 mx-auto   py-6   ">
            <div className="flex justify-center my-7 items-center">
                <div className="flex gap-9    ">
                    {Array.from({ length: 4 }, (_, i) => i + 1).map((number, index) => (
                        <Button
                            className={`bg-white px-4 ${
                                step === index + 1 && `border border-primaryColor text-primaryColor`
                            }  `}
                            variant="tertiary"
                            size="circle"
                            key={number + index}
                        >
                            {number}
                        </Button>
                    ))}
                </div>
            </div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)} className=" w-[100%] ">
                    {step === 1 && (
                        <AttesterInputStepOne
                            powers={powers}
                            setPowers={setPowers}
                            selectedPowers={selectedPowers}
                            setSelectedPowers={setSelectedPowers}
                            register={register}
                            errors={errors}
                            setStep={handleStepChange}
                        />
                    )}
                    {step === 2 && (
                        <AttesterInputStepTwo register={register} errors={errors} setStep={handleStepChange} />
                    )}
                    {step === 3 && (
                        <AttesterInputStepThree register={register} errors={errors} setStep={handleStepChange} />
                    )}
                    {step === 4 && (
                        <AttesterInputStepFour
                            handleManualValueSetting={handleManualValueSetting}
                            register={register}
                            errors={errors}
                            setStep={handleStepChange}
                        />
                    )}
                </form>
            </div>
        </div>
    );
}

export default AttesterInput;
