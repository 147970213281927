import React from 'react';

const ToRegisterBounty = () => {
    return (
        <svg width="21" height="9" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.3991 3.6C15.5491 2 13.1491 1 10.4991 1C5.84906 1 1.91906 4.03 0.539062 8.22L2.89906 9C3.42548 7.39983 4.44358 6.00666 5.80826 5.01907C7.17293 4.03147 8.81452 3.49986 10.4991 3.5C12.4491 3.5 14.2291 4.22 15.6191 5.38L11.9991 9H20.9991V0L17.3991 3.6Z"
                fill="#5E5E5E"
            />
        </svg>
    );
};

export default ToRegisterBounty;
