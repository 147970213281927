const AccordionDown = ({ className = '' }) => {
    return (
        <svg
            className={`${className}`}
            width="24"
            height="14"
            viewBox="0 0 24 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.39801 0.133568L0.333007 2.19857L11.9997 13.8652L23.6663 2.19857L21.6013 0.133567L11.9997 9.73523L2.39801 0.133568Z"
                fill="#A3A3A3"
            />
        </svg>
    );
};

export default AccordionDown;
