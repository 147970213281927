import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../ui/Table';
import Input from '../../ui/Input';
import search from '../../assets/search.png';
import Menus from '../../ui/Menus';
import AvatarImage from '../../ui/AvatarImage';
import DeleteBountyModal from '../bounties/DeleteBountyModal';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import PublishMenuIcon from '../../assets/icons/PublishMenuIcon';
import DuplicateBountyModal from '../bounties/DuplicateBountyModal';
import DuplicateMenuIcon from '../../assets/icons/DuplicateMenuIcon';
import EditMenuIcon from '../../assets/icons/EditMenuIcon';
import { BOUNTY_STATUS_TAG_COLOR } from '../../constants/datas';
import { publishOrUnpublishBounty } from '../../apis/bounties/bounties';
import RequestTreasuryApprovalModal from '../bounties/RequestTreasuryApprovalModal';
import PopOver from '../../ui/PopOver';
import { getBountiesInEvent } from '../../apis/events/getEvents';
import NoDataAvailable from '../../ui/NoDataAvailable';
import StartBountyModal from '../bounties/StartBountyModal';
import { toast } from 'sonner';
import { AuthContext } from '../../contexts/AuthContext';
import ToNormalBounty from '../../assets/icons/ToNormalBounty';
import ToRegisterBounty from '../../assets/icons/ToRegisterBounty';
import ToggleOnboardBountyModal from './ToggleOnboardBountyModal';
import Button from '../../ui/Button';

function BountiesInEvent({ eventTitle }) {
    const [bountiesInEvent, setBountiesInEvent] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false); // Modal visibility state
    const [modalAction, setModalAction] = useState(''); // Track action type: 'duplicate' or 'delete'
    const [selectedBounty, setSelectedBounty] = useState(null); // Track selected bounty for modal
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];
    let filteredData = bountiesInEvent;
    const [searchString, setSearchString] = useState('');
    const { eventId, id } = useParams();
    const navigate = useNavigate();
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getBountiesInEvent(eventId);

                    setBountiesInEvent(data);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [eventId]
    );
    const handleTogglePublish = async (oppStatus, id) => {
        try {
            const afterToggling = bountiesInEvent.map((bounty) => {
                if (bounty._id === id) return { ...bounty, status: oppStatus };
                return bounty;
            });
            setBountiesInEvent(afterToggling);
            const res = await publishOrUnpublishBounty(id);
        } catch (error) {}
    };

    const handleOpenModal = (action, bounty) => {
        setModalAction(action); // Set the action type ('duplicate' or 'delete')
        setSelectedBounty(bounty); // Set the selected bounty
        setModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setModalOpen(false); // Close the modal
        setModalAction(''); // Clear action type
        setSelectedBounty(null); // Clear selected bounty
    };

    const handleConfirmAction = (newBounty) => {
        if (modalAction === 'duplicate') {
            setBountiesInEvent((prev) => {
                const index = prev.findIndex((bounty) => bounty._id === selectedBounty._id);
                const updatedBounties = [...prev];
                updatedBounties.splice(index + 1, 0, {
                    ...newBounty,
                    creatorProfileImage: userProfile?.profileImage,
                    creatorUsername: userProfile?.userName,
                    projectTitle: selectedBounty?.projectTitle,
                }); // Insert the duplicate right after the original
                return updatedBounties;
            });
            // Perform duplication logic here
        } else if (modalAction === 'delete') {
            setBountiesInEvent((prev) => prev.filter((bounty) => bounty._id !== selectedBounty._id));
        } else if (modalAction === 'request') {
            setBountiesInEvent((prev) =>
                prev.map((bounty) => {
                    if (bounty._id === selectedBounty._id) {
                        return { ...bounty, status: 'Creation Requested' };
                    }
                    return bounty;
                })
            );
        } else if (modalAction === 'start') {
            setBountiesInEvent((prev) =>
                prev.map((bounty) => {
                    if (bounty._id === selectedBounty._id) {
                        return { ...bounty, status: 'Live and Published' };
                    }
                    return bounty;
                })
            );
        } else if (modalAction === 'onBoard') {
            setBountiesInEvent((prev) =>
                prev.map((bounty) => {
                    if (bounty._id === newBounty?.onboardingBounty) {
                        return { ...bounty, isOnboardingBounty: true };
                    }
                    return { ...bounty, isOnboardingBounty: false };
                })
            );
        }
        handleCloseModal();
    };
    const updateRenderData = (type, id) => {
        if (type === 'delete') {
            setBountiesInEvent((initial) => initial.filter((bounty) => bounty._id !== id));
        }
        // if (type === 'duplicate') setBounties(initial);
    };
    if (searchString)
        filteredData = filteredData.filter(({ _id, status, title, projectTitle }) =>
            title
                .toLowerCase()
                .includes(searchString.toLowerCase() || projectTitle.toLowerCase().includes(searchString.toLowerCase()))
        );

    // /organizations/6609a9cae9b44a9794cb323d/view-bounties/66bade3a110b13247911870e

    console.log('bountiesInEvent', bountiesInEvent);
    const hasOnboardingBounty = bountiesInEvent.filter((b) => b?.isOnboardingBounty);

    console.log('bountiesInEvent', bountiesInEvent, hasOnboardingBounty);
    return (
        <>
            <Menus>
                <Table className="gap-8 " cols="grid-cols-tableBountiesProject">
                    <Table.Description
                        description="Bounties in the Event"
                        primaryBtn="+ Add Bounty"
                        clickOnPrimary={() => navigate(`/${id}/createBounty?eventName=${eventTitle}`)}
                    />
                    <div className="flex items-center w-[441px] gap-3 py-2 px-3 mb-4 border border-borderColor rounded-lg ">
                        <img className="w-6 h-6  object-cover" src={search} alt="" />
                        <Input
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            placeholder="Search Bounty Title or Project Title"
                            className=" !w-full !border-0 !px-0 !py-0 outline-none "
                            containerClass="flex-1"
                            type="text"
                        />
                    </div>
                    <Table.Header>
                        <p className="text-sm justify-self-start  font-semibold text-secondaryText ">Bounty ID</p>
                        <p className="text-sm  font-semibold text-secondaryText ">Bounty Title</p>
                        {/* <p className="text-sm  font-semibold text-secondaryText ">Project Title</p> */}
                        <p className="text-sm  font-semibold text-secondaryText ">Status</p>
                        <p className="text-sm  font-semibold text-secondaryText ">Created By</p>
                    </Table.Header>
                    {!hasOnboardingBounty.length > 0 && (
                        <Table.Row rowClassName={'bg-[#F9F9F9] pt-5 pb-5 '}>
                            <div></div>
                            <div>
                                <Button
                                    onClick={() =>
                                        navigate(`/${id}/createBounty?eventName=${eventTitle}&eventId=${eventId}`)
                                    }
                                    className="text-end"
                                    size="none"
                                    variant="transparent"
                                >
                                    + Add Registration Bounty
                                </Button>
                            </div>
                        </Table.Row>
                    )}
                    {filteredData.length > 0 ? (
                        filteredData?.map((bounty) => (
                            <Table.Row key={bounty._id} rowClassName="relative">
                                <p
                                    onClick={() => navigate(`/organizations/${id}/view-bounties/${bounty._id}`)}
                                    className="text-primaryColor self-center cursor-pointer truncate w-full uppercase justify-self-start font-medium"
                                >
                                    {bounty._id}
                                </p>
                                <p className="self-center">{bounty.title}</p>
                                {/* <p>{bounty.projectTitle}</p> */}

                                <PopOver
                                    rounded="rounded-lg "
                                    className="px-2 py-1 leading-3 self-center   "
                                    textClassName="text-xs font-semibold"
                                    bg={BOUNTY_STATUS_TAG_COLOR[bounty.status]?.bg}
                                    textColor={BOUNTY_STATUS_TAG_COLOR[bounty.status]?.text}
                                    borderColor={BOUNTY_STATUS_TAG_COLOR[bounty.status]?.border}
                                    text={bounty.status}
                                    active={
                                        bounty.status === 'Live and Published' ||
                                        bounty.status === 'Live and Unpublished'
                                            ? 'Live'
                                            : bounty.status
                                    }
                                />
                                <div className="flex items-center  gap-3 ">
                                    <AvatarImage w="w-8" h="h-8" url={bounty.creatorProfileImage} />
                                    <p className="text-lightBlack font-medium ">{bounty.creatorUserName}</p>
                                </div>

                                <Menus.Toggle id={bounty._id} verticalOffset={15} />
                                {/* <Menus.List id={bounty._id}>
                            <Menus.Button icon={<EditMenuIcon />}>Edit</Menus.Button>
                            <Modal>
                                <Modal.Button
                                    className="flex gap-2 cursor-pointer items-center"
                                    opens={'duplicatebounty'}
                                >
                                    <Menus.Button icon={<DuplicateMenuIcon />}>Duplicate</Menus.Button>
                                </Modal.Button>
                                <Modal.Window name={'duplicatebounty'}>
                                    <DuplicateBountyModal bountyName={bounty.title} bountyId={bounty._id} />
                                </Modal.Window>
                            </Modal>

                            <Menus.Button icon={<PublishMenuIcon />}>Publish</Menus.Button>
                            <Menus.Button icon={<RequestTreasuryMenuIcon />}>Request Treaury Approval</Menus.Button>

                            <Modal>
                                <Modal.Button className="flex gap-2 cursor-pointer items-center" opens={'deletebounty'}>
                                    <Menus.Button icon={<DeleteIcon />}>Delete</Menus.Button>
                                </Modal.Button>
                                <Modal.Window name={'deletebounty'}>
                                    <DeleteBountyModal
                                        onDelete={updateRenderData}
                                        bountyName={bounty.title}
                                        bountyId={bounty._id}
                                    />
                                </Modal.Window>
                            </Modal>
                        </Menus.List> */}
                                <Menus.List id={bounty._id}>
                                    {bounty?.isOnboardingBounty ? (
                                        <Menus.Button
                                            shouldCloseMenu={true}
                                            onClick={(fn) => {
                                                handleOpenModal('onBoard', bounty);
                                                fn();
                                            }}
                                            icon={<ToNormalBounty />}
                                        >
                                            Convert To <br /> Normal Bounty
                                        </Menus.Button>
                                    ) : (
                                        <Menus.Button
                                            shouldCloseMenu={true}
                                            onClick={(fn) => {
                                                handleOpenModal('onBoard', bounty);
                                                fn();
                                            }}
                                            icon={<ToRegisterBounty />}
                                        >
                                            Convert To <br /> Registration&nbsp;Bounty
                                        </Menus.Button>
                                    )}
                                    <Menus.Button
                                        shouldCloseMenu={true}
                                        onClick={(fn) => {
                                            handleOpenModal('duplicate', bounty);
                                            fn();
                                        }}
                                        icon={<DuplicateMenuIcon />}
                                    >
                                        Duplicate
                                    </Menus.Button>
                                    {/* Delete Action */}
                                    {bounty.status === 'Draft' && (
                                        <>
                                            <Menus.Button
                                                onClick={(fn) => {
                                                    handleOpenModal('delete', bounty);
                                                    fn();
                                                }}
                                                icon={<DeleteIcon />}
                                            >
                                                Delete
                                            </Menus.Button>

                                            <Menus.Button
                                                onClick={(fn) => {
                                                    navigate(`/${id}/createBounty?bountyId=${bounty._id}`);
                                                    fn();
                                                }}
                                                icon={<EditMenuIcon />}
                                            >
                                                Edit
                                            </Menus.Button>
                                            <Menus.Button
                                                onClick={(fn) => {
                                                    handleOpenModal('start', bounty);
                                                    fn();
                                                }}
                                                icon={<PublishMenuIcon />}
                                            >
                                                Publish
                                            </Menus.Button>
                                        </>
                                    )}

                                    {bounty.status === 'Live and Published' && (
                                        <>
                                            <Menus.Button
                                                onClick={() => handleTogglePublish('Live and Unpublished', bounty._id)}
                                                icon={<PublishMenuIcon />}
                                            >
                                                Unpublish
                                            </Menus.Button>
                                            <Menus.Button
                                                onClick={(fn) => {
                                                    toast.error('Please unpublish the bounty first');
                                                    fn();
                                                }}
                                                icon={<EditMenuIcon />}
                                            >
                                                Edit
                                            </Menus.Button>
                                        </>
                                    )}
                                    {bounty.status === 'Live and Unpublished' && (
                                        <>
                                            <Menus.Button
                                                onClick={() => handleTogglePublish('Live and Published', bounty._id)}
                                                icon={<PublishMenuIcon />}
                                            >
                                                Publish
                                            </Menus.Button>
                                            <Menus.Button
                                                onClick={(fn) => {
                                                    navigate(`/${id}/createBounty?bountyId=${bounty._id}`);
                                                    fn();
                                                }}
                                                icon={<EditMenuIcon />}
                                            >
                                                Edit
                                            </Menus.Button>
                                        </>
                                    )}
                                </Menus.List>
                            </Table.Row>
                        ))
                    ) : (
                        <NoDataAvailable />
                    )}
                </Table>
            </Menus>
            {isModalOpen && (
                <div>
                    {modalAction === 'duplicate' && (
                        <DuplicateBountyModal
                            handleConfirmAction={handleConfirmAction}
                            bountyName={selectedBounty.title}
                            bountyId={selectedBounty._id}
                            close={handleCloseModal}
                        />
                    )}
                    {modalAction === 'onBoard' && (
                        <ToggleOnboardBountyModal
                            eventId={eventId}
                            handleConfirmAction={handleConfirmAction}
                            bountyName={selectedBounty.title}
                            bountyId={selectedBounty._id}
                            close={handleCloseModal}
                            isOnboardingBounty={selectedBounty?.isOnboardingBounty}
                        />
                    )}
                    {modalAction === 'delete' && (
                        <DeleteBountyModal
                            onDelete={handleConfirmAction}
                            bountyName={selectedBounty.title}
                            bountyId={selectedBounty._id}
                            close={handleCloseModal}
                        />
                    )}
                    {modalAction === 'start' && (
                        <StartBountyModal
                            handleConfirmAction={handleConfirmAction}
                            bountyName={selectedBounty.title}
                            bountyId={selectedBounty._id}
                            close={handleCloseModal}
                        />
                    )}
                    {modalAction === 'request' && (
                        <RequestTreasuryApprovalModal
                            handleConfirmAction={handleConfirmAction}
                            bountyName={selectedBounty.title}
                            bountyId={selectedBounty._id}
                            updatedTime={selectedBounty.updatedAt}
                            close={handleCloseModal}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default BountiesInEvent;
