import { useController } from 'react-hook-form';
import Input from '../../../ui/Input';

import IsSkippable from './IsSkippable';
import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';

const UploadFile = ({ register, index, errors, control, microtaskEditDetail }) => {
    const { isEdit } = useContext(BountiesContext);
    return (
        <div>
            <Input
                {...register(`microtasks.${index}.uploadFile_Title`, {
                    required: 'Please enter a title',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.uploadFile_Title || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.uploadFile_Title?.message}
                placeholder={'Title *'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 '}
            />
            <Input
                {...register(`microtasks.${index}.uploadFile_Description`, {
                    required: 'Please enter a description',
                })}
                type="transparent"
                {...(isEdit && { defaultValue: microtaskEditDetail?.uploadFile_Description || '' })}
                error={errors?.microtasks && errors?.microtasks[index]?.uploadFile_Description?.message}
                placeholder={'Task Description *'}
                transparentTextSize="text-sm"
                transparentFontWeight={'font-normal'}
                className={'w-[100%] mb-1 mt-2 '}
            />
            {/* <div className="flex gap-9 my-2 items-center ">
                <IsSkippable
                    selectedOption={skippableField.value}
                    onSelectOption={(value) => {
                        skippableField.onChange(value);
                    }}
                    options={['Yes', 'No']}
                />
            </div> */}
        </div>
    );
};

export default UploadFile;
