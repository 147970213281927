import { toast } from 'sonner';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';
import axios from 'axios';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const deleteEvent = async (eventId) => {
    try {
        const url = `${BASE_URL}/event/${eventId}`;

        const accessToken = await getAccessToken();

        const response = await axios.delete(url, getConfig(accessToken));

        return response.data;
    } catch (error) {
        toast.error(error?.response?.data?.message);
    }
};
